import { useEffect } from 'react';
import styles from './TableSummary.module.css'
import { useSelector, useDispatch } from 'react-redux';


const TableSummary = () => {

    //The amount of scenarios
    const totalScenarios = useSelector((state) => state.scenarioPerformance.totalScenarios);

    //The amount of scenario groups
    const totalScenarioGroups = useSelector((state) => state.scenarioPerformance.totalScenarioGroups);

    return (
        <div className={styles.tableSummaryWrapper}>
            <div className={styles.textWrapper}>{totalScenarioGroups} Detecting Solutions</div>
            <div className={styles.alertTypesWrapper}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8" fill="none" className={styles.bullet}>
                    <path fillRule="evenodd" clipRule="evenodd" d="M4 8C6.20914 8 8 6.20914 8 4C8 1.79086 6.20914 0 4 0C1.79086 0 0 1.79086 0 4C0 6.20914 1.79086 8 4 8Z" fill="url(#paint0_linear_3608_16925)"/>
                    <defs>
                        <linearGradient id="paint0_linear_3608_16925" x1="0" y1="4" x2="8" y2="4" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#A5F2A3"/>
                            <stop offset="1" stopColor="#4DC842"/>
                        </linearGradient>
                    </defs>
                </svg>

                Non Relevant Alerts

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8" fill="none" className={styles.bullet}>
                    <path fillRule="evenodd" clipRule="evenodd" d="M4 8C6.20914 8 8 6.20914 8 4C8 1.79086 6.20914 0 4 0C1.79086 0 0 1.79086 0 4C0 6.20914 1.79086 8 4 8Z" fill="url(#paint0_linear_3608_16928)"/>
                    <defs>
                        <linearGradient id="paint0_linear_3608_16928" x1="4" y1="0" x2="4" y2="8" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#FEE4A3"/>
                            <stop offset="1" stopColor="#F6BA5E"/>
                        </linearGradient>
                    </defs>
                </svg>

                Relevant Alerts

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8" fill="none" className={styles.bullet}>
                    <path fillRule="evenodd" clipRule="evenodd" d="M4 8C6.20914 8 8 6.20914 8 4C8 1.79086 6.20914 0 4 0C1.79086 0 0 1.79086 0 4C0 6.20914 1.79086 8 4 8Z" fill="url(#paint0_linear_3608_16931)"/>
                    <defs>
                        <linearGradient id="paint0_linear_3608_16931" x1="-0.145053" y1="6.39327" x2="7.65323" y2="1.89092" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#FDBCBC"/>
                            <stop offset="1" stopColor="#E46F6F"/>
                            <stop offset="1.0001" stopColor="#F58484"/>
                        </linearGradient>
                    </defs>
                </svg>

                SAR's

            </div>

            <div className={styles.totalAlerts}>
                <div className={styles.bigNumber}>{totalScenarios}</div>
                <div>Total alerts</div>
            </div>

            <div className={styles.totalAlerts}>
                <div className={styles.bigNumber}></div>
                <div></div>
            </div>
        </div>
    )

}


export default TableSummary;