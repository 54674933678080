import React, { useState, useEffect} from 'react'
import styles from './ScenariosTable.module.css'
import { DataGrid } from '@mui/x-data-grid';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import {
    fetchScenarioOverview,
    switchOpenedStatus,
    switchOpenedScenarioStatus,
    setCurrentPage,
    setTotalScenarios,
    setTotalScenariosGroups,
    setRequestedAtl,
    setRequestedBtl,
    setRequestedLogic,
    setOpenedGroups,
    fetchRecommendations,
    clearRecommendations,
    setRecommendationMessage,
    setOpenedScenarios
  } from '../../../features/scenario_overview/scenarioOverviewSlice';
    import {
        setSelectedSegment,
        setSelectedSubScenario,
        fetchSegments,
    } from '../../../features/atl_tool/atlToolSlice';
import BarComponent from '../horizontal_bar/HorizonBarComp';

const ScenPerformOverview = () => {

    const navigate = useNavigate(); // Para navegar programáticamente

    const dispatch = useDispatch();

    //All the scenario groups
    const scenarioGroups = useSelector((state) => state.scenarioPerformance.scenarioGroups);

    //The opened scenario groups
    const openedGroups = useSelector((state) => state.scenarioPerformance.openedGroups);

    //The opened detecton scenarios
    const openedScenarios = useSelector((state) => state.scenarioPerformance.openedScenarios);

    //The search query
    const searchQuery = useSelector((state) => state.scenarioPerformance.searchQuery);

    //The recommendations
    const recommendations = useSelector((state) => state.scenarioPerformance.recommendations);

    //Used for paging
    const currentPage = useSelector((state) => state.scenarioPerformance.currentPage);
    const pageSize = 5; // Limit to 5 rows per page

    //To determine the background of the individuel scenarios when hovered
    const [hoveredScenario, setHoveredScenario] = useState(-1);
    const [hoveredGroup, setHoveredGroup] = useState(-1);
    const [hoveredCustomerSegment, setHoveredCustomerSegment] = useState(-1);

    // Unique key to force re-render of DataGrid
    const [dataGridKey, setDataGridKey] = useState(0);

    const selectedYearMonthPairs = useSelector((state) => state.scenarioPerformance.selectedYearMonthPairs);

    //Get the scenarios
    useEffect(() => {
        dispatch(fetchScenarioOverview({year_month_pairs: selectedYearMonthPairs}))
        dispatch(setOpenedGroups(Array(0)))
        dispatch(setOpenedScenarios(Array(0)))
    }, []);

    const rows = scenarioGroups;

    // Filter the rows via the search query
    const filteredRows = rows
    .filter((row) =>
        ((row.Title).toLowerCase()).includes(searchQuery.toLowerCase())
      )
      .map((row) => {
        return {
          id: row['id'],
          Title: row['Title'],
          isUsingSegment: row['isUsingSegment'],
          alerts: row['alerts'],
          total_alerts: row['total_alerts'],
          childs: row['childs'],
        };
    });

    // Apply the correct sorting rule to the filtered results
    var selectedRows = [];
    const sortingCol = useSelector((state) => state.scenarioPerformance.selectedSortingRule)
    if (sortingCol === 'none'){
        selectedRows = filteredRows;
    }
    else if (sortingCol === 'name'){
        selectedRows = filteredRows.sort((a, b) =>
        {
            if (a.Title < b.Title){
                return -1;
            }
            if (a.Title > b.Title){
                return +1;
            }
            return 0;
        })
    }
    else if (sortingCol === 'alerts'){
        selectedRows = filteredRows.sort((a, b) => 
        {
            if ((a.alerts[2]/a.total_alerts) < (b.alerts[2]/b.total_alerts)){
                return +1;
            }
            if ((a.alerts[2]/a.total_alerts) > (b.alerts[2]/b.total_alerts)){
                return -1;
            }
            if ((a.alerts[1]/a.total_alerts) < (b.alerts[1]/b.total_alerts)){
                return +1;
            }
            if ((a.alerts[1]/a.total_alerts) > (b.alerts[1]/b.total_alerts)){
                return -1;
            }
            return 0;
        })
    }
    else if (sortingCol === 'total'){
        selectedRows = filteredRows.sort((a, b) =>
        {
            if (a.total_alerts < b.total_alerts){
                return -1;
            }
            if (a.total_alerts > b.total_alerts){
                return +1;
            }
            return 0;
        })
    }
    
    // Revert the sorting if needed 
    const revertSorting = useSelector((state) => state.scenarioPerformance.sortingRuleReverted)
    if (revertSorting){
        selectedRows = selectedRows.reverse();
    }

    // Open or close a row when clicked, also close all the scenarios in the group
    const handleRowClick = (params) => {
        dispatch(switchOpenedStatus(params.id));

        // close scenarios
        if (openedGroups.includes(params.id)) {
            for (const child of params.childs) {
                if (openedScenarios.includes(child.child_id)){
                    dispatch(switchOpenedScenarioStatus(child.child_id));
                }
            }
        }

        // Ensure there are no unnecessary recommendations fetched (if already fetched, don't fetch again)
        if (!recommendations.some(recommendation => params.childs.some(child => recommendation.scenario.scenario_id === child.child_id))) {
            for (const child of params.childs) {
                dispatch(fetchRecommendations({ scenario_id: child.child_id, subscenario_id: 0, year_month_pairs: selectedYearMonthPairs }));
            }
        }
    }

    const handleDsClick = (params) => {
        dispatch(switchOpenedScenarioStatus(params.child_id));
    }

    const handlebuttonClick = (child, label, reason, customer_segment=null) => {
        const lowerCaseLabel = label.toLowerCase();
        if (lowerCaseLabel === "atl") {
            dispatch(setRequestedAtl({scenario_id: child.child_id, customer_segment: customer_segment ? customer_segment : null}));
            dispatch(setRecommendationMessage(reason));
        } else if (lowerCaseLabel === "btl") {
            const group = scenarioGroups.find((item) => item.childs.some((child_l) => child_l.child_id === child.child_id));
            dispatch(setRequestedBtl(group.category_id));
            dispatch(setRecommendationMessage(reason));
        } else if (lowerCaseLabel === "logic") {
            const group = scenarioGroups.find((item) => item.childs.some((child_l) => child_l.child_id === child.child_id));
            dispatch(setRequestedLogic({category_id: group.category_id, scenario_id: child.child_id}));
            dispatch(setRecommendationMessage(reason));
        }
    }

    // Set the selected segment
    const handleCustomerSegmentClick = (segment, scenario) => {
        const transformedSegment = {
            id: segment.id,
            name: segment.Title,
            non_relevant_alerts: segment.alerts[0],
            relevant_alerts: segment.alerts[1],
            sar_alerts: segment.alerts[2],
        }
        goToAtl(transformedSegment, scenario);
    }

    const goToAtl = (segment, scenario) => {
        dispatch(setSelectedSubScenario(null));
        navigate(`/scenario_performance/${scenario.child_id}`)
        dispatch(fetchSegments({selectedScenarioId: scenario.child_id, selectedSubScenario: null, selectedYearState: new Date().getFullYear()}));
        dispatch(setSelectedSegment(segment));
    }

    // Generate the needed html for the recommendation button
    const recomendationHtml = (child, recommendation, customer_segment=null) => {
        let label = recommendation.recommendation;
        let style = { 
            background: 'linear-gradient(92deg, #8B83F6 0%, #AFAAF6 100%)', 
            borderRadius: 6, 
            justifyContent: 'center', 
            alignItems: 'center', 
            display: 'inline-flex', 
            padding: "8px 10px",
            gap: 8.58,
            with: 'auto',
            maxWidth: '120px',
            minWidth: '120px',
            height: 'auto'
        };
        let svg = null;

        if (label.toLowerCase() === "atl"){
            svg =                     
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                <g id="ATL Icon">
                <path id="Vector" d="M7.91992 8C8.19606 8 8.41992 7.77614 8.41992 7.5C8.41992 7.22386 8.19606 7 7.91992 7C7.64378 7 7.41992 7.22386 7.41992 7.5C7.41992 7.77614 7.64378 8 7.91992 8Z" fill="white" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path id="Vector_2" d="M18.9199 6C19.1961 6 19.4199 5.77614 19.4199 5.5C19.4199 5.22386 19.1961 5 18.9199 5C18.6438 5 18.4199 5.22386 18.4199 5.5C18.4199 5.77614 18.6438 6 18.9199 6Z" fill="white" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path id="Vector_3" d="M11.9199 12C12.1961 12 12.4199 11.7761 12.4199 11.5C12.4199 11.2239 12.1961 11 11.9199 11C11.6438 11 11.4199 11.2239 11.4199 11.5C11.4199 11.7761 11.6438 12 11.9199 12Z" fill="white" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path id="Vector_4" d="M7.91992 17C8.19606 17 8.41992 16.7761 8.41992 16.5C8.41992 16.2239 8.19606 16 7.91992 16C7.64378 16 7.41992 16.2239 7.41992 16.5C7.41992 16.7761 7.64378 17 7.91992 17Z" fill="white" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path id="Vector_5" d="M17.9199 15C18.1961 15 18.4199 14.7761 18.4199 14.5C18.4199 14.2239 18.1961 14 17.9199 14C17.6438 14 17.4199 14.2239 17.4199 14.5C17.4199 14.7761 17.6438 15 17.9199 15Z" fill="white" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path id="Vector_6" d="M3.41992 3V19C3.41992 19.5304 3.63064 20.0391 4.00571 20.4142C4.38078 20.7893 4.88949 21 5.41992 21H21.4199" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                </svg>; 
        }

        else if (label.toLowerCase() === "btl"){
            svg =
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                <g id="BTL Icon">
                <path id="Vector" d="M15.4199 2H6.41992C5.88949 2 5.38078 2.21071 5.00571 2.58579C4.63064 2.96086 4.41992 3.46957 4.41992 4V20C4.41992 20.5304 4.63064 21.0391 5.00571 21.4142C5.38078 21.7893 5.88949 22 6.41992 22H18.4199C18.9504 22 19.4591 21.7893 19.8341 21.4142C20.2092 21.0391 20.4199 20.5304 20.4199 20V7L15.4199 2Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path id="Vector_2" d="M14.4199 2V6C14.4199 6.53043 14.6306 7.03914 15.0057 7.41421C15.3808 7.78929 15.8895 8 16.4199 8H20.4199" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path id="Vector_3" d="M8.41992 18V16" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path id="Vector_4" d="M12.4199 18V14" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path id="Vector_5" d="M16.4199 18V12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                </svg>; 
        }

        else if (label.toLowerCase() === "logic"){
            svg = 
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                <g id="Logic Icon">
                <path id="Vector" d="M22.0607 3.63882L20.7807 2.35882C20.6682 2.24514 20.5342 2.15489 20.3866 2.0933C20.239 2.03171 20.0806 2 19.9207 2C19.7608 2 19.6024 2.03171 19.4548 2.0933C19.3072 2.15489 19.1732 2.24514 19.0607 2.35882L2.7807 18.6388C2.66702 18.7513 2.57677 18.8853 2.51518 19.0329C2.45359 19.1805 2.42188 19.3389 2.42188 19.4988C2.42188 19.6588 2.45359 19.8171 2.51518 19.9648C2.57677 20.1124 2.66702 20.2463 2.7807 20.3588L4.0607 21.6388C4.17252 21.7537 4.30623 21.8451 4.45394 21.9074C4.60165 21.9698 4.76036 22.0019 4.9207 22.0019C5.08104 22.0019 5.23974 21.9698 5.38745 21.9074C5.53516 21.8451 5.66888 21.7537 5.7807 21.6388L22.0607 5.35882C22.1756 5.247 22.2669 5.11329 22.3293 4.96558C22.3917 4.81787 22.4238 4.65916 22.4238 4.49882C22.4238 4.33849 22.3917 4.17978 22.3293 4.03207C22.2669 3.88436 22.1756 3.75065 22.0607 3.63882Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path id="Vector_2" d="M14.4199 7L17.4199 10" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path id="Vector_3" d="M5.41992 6V10" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path id="Vector_4" d="M19.4199 14V18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path id="Vector_5" d="M10.4199 2V4" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path id="Vector_6" d="M7.41992 8H3.41992" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path id="Vector_7" d="M21.4199 16H17.4199" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path id="Vector_8" d="M11.4199 3H9.41992" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                </svg>;
        }

        // When recommendation is no recommendation, return an empty div
        else {
            return <></>
        }
            
        return (
            <>
                {/* <NavLink to={path}> */}
                <div style={style} onClick={(e)=>{handlebuttonClick(child, label, recommendation.recommendation_reason, customer_segment); e.stopPropagation();}}>
                    {svg}
                    <div className={styles.btnText}>
                        {label}
                    </div>
                </div>
                {/* </NavLink> */}
            </>
        )
    } 

    const getRecomendationButtonScenario = (child) => {

        const recommendation = recommendations.find(recommendation => recommendation.scenario.scenario_id === child.child_id);

        if (recommendation) {
            return recomendationHtml(child, recommendation.scenario.recommendations[0]);
        }

        // If no recommendation is found, return an empty div
        // This should not happen; only when the fetching is loading or failed
        return (
            <></>
        )

    }

    const getRecomendationButtonCS = (child, customer_segment) => {
        const recommendation = recommendations.find(recommendation => recommendation.scenario.scenario_id === child.child_id);

        const csRecommendation = recommendation ? recommendation.customer_segments.find(segment => segment.customer_segment_id === customer_segment.id): null;

        if (csRecommendation) {
            return recomendationHtml(child, csRecommendation.recommendations[0], customer_segment);
        }

        // If no recommendation is found, return an empty div
        // This should not happen; only when the fetching is loading or failed
        return (
            <></>
        )
    }
     
    //Change the page of the table
    const handlePageChange = (newPage) => {
        dispatch(setCurrentPage(newPage))
        setHoveredGroup(null);
        setDataGridKey((prevKey) => prevKey + 1); // Force re-render of DataGrid
    }

    const totalAmountOfDS = useSelector((state) => state.scenarioPerformance.totalScenarioGroups)

    useEffect(() => {
     
        setDataGridKey((prevKey) => prevKey + 1); // Force re-render of DataGrid

    }, [sortingCol, revertSorting, totalAmountOfDS]);

    // Used for change in background color when hovered
    const handleScenarioHover = (child_id) => {
        setHoveredScenario(child_id);
    }
    const handleGroupHover = (id) => {
        setHoveredGroup(id);
    }
    const handleCustomerSegmentHover = (id) => {
        setHoveredCustomerSegment(id);
    }

    //Get the row height for a given row
    const getRowHeight = (params) => {
        // include 2 for the border at the bottom
        if (openedGroups.includes(params.id)){
            var result = ((params.childs.length + 1) * 96) + 2;

            params.childs.map((child) => {
                if (openedScenarios.includes(child.child_id)) {
                    child.customer_segments.map(() => {
                        result += 96;
                    });
                }
            })

            return result;
        }
        else {
            return 96 + 2;
        }
    }

    const getHighestAlertCountChild = (childs) => {
        var highest = 0;
        childs.map((child) => {
            if (child.total_alerts > highest){
                highest = child.total_alerts;
            }
        });
        return highest;
    }

    const getHighestAlertCount = (params) => {
        var highest = 0;
        params.map((child) => {
            if (child.total_alerts > highest){
                highest = child.total_alerts;
            }
        });
        return highest;
    }

    //Create all the page numbers
    const createPageNumbers = () => {
        const totalPages = Math.ceil(filteredRows.length / pageSize);
        const pageNumbers = [];
        const maxPagesToShow = 5;
        let startPage = Math.max(1, currentPage + 1 - Math.floor(maxPagesToShow / 2));
        let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
    
        if (endPage - startPage < maxPagesToShow - 1) {
          startPage = Math.max(1, endPage - maxPagesToShow + 1);
        }
    
        for (let i = startPage; i <= endPage; i++) {
          pageNumbers.push(i);
        }
    
        if (startPage > 1) {
          pageNumbers.unshift(1, '...');
        }
        if (endPage < totalPages) {
          pageNumbers.push('...', totalPages);
        }
    
        return pageNumbers;
      };

    //Filter for the right page
    const paginatedRows = filteredRows.slice(currentPage * pageSize, (currentPage + 1) * pageSize);

    //Set the slice variables
    useEffect (() => {
        dispatch(setTotalScenariosGroups(selectedRows.length));
        var total = 0;
        selectedRows.map((group) => total += group.total_alerts);
        dispatch(setTotalScenarios(total));
      }, [selectedRows]);

    //Get the total amount of pages
    const totalPages = Math.ceil(filteredRows.length / pageSize);

    //Decide the rendering of the columns

    //Column 1 (name)
    const columns = [
    { field: 'Title', headerName: 'Title', resizable: false, flex: 384, transition: 'all 3s ease', renderCell: (params) =>     
    <div className={`${styles.rowWrapper1} ${openedGroups.includes(params.id) ? styles.expanded : ''}`} 
        onClick={() => handleRowClick(params.row)} 
        onMouseEnter={() => handleGroupHover(params.id)}>
        <div className={`${styles.nameWrapper} ${openedGroups.includes(params.id) ? styles.expandedHead : ''}`} >
            <div className={`${styles.svgLogo} ${openedGroups.includes(params.id) ? styles.expandedSvgLogo : ''}`}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M9 18L15 12L9 6" stroke="#7A5AF8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
            <div className={styles.scenNameParent}>{params.value}</div>
        </div>
    {
        <>
        {params.row.childs.map((child) => (
            <div className={`${styles.nameWrapperChild}`}
                onClick={(e) => {handleDsClick(child); e.stopPropagation()}}>
                <div className={`${styles.scenNameChild} ${(hoveredScenario === child.child_id && hoveredGroup === params.id) ? styles.hoveredScenario : ''} 
                    ${openedScenarios.includes(child.child_id) ? styles.hoveredScenario : ''}`}
                    onMouseEnter={() => handleScenarioHover(child.child_id)} 
                    onMouseLeave={() => handleScenarioHover(null)}>
                    <div className={`${styles.svgLogo} ${openedScenarios.includes(child.child_id) ? styles.expandedSvgLogo : ''}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M9 18L15 12L9 6" stroke="#7A5AF8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>    
                    <p className={styles.textEllipsis}>
                        {child.Title}
                    </p>
                </div>
                {openedScenarios.includes(child.child_id) && child.customer_segments.map((customer_segment) => (
                    <div className={`${styles.customerSegmentName}
                        ${(hoveredCustomerSegment === customer_segment.id && hoveredGroup === params.id) ? styles.hoveredScenario : ''}`}
                        onMouseEnter={() => handleCustomerSegmentHover(customer_segment.id)}
                        onMouseLeave={() => handleCustomerSegmentHover(null)}
                        onClick={(e) => {handleCustomerSegmentClick(customer_segment, child); e.stopPropagation()}}>
                        {customer_segment.Title}
                    </div>
                ))}
            </div>
            ))}
        </>
    }
    </div>

    //Column 2 (alerts)
    },
    { field: 'alerts', headerName: 'Alerts Graph', flex: 615, renderCell: (params) => 
    <div className={`${styles.rowWrapper2} ${openedGroups.includes(params.id) ? styles.expanded : ''}`} 
        onClick={() => handleRowClick(params.row)} 
        onMouseEnter={() => handleGroupHover(params.id)}>
        <div  className={`${styles.barComponentWrapper} ${openedGroups.includes(params.id) ? styles.expandedHead : ''}`}>
            <div className={styles.customerSegmentBarComp}>
                <BarComponent highestScaled={true} isOpened={-1} alerts={params.formattedValue} highestAlertCount={getHighestAlertCount(paginatedRows)} key={totalAmountOfDS}/>
            </div>
        </div>
    {
        <>
            {params.row.childs.map((child) => (
                <div className={`${styles.barComponentWrapper}`} 
                    onClick={(e) => {handleDsClick(child); e.stopPropagation()}}>
                    <div className={`${styles.customerSegmentBarComp} ${(hoveredScenario === child.child_id && hoveredGroup === params.id) ? styles.hoveredScenario : ''}
                        ${openedScenarios.includes(child.child_id) ? styles.hoveredScenario : ''}`}
                        onMouseEnter={() => handleScenarioHover(child.child_id)} 
                        onMouseLeave={() => handleScenarioHover(null)}>
                        <BarComponent highestScaled={false}  highestAlertCount={getHighestAlertCountChild(params.row.childs)} isOpened={openedGroups.includes(params.row.id)} alerts={child.alerts}/>
                    </div>

                    {openedScenarios.includes(child.child_id) && child.customer_segments.map((customer_segment) => (
                        <div className={`${styles.customerSegmentBarComp}
                            ${(hoveredCustomerSegment === customer_segment.id && hoveredGroup === params.id) ? styles.hoveredScenario : ''}`}
                            onMouseEnter={() => handleCustomerSegmentHover(customer_segment.id)}
                            onMouseLeave={() => handleCustomerSegmentHover(null)}
                            onClick={(e) => {handleCustomerSegmentClick(customer_segment, child); e.stopPropagation()}}>
                            <BarComponent highestScaled={false} isOpened={openedScenarios.includes(child.child_id)} alerts={customer_segment.alerts}/>
                        </div>
                    ))}
                </div>
          ))}
        </>
    }
    </div>
    },
    
    //Column 3 (total)
    { field: 'total_alerts', headerName: 'Total Alerts', flex: 237, renderCell: (params) => 
    <div className={`${styles.rowWrapper2} ${openedGroups.includes(params.id) ? styles.expanded : ''}`}
        onClick={() => handleRowClick(params.row)} 
        onMouseEnter={() => handleGroupHover(params.id)}>
        <div className={`${styles.totalAlertsWrapper2} ${openedGroups.includes(params.id) ? styles.expandedHead : ''}`}>
            <div className={styles.textBox}>
                <div className={styles.bigNumber}>{params.row.total_alerts}</div>
                <div className={styles.otherText}>Alerts</div>
            </div>
           
        </div>
    {
        <>
            {params.row.childs.map((child) => (
                <div className={`${styles.totalAlertsWrapperChilds}`} 
                    onClick={(e) => {handleDsClick(child); e.stopPropagation()}}>
                    <div className={`${styles.textBoxChild}  ${(hoveredScenario === child.child_id && hoveredGroup === params.id) ? styles.hoveredScenario : ''}
                        ${openedScenarios.includes(child.child_id) ? styles.hoveredScenario : ''}`}
                        onMouseEnter={() => handleScenarioHover(child.child_id)} 
                        onMouseLeave={() => handleScenarioHover(null)}>
                        <div className={styles.bigNumber}>{child.total_alerts}</div>
                        <div className={styles.otherText}>Alerts</div>
                    </div>
                    {openedScenarios.includes(child.child_id) && child.customer_segments.map((customer_segment) => (
                        <div className={`${styles.textBoxChild}
                            ${(hoveredCustomerSegment === customer_segment.id && hoveredGroup === params.id) ? styles.hoveredScenario : ''}`}
                            onMouseEnter={() => handleCustomerSegmentHover(customer_segment.id)}
                            onMouseLeave={() => handleCustomerSegmentHover(null)}
                            onClick={(e) => {handleCustomerSegmentClick(customer_segment, child); e.stopPropagation()}}>
                            <div className={styles.bigNumber}>{customer_segment.total_alerts}</div>
                            <div className={styles.otherText}>Alerts</div>
                        </div>
                    ))}
                </div>
            ))}
        </>
    }
    </div>
    },

    // Column 4 (recomendation)
    { field: 'recomendation', headerName: 'Recomendation', flex: 237, renderCell: (params) => 
        <div className={`${styles.rowWrapper3} ${openedGroups.includes(params.id) ? styles.expanded : ''}`}
            onClick={() => handleRowClick(params.row)} 
            onMouseEnter={() => handleGroupHover(params.id)}>
            <div  className={`${styles.totalAlertsWrapper} ${openedGroups.includes(params.id) ? styles.expandedHead : ''}`} style={{ justifyContent:"center" }}>
                <div className={styles.textBox}  style={{ textAlign:"right" , gap:"10px", }}>
                    {openedGroups.includes(params.id) && 
                    <>
                     <div className={styles.otherText}>Recomendation</div>
                     <div className={styles.otherText}>
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g id="arrow-down">
                        <path id="Icon" d="M11.998 5V19M11.998 19L18.998 12M11.998 19L4.99805 12" stroke="#7A5AF8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        </svg>
                    </div>
                     </>
                    }
                </div>
            </div>

            {
                <>
                    {params.row.childs.map((child) => (
                        <div className={`${styles.recomendationWrapper}`} 
                            style={{ justifyContent:"right" }} >
                            <div className={`${styles.recomendationBox} ${(hoveredScenario === child.child_id && hoveredGroup === params.id) ? styles.hoveredScenario : ''}
                                ${openedScenarios.includes(child.child_id) ? styles.hoveredScenario : ''}`}
                                onMouseEnter={() => handleScenarioHover(child.child_id)} 
                                onMouseLeave={() => handleScenarioHover(null)}
                                onClick={(e) => e.stopPropagation()}>
                                <div className={styles.otherText}>
                                    {getRecomendationButtonScenario(child)}
                                </div>
                            </div>
                            {openedScenarios.includes(child.child_id) && child.customer_segments.map((customer_segment) => (
                                <div className={`${styles.recomendationBox}
                                    ${(hoveredCustomerSegment === customer_segment.id && hoveredGroup === params.id) ? styles.hoveredScenario : ''}`}
                                    onMouseEnter={(e) => {handleCustomerSegmentHover(customer_segment.id)}}
                                    onMouseLeave={() => handleCustomerSegmentHover(null)}
                                    onClick={(e) => {handleCustomerSegmentClick(customer_segment, child); e.stopPropagation()}}> 
                                    {getRecomendationButtonCS(child, customer_segment)}
                                </div>
                            ))}
                        </div>
                    ))}
                </>
            }
        </div>
        },
    

];
    
    // Render the DataGrid
    return (
        <>
        <div className={styles.gridWrapper}>
            <DataGrid key={dataGridKey} rows={paginatedRows} columns={columns} pageSize={5} getRowHeight={(params) => getRowHeight(params.model)}
            hideFooterSelectedRowCount
            autoHeight
            disableColumnMenu
            hideFooter
            hideScrollBar
            disableRowSelectionOnClick={true}
            scrollbarSize={0}
            sx={{
                '&, [class^=MuiDataGrid]': { 
                    border: 'none' ,
                }, // Hides outside border of table
                '&, .MuiDataGrid-virtualScrollerContent': {
                    transition: 'height 0.5s ease',
                },
                '& .MuiDataGrid-scrollbar': {
                    width: 0, // Removes scrollbar
                },
                '& .MuiDataGrid-scrollbarFiller': {
                    display: 'none' // Removes the filler of the scrollbar
                },
                '& .MuiDataGrid-cell': {
                    padding: 0,
                    border: '0px',

                    transition: 'all 0.5s ease',
                },
                '& .MuiDataGrid-cell:hover': {
                    cursor: 'pointer',
                },
                '& .MuiDataGrid-cell:focus': {
                    outline: 'none'
                },
                '& .MuiDataGrid-filler': {
                    display: 'none',
                },
                '& .MuiDataGrid-columnHeader': {
                    display: 'none',
                },
                '& .MuiDataGrid-row': {
                    borderRadius: '0px',
                    transition: 'all 0.5s ease',
                    overflow: 'hidden',
                },
                '& .MuiDataGrid-row:hover': {
                    backgroundColor: '#f9f5ff',
                },
                '& .MuiDataGrid-root': {
                    border: 'none',
                }
            }}/>
        </div>


        <div className={styles.pagination}>
            <button
                className={styles.paginationButton}
                style={{ marginLeft: "24px" }}
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 0}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 21" fill="none">
                    <path d="M15.8327 10.1693H4.16602M4.16602 10.1693L9.99935 16.0026M4.16602 10.1693L9.99935 4.33594" stroke="#6941C6" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                Previous
            </button>
            <div className={styles.pageNumbers}>
                {createPageNumbers().map((number, index) => (
                <button
                    key={index}
                    onClick={() => {
                    if (number !== '...') {
                        handlePageChange(number - 1);
                    }
                    }}
                    disabled={number === '...'}
                    className={(currentPage === number - 1) ? styles.activePage : styles.unactivePage}
                >
                    {number}
                </button>
                ))}
            </div>
            <button
            className={styles.paginationButton} style={{ marginRight: "24px" }}
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage >= totalPages - 1}
            >
            Next
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 21" fill="none">
              <path d="M4.16602 10.1693H15.8327M15.8327 10.1693L9.99935 4.33594M15.8327 10.1693L9.99935 16.0026" stroke="#6941C6" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            </button>
        </div>
        </>

    )

}


export default ScenPerformOverview;