import { useDispatch, useSelector } from 'react-redux';
import styles from './CommentTable.module.css'
import React, { useState, useEffect, useRef } from 'react';
import NameBadge from '../all_alert_table/name_badge/NameBadge.js';
import { addComment } from '../../../features/overlap_analysis/overlapAnalysisSlice.js';
import { useTimeout } from '@mui/x-data-grid/internals';

const CommentTable = () => {
  const dispatch = useDispatch();

  const { overlapData, selectedOverlapAlertId, selectedSegment, alertInfoType } = useSelector((state) => state.overlapAnalysis);
  const selectedOverlap = overlapData.find((overlap) => overlap['Alert ID'] === "A00000002")

  const handleRowClick = () => {
    //TODO
    return ;
  }

  const handleDeleteRow = () => {
    //TODO
    return ;
  }

  const [currentPage, setCurrentPage] = useState(1);
  const [addingComment, setAddingComment] = useState(false);
  const [comment, setComment] = useState('');
  const [recentlyAddedComment, setRecentlyAddedComment] = useState(false);

  const rows = selectedOverlap['Audit Log'];
  const rowsPerPage = 7;
  const totalPages = !selectedOverlap ? null : Math.ceil(rows.length / rowsPerPage);
  const currentRows = !selectedOverlap ? null : rows.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  
  const handleAddComment = () => {
    dispatch(addComment({user: 'Matias Daneels', comment: comment}));
    setCurrentPage(1);
    var myDiv = document.getElementById('table');

    myDiv.scrollTop = 0;
    setAddingComment(false);
    setRecentlyAddedComment(true);
    setTimeout(() => {
      setRecentlyAddedComment(false)}, 3000);
  }

  const createPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;
    let startPage = Math.max(1, currentPage + 1 - Math.floor(maxPagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    if (endPage - startPage < maxPagesToShow - 1) {
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    if (startPage > 1) {
      pageNumbers.unshift(1, '...');
    }
    if (endPage < totalPages) {
      pageNumbers.push('...', totalPages);
    }

    return pageNumbers;
  };

  return (
    <>
    <div id='table' className={styles.optimisationTable} style={{maxHeight: addingComment ? '25vh' : '40vh'}}>
      <div style={{ height: '100%'}}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.headerCell} style={{ width: '33.33%' }}> 
                User
              </th>
              <th className={styles.headerCell} style={{ width: '33.33%' }}>
                Comment
              </th>
              <th className={styles.headerCell} style={{ width: '33.33%' }}>
                Date
              </th>
            </tr>
          </thead>
          <tbody>
            {currentRows.map((row, index) => (
              <>
                <tr
                key={index}
                className={`
                  ${styles.row}
                  ${styles.normalRow}`}
                onClick={() => {
                    handleRowClick(index, row);
                }}
              >

                <td className={`${(index === 0 && recentlyAddedComment) ? styles.newComment : styles.cell}`}><NameBadge name={row.User} size={'32px'}/></td>
                <td className={`${(index === 0 && recentlyAddedComment) ? styles.newComment : styles.cell}`}>{row.Comment}</td>
                <td className={`${(index === 0 && recentlyAddedComment) ? styles.newComment : styles.cell}`} style={{ display: 'flex'}}>
                  <div>
                    {row.Date}
                  </div>

                  {recentlyAddedComment === true && index === 0 && (
                    <svg width={30} className={`${(index === 0 && recentlyAddedComment) ? styles.newCheckMark : styles.checkMark}`} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 24 24" id="Layer_1" version="1.0" viewBox="0 0 24 24" xmlSpace="preserve">
                      <polyline class={styles.path} fill="none" points="20,6 9,17 4,12" stroke-miterlimit="10" stroke-width="2" />
                    </svg>
                  )} 
                  
                </td>

              </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>
    </div>

    <div className={styles.footer}>
      {!addingComment && (
        <div className={styles.addCommentBox}>
          <div className={styles.bigButton} onClick={() => setAddingComment(true)}>
              Add a new comment
          </div>
        </div>
      )}

      {addingComment && (
        <div className={styles.addingCommentWrapper}>

          <div className={styles.commentInputWrapper}> 

            <div className={styles.textHolder}>
              Comment
            </div>
            <div className={styles.commentInputBox}>
              <textarea className={styles.commentInput} onChange={(e) => setComment(e.target.value)}></textarea>
            </div>

          </div>

          <div className={styles.bigButton} onClick={() => handleAddComment()}>
            Submit
          </div>

        </div>
      )}

      <div className={styles.pagination}>
        <button
            className={styles.paginationButton}
            style={{ marginLeft: "24px" }}
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 21" fill="none">
            <path d="M15.8327 10.1693H4.16602M4.16602 10.1693L9.99935 16.0026M4.16602 10.1693L9.99935 4.33594" stroke="#6941C6" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          Previous
        </button>
        
        <div className={styles.pageNumbers}>
          {createPageNumbers().map((number, index) => (
            <button
              key={index}
              onClick={() => {
                if (number !== '...') {
                  handlePageChange(number);
                }
              }}
              disabled={number === '...'}
              className={currentPage === number ? styles.activePage : styles.unactivePage}
            >
              {number}
            </button>
          ))}
        </div>
        
        <button
            className={styles.paginationButton} style={{ marginRight: "24px" }}
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage >= totalPages}
        >
          Next
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 21" fill="none">
            <path d="M4.16602 10.1693H15.8327M15.8327 10.1693L9.99935 4.33594M15.8327 10.1693L9.99935 16.0026" stroke="#6941C6" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
      </div>
    </div>
    </>
  )
}


export default CommentTable;