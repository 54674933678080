import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedSegment } from '../../../../features/atl_tool/atlToolSlice';
import styles from './SegmentSelection.module.css';

export default function BarComponent({ segment, height, showSAR, showRelevant, showNonRelevant, showGrayBar=false }) {
    const dispatch = useDispatch();
    const selectedSegment = useSelector((state) => state.atlTool.selectedSegment);
    const chartHeight = useSelector((state) => state.atlTool.chartHeight);

    const borderRadius = 11;

    const [nonRelevantHeight, setNonRelevantHeight] = useState(0);
    const [relevantHeight, setRelevantHeight] = useState(0);
    const [sarHeight, setSarHeight] = useState(0);
    const [emptyHeight, setEmptyHeight] = useState(height - 20);
    const selectedSubScenario = useSelector((state) => state.atlTool.selectedSubScenario);

    const [transition, setTransition] = useState("all 1s ease");

    const isEmpty = segment.non_relevant_alerts === 0 && segment.relevant_alerts === 0 && segment.sar_alerts === 0;

    useEffect(() => {
        // After a short delay, set the heights to trigger the animation
        setTransition("none");
        setNonRelevantHeight(0);
        setRelevantHeight(0);
        setSarHeight(0);

        const timeoutId = setTimeout(() => {
            setTransition("all 1s ease");
            const nonRelevantH = showNonRelevant ? Math.floor(height * (segment.non_relevant_alerts / chartHeight)) : 0;
            const relevantH = showRelevant ? Math.floor(height * (segment.relevant_alerts / chartHeight)) : 0;
            const sarH = showSAR ? Math.floor(height * (segment.sar_alerts / chartHeight)) : 0;

            setNonRelevantHeight(nonRelevantH);
            setRelevantHeight(relevantH);
            setSarHeight(sarH);
            setEmptyHeight(isEmpty ? height - 20 : 0);
        }, 300); // Adjust the delay as needed

        return () => clearTimeout(timeoutId); // Cleanup on unmount
    }, [segment, height, isEmpty, selectedSubScenario]);

    useEffect(() => {
        // Adjust heights when filters change
        const timeoutId = setTimeout(() => {
            setTransition("all 1s ease");
            const nonRelevantH = showNonRelevant ? Math.floor(height * (segment.non_relevant_alerts / chartHeight)) : 0;
            const relevantH = showRelevant ? Math.floor(height * (segment.relevant_alerts / chartHeight)) : 0;
            const sarH = showSAR ? Math.floor(height * (segment.sar_alerts / chartHeight)) : 0;

            setNonRelevantHeight(nonRelevantH);
            setRelevantHeight(relevantH);
            setSarHeight(sarH);
            setEmptyHeight(isEmpty ? height - 20 : 0);
        }, 300); // Adjust the delay as needed

        return () => clearTimeout(timeoutId); // Cleanup on unmount
    }, [showSAR, showRelevant, showNonRelevant, chartHeight]);

    const handleClick = () => {
        dispatch(setSelectedSegment(segment));
    };

    const [isHovered, setIsHovered] = useState(false);

    // Calculate top positions for each bar
    const nonRelevantTop = height - nonRelevantHeight;
    const relevantTop = height - relevantHeight;
    const sarTop = height - sarHeight;

    // Create an array of bars with their styles and positions
    const bars = [];

    if (nonRelevantHeight > 0) {
        bars.push({
            name: 'nonRelevant',
            height: nonRelevantHeight,
            top: nonRelevantTop,
            background: 'linear-gradient(90deg, #A5F2A3 0%, #4DC842 100%)',
        });
    }

    if (relevantHeight > 0) {
        bars.push({
            name: 'relevant',
            height: relevantHeight,
            top: relevantTop,
            background: 'linear-gradient(180deg, #FEE4A3 0%, #F6BA5E 100%)',
        });
    }

    if (sarHeight > 0) {
        bars.push({
            name: 'sar',
            height: sarHeight,
            top: sarTop,
            background: 'linear-gradient(100deg, #FDBCBC 0%, #E46F6F 100%, #F58484 100%)',
        });
    }

    // Sort bars by their 'top' value (lower 'top' means higher bar)
    bars.sort((a, b) => a.top - b.top);

    // Assign dynamic z-index based on sorted order
    bars.forEach((bar, index) => {
        bar.zIndex = index + 1; // Higher index means higher z-index
    });

    if (showGrayBar){
        return (
            <div
                className={`${styles.barComponent} ${selectedSegment && selectedSegment.id === segment.id ? styles.selected : ''}`}
                onClick={() => {}}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <div className={styles.line}>
                    <div style={{ width: 15, height: height, position: 'relative' }}>
                        {/* Render the empty bar */}
                        <div
                            style={{
                                width: 15,
                                height: height - 20,
                                left: 0,
                                top: 20,
                                position: 'absolute',
                                background: '#E8EBFE',
                                borderRadius: borderRadius,
                                transition: transition,
                                zIndex: 0,
                            }}
                        />
                    </div>
                </div>
                <div
                    style={{
                        alignSelf: 'stretch',
                        height: 45,
                        paddingTop: 15,
                        textAlign: 'left',
                        paddingBottom: '5px',
                        textOverflow: 'ellipsis',
                        color: isEmpty ? 'var(--Reform-Blue-2, #BFC5FF)' : '',
                    }}
                >
                    <span className={styles.labelcheckbox}>{"No alerts"}</span>
                </div>
            </div>
        )
    }

    return (
        <div
            className={`${styles.barComponent} ${selectedSegment && selectedSegment.id === segment.id ? styles.selected : ''}`}
            onClick={handleClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className={styles.line}>
                <div style={{ width: 15, height: height, position: 'relative' }}>
                    {/* Render the empty bar first */}
                    <div
                        style={{
                            width: 15,
                            height: emptyHeight,
                            left: 0,
                            top: height - emptyHeight,
                            position: 'absolute',
                            background: '#E8EBFE',
                            borderRadius: borderRadius,
                            transition: transition,
                            zIndex: 0,
                        }}
                    />

                    {/* Render the bars in the same order but with dynamic z-index */}
                    {/* sar */}
                    <div
                        style={{
                            width: 15,
                            height: sarHeight,
                            left: 0,
                            top: sarTop,
                            position: 'absolute',
                            background: 'linear-gradient(100deg, #FDBCBC 0%, #E46F6F 100%, #F58484 100%)',
                            borderRadius: borderRadius,
                            transition: transition,
                            zIndex: bars.find(bar => bar.name === 'sar')?.zIndex || 0,
                        }}
                    />
                    {/* relevant */}
                    <div
                        style={{
                            width: 15,
                            height: relevantHeight,
                            left: 0,
                            top: relevantTop,
                            position: 'absolute',
                            background: 'linear-gradient(180deg, #FEE4A3 0%, #F6BA5E 100%)',
                            borderRadius: borderRadius,
                            transition: transition,
                            zIndex: bars.find(bar => bar.name === 'relevant')?.zIndex || 0,
                        }}
                    />
                    {/* nonRelevant */}
                    <div
                        style={{
                            width: 15,
                            height: nonRelevantHeight,
                            left: 0,
                            top: nonRelevantTop,
                            position: 'absolute',
                            background: 'linear-gradient(90deg, #A5F2A3 0%, #4DC842 100%)',
                            borderRadius: borderRadius,
                            transition: transition,
                            zIndex: bars.find(bar => bar.name === 'nonRelevant')?.zIndex || 0,
                        }}
                    />
                </div>

                <div
                    className={styles.sublegend}
                    style={{
                        opacity: (selectedSegment && selectedSegment.id === segment.id) || isHovered ? 1 : 0,
                    }}
                >
                    {showSAR && (
                        <div className={styles.sarAlerts}>
                            <img className={styles.smallColorIcon} alt="" src={`${process.env.PUBLIC_URL}/Color_red.svg`} />
                            <div className={styles.alert_amount_text}>{segment.sar_alerts}</div>
                        </div>
                    )}
                    {showRelevant && (
                        <div className={styles.sarAlerts}>
                            <img className={styles.smallColorIcon} alt="" src={`${process.env.PUBLIC_URL}/Color_yellow.svg`} />
                            <div className={styles.alert_amount_text}>{segment.relevant_alerts}</div>
                        </div>
                    )}
                    {showNonRelevant && (
                        <div className={styles.sarAlerts}>
                            <img className={styles.smallColorIcon} alt="" src={`${process.env.PUBLIC_URL}/Color_green.svg`} />
                            <div className={styles.alert_amount_text}>{segment.non_relevant_alerts}</div>
                        </div>
                    )}
                </div>
            </div>
            <div
                style={{
                    alignSelf: 'stretch',
                    height: 45,
                    paddingTop: 15,
                    textAlign: 'left',
                    paddingBottom: '5px',
                    textOverflow: 'ellipsis',
                    color: isEmpty ? 'var(--Reform-Blue-2, #BFC5FF)' : '',
                }}
            >
                <span className={styles.labelcheckbox}>{segment.name}</span>
            </div>
        </div>
    );
}
