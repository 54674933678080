import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedRowId } from '../../../features/atl_tool/atlToolSlice';
import styles from './AlertPopUp.module.css';
import InputComponent from './inputs/InputComponent';
import HeaderSection from '../../../layout/HeaderSection';

const AlertPopUp = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const { alertsTable, selectedRowId, selectedSegment } = useSelector((state) => state.atlTool);
  const popupRef = useRef();

  const selectedAlert = alertsTable.find((alert) => alert["Alert Code"] === selectedRowId);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';

      const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
          handleClose();
        }
      };

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        document.body.style.overflow = 'auto';
      };
    }
  }, [isOpen]);

  const handleClose = () => {
    dispatch(setSelectedRowId(null));
    onClose();
  };

  if (!isOpen || !selectedAlert) return null;

  return (
    <div className={styles.overlay}>
      <div className={styles.popup} ref={popupRef}>
        <div className={styles.header}>
          <HeaderSection SectionName={"Alert Details"} AdditionalText={selectedSegment.name} />
        </div>
      
        <div className={styles.content}>


        {/* <InputComponent type="status" label="Segment" badges={[{ label: selectedAlert['Status'], color: selectedAlert['Status'].toLowerCase() }]} /> */}
          {/* <InputComponent type="normal" label="Alert ID" value={selectedAlert['Alert ID']} />
          <InputComponent type="money" label="NetR" value={selectedAlert['NetR.']} currencyOptions={['USD', 'EUR', 'GBP']} onChange={() => {}} />
          <InputComponent type="link" label="Domain" value={selectedAlert['Domain']} />
          <InputComponent type="info" label="Segment" value={selectedAlert['Segment']} infoText="This is the segment of the alert." />
          <InputComponent type="money" label="NetR" value={selectedAlert['NetR.']} currencyOptions={['USD', 'EUR', 'GBP']} onChange={() => {}} />
          <InputComponent type="link" label="Domain" value={selectedAlert['Domain']} />
          <InputComponent type="status" label="Segment" badges={[{ label: selectedAlert['Status'], color: selectedAlert['Status'].toLowerCase() }]} />
          <InputComponent type="normal" label="Alert ID" value={selectedAlert['Alert ID']} />
          <InputComponent type="money" label="NetR" value={selectedAlert['NetR.']} currencyOptions={['USD', 'EUR', 'GBP']} onChange={() => {}} />
          <InputComponent type="link" label="Domain" value={selectedAlert['Domain']} />
          <InputComponent type="info" label="Segment" value={selectedAlert['Segment']} infoText="This is the segment of the alert." />
          <InputComponent type="status" label="Segment" badges={[{ label: selectedAlert['Status'], color: selectedAlert['Status'].toLowerCase() }]} />
          <InputComponent type="normal" label="Alert ID" value={selectedAlert['Alert ID']} />
          <InputComponent type="money" label="NetR" value={selectedAlert['NetR.']} currencyOptions={['USD', 'EUR', 'GBP']} onChange={() => {}} />
          */}



          <InputComponent type="normal" label="Alert Close Reason" value={selectedAlert['Alert Close Reason']} />
          <InputComponent type="normal" label="Alert Closure Date" value={selectedAlert['Alert Closure Date']} />
          <InputComponent type="normal" label="Alert Code" value={selectedAlert['Alert Code']} />
          
          <InputComponent type="normal" label="Alert Creation Date" value={selectedAlert['Alert Creation Date']} />
          <InputComponent type="normal" label="Alert Json" value={JSON.stringify(selectedAlert['Alert Json'], null, 2)} />

          <InputComponent type="status" label="Alert Relevancy" badges={[{ label: selectedAlert['Alert Relevancy'], color: selectedAlert['Alert Relevancy'].toLowerCase() }]} />

          <InputComponent type="normal" label="Alert Status" value={selectedAlert['Alert Status']} />
          <InputComponent type="normal" label="Alert Source" value={selectedAlert['Alert Source']} />
         
          <InputComponent type="normal" label="Alert Type" value={selectedAlert['Alert Type']} />
          <InputComponent type="normal" label="Case Id" value={selectedAlert['Case Id']} />
          <InputComponent type="normal" label="Customer Segment Id" value={selectedAlert['Customer Segment Id']} />
          <InputComponent type="normal" label="Id" value={selectedAlert['Id']} />
          <InputComponent type="info" label="Load File" value={selectedAlert['Load File']} />
          <InputComponent type="normal" label="Load Proc" value={selectedAlert['Load Proc']} />
          <InputComponent type="normal" label="Load Timestamp" value={selectedAlert['Load Timestamp']} />
          <InputComponent type="normal" label="Risk Score" value={selectedAlert['Risk Score']} />
          <InputComponent type="normal" label="Sar Reference" value={selectedAlert['Sar Reference']} />
          <InputComponent type="normal" label="Scenario Id" value={selectedAlert['Scenario Id']} />
          <InputComponent type="normal" label="Segment" value={selectedAlert['Segment']} />
          <InputComponent type="normal" label="Subscenario Id" value={selectedAlert['Subscenario Id']} />
        
        </div>
        <div style={{ paddingLeft: "20px", paddingRight:"20px" }}>
        <InputComponent type="comment" label="Alert Comment" value={selectedAlert['Alert Comment']} />
        </div>
      </div>
    </div>
  );
};

export default AlertPopUp;
