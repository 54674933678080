import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './ChartDataGrid.module.css';
import layout_styles from '../../../layout/Layout.module.css';
import { Scrollbar } from 'react-scrollbars-custom';

const BoxplotDataGrid = ({ id }) => {
  const visualisations = useSelector((state) => state.atlTool.visualisations);
  const visualisation = visualisations.find((visualisation) => visualisation.key === id);
  const thresholdRule = visualisation.xAxis;
  const boxPlotData = useSelector((state) => state.atlTool.boxPlotData);

   //if the thresholdRule contains a _TH at the end, remove it
  const removeTH = (thresholdName) => {
    if (thresholdName.endsWith('_TH')) {
      return thresholdName.slice(0, -3);
    }
    return thresholdName;
  };

   //name of the threshold
  const correctThresholdName = removeTH(thresholdRule);

  // Encuentra los datos correspondientes en boxPlotData
  const data = boxPlotData.find(
    (data) => removeTH(data.threshold_name).toLowerCase() === correctThresholdName.toLowerCase()
  );

  const [scrollHeight, setScrollHeight] = useState();
  const tableRef = React.createRef();

  useEffect(() => {
    if (tableRef.current) {
      setScrollHeight(tableRef.current.clientHeight);
    }
  }, [tableRef]);

  const roundNumber = (num, decimals = 2) => {
    if (!num) {
      return 0;
    }
    return num.toFixed(decimals);
  };

   // update rows when new data is received
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (data) {
      const updatedRows = [
        {
          id: 1,
          label: 'Non Relevant',
          min: roundNumber(data.non_relevant_alerts.min),
          q1: roundNumber(data.non_relevant_alerts.q1),
          median: roundNumber(data.non_relevant_alerts.median),
          q3: roundNumber(data.non_relevant_alerts.q3),
          max: roundNumber(data.non_relevant_alerts.max),
        },
        {
          id: 2,
          label: 'Relevant',
          min: roundNumber(data.relevant_alerts.min),
          q1: roundNumber(data.relevant_alerts.q1),
          median: roundNumber(data.relevant_alerts.median),
          q3: roundNumber(data.relevant_alerts.q3),
          max: roundNumber(data.relevant_alerts.max),
        },
        {
          id: 3,
          label: 'SAR',
          min: roundNumber(data.sar_alerts.min),
          q1: roundNumber(data.sar_alerts.q1),
          median: roundNumber(data.sar_alerts.median),
          q3: roundNumber(data.sar_alerts.q3),
          max: roundNumber(data.sar_alerts.max),
        },
      ];
      setRows(updatedRows);
    }
  }, [data]);

  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

  const formatClassName = (label) => {
    return label
      .split(' ')
      .map((word, index) => {
        if (index === 0) return word.toLowerCase();
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join('') + 'Badge';
  };

  const onSort = (column) => {
    let direction = 'ascending';
    if (sortConfig.key === column && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    const sortedRows = [...rows].sort((a, b) => {
      if (a[column] < b[column]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[column] > b[column]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    setRows(sortedRows);
    setSortConfig({ key: column, direction });
  };

  const renderSortIndicator = (column) => {
    if (sortConfig.key !== column) {
      return <span className={styles.sortArrow}>↑</span>;
    }

    if (sortConfig.direction === 'ascending') {
      return <span className={`${styles.sortArrow} ${styles.activeSort}`}>↑</span>;
    }
    return <span className={`${styles.sortArrow} ${styles.activeSort}`}>↓</span>;
  };

  if (!visualisation || !boxPlotData) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.statsTable}>
      <Scrollbar
        style={{ height: scrollHeight }}
        trackXProps={{ style: { zIndex: '100', height: '5px', backgroundColor: '#E9D7FE' } }}
        thumbXProps={{ style: { backgroundColor: '#D6BBFB' } }}
        trackYProps={{ style: { width: '0px' } }}
        wrapperProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return (
              <span
                {...restProps}
                ref={elementRef}
                style={{ marginBottom: '-5px', position: 'absolute', inset: '0px 0px 5px 0px', overflow: 'hidden' }}
              />
            );
          },
        }}
      >
        <div ref={tableRef} className={styles.dataGridContainer}>
          <table className={styles.customTable}>
            <thead>
              <tr>
                <th
                  className={`${styles.headerCell} ${styles.fixedColumn}`}
                  onClick={() => onSort('label')}
                  style={{ width: '140px' }}
                >
                  Alert type {renderSortIndicator('label')}
                </th>
                <th className={styles.headerCell} onClick={() => onSort('min')}>
                  Min {renderSortIndicator('min')}
                </th>
                <th className={styles.headerCell} onClick={() => onSort('q1')}>
                  Q1 {renderSortIndicator('q1')}
                </th>
                <th className={styles.headerCell} onClick={() => onSort('median')}>
                  Median {renderSortIndicator('median')}
                </th>
                <th className={styles.headerCell} onClick={() => onSort('q3')}>
                  Q3 {renderSortIndicator('q3')}
                </th>
                <th className={styles.headerCell} onClick={() => onSort('max')}>
                  Max {renderSortIndicator('max')}
                </th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row) => (
                <tr key={row.id} className={styles.tableRow}>
                  <td className={`${styles.fixedColumn}`} style={{ width: '140px' }}>
                    <div className={`${layout_styles[formatClassName(row.label)]}`} style={{ verticalAlign: 'middle' }}>
                      {row.label}
                    </div>
                  </td>
                  <td>{row.min}</td>
                  <td>{row.q1}</td>
                  <td>{row.median}</td>
                  <td>{row.q3}</td>
                  <td>{row.max}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Scrollbar>
    </div>
  );
};

export default BoxplotDataGrid;
