import { useRef, useState, useEffect, Fragment } from "react";
import * as XLSX from "xlsx";

import Checkbox from "../buttons/Checkbox";
import styles from "./Table.module.css";
import { ExportButton } from "../buttons/IconButton";
import ModalPopUp from "../modal/Modal";
import { SecondaryButton, PrimaryButton } from "../buttons/NormalButton";

export class SelectActionButton {
  /**
   * @param {string} actionName - The name of the action.
   * @param {string} object - The object where the action is performing on.
   * @param {function(string, boolean, function): void} action - A function to handle
   * the action, based on selectAllMode and selectedIds the caller knows which are selected
   *   - `selectedIds`: The id selected.
   *   - `selectAllMode`: Whether all are selected.
   *   - `onSuccess`: A callback function to invoke on success.
   * @param {string} icon - The icon of the button.
   * @param {boolean} useModal - Whether the button should use a modal.
   */
  constructor(actionName, object, onClick, icon, useModal = false) {
    this.actionName = actionName;
    this.object = object;
    this.action = onClick;
    this.icon = icon;
    this.useModal = useModal;
  }
}

function capitalizeFirstLetter(string) {
  if (!string) return ""; // Handle empty or undefined strings
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function exportToExcel(headers, columns, title, sheetName = undefined) {
  const worksheetData = [headers, ...columns];
  const ws = XLSX.utils.aoa_to_sheet(worksheetData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, `${sheetName ? sheetName : title}`);

  XLSX.writeFile(wb, `${title}.xlsx`);
}

function Export({ onExport }) {
  const [isLoading, setIsLoading] = useState(false);

  async function handleExport() {
    setIsLoading(true);
    await onExport();
    setIsLoading(false);
  }

  return <ExportButton onClick={handleExport} disabled={isLoading} />;
}

function SearchBar({ searchTerm, setSearchTerm }) {
  return (
    <div className={styles.searchContainer}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 21"
        fill="none">
        <path
          d="M17.5 18.3906L13.875 14.7656M15.8333 10.0573C15.8333 13.7392 12.8486 16.724 9.16667 16.724C5.48477 16.724 2.5 13.7392 2.5 10.0573C2.5 6.37539 5.48477 3.39062 9.16667 3.39062C12.8486 3.39062 15.8333 6.37539 15.8333 10.0573Z"
          stroke="#667085"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <input
        type="text"
        placeholder="Search"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className={styles.searchInput}
      />
    </div>
  );
}

function SelectAction({
  resetSelected,
  actionButton,
  selectedIds,
  selectAllMode,
  rows,
  rowsAmount,
  fetchFunction,
}) {
  const [openModal, setOpenModal] = useState(false);
  const { actionName, object, action, icon, useModal } = actionButton;
  console.log("testing", selectedIds);
  console.log(selectAllMode);
  console.log(rows);

  const realSelectedIds = fetchFunction
    ? Array.from(selectedIds)
    : selectAllMode
    ? rows.filter((row) => !selectedIds.has(row.id)).map((row) => row.id)
    : Array.from(selectedIds);

  const realAllMode = fetchFunction ? selectAllMode : fetchFunction;
  const amountSelected = realAllMode
    ? rowsAmount - realSelectedIds.length
    : realSelectedIds.length;

  function handleCloseModal() {
    setOpenModal(false);
    resetSelected();
  }

  function actionButtonFunc() {
    action({
      selectedIds: realSelectedIds,
      selectAllMode: realAllMode,
      onSuccess: handleCloseModal,
    });
  }

  let modal = null;

  if (useModal) {
    const modalButtons = [
      <SecondaryButton
        content={"Cancel"}
        action={handleCloseModal}
        style={{ flex: 1 }}
      />,
      <PrimaryButton
        content={capitalizeFirstLetter(actionName)}
        action={actionButtonFunc}
        style={{ flex: 1 }}
      />,
    ];

    modal = (
      <ModalPopUp
        handleClose={() => setOpenModal(false)}
        isOpen={openModal}
        title={`${capitalizeFirstLetter(actionName)} ${object}`}
        description={`Are you sure you want to ${actionName.toLowerCase()} ${amountSelected} selected ${object}${
          amountSelected > 1 && "s"
        }?`}
        icon={icon}
        actionButtons={modalButtons}
      />
    );
  }

  function onClickIcon() {
    if (useModal) {
      return setOpenModal(true);
    } else {
      actionButtonFunc();
    }
  }

  return (
    <Fragment>
      <div className={styles.iconButton} onClick={onClickIcon}>
        {icon}
      </div>
      {useModal && modal}
    </Fragment>
  );
}

function SelectActionsContainer({
  resetSelected,
  rows,
  rowsAmount,
  selectedIds,
  selectAllMode,
  onSelectActionButtons,
  fetchFunction,
}) {
  const canUseActions = selectedIds.size > 0 || selectAllMode === true;
  const [widthContainer, setWidthContainer] = useState(
    canUseActions ? "fit-content" : 0
  );
  const [opacity, setOpacity] = useState(canUseActions ? 1 : 0);
  const [scale, setScale] = useState(canUseActions ? 1 : 0.1);
  const deleteContainerRef = useRef(null);

  useEffect(() => {
    if (canUseActions && deleteContainerRef.current) {
      const innerWidth = deleteContainerRef.current.offsetWidth;
      setWidthContainer(innerWidth);
      setOpacity(1);
      setScale(1);
    } else {
      setWidthContainer(0);
      setOpacity(0);
      setScale(0.1);
    }
  }, [canUseActions, onSelectActionButtons.length]);

  return (
    <div
      className={styles.actionButtonContainer}
      style={{
        width: widthContainer,
        opacity: opacity,
        transform: `scale(${scale})`,
      }}>
      <div ref={deleteContainerRef} className={styles.actionButtons}>
        {onSelectActionButtons.map((action, index) => (
          <Fragment key={index}>
            <SelectAction
              resetSelected={resetSelected}
              actionButton={action}
              selectedIds={selectedIds}
              selectAllMode={selectAllMode}
              rows={rows}
              rowsAmount={rowsAmount}
              fetchFunction={fetchFunction}
            />
          </Fragment>
        ))}
      </div>
    </div>
  );
}

export function TableTools({
  rowsAmount,
  resetSelected,
  rows,
  canSearch = true,
  searchTerm = "",
  setSearchTerm = () => {},
  canSelect = false,
  allSelected = false,
  selectAllMode = false,
  selectedIds = [],
  toggleSelectAll = () => {},
  onSelectActionButtons = [],
  onExport = undefined,
  actionButtonsLeft = [],
  actionButtonsRight = [],
  fetchFunction = undefined,
}) {
  return (
    <div className={styles.tableTools}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ display: "flex", gap: "12px" }}>
          {canSelect && (
            <Checkbox
              checked={allSelected}
              onChange={toggleSelectAll}
              disabled={rowsAmount === 0}
            />
          )}
          <SelectActionsContainer
            onSelectActionButtons={onSelectActionButtons}
            selectedIds={selectedIds}
            selectAllMode={selectAllMode}
            rows={rows}
            fetchFunction={fetchFunction}
            resetSelected={resetSelected}
            rowsAmount={rowsAmount}
          />
          <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
            {actionButtonsLeft.map((button, index) => (
              <Fragment key={index}>{button}</Fragment>
            ))}
          </div>
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
        <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
          {actionButtonsRight.map((button, index) => (
            <Fragment key={index}>{button}</Fragment>
          ))}
        </div>
        {canSearch && (
          <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        )}
        {onExport && <Export onExport={onExport} />}
      </div>
    </div>
  );
}
