import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './LabelList.module.css';
import Scrollbar from 'react-scrollbars-custom';
import { SecondaryButton } from '../../layout/buttons/NormalButton';


const LabelList = ({ selectedId, labels, setLabels, title, onPlusClick, handleLabelClick, handleDeleteClick , palette, addButtonVisible , handleAddClick}) => {

    const dispatch = useDispatch();

    console.log("selectedId: ", selectedId)

    const [searchTerm, setSearchTerm] = useState('');
    const [scrollHeight, setScrollHeight] = useState(0);
    const [scrollWidth, setScrollWidth] = useState(0);
    const scrollWrapperRef = useRef(null);

    // purple

    // const palette = {
    //     borderColor: '#8A42FF',
    //     backgroundColor: 'rgba(181, 120, 255, 0.20)',
    //     textColor: '#7F56D9',
    //     selectedBackgroundColor: 'rgba(181, 120, 255, 0.50)'
    // };


    // blue
    // const palette = {
    //     borderColor: '#026AA2',
    //     backgroundColor: '#F0F9FF',
    //     textColor: '#026AA2',
    //     selectedBackgroundColor: 'rgba(2, 106, 162, 0.20)'
    // };

    const [filteredLabels, setFilteredLabels] = useState([]);

    useEffect(() => {
        setFilteredLabels(labels.filter((label) => (label.name.toLowerCase()).includes(searchTerm.toLowerCase())));
    }, [labels, searchTerm]);

    useEffect(() => {
        if (scrollWrapperRef.current) {
            setScrollHeight(scrollWrapperRef.current.clientHeight);
            setScrollWidth(scrollWrapperRef.current.width);
        }
    }, [scrollWrapperRef, filteredLabels, labels]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleRemoveLabel = (labelToRemove) => {
        setLabels(labels.filter(label => label !== labelToRemove));
    };

    return (
        <div className={styles.labelContainer}>
            <div className={styles.labelHeader}>
                <div className={styles.labelTitle}>
                    <span>{title}</span>
                    <span 
                        className={styles.labelCount} 
                        style={{
                            borderColor: palette.borderColor,
                            backgroundColor: palette.backgroundColor,
                            color: palette.textColor
                        }}
                    >
                        {labels.length}
                    </span>
                </div>

                <div className={styles.plusButton} onClick={() => onPlusClick()}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                    >
                        <path
                            d="M15 2.5C8.1 2.5 2.5 8.1 2.5 15C2.5 21.9 8.1 27.5 15 27.5C21.9 27.5 27.5 21.9 27.5 15C27.5 8.1 21.9 2.5 15 2.5ZM21.25 16.25H16.25V21.25H13.75V16.25H8.75V13.75H13.75V8.75H16.25V13.75H21.25V16.25Z"
                            fill="url(#paint0_linear_5324_790468)"
                            fillOpacity="0.7"
                        />
                        <defs>
                            <linearGradient
                                id="paint0_linear_5324_790468"
                                x1="27.5"
                                y1="14.9977"
                                x2="2.5"
                                y2="15.0023"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#AD98FF" />
                                <stop offset="1" stopColor="#7A6EFF" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
            </div>



     



            <div className={styles.searchContainer}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 21"
                    fill="none"
                >
                    <path
                        d="M17.5 18.3906L13.875 14.7656M15.8333 10.0573C15.8333 13.7392 12.8486 16.724 9.16667 16.724C5.48477 16.724 2.5 13.7392 2.5 10.0573C2.5 6.37539 5.48477 3.39062 9.16667 3.39062C12.8486 3.39062 15.8333 6.37539 15.8333 10.0573Z"
                        stroke="#BDB4FE"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
                <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className={styles.searchInput}
                />
            </div>


            {/* <button className={styles.addButton} onClick={() => handleAddClick()}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                >
                    <path
                        d="M10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5ZM13.75 11.25H11.25V13.75H8.75V11.25H6.25V8.75H8.75V6.25H11.25V8.75H13.75V11.25Z"
                        fill="#7F56D9"
                    />
                </svg>
            </button> */}

{addButtonVisible &&
      

      <div style={{display: 'flex', marginTop:"24px",  alignItems: 'center'}}>
        <SecondaryButton content="Create parameter list" action={handleAddClick} style={{flex:1}} />
        </div>

}



            <Scrollbar
                trackYProps={{
                    style: { width: "5px", backgroundColor: "#E9D7FE" },
                }}
                thumbYProps={{ style: { backgroundColor: "#D6BBFB" } }}
                trackXProps={{
                    style: { height: "5px", backgroundColor: "#E9D7FE" },
                }}
                thumbXProps={{ style: { backgroundColor: "#D6BBFB" } }}
                className={styles.barChart}
                style={{
                    position: "relative",
                    height: scrollHeight,
                    minHeight: '200px',
                    maxHeight: '520px',
                    marginTop: "24px",
                    width: "100%",
                    overflowY: "visible",
                }}
            >
                <div ref={scrollWrapperRef} className={styles.labelList}>
                    {filteredLabels.map((label, index) => (
                        <div
                            key={index}
                            className={`${styles.labelItem} ${
                                selectedId === label.id ? styles.selectedLabel : ''
                            }`}
                            style={{
                                backgroundColor: selectedId === label.id
                                    ? palette.selectedBackgroundColor
                                    : palette.backgroundColor,
                                color: selectedId === label.id ? palette.selectedTextColor ? palette.selectedTextColor : palette.textColor  : palette.textColor,
                            }}
                            onClick={() => handleLabelClick ? handleLabelClick(label) : null}
                        >
                            <span>{label.name}</span>
                            <button
                                className={styles.removeButton}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleRemoveLabel(label);
                                }}
                            >
                                <div className={styles.crossIcon} onClick={() =>handleDeleteClick ? handleDeleteClick(label) : null }>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="21"
                                        height="20"
                                        viewBox="0 0 21 20"
                                        fill="none"
                                    >
                                        <path
                                            d="M15.585 5L5.58496 15"
                                            stroke={ selectedId === label.id ? palette.selectedTextColor ? palette.selectedTextColor : palette.textColor  : palette.textColor }
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M5.58496 5L15.585 15"
                                            stroke={ selectedId === label.id ? palette.selectedTextColor ? palette.selectedTextColor : palette.textColor  : palette.textColor }
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </div>
                            </button>
                        </div>
                    ))}
                </div>
            </Scrollbar>
        </div>
    );
};

export default LabelList;
