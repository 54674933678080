import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setParameterOptimisationIsWaiting,
  setSelectedRowId
} from '../../../features/atl_tool/atlToolSlice';
import styles from '../parameter_optimisation/optimisation_table/OptimisationTable.module.css';
import HeaderSection from '../../../layout/HeaderSection';
import Checkbox from '../../../layout/buttons/Checkbox';
import AlertPopUp from './AlertPopUp';
import { Scrollbar } from 'react-scrollbars-custom';
import ExportButton from '../../../layout/buttons/ExportButton';

const AlertsTable = () => {
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const dispatch = useDispatch();
  const {
    selectedSegment,
    alertsTable,
  } = useSelector((state) => state.atlTool);

  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const pageSize = 5; // Limit to 5 rows per page
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
 
  const flexDivRef = useRef(null);
  const scrollWrapperRef = useRef(null);

  const [allColumns, setAllColumns] = useState([]); // Store all columns
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [showColumnSelector, setShowColumnSelector] = useState(false);
  const columnSelectorRef = useRef(null);
  const buttonRef = useRef(null);

  // Extract columns dynamically from the alertsTable data, excluding "Alert Creation Date" and "Alert Comment"
  useEffect(() => {
    if (alertsTable.length > 0) {
      const columns = Object.keys(alertsTable[0])
        .filter(key => key !== 'Alert Creation Date' && key !== 'Alert Comment')
        .map(key => ({
          field: key,
          headerName: key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()),
          minWidth: 150,
        }));
      setAllColumns(columns); // Store all columns
      setSelectedColumns(columns.map(col => col.field)); // By default select all columns except the excluded ones
    }
  }, [alertsTable]);

  useEffect(() => {
    const syncScroll = () => {
      if (flexDivRef.current && scrollWrapperRef.current) {
        flexDivRef.current.style.marginLeft = `-${scrollWrapperRef.current.scrollLeft}px`;
      }
    };

    const scrollWrapper = scrollWrapperRef.current;
    if (scrollWrapper) {
      scrollWrapper.addEventListener('scroll', syncScroll);
    }
 
    return () => {
      if (scrollWrapper) {
        scrollWrapper.removeEventListener('scroll', syncScroll);
      }
    };
  }, []);

  useEffect(() => {
    dispatch(setParameterOptimisationIsWaiting(false));
  }, [alertsTable.length, dispatch]);
 
  const handleClickOutside = useCallback((event) => {
    if (columnSelectorRef.current && buttonRef.current && !columnSelectorRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
      setTimeout(() => {
        setShowColumnSelector(false);
      }, 100);
    }
  }, []);
 
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const getColorFromRiskLevel = (risk_level) => {
    if (risk_level === 'low'){
      return 'rgba(181, 120, 255, 0.10)';
    }
    else if (risk_level === 'medium'){
      return 'rgba(181, 120, 255, 0.30)';
    }
    else if (risk_level === 'high'){
      return 'rgba(181, 120, 255, 0.50)';
    }
    else {
      return 'rgba(181, 120, 255, 0.70)';
    }
  }



  const filteredRows = alertsTable
    .filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(searchQuery.toLowerCase())
      )
    );

  if (sortedColumn) {
    filteredRows.sort((a, b) => {
      if (a[sortedColumn] < b[sortedColumn]) {
        return -1;
      }
      else if (a[sortedColumn] > b[sortedColumn]) {
        return 1;
      }
      return 0;
    });
    if (sortOrder === "asc") {
      filteredRows.reverse();
    }
  }

  const paginatedRows = filteredRows.slice(currentPage * pageSize, (currentPage + 1) * pageSize);

  const exportData = filteredRows.map(row => {
    const combinedRow = {};
    selectedColumns.forEach(header => {
      combinedRow[header] = row[header];
    });
    return combinedRow;
  });
  useEffect(() => {

    // createPageNumbers();

  }, [exportData,paginatedRows, currentPage]);

  const totalPages = Math.ceil(filteredRows.length / pageSize);
  
  const [totalPagesObject, setTotalPagesObject] = useState([]);


  const handleSort = (column) => {
    const newSortOrder = sortedColumn === column && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortedColumn(column);
    setSortOrder(newSortOrder);
  };

  const handleRowClick = (row) => {
    dispatch(setSelectedRowId(row['Alert Code']));
    setIsPopUpOpen(true);
  };
 
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
 
  const handleColumnChange = (field) => {
    setSelectedColumns((prevSelectedColumns) =>
      prevSelectedColumns.includes(field)
        ? prevSelectedColumns.filter((col) => col !== field)
        : [...prevSelectedColumns, field]
    );
  };
 
  // const createPageNumbers = () => {
  //   const pageNumbers = [];
  //   for (let i = 0; i < totalPages; i++) {
  //     pageNumbers.push(i + 1);
  //   }
  //   return pageNumbers;
  // };


  const createPageNumbers = () => {

   
    const maxPagesToShow = 5;
    const halfPagesToShow = Math.floor(maxPagesToShow / 2);

    let startPage = Math.max(1, currentPage + 1 - halfPagesToShow);
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    if (endPage - startPage < maxPagesToShow - 1) {
        startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    const pageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);

    if (startPage > 1) {
        pageNumbers.unshift(1, '...');
    }
    if (endPage < totalPages) {
        pageNumbers.push('...', totalPages);
    }
    setTotalPagesObject(pageNumbers);
};


  const [scrollHeight, setScrollHeight] = useState();
  const tableRef = useRef(null);

  const handleResize = () => {
    if (tableRef.current) {
      setScrollHeight(tableRef.current.clientHeight);
    }
  };

  // Set initial height
  useEffect(() => {
    handleResize();
  }, [tableRef]);

  // Add and clean up resize event listener
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Helper function to render cell content
  const renderCellContent = (value, column) => {
    if (typeof value === 'object' && value !== null) {
        return JSON.stringify(value);
    }

    if (column === 'Alert Relevancy') {
      return (
        <div style={{display: 'flex', width: '100%', justifyContent:'center', alignContent:'center', alignItems:'center'}}>
          <div  className={`${styles.badge} ${styles.badge}  ${value.toLowerCase() === "non relevant" ? styles.green : value.toLowerCase() === "relevant" ? styles.yellow : styles.red}`}>
            <span className={` ${styles.bulletPoint}  ${value.toLowerCase() === "non relevant" ? styles.green : value.toLowerCase() === "relevant" ? styles.yellow : styles.red}`}></span>
            <span className={` ${styles.badgeSpan}`}> {value}</span>
          </div>
        </div>
      );
    }

    if (column === 'Risk Score') {
      return (
        <div className={styles.stateBox} style={{ backgroundColor: getColorFromRiskLevel(value)}}>
          <span className={styles.badgespan}>{value}</span>
        </div>
      );
    }

    return value;
  };
 
  return (
    <div>
      <HeaderSection SectionName='Supressed Alerts' Count={filteredRows.length} AdditionalText={selectedSegment.name} />
      <div className={styles.optimisationTable}>
        
      {paginatedRows.length > 0 &&
        <div ref={buttonRef} className={styles.controls}>
        <button
          className={`${styles.addColumnButton} ${showColumnSelector ? styles.rotate : ''}`}
          onClick={(e) => {
            e.stopPropagation();
            setShowColumnSelector((prevState) => !prevState);
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
            <path d="M9.99935 5.05664V16.7233M4.16602 10.89H15.8327" stroke="white" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          {showColumnSelector ? 'Close' : 'Add Column'}
        </button>
        <div style={{display: "flex"}}>
          <div className={styles.searchContainer}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 21" fill="none">
              <path d="M17.5 18.3906L13.875 14.7656M15.8333 10.0573C15.8333 13.7392 12.8486 16.724 9.16667 16.724C5.48477 16.724 2.5 13.7392 2.5 10.0573C2.5 6.37539 5.48477 3.39062 9.16667 3.39062C12.8486 3.39062 15.8333 6.37539 15.8333 10.0573Z" stroke="#667085" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className={styles.searchInput}
            />
               
          </div>
          <div style={{ marginRight: "24px" , display:"flex", alignItems: "center"}}>
            
            <ExportButton componentName={'Supressed Alerts'}   data={exportData} />
          </div>
        </div>
        </div>
        }
 
        <div ref={columnSelectorRef} className={`${styles.columnSelector} ${showColumnSelector ? styles.show : ''}`}>
          {allColumns.length > 0 && allColumns.map((column) => (
             <div  className={styles.columnSelectorItem}>
              <Checkbox
                checked={selectedColumns.includes(column.field)}
                onChange={() => handleColumnChange(column.field)}
              />
            </div>
          ))}
        </div>

        <div className={styles.optimisationTable}>
          <Scrollbar 
            style={{ height: scrollHeight }} 
            trackXProps={{ style: { zIndex: '100', bottom: '0px', left: '10px', height: '5px' , backgroundColor: '#E9D7FE' } }} 
            thumbXProps={{ style: { backgroundColor: '#D6BBFB' }}}
            trackYProps={{ style: { width: '0px' }}}
            wrapperProps={{
              renderer: props => {
                const { elementRef, ...restProps } = props;
                return (
                  <span
                    {...restProps}
                    ref={elementRef}
                    style={{marginBottom: '-5px', position: 'absolute', inset: '0px 0px 5px 0px', overflow:'hidden'}}
                  />
                );
              }
          }}>

            <div style={{ overflowX: 'visible', overflowY: 'none' }}>
              <table className={styles.table} ref={tableRef}>
                <thead>
                  <tr style={{border: 'none', display:"flex"}}>
                    {/* Alert Creation Date Column */}

                    {paginatedRows.length == 0 &&
                        <th
                        key="NO DATA"
                        className={`${styles.stickyColumnHeader} ${styles.headerCell}`}
                        style={{ width: '200px' }}
                      >
                          <div style={{  height: "70px" , width: "100%",  alignContent: "center" }}>
                            No supressed alerts found
                          </div>
                      </th>
                    }


                          {paginatedRows.length > 0 &&
                                              <th
                                                key="Alert Creation Date"
                                                className={`${styles.stickyColumnHeader} ${styles.headerCell}`}
                                                style={{ width: '200px' }}
                                                onClick={() => handleSort("alert_creation_date")}
                                              >
                                                  <div style={{  height: "70px" , width: "100%",  alignContent: "center" }}>
                                                Alert Creation Date

                                                {sortedColumn === 'alert_creation_date' && (sortOrder === 'asc' ? '↑' : '↓')}
                                                </div>
                                              
                                              </th>
                          }
                
                    {selectedColumns.map((column, index) => (
                      <th
                        key={column+index}
                        className={styles.headerCell}
                        style={{ width: '200px' }}
                        onClick={() => handleSort(column)}
                      >
                        {column.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}
                        {sortedColumn === column && (sortOrder === 'asc' ? '↑' : '↓')}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>

                

                  {paginatedRows.length > 0 && paginatedRows.map((row, rowIndex) => (
                    <tr key={rowIndex} className={styles.row} onClick={() => handleRowClick(row)}>

                      <td
                        key="Alert Creation Date"
                        className={styles.stickyColumn}
                        style={{ width: '200px',  }}
                      >
                         <div style={{ borderRight: '1px solid #e0e0e0', height: "70px" , width: "100%",  alignContent: "center" }}>
                         {renderCellContent(row['Alert Creation Date'], "Alert Creation Date") }
                         </div>
                        
                      </td>
                      {/* Other Selected Columns */}
                      {selectedColumns.map((column, colIndex) => (
                        <td
                          key={"cell"+column+colIndex}
                          className={styles.cell}
                          style={{ width: '200px' }}
                        >
                          
                           {renderCellContent(row[column], column)}
                         
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

          </Scrollbar>

          <div className={styles.pagination}>
            <button
              className={styles.paginationButton}
              style={{ marginLeft: "24px" }}
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 0}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 21" fill="none">
                <path
                  d="M15.8327 10.1693H4.16602M4.16602 10.1693L9.99935 16.0026M4.16602 10.1693L9.99935 4.33594"
                  stroke="#6941C6"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Previous
            </button>
            <div className={styles.pageNumbers}>
              {totalPagesObject.map((number, index) => (
                <button
                  key={"pageNumber"+index}
                  onClick={() => handlePageChange(number - 1)}
                  disabled={number === "..."}
                  className={
                    currentPage === number - 1
                      ? styles.activePage
                      : styles.unactivePage
                  }
                >
                  {number}
                </button>
              ))}
            </div>
            <button
              className={styles.paginationButton}
              style={{ marginRight: "24px" }}
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage >= totalPages - 1}
            >
              Next
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 21" fill="none">
                <path
                  d="M4.16602 10.1693H15.8327M15.8327 10.1693L9.99935 4.33594M15.8327 10.1693L9.99935 16.0026"
                  stroke="#6941C6"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
             
            </button>
          </div>
        </div>
<AlertPopUp isOpen={isPopUpOpen} onClose={() => setIsPopUpOpen(false)} />
      </div>
    </div>
  );
};
 
export default AlertsTable;