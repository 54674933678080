// HeaderSection.js
import React from 'react';
import styles from './HeaderSection.module.css'; // Ensure you have the correct path to your CSS module

const HeaderSection = ({ SectionName, AmountOfResults }) => {

  return (
    <div className={styles.headerSection}>
      <div className={styles.kycSegmentRisk}>{SectionName}</div>
      <div className={styles.amountOfResults}>{AmountOfResults} results </div>
    </div>
  );
};

export default HeaderSection;