import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { HeaderClass } from "../../../layout/table/Header.js";
import { CellClass, RowCellClass } from "../../../layout/table/Cell.js";
import TableGrid from "../../../layout/table/Table.js";
import styles from "./CommentTable.module.css";
import { useState } from "react";
import NameBadge from "../name_badge/NameBadge.js";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../layout/buttons/NormalButton.js";
import {
  addComment,
  fetchAuditLogs,
} from "../../../features/all_alerts/allAlertsSlice.js";

function timeAgo(date, now) {
  const past = new Date(date);
  const diffInSeconds = Math.floor((now - past) / 1000);

  if (diffInSeconds < 60) {
    return `${diffInSeconds} seconds ago`;
  } else if (diffInSeconds < 3600) {
    const minutes = Math.floor(diffInSeconds / 60);
    return `${minutes} minutes ago`;
  } else if (diffInSeconds < 86400) {
    const hours = Math.floor(diffInSeconds / 3600);
    return `${hours} hours ago`;
  } else if (diffInSeconds < 604800) {
    const days = Math.floor(diffInSeconds / 86400);
    return `${days} days ago`;
  } else if (diffInSeconds < 2419200) {
    const weeks = Math.floor(diffInSeconds / 604800);
    return `${weeks} weeks ago`;
  } else if (diffInSeconds < 29030400) {
    const months = Math.floor(diffInSeconds / 2419200);
    return `${months} months ago`;
  } else {
    const years = Math.floor(diffInSeconds / 29030400);
    return `${years} years ago`;
  }
}

const TimeDisplay = (value) => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    setCurrentTime(new Date());
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 2000);

    return () => clearInterval(interval);
  }, [value]);

  return timeAgo(value, currentTime);
};

const CommentTable = () => {
  const dispatch = useDispatch();

  const { auditLogs, selectedAlert } = useSelector((state) => state.allAlerts);

  const [addingComment, setAddingComment] = useState(false);
  const [comment, setComment] = useState("");
  const [recentlyAddedComment, setRecentlyAddedComment] = useState(false);

  const [rerenderTable, setRerenderTable] = useState(1);
  const [repaginate, setRepaginate] = useState(1);

  useEffect(() => {
    setRerenderTable(rerenderTable + 1);
  }, [selectedAlert]);

  const handleAddComment = async () => {
    const response = await dispatch(
      addComment({ alertId: selectedAlert, comment: comment })
    );
    await dispatch(fetchAuditLogs({ alertId: selectedAlert }));
    if (response.meta.requestStatus == "fulfilled") {
      const commentId = response.payload.id;
      setRecentlyAddedComment([commentId]);
      setTimeout(() => {
        setRecentlyAddedComment([]);
      }, 3000);
      setRepaginate(repaginate + 1);
    }
    setAddingComment(false);
  };

  const headers = [
    new HeaderClass("User", "User", true, true, { minWidth: 300 }),
    new HeaderClass("Comment", "Comment", false, false, { minWidth: 400 }),
    new HeaderClass("Date", "Date", true, false, {
      minWidth: 200,
      maxWidth: 200,
      width: 200,
    }),
  ];

  const rows = Object.entries(auditLogs).map(([_, rowData]) => {
    const logId = rowData.Id;
    const cells = headers.map((header) => {
      const value = rowData[header.label];

      if (header.label === "User") {
        return new CellClass(value, header.field, () => (
          <NameBadge name={value !== "" ? value : "Unassigned"} />
        ));
      }

      if (header.label === "Date") {
        return new CellClass(value, header.field, () => TimeDisplay(value));
      }

      if (header.label === "Comment") {
        return new CellClass(value, header.field, () => (
          <p
            style={{
              whiteSpace: "pre-wrap",
              textAlign: "left",
              fontStyle: "italic",
            }}>
            {value}
          </p>
        ));
      }
    });

    return new RowCellClass(logId, cells);
  });

  const commentSection = (
    <div>
      {!addingComment && (
        <div className={styles.addCommentBox}>
          <PrimaryButton
            content={"Comment"}
            action={() => setAddingComment(true)}
          />
        </div>
      )}

      {addingComment && (
        <div className={styles.addingCommentWrapper}>
          <div className={styles.commentInputWrapper}>
            <div className={styles.textHolder}>Comment</div>
            <div className={styles.commentInputBox}>
              <textarea
                className={styles.commentInput}
                onChange={(e) => setComment(e.target.value)}></textarea>
            </div>
          </div>

          <div className={styles.buttonContainer}>
            <PrimaryButton
              content={"Submit"}
              action={() => handleAddComment()}
            />
            <SecondaryButton
              content={"Cancel"}
              action={() => setAddingComment(false)}
            />
          </div>
        </div>
      )}
    </div>
  );

  return (
    <TableGrid
      title={`Comments`}
      headers={headers}
      rows={rows}
      noDataPlaceholder={"There are no comments"}
      extraContent={commentSection}
      pageSize={5}
      newIds={recentlyAddedComment}
      resetTable={rerenderTable}
      resetPagination={repaginate}
    />
  );
};

export default CommentTable;
