import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './miblReport.module.css';
import view_style from '../../layout/Layout.module.css'; 
import MonthlyOverview from './overview_table/MonthlyOverview';

const MiblReport = () => {

	const dispatch = useDispatch();
	const state = useSelector((state) => state.miblReport);

	return(
		<div className={view_style.viewWrapper}>
		<MonthlyOverview />
		</div>
	);
};


export default MiblReport;
