import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const notifySuccess = (text) => {
  toast.success(text, {
    position: "bottom-right",
    autoClose: 3000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    closeButton: false,
    style: {
      border: "1px solid #12B76A",
      borderRadius: "10px",
      backgroundColor: "#ECFDF3",
      color: "#4CAF50",
      fontSize: "16px",
      padding: "16px",
    },
  });
};

export const notifyError = (text) => {
  toast.error(text, {
    position: "bottom-right",
    autoClose: 3000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    closeButton: false,
    style: {
      border: "1px solid #D32F2F",
      borderRadius: "10px",
      backgroundColor: "#FDECEA",
      color: "#D32F2F",
      fontSize: "16px",
      padding: "16px",
    },
  });
};
