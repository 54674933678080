import styles from "./SliderButton.module.css";

function SliderButton({ left, right, onClick, leftActive }) {
  return (
    <div className={styles.originAlertsContainer}>
      <div
        className={styles.slider}
        style={{ left: !leftActive ? "50%" : "0%" }}></div>
      <div
        className={`${styles.option} ${leftActive ? styles.active : ""}`}
        onClick={() => onClick(left)}>
        {left}
      </div>
      <div
        className={`${styles.option} ${!leftActive ? styles.active : ""}`}
        onClick={() => onClick(right)}>
        {right}
      </div>
    </div>
  );
}

export default SliderButton
