import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../axiosConfig';


const convertDsMatrixData = (data) => {
  var result = [];
  
  data.map((category) => {
    // Create an array for the 'data' field with all elements from category.ds
    const dsData = category.ds.map((dsItem) => ({
      id: dsItem.ds_id,
      name: dsItem.ds_name,
      data: dsItem.data,
    }));

    // Push the category with all ds elements to the result array
    result.push({
      category_id: category.category_id,
      category_name: category.category,
      data: dsData
    });
  });
  return result
}

const convertScenariosMatrixData = (data) => {
  var result = [];

  data.scenarios.map((scenario) => {
    result.push(
      {
        id: scenario.scenario_id,
        name: scenario.scenario_name,
        data: scenario.overlap_data,
      }
    )
  })
  
  return {
    row_ds_id: data.row_ds_id,
    row_ds_name: data.row_ds_name,
    col_ds_id: data.col_ds_id,
    col_ds_name: data.col_ds_name,
    scenarios: result,
  }
}

const convertOverlappingAlertsData = (data) => {
  // Define the resulting array to hold the formatted data
  console.log("Where are you", data);
  const formattedData = [];
  if (data.total_overlaps === 0)
    return formattedData;
  // Iterate over each overlap in the overlap_alerts array
  console.log("is this reachable:");
  data.overlap_alerts.forEach(overlap => {
      const alert1 = overlap.alert_1[0];
      const alert2 = overlap.alert_2[0];

      // Extract and format the fields from alert1
      const alert_code = alert1.alert_code;
      const risk_score = alert1.risk_score;
      const event_date = alert1.event_date;
      const year_month = event_date.substring(0, 7); // Get the year and month from the date
      const alert_relevancy = alert1.alert_relevancy;
      const alert_status = alert1.alert_status;

      // Extract and format the fields from alert2 with overlap_ prefix
      const overlap_alert_code = alert2.alert_code;
      const overlap_risk_score = alert2.risk_score;
      const overlap_event_date = alert2.event_date;
      const overlap_year_month = overlap_event_date.substring(0, 7); // Get the year and month from the date
      const overlap_alert_relevancy = alert2.alert_relevancy;
      const overlap_alert_status = alert2.alert_status;

      // Create an object for the current pair and add it to the formatted data array
      formattedData.push({
          alert_code,
          risk_score,
          event_date,
          year_month,
          alert_relevancy,
          alert_status,
          overlap_alert_code,
          overlap_risk_score,
          overlap_event_date,
          overlap_year_month,
          overlap_alert_relevancy,
          overlap_alert_status
      });
  });
  console.log("last steps:", formattedData);
  // Return the formatted data and the total count of overlapping alerts
  return {formattedData, total_count: data.total_count};
};

const parseYearMonthPairs = (inputArray) => {
  // Check if the input is a non-empty array
  console.log("inputarray: ", inputArray)
  if (!Array.isArray(inputArray) || inputArray.length === 0) {
    return "";
  }

  // Join the array elements with the desired format
  const parsedString = inputArray.map(pair => `year_month_pairs=${pair}`).join("&");

  // Prepend the "?" to the resulting string
  return parsedString;
}

// Thunks

// export const fetchAllAlerts = createAsyncThunk(
//   'overlapAnalysis/fetchAllAlerts',
//   async () => {
//     // const response = await apiClient.get('/overlap-analysis');
//     // return response.data;
//     // Use the mock data instead of making an actual API call for now
//     return mockAllAlerts;
//   }
// );

export const fetchOverlapAnalysisMatrix = createAsyncThunk(
  'overlapAnalysis/fetchOverlapAnalysisMatrix',
  async ( {},{getState} ) => {
    const state = getState().overlapAnalysis;
    const year_month_pairs_string = parseYearMonthPairs(state.selectedYearMonthPairs);
    const response = await apiClient.get(`/ds-overlap-analysis-view?${year_month_pairs_string}`);
    const result = convertDsMatrixData(response.data);
    return result;
  }
);

export const fetchOverlapAnalysisScenariosMatrix = createAsyncThunk(
  'overlapAnalysis/fetchOverlapAnalysisScenariosMatrix',
  async ({ ds_id_1, ds_id_2, year_month_pairs }) => {
    console.log("Fetching scenario overlap data from API...");
    const year_month_pairs_string = parseYearMonthPairs(year_month_pairs)
    const response = await apiClient.get(`/scenario-overlap-analysis-view?ds_id_1=${ds_id_1}&ds_id_2=${ds_id_2}&${year_month_pairs_string}`)
    const result = convertScenariosMatrixData(response.data);
    return result;
  }
)

export const fetchOverlapAnalysis = createAsyncThunk(
  'overlapAnalysis/fetchOverlapAnalysis',
  async ({scenario_id_1, scenario_id_2, year_month_pairs, skip = 0, limit = 10}) => {
    console.log("Fetching overlapping alerts data from API...");
    console.log("year_mont_pairs: ", year_month_pairs);
    const year_month_pairs_string = parseYearMonthPairs(year_month_pairs);
    console.log("scen ids ", scenario_id_1, scenario_id_2);
    const response = await apiClient.get(`/overlap-analysis-alerts?scenario_id_1=${scenario_id_1}&scenario_id_2=${scenario_id_2}&${year_month_pairs_string}&skip=${skip}&limit=${limit}`);
    const result = convertOverlappingAlertsData(response.data);
    console.log("formatted overlap alerts: ", result);
    return result;
    // Use the mock data instead of making an actual API call for now
    // return mockOverlapAlerts;
  }
);

const overlapAnalysisSlice = createSlice({
  name: 'overlapAnalysis',
  initialState: {
    overlapData: [],
    allAlertsData: [],
    matrixData: [],
    status: 'idle',
    error: null,
    overlapScenariosData: [],

    // used for scenarios overlap
    ds_id_1: -1,
    ds_id_2: -1,
    ds_name_1: -1,
    ds_name_2: -1,
    scenarioIds: [],
    selectedYearMonthPairs: ["2022-01", "2022-02", "2022-03"],

    totalAlerts: 0, // Track total alerts count for pagination

    totalAlerts: 0, // Track total alerts count for pagination

    //POPUP
    alertInfoType: 'Alert Details',
    selectedOverlapAlertId: null,
    

    //AllAlerts
    isAllAlertsIDFilterOpen: false,
    isAllAlertsAssignedToFilterOpen: false,
    isAllAlertsScenarioFilterOpen: false,
    isAllAlertsCustomerNameFilterOpen: false,
    isAllAlertsCustomerNumberFilterOpen: false,
    isAllAlertsCreationDateFilterOpen: false,
    isAllAlertsStatusFilterOpen: false,
    allAlertsIDFilter: [],
    allAlertsAssignedToFilter: [],
    allAlertsScenarioFilter: [],
    allAlertsCustomerNameFilter: [],
    allAlertsCustomerNumberFilter: [],
    allAlertsCreationDateFilter: [],
    allAlertsStatusFilter: [],
    alertSet: "All alerts",
    
    selectedAlert : null,


    // Overlapping DS
    isAlertIDFilterOpen: false,
    isScenarioFrequencyFilterOpen: false,
    isScenarioGroupFilterOpen: false,
    isAlertStatusCategoryFilterOpen: false,
    isEventDateFilterOpen: false,
    isYearMonthFilterOpen: false,
    isCustomerIDFilterOpen: false,
    isCustomerTypeFilterOpen: false,
    isRiskLevelFilterOpen: false,
    isOverlapIDFilterOpen: false,
    alertIDFilter: [],
    scenarioFrequencyFilter: [],
    scenarioGroupFilter: [],
    relevanceFilter: [],
    eventDateFilter: [],
    yearMonthFilter: [],
    customerIDFilter: [],
    customerTypeFilter: [],
    riskLevelFilter: [],
    overlapIDFilter: [],

    
    // Overlapping DS filters
    dsAlertIDFilter: [],
    dsScenarioFrequencyFilter: [],
    dsScenarioGroupFilter: [],
    dsScenarioFilter: [],
    dsRelevanceFilter: [],
    dsEventDateFilter: [],
    dsYearMonthFilter: [],
    dsCustomerIDFilter: [],
    dsCustomerTypeFilter: [],
    dsRiskLevelFilter: [],
    dsOverlapIDFilter: [],
    dsOverlapScenarioFilter: [],
    dsOverlapScenarioGroupFilter: [],
    
    // Overlapping scenario filters
    alertIDFilter: [],
    scenarioFrequencyFilter: [],
    scenarioGroupFilter: [],
    relevanceFilter: [],
    eventDateFilter: [],
    yearMonthFilter: [],
    customerIDFilter: [],
    customerTypeFilter: [],
    riskLevelFilter: [],
    overlapIDFilter: [],

    tableStatus: 'idle',
  },
  reducers: {
    setSelectedAlert(state, action){
      state.selectedAlert = action.payload;
    },

    addComment(state, action){
      const selectedOverlap = state.overlapData.find((overlap) => overlap['Alert ID'] === state.selectedOverlapAlertId);
      selectedOverlap['Audit Log'].unshift({"User": action.payload.user, "Comment": action.payload.comment, "Date": "A few seconds ago"});
    },
    setSelectedOverlap(state, action) {
      state.selectedOverlapAlertId = action.payload;
    },
    setAlertInfoType(state, action){
      state.alertInfoType = action.payload;
    },
    setDsId(state, action){
      if (action.payload.number === 1){
        state.ds_id_1 = action.payload.id;
      }
      else if (action.payload.number === 2){
        state.ds_id_2 = action.payload.id;
      }
    },
    setScenarioIds(state, action) {
      console.log("action apyload", action.payload);
      state.scenarioIds = action.payload;
      console.log("Scenario ids please load:", state.scenarioIds);
    },

    setSelectedYearMonthPairs(state, action) {
      state.selectedYearMonthPairs = action.payload;
    },

    setFilterOpen(state, action) {
      const { filterName, isOpen } = action.payload;
      state.filters.isOpen[filterName] = isOpen;
    },
    setFilterOptions(state, action) {
        const { filterName, options } = action.payload;
        state.filters.selectedOptions[filterName] = options;
    },


  

    setIsAllAlertsIDFilterOpen(state, action) {
      state.isAllAlertsIDFilterOpen = action.payload;
    },
    setIsAllAlertsAssignedToFilterOpen(state, action) {
      state.isAllAlertsAssignedToFilterOpen = action.payload;
    },
    setIsAllAlertsScenarioFilterOpen(state, action) {
      state.isAllAlertsScenarioFilterOpen = action.payload;
    },
    setIsAllAlertsCustomerNameFilterOpen(state, action) {
      state.isAllAlertsCustomerNameFilterOpen = action.payload;
    },
    setIsAllAlertsCustomerNumberFilterOpen(state, action) {
      state.isAllAlertsCustomerNumberFilterOpen = action.payload;
    },
    setIsAllAlertsCreationDateFilterOpen(state, action) {
      state.isAllAlertsCreationDateFilterOpen = action.payload;
    },
    setIsAllAlertsStatusFilterOpen(state, action) {
      state.isAllAlertsStatusFilterOpen = action.payload;
    },

    setAllAlertsIDFilter(state, action) {
      state.allAlertsIDFilter = action.payload;
    }, 
    setAllAlertsAssignedToFilter(state, action) {

      state.allAlertsAssignedToFilter = action.payload;
    },
    setAllAlertsScenarioFilter(state, action) {
      state.allAlertsScenarioFilter = action.payload;
    },
    setAllAlertsCustomerNameFilter(state, action) {
      state.allAlertsCustomerNameFilter = action.payload;
    },
    setAllAlertsCustomerNumberFilter(state, action) {
      state.allAlertsCustomerNumberFilter = action.payload;
    },
    setAllAlertsCreationDateFilter(state, action) {
      state.allAlertsCreationDateFilter = action.payload;
    },
    setAllAlertsStatusFilter(state, action) {
      state.allAlertsStatusFilter = action.payload;
    },


   setIsAlertIDFilterOpen(state, action) {
      state.isAlertIDFilterOpen = action.payload;
    },
    setIsScenarioFrequencyFilterOpen(state, action) {
      state.isScenarioFrequencyFilterOpen = action.payload;
    },
    setIsScenarioGroupFilterOpen(state, action) {
      state.isScenarioGroupFilterOpen = action.payload;
    },
    setIsAlertStatusCategoryFilterOpen(state, action) {
      state.isAlertStatusCategoryFilterOpen = action.payload;
    },
    setIsEventDateFilterOpen(state, action) {
      state.isEventDateFilterOpen = action.payload;
    },
    setIsYearMonthFilterOpen(state, action) {
      state.isYearMonthFilterOpen = action.payload;
    },
    setIsCustomerIDFilterOpen(state, action) {
      state.isCustomerIDFilterOpen = action.payload;
    },
    setIsCustomerTypeFilterOpen(state, action) {
      state.isCustomerTypeFilterOpen = action.payload;
    },
    setIsRiskLevelFilterOpen(state, action) {
      state.isRiskLevelFilterOpen = action.payload;
    },
    setIsOverlapIDFilterOpen(state, action) {
      state.isOverlapIDFilterOpen = action.payload;
    },
    setAlertIDFilter(state, action) {
      state.alertIDFilter = action.payload;
    },
    setRelevanceFilter(state, action) {
      state.relevanceFilter = action.payload;
    },
    setRiskLevelFilter(state, action) {
      state.riskLevelFilter = action.payload;
    },
    setScenarioFrequencyFilter(state, action) {
      state.scenarioFrequencyFilter = action.payload;
    },
    setScenarioGroupFilter(state, action) {
      state.scenarioGroupFilter = action.payload;
    },
    setEventDateFilter(state, action) {
      state.eventDateFilter = action.payload;
    },
    setYearMonthFilter(state, action) {
      state.yearMonthFilter = action.payload;
    },
    setCustomerIDFilter(state, action) {
      state.customerIDFilter = action.payload;
    },
    setCustomerTypeFilter(state, action) {
      state.customerTypeFilter = action.payload;
    },
    setOverlapIDFilter(state, action) {
      state.overlapIDFilter = action.payload;
    },




    setDSAlertIDFilter(state, action) {
      state.dsAlertIDFilter = action.payload;
    },
    setDSRelevanceFilter(state, action) {
      state.dsRelevanceFilter = action.payload;
    },
    setDSRiskLevelFilter(state, action) {
      state.dsRiskLevelFilter = action.payload;
    },
    setDSScenarioFrequencyFilter(state, action) {
      state.dsScenarioFrequencyFilter = action.payload;
    },
    setDSScenarioGroupFilter(state, action) {
      state.dsScenarioGroupFilter = action.payload;
    },
    setDSScenarioFilter(state, action) {
      state.dsScenarioFilter = action.payload;
    },
    setDSEventDateFilter(state, action) {
      state.dsEventDateFilter = action.payload;
    },
    setDSYearMonthFilter(state, action) {
      state.dsYearMonthFilter = action.payload;
    },
    setDSCustomerIDFilter(state, action) {
      state.dsCustomerIDFilter = action.payload;
    },
    setDSCustomerTypeFilter(state, action) {
      state.dsCustomerTypeFilter = action.payload;
    },
    setDSOverlapIDFilter(state, action) {
      state.dsOverlapIDFilter = action.payload;
    },
    setDSOverlapScenarioFilter(state, action) {
      state.dsOverlapScenarioFilter = action.payload;
    },
    setDSOverlapScenarioGroupFilter(state, action) {
      state.dsOverlapScenarioGroupFilter = action.payload;
    },

    // Overlapping scenario filters
    setAlertIDFilter(state, action) {
      state.alertIDFilter = action.payload;
    },
    setRelevanceFilter(state, action) {
      state.relevanceFilter = action.payload;
    },
    setRiskLevelFilter(state, action) {
      state.riskLevelFilter = action.payload;
    },
    setScenarioFrequencyFilter(state, action) {
      state.scenarioFrequencyFilter = action.payload;
    },
    setScenarioGroupFilter(state, action) {
      state.scenarioGroupFilter = action.payload;
    },
    setEventDateFilter(state, action) {
      state.eventDateFilter = action.payload;
    },
    setYearMonthFilter(state, action) {
      state.yearMonthFilter = action.payload;
    },
    setCustomerIDFilter(state, action) {
      state.customerIDFilter = action.payload;
    },
    setCustomerTypeFilter(state, action) {
      state.customerTypeFilter = action.payload;
    },
    setOverlapIDFilter(state, action) {
      state.overlapIDFilter = action.payload;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOverlapAnalysisMatrix.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOverlapAnalysisMatrix.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.matrixData = action.payload;
        console.log("fetched from api: ", action.payload)
      })
      .addCase(fetchOverlapAnalysisMatrix.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        console.log("error: ", action.error)
      })
      .addCase(fetchOverlapAnalysis.pending, (state) => {
        state.tableStatus = 'loading';
      })
      .addCase(fetchOverlapAnalysis.fulfilled, (state, action) => {
        state.tableStatus = 'succeeded';

        state.overlapData = action.payload.formattedData;



        console.log("fetched from api:sfdnkjsdhfkjushjklfshjkfhjskl ", action.payload.formattedData)
        state.totalAlerts = action.payload.total_count;
        console.log("total alerts: ", state.totalAlerts);
      })
      .addCase(fetchOverlapAnalysis.rejected, (state, action) => {

        state.tableStatus = 'failed';

        state.error = action.error.message;
      })
      // .addCase(fetchAllAlerts.pending, (state) => {
      //   state.status = 'loading';
      // })
      // .addCase(fetchAllAlerts.fulfilled, (state, action) => {
      //   state.status = 'succeeded';
      //   state.allAlertsData = action.payload;
      // })
      // .addCase(fetchAllAlerts.rejected, (state, action) => {
      //   state.status = 'failed';
      //   state.error = action.error.message;
      // })
      .addCase(fetchOverlapAnalysisScenariosMatrix.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOverlapAnalysisScenariosMatrix.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.overlapScenariosData = action.payload.scenarios;
        console.log("Scenario overlap data loaded: ", state.overlapScenariosData)
        state.ds_name_1 = action.payload.row_ds_name;
        state.ds_name_2 = action.payload.col_ds_name;
      })
      .addCase(fetchOverlapAnalysisScenariosMatrix.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });


  },
});

export const { 
  setSelectedAlert, 

  setSelectedOverlap,
  addComment,
  setAlertInfoType,
  setDsId,
  setScenarioIds,
  setSelectedYearMonthPairs,

  setIsAllAlertsIDFilterOpen,
  setIsAllAlertsAssignedToFilterOpen,
  setIsAllAlertsScenarioFilterOpen,
  setIsAllAlertsCustomerNameFilterOpen,
  setIsAllAlertsCustomerNumberFilterOpen,
  setIsAllAlertsCreationDateFilterOpen,
  setIsAllAlertsStatusFilterOpen,
  setAllAlertsIDFilter,
  setAllAlertsAssignedToFilter,
  setAllAlertsScenarioFilter,
  setAllAlertsCustomerNameFilter,
  setAllAlertsCustomerNumberFilter,
  setAllAlertsCreationDateFilter,
  setAllAlertsStatusFilter,


  setIsAlertIDFilterOpen,
  setIsScenarioFrequencyFilterOpen,
  setIsScenarioGroupFilterOpen,
  setIsAlertStatusCategoryFilterOpen,
  setIsEventDateFilterOpen,
  setIsYearMonthFilterOpen,
  setIsCustomerIDFilterOpen,
  setIsCustomerTypeFilterOpen,
  setIsRiskLevelFilterOpen,
  setIsOverlapIDFilterOpen,
  setAlertIDFilter, 
  setRelevanceFilter, 
  setRiskLevelFilter, 
  setScenarioFrequencyFilter,
  setScenarioGroupFilter, 
  setEventDateFilter,
  setYearMonthFilter,
  setCustomerIDFilter,
  setCustomerTypeFilter,
  setOverlapIDFilter,
  
  setDSSelectedOverlap,
  setDSAlertIDFilter, 
  setDSRelevanceFilter, 
  setDSRiskLevelFilter, 
  setDSScenarioFrequencyFilter,
  setDSScenarioGroupFilter, 
  setDSScenarioFilter,
  setDSEventDateFilter,
  setDSYearMonthFilter,
  setDSCustomerIDFilter,
  setDSCustomerTypeFilter,
  setDSOverlapIDFilter,
  setDSOverlapScenarioFilter,
  setDSOverlapScenarioGroupFilter,

  } = overlapAnalysisSlice.actions;

export default overlapAnalysisSlice.reducer;
