import React from 'react';
import styles from './Scenarios.module.css';
import Overview from './overview/Overview.js';
import Edit from './edit/Edit';
import { useSelector } from 'react-redux';

const Scenarios = ({configId}) => {

    // variable deciding if the overview or edit is shown
    const {showEdit} = useSelector((state) => state.configuration);

    return (
        <div className={`${styles.tabWrapper} ${showEdit ? styles.slide : ''}`}>

            <Overview   configId={configId}/>
            
            <Edit configId={configId} />

        </div>
    );
};

export default Scenarios; 