import React, { useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import styles from './ReportBuilder.module.css';

const EditableTitle = forwardRef(({ initialTitle }, ref) => {
  const titleRef = useRef(null);

  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.innerText = initialTitle;
    }
  }, [initialTitle]);

  useImperativeHandle(ref, () => ({
    getTitle: () => titleRef.current.innerText,
  }));

  return (
    <div
      ref={titleRef}
      contentEditable={true}
      suppressContentEditableWarning={true}
      className={styles.reportTitleInput}
    />
  );
});

export default React.memo(EditableTitle);
