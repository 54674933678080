import React, {useState, useEffect, useRef } from 'react';
import view_style from '../../layout/Layout.module.css';
import MatrixOverlap from './matrix_overlap/MatrixOverlap';

import { useParams } from 'react-router-dom';

const OverlapAnalysis = () => {

    const wrapperRef = useRef(null);
    
    return  (
        <div ref={wrapperRef} className={view_style.viewWrapper}>
            <MatrixOverlap  />

            {/* <MatrixScenarios />
            <AllAlertsTable/> */}

            {/* <DSAlertsOverlap /> */}
      </div>
    );
}

export default OverlapAnalysis;