import React, { useEffect, useState, useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useSelector, useDispatch } from 'react-redux';
//import styles from './MonthlyOverview.module.css';
import {
	fetchMonthlyOverview,
} from '../../../features/mibl_report/miblReportSlice';
import styles from '../../atl_tool/parameter_optimisation/optimisation_table/OptimisationTable.module.css';
import view_style from '../../../layout/Layout.module.css';
import HeaderSection from "../../../layout/HeaderSection";

const MonthlyOverview = () => {

	const dispatch = useDispatch();
	const {incomingTXTable} = useSelector((state) => state.miblReport);

  const [initialRowCount, setInitialRowCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
	const pageSize = 10;
  const [selectedColumns, setSelectedColumns] = useState([
		'sender_bank', 'currency', 'originator_country_risk', 'originator_country', 'month', 'incoming_txs', 'total_txs_amount', 'tx_red_flags',
  ]);

  const [showColumnSelector, setShowColumnSelector] = useState(false);
  const columnSelectorRef = useRef(null);

  useEffect(() => {
		dispatch(MonthlyOverview());
	}, []);

	const allColumns = [ 
		{ field: 'sender_bank', headerName:'Sender Correspondent Bank', flex: .7, headerClassName: styles.headerCell, renderCell: (params) => <div className={styles.cellContent}>{params.value}</div> },
		{ field: 'currency', headerName:'Currency', flex: .7, headerClassName: styles.headerCell, renderCell: (params) => <div className={styles.cellContent}>{params.value}</div> },
		{ 
			field: 'originator_country_risk', resizable:false, headerName:'Originator Country Risk', flex: .9, headerClassName: styles.headerCell, 
			renderCell: (params) => {
        let riskClass = '';
        switch (params.value) {
          case 'HIGH':
            riskClass = styles.highRisk;
            break;
          case 'MEDIUM':
            riskClass = styles.mediumRisk;
            break;
          case 'STANDARD':
            riskClass = styles.standardRisk;
            break;
          default:
            break;
        }
				return <div className={`${riskClass} ${styles.cellContent}`}>{params.value}</div>;
			}
		},
		// originator country TODO: add flag 
		{ field: 'originator_country', headerName:'Originator Country', flex: .7, headerClassName: styles.headerCell, renderCell: (params) => <div className={styles.cellContent}>{params.value}</div> },
		{ field: 'month', headerName:'Month', flex: .7, headerClassName: styles.headerCell, renderCell: (params) => <div className={styles.cellContent}>{params.value}</div> },
		{ field: 'incoming_txs', headerName:'Incoming Transactions', flex: .7, headerClassName: styles.headerCell, renderCell: (params) => <div className={styles.cellContent}>{params.value}</div> },
		{ field: 'total_txs_amount', headerName:'Total Transaction Amount', flex: .7, headerClassName: styles.headerCell, renderCell: (params) => <div className={styles.cellContent}>{params.value}</div> },
		{ field: 'tx_red_flags', headerName:'T. Red Flags', flex: .7, headerClassName: styles.headerCell, renderCell: (params) => <div className={styles.cellContent}>{params.value}</div> },
	];

	const columns = allColumns.filter(column => selectedColumns.includes(column.field));

	const filteredRows = incomingTXTable
	.filter((row) =>
		Object.values(row).some((value) =>
			String(value).toLowerCase().includes(searchQuery.toLowerCase())
		)
	)
	.map((row, index) => {
		return {
      id: index,
			sender_bank: row['Sender Correspondent Bank'],
			currency: row['Currency'],
			originator_country_risk: row['Originator Country Risk'],
			originator_country: row['Originator Country'],
			month: row['Month'],
			incoming_txs: row['Incoming Transactions'],
			total_txs_amount: row['Total Transaction Amount'],
			tx_red_flags: row['T. Red Flags'],
		};
	});

	const handleRowClick = (params) => {
		//TODO
	};
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleColumnChange = (field) => {
    setSelectedColumns((prevSelectedColumns) =>
      prevSelectedColumns.includes(field)
        ? prevSelectedColumns.filter((col) => col !== field)
        : [...prevSelectedColumns, field]
    );
  };

  const handleAddColumnButtonClick = () => {
    const newShowColumnSelector = !showColumnSelector;
    setShowColumnSelector(newShowColumnSelector);
    const svgElement = document.querySelector(`.${styles.addColumnButton} svg`);
    const buttonElement = document.querySelector(`.${styles.addColumnButton}`);
    if (newShowColumnSelector) {
      svgElement.style.transform = 'rotate(45deg)';
      buttonElement.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none" style="transform: rotate(45deg);">
          <path d="M9.99935 5.05664V16.7233M4.16602 10.89H15.8327" stroke="white" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        Close
      `;
    } else {
      svgElement.style.transform = 'rotate(0deg)';
      buttonElement.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
          <path d="M9.99935 5.05664V16.7233M4.16602 10.89H15.8327" stroke="white" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        Add Column
      `;
    }
  };

  const createPageNumbers = () => {
    const totalPages = Math.ceil(filteredRows.length / pageSize);
    const pageNumbers = [];
    const maxPagesToShow = 5;
    let startPage = Math.max(1, currentPage + 1 - Math.floor(maxPagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    if (endPage - startPage < maxPagesToShow - 1) {
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    if (startPage > 1) {
      pageNumbers.unshift(1, '...');
    }
    if (endPage < totalPages) {
      pageNumbers.push('...', totalPages);
    }

    return pageNumbers;
  };

  const paginatedRows = filteredRows.slice(currentPage * pageSize, (currentPage + 1) * pageSize);
  const totalPages = Math.ceil(filteredRows.length / pageSize);

	return (
		<div className={view_style.sectionWrapper}>
      <HeaderSection SectionName='Monthly Overview on Incoming Transactions'/>
			{ <div className={styles.optimisationTable}>
        <div className={styles.controls}>
          <button className={styles.addColumnButton} onClick={handleAddColumnButtonClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
              <path d="M9.99935 5.05664V16.7233M4.16602 10.89H15.8327" stroke="white" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            Add Column
          </button>
          <div className={styles.searchContainer}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 21" fill="none">
              <path d="M17.5 18.3906L13.875 14.7656M15.8333 10.0573C15.8333 13.7392 12.8486 16.724 9.16667 16.724C5.48477 16.724 2.5 13.7392 2.5 10.0573C2.5 6.37539 5.48477 3.39062 9.16667 3.39062C12.8486 3.39062 15.8333 6.37539 15.8333 10.0573Z" stroke="#667085" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className={styles.searchInput}
            />
          </div>
        </div>

				<div className={styles.dataGridContainer}>
					<DataGrid
            resizeThrottleMs={166}
            rows={paginatedRows}
            columns={columns}
            pageSize={pageSize}
            autoHeight
            columnHeaderHeight={90}
            rowHeight={70}
            disableExtendRowFullWidth={false}
            columnBuffer={10}
            getCellClassName={() => styles.cell}
            disableSelectionOnClick
            disableRowSelectionOnClick
            disableColumnMenu
            hideFooter
            onRowClick={handleRowClick}
            // getRowClassName={(params) => {
            //   return (params.id === selectedRowId ? styles.selectedRow : '');
            // }}
            sx={{
              '&, [class^=MuiDataGrid]': { border: 'none' },
              '& .MuiDataGrid-row': {
                transition: 'all .2s linear',
                border: 'none',
                '--DataGrid-rowBorderColor': '#e9d7fe',
              },
              '& .MuiDataGrid-root': {
                transition: 'all .2s linear',
                border: 'none',
                '--DataGrid-rowBorderColor': '#e9d7fe',
              },
              rowBorderColor: 'none',
              '& .MuiDataGrid-columnHeaderTitleContainer': {
                display: 'flex',
                transition: 'all .2s linear',
                justifyContent: 'space-between',
                '&:focus': {
                  transition: 'all .2s linear',
                  outline: 'none',
                },
                '&.Mui-selected': {
                  transition: 'all .2s linear',
                  outline: 'none',
                },
              },
              '& .MuiDataGrid-columnHeader': {
                transition: 'all .2s linear',
              },
              '& .MuiDataGrid-cell': {
                transition: 'all .2s linear',
                paddingInline: '30px',
                borderBottom: 'none',
                '&:focus': {
                  outline: 'none',
                },
                '&.Mui-selected': {
                  outline: 'none',
                },
              },
              '& .MuiDataGrid-columnHeaders': {
                border: 'none',
                '--DataGrid-rowBorderColor': '#e9d7fe',
              },
              '& .MuiDataGrid-columnHeaderRow': {
                border: 'none',
                '--DataGrid-rowBorderColor': '#e9d7fe',
              },
              '& .MuiDataGrid-columnHeader': {
                paddingInline: '30px',
                outline: 'none',
                borderBottom: 'none',
                '--DataGrid-rowBorderColor': '#e9d7fe',
                '&:focus': {
                  outline: 'none',
                },
                '&:focus-within': {
                  outline: 'none',
                },
              },
            }}
          />
				</div>
        <div className={styles.pagination}>
          <button
            className={styles.paginationButton}
            style={{ marginLeft: "24px" }}
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 0}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 21" fill="none">
              <path d="M15.8327 10.1693H4.16602M4.16602 10.1693L9.99935 16.0026M4.16602 10.1693L9.99935 4.33594" stroke="#6941C6" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            Previous
          </button>
          <div className={styles.pageNumbers}>
            {createPageNumbers().map((number, index) => (
              <button
                key={index}
                onClick={() => {
                  if (number !== '...') {
                    handlePageChange(number - 1);
                  }
                }}
                disabled={number === '...'}
                className={currentPage === number - 1 ? styles.activePage : styles.unactivePage}
              >
                {number}
              </button>
            ))}
          </div>
          <button
            className={styles.paginationButton} style={{ marginRight: "24px" }}
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage >= totalPages - 1}
          >
            Next
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 21" fill="none">
              <path d="M4.16602 10.1693H15.8327M15.8327 10.1693L9.99935 4.33594M15.8327 10.1693L9.99935 16.0026" stroke="#6941C6" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        </div>				
			</div> }
		</div>
	);
};

export default MonthlyOverview;