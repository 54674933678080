import React from 'react';
import { useSelector } from 'react-redux';
import styles from './Visualisation.module.css';

const AlertsLegend = () => {
   const selectedRiskScore = useSelector((state) => state.atlTool.selectedRiskScore);

   // Check if selectedRiskScore is null or undefined
   if (!selectedRiskScore) {
     return <div>Loading...</div>;
   }

   const total_alert = selectedRiskScore.non_relevant_alerts + selectedRiskScore.relevant_alerts + selectedRiskScore.sar_alerts;
   const non_relevant_alerts = ((selectedRiskScore.non_relevant_alerts / total_alert) * 100).toFixed(2);
   const relevant_alerts = ((selectedRiskScore.relevant_alerts / total_alert) * 100).toFixed(2);
   const sar_alerts = ((selectedRiskScore.sar_alerts / total_alert) * 100).toFixed(2);

   return (
     <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
        <div style={{ display: 'flex', marginRight: '20px', marginBottom: '20px' }}>
            <div style={{ background: 'linear-gradient(90deg, rgb(165, 242, 163) 0%, rgb(77, 200, 66) 100%)', width: '10px', height: '10px', borderRadius: '50%', marginRight: '24px', marginTop: '5px' }}></div>
            <div className={styles.Scatter_alerts_label} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <span>Non relevant alerts</span><span><strong>{selectedRiskScore.non_relevant_alerts}</strong> {non_relevant_alerts}%</span>
            </div>
        </div>
        <div style={{ display: 'flex', marginRight: '20px', marginBottom: '20px' }}>
            <div style={{ background: 'linear-gradient(90deg, rgb(254, 228, 163) 0%, rgb(246, 186, 94) 100%)', width: '10px', height: '10px', borderRadius: '50%', marginRight: '24px', marginTop: '5px' }}></div>
            <div className={styles.Scatter_alerts_label}  style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <span>Relevant alerts</span><span><strong>{selectedRiskScore.relevant_alerts}</strong> {relevant_alerts}%</span>
            </div>
        </div>
        <div style={{ display: 'flex', marginRight: '20px', marginBottom: '20px' }}>
            <div style={{ background: 'linear-gradient(90deg, rgb(253, 188, 188) 0%, rgb(228, 111, 111) 100%)', width: '10px', height: '10px', borderRadius: '50%', marginRight: '24px', marginTop: '5px' }}></div>
            <div className={styles.Scatter_alerts_label}  style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <span>SAR alerts</span><span><strong>{selectedRiskScore.sar_alerts}</strong> {sar_alerts}%</span>
            </div>
        </div>
    </div>
   );
};

export default AlertsLegend;