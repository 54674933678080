import { useState } from "react";
import styles from "./ToggleButton.module.css";

function ToggleButton({ checked, onChange, disabled = false }) {
  return (
    <div
      className={`${styles.toggleButton} ${checked && styles.checked} ${
        disabled && styles.disabled
      }`}
      onClick={() => {
        if (!disabled) {
          onChange();
        }
      }}>
      <div className={styles.button} />
    </div>
  );
}

export default ToggleButton;
