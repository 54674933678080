import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import HeaderSection from "../../../layout/HeaderSection";
import c_styles from '../AtlTool.module.css';
import styles from './SegmentOverview.module.css';
import PieChart from './PieChart.js';
import { Scrollbar } from 'react-scrollbars-custom';
import ParameterOptimisation from '../parameter_optimisation/ParameterOptimisation.js'

const SegmentOverview = () => {
  const risk_scores = useSelector((state) =>
    state.atlTool.riskScores.filter(
      (risk_score) =>
        risk_score.name !== "Unknown" ||
        risk_score.non_relevant_alerts > 0 ||
        risk_score.relevant_alerts > 0 ||
        risk_score.sar_alerts > 0 ||
        (risk_score.alert_ongoing_alerts ?? 0) > 0
    )
  );

  const selectedSegment = useSelector((state) => state.atlTool.selectedSegment);
  const selectedSegmentOverview = useSelector((state) => state.atlTool.selectedSegmentOverview);
  const selectedRiskScore = useSelector((state) => state.atlTool.selectedRiskScore);
    const pieChartContainerRef = useRef(null); // Referencia para el área con scroll
    const getTotalAlerts = () => {
      let totalAlerts = 0;
      if (selectedRiskScore){
        totalAlerts = selectedRiskScore.non_relevant_alerts + selectedRiskScore.relevant_alerts +  selectedRiskScore.sar_alerts ; 
      }
      
      return totalAlerts ;
    }
  return (
    <div  className={c_styles.section}>

    <div ref={pieChartContainerRef}>
      <HeaderSection
        SectionName="KYC Segment Risk Score"
        Type="piechart"
        CaptureRef={pieChartContainerRef}  // Pasamos solo el área de gráficos con scroll
        AdditionalText={selectedSegment.name}
        CanAddToReport={true}
        CaptureSectionName="KYC Segment Risk Score"
      />

      <Scrollbar
        style={{ height: "360px", marginBottom: "20px" }}
        trackXProps={{ style: { height: '5px', backgroundColor: '#E9D7FE' } }}
        thumbXProps={{ style: { backgroundColor: '#D6BBFB' }}}
        trackYProps={{ style: { display: 'none' }}}
        wrapperProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return (
              <span
                {...restProps}
                ref={elementRef}
                style={{ marginBottom: '-5px', position: 'absolute', inset: '0px 0px 5px 0px', overflow: 'hidden' }}
              />
            );
          }
        }}
      >
        <div  className={styles.subsegments}>
          {risk_scores.map((risk_score, i) => (
            <div key={risk_score.id} className={styles.pieChartContainer}>
              <PieChart
                risk_score_id={risk_score.id}
                risk_level={i / (risk_scores.length - 1)}
              />
            </div>
          ))}
        </div>
      </Scrollbar>
    </div>
    {selectedSegmentOverview > -1 && selectedRiskScore !== null && getTotalAlerts() > 0 && (
        <div className={c_styles.sectionWrapperBordered}>
          <ParameterOptimisation />
        </div>
      )}
    </div>
  );
};

export default SegmentOverview;
