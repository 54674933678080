// components/atl_tool/parameter_optimisation/RiskScoreSelection.js

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedRiskScore } from '../../../features/atl_tool/atlToolSlice';
import styles from './ParameterOptimisation.module.css';

const RiskScoreSelection = () => {
  const dispatch = useDispatch();
  const { riskScores, selectedRiskScore } = useSelector((state) => state.atlTool);

  const handleRiskScoreClick = (riskScore) => {
    dispatch(setSelectedRiskScore(riskScore));
  };

  return (
    <div className={styles.badgestandardTagParent}>
      {riskScores.map((riskScore, i) => (
        <div
          key={riskScore.id}
          className={`${styles.tag} ${selectedRiskScore && selectedRiskScore.id === riskScore.id ? styles.standardTag : ''}`}
          style={{ backgroundColor: `rgba(181, 120, 255, ${0.1 + 0.4 * i / (riskScores.length - 1)})` }}
          onClick={() => handleRiskScoreClick(riskScore)}
        >
          <div className={styles.tagText}>{riskScore.name}</div>
        </div>
      ))}
    </div>
  );
};

export default RiskScoreSelection;
