import styles from "./RadioButton.module.css";

export function RadionButton({
  checked,
  onChange,
  index,
  label,
  disabled = false,
  canUndo = false,
}) {
  const handleClick = (event) => {
    event.stopPropagation();
    if (!disabled && canUndo) {
      onChange(!checked);
    }
  };
  return (
    <label
      className={`${styles.labelContainer} ${disabled && styles.disabled}`}
      key={index}
      onClick={handleClick}>
      <input
        type="radio"
        checked={checked}
        onChange={onChange}
        className={styles.radioButton}
        disabled={disabled}
      />
      <div className={styles.labelText}>{label}</div>
    </label>
  );
}

/**
 * Renders a container with radio buttons based on provided options.
 *
 * @param {Object.<string, string>} options - Key-value pairs where keys are used as values and the values are displayed as labels.
 * @param {string} currentKey - The key of the currently selected option.
 * @param {function(string): void} setKey - Function to set the selected key.
 * @param {boolean} [vertical=true] - Determines if radio buttons are displayed vertically (true) or horizontally (false).
 */
function RadioButtonsContainer({
  options,
  currentKey,
  setKey,
  vertical = true,
}) {
  return (
    <div
      className={styles.container}
      style={{ flexDirection: vertical ? "column" : "row" }}>
      {Object.entries(options).map(([option_key, option_value]) => (
        <RadionButton
          checked={currentKey === option_key}
          index={option_key}
          label={option_value}
          onChange={() => setKey(option_key)}
        />
      ))}
    </div>
  );
}

export default RadioButtonsContainer;
