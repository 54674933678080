import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./FilterSelector.module.css";
import { Scrollbar } from "react-scrollbars-custom";
import Checkbox from "../../../../layout/buttons/Checkbox.js";

const FilterSelector = ({
  headerName,
  overwriteClass,
  all_options,
  applyFunction,
  title,
  selected_options,
  setSelectedOptions,
  isFilterOpen,
  applyIsFilterOpenFunction,
}) => {
  const dispatch = useDispatch();
  const { isAlertIDFilterOpen } = useSelector((state) => state.overlapAnalysis);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef(null);

  // Local state for managing selected options
  const [localSelectedOptions, setLocalSelectedOptions] =
    useState(selected_options);

  // Update local selected options when prop changes
  useEffect(() => {
    setLocalSelectedOptions(selected_options);
  }, [selected_options]);

  const handleOptionChange = (opt_type) => {
    const actualValue = opt_type === "Not Assigned" ? null : opt_type;

    const newSelectedOptions = localSelectedOptions.includes(actualValue)
      ? localSelectedOptions.filter((option) => option !== actualValue)
      : [...localSelectedOptions, actualValue];

    setLocalSelectedOptions(newSelectedOptions);
  };

  const isNotAssignedSelected = localSelectedOptions.includes(null);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const isString = (obj) => {
    return Object.prototype.toString.call(obj) == "[object String]";
  };

  const mappedOptions = all_options.map((opt_type) =>
    opt_type === null ? "Not Assigned" : opt_type
  );

  const filteredOptions = mappedOptions.filter((opt_type) => {
    if (opt_type && isString(opt_type)) {
      return opt_type.toLowerCase().includes(searchTerm.toLowerCase());
    }
    return true;
  });

  const selectAll = () => {
    if (title === "Assigned To") {
      const optionsWithNull = [...all_options, null];
      setLocalSelectedOptions(optionsWithNull);
    } else {
      setLocalSelectedOptions([...all_options]);
    }
  };

  const clearAll = () => {
    setLocalSelectedOptions([]);
  };

  const applyFilters = () => {
    setSelectedOptions(localSelectedOptions);
    dispatch(applyFunction(localSelectedOptions));
    dispatch(applyIsFilterOpenFunction(false)); // Close the popup if desired
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      dispatch(applyIsFilterOpenFunction(false));
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`${styles.dropDownWrapper} ${overwriteClass}`}
      style={{
        width: "260px",
        border: "none",
        boxShadow: "none",
        backgroundColor: "transparent",
      }}
      ref={dropdownRef}
      onMouseDown={(e) => e.stopPropagation()} // Prevent clicks inside from triggering click outside
    >
      <div
        className={`${styles.dropdownContent} ${
          isFilterOpen ? styles.show : ""
        }`}
        style={{ width: "260px" }}>
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Search..."
          className={styles.searchBar}
        />

        <div className={styles.dropdownActions}>
          <div className={styles.selectionActionHolder}>
            <div
              onClick={(e) => {
                e.stopPropagation();
                selectAll();
              }}
              className={styles.actionButton}>
              Select All
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                clearAll();
              }}
              className={styles.actionButton}>
              Clear All
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                applyFilters();
              }}
              className={styles.actionButton}>
              Apply
            </div>
          </div>
        </div>

        <div className={styles.dropDownLabel}>{title}</div>

        <Scrollbar
          trackYProps={{ style: { width: "5px", backgroundColor: "#E9D7FE" } }}
          thumbYProps={{ style: { backgroundColor: "#D6BBFB" } }}
          trackXProps={{ style: { height: "5px", backgroundColor: "#E9D7FE" } }}
          thumbXProps={{ style: { backgroundColor: "#D6BBFB" } }}
          className={styles.barChart}
          style={{
            position: "relative",
            height: "100px",
            width: "100%",
            overflowY: "visible",
          }}>
          <div className={styles.selectionHolder}>
            {filteredOptions.map((opt_type, i) => (
              <div key={i} className={styles.dropdownItem}>
                <Checkbox
                  checked={
                    opt_type === "Not Assigned"
                      ? isNotAssignedSelected
                      : localSelectedOptions.includes(opt_type)
                  }
                  onChange={() => handleOptionChange(opt_type)}
                  label={opt_type}
                />
              </div>
            ))}
            {filteredOptions.length === 0 && (
              <div className={styles.noResults}>No results found</div>
            )}
          </div>
        </Scrollbar>
      </div>
    </div>
  );
};

export default FilterSelector;
