import styles from "./Buttons.module.css";

export function RoundIconButton({ icon, disabled, action }) {
  return (
    <div
      className={`${styles.roundIconFill} ${disabled && styles.disabled}`}
      onClick={action}>
      {icon}
    </div>
  );
}

export function RoundBackButton({ disabled, action }) {
  return (
    <div
      className={`${styles.roundBackButton} ${disabled && styles.disabled}`}
      onClick={action}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 15"
        fill="none">
        <path
          d="M9 1.10156L3 7.10156L9 13.1016"
          stroke="#5E6EFF"
          strokeOpacity="0.8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
