import React, { useState, useEffect, useRef, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import {
  setParameterOptimisationIsWaiting,
  setSelectedSegmentOverviewUpdates,
  setJustAddedNewOptimisationRow,
  fetchSupressedAlerts,
  setSelectedRowId,
  setManualThresholds,
  setCanManualOpt,
  setAlertsTable,
} from "../../../../features/atl_tool/atlToolSlice";
import styles from "./OptimisationTable.module.css";
import Skeleton from "@mui/material/Skeleton";
import { Scrollbar } from "react-scrollbars-custom";
import Checkbox from "../../../../layout/buttons/Checkbox";

const tableRef = React.createRef();
//To display the percentage
const PercentageDisplay = (r) => {
  if (isNaN(r)) {
    return "";
  }
  const percentage = (r * 100).toFixed(1);
  return `${formatNumber(percentage)}%`;
};

const formatNumber = (r) => {
  if (isNaN(r)) {
    return "";
  }
  return new Intl.NumberFormat("de-DE").format(r);
};

const ManualRow = ({
  scenarioParameters,
  initValues,
  dispatch,
  thresholdIdMap,
}) => {
  const [inputStates, setInputStates] = useState(
    scenarioParameters.reduce((acc, param) => {
      acc[param] = { value: initValues[param], error: false };
      return acc;
    }, {})
  );

  useEffect(() => {
    const thresholdsWithValues = scenarioParameters.map((param) => {
      const thresholdId = thresholdIdMap[param];
      const newValue = inputStates[param].value;
      return [thresholdId, newValue];
    });

    dispatch(setManualThresholds(thresholdsWithValues));
    const hasErrors = scenarioParameters.some(
      (param) => inputStates[param].error
    );

    if (hasErrors) {
      dispatch(setCanManualOpt(false));
    } else {
      dispatch(setCanManualOpt(true));
    }
  }, [inputStates]);

  const handleChange = (param, event) => {
    const newValue = event.target.value;
    setInputStates((prev) => ({
      ...prev,
      [param]: { ...prev[param], value: newValue, error: !newValue },
    }));
  };

  // Handle the cell click to trigger input focus
  const handleCellClick = (e) => {
    // Find the input element inside the clicked td and focus it
    const inputElement = e.currentTarget.querySelector("input");
    if (inputElement) {
      inputElement.focus();
    }
  };

  return (
    <CSSTransition
      key={"manual"}
      timeout={300}
      classNames={{
        enter: styles.rowEnter,
        enterActive: styles.rowEnterActive,
        exit: styles.rowExit,
        exitActive: styles.rowExitActive,
      }}>
      <tr className={`${styles.row} ${styles.manualRow}`}>
        <td
          className={styles.stickyColumnNoColor}
          style={{ width: "230px", padding: "0" }}>
          <div
            className={`${styles.cell} `}
            style={{ borderRight: "1px solid #ebe9fe" }}>
            <div
              style={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
                justifyContent: "center",
              }}>
              -
            </div>
          </div>
        </td>
        {scenarioParameters.map((parameter) => (
          <td
            key={parameter}
            className={`${styles.cellInput} ${
              inputStates[parameter].error ? styles.error : ""
            }`}
            onClick={handleCellClick}>
            <input
              type="number"
              value={inputStates[parameter].value}
              onChange={(e) => handleChange(parameter, e)}
              className={`${styles.input}`}
            />
          </td>
        ))}
        <td className={styles.cell}>-</td>
        <td className={styles.cell}>-</td>
        <td className={styles.cell}>-</td>
        <td className={styles.cell}>-</td>
      </tr>
    </CSSTransition>
  );
};

const OptimisationTable = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const { selectedRows, toggleRowSelection, isAddingManual } = props;

  const [scrollHeight, setScrollHeight] = useState();
  //Needed variables from slice
  const {
    parameterOptimisationIsWaiting,
    optimisationTable,
    selectedScenario,
    selectedSegment,
    selectedRiskScore,
    selectedRowId,
    selectedSubScenario,
    selectedYearState,
    reloadOptimisationTable,
    justAddedNewOptimisationRow,
  } = useSelector((state) => state.atlTool);

  //Needed state variables
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  //Used for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  useEffect(() => {
    dispatch(setParameterOptimisationIsWaiting(false));
  }, [optimisationTable.table.length]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedRiskScore]);

  //Handle a click on a row
  const handleRowClick = (index, row) => {
    if (index === "skeleton") {
      return;
    } // Prevent click actions on skeleton row
    let segmentOverviewUpdates = null;
    if (index === 0 && currentPage === 1) {
      // First item from table
      dispatch(setSelectedRowId(null));
      dispatch(setAlertsTable(null));
      segmentOverviewUpdates = null;
    } else {
      dispatch(setSelectedRowId(row.id));
      const row_id = row.id;
      if (row.efficiency.replace(/\s/g, "") === "") {
        dispatch(setAlertsTable(null));
      } else {
        segmentOverviewUpdates = {
          id: selectedRiskScore.id,
          name: selectedRiskScore.name,
          non_relevant_alerts: row.non_relevant_alerts,
          relevant_alerts: row.relevant_alerts,
          sar_alerts: row.sar_alerts,
        };
        dispatch(
          fetchSupressedAlerts({
            selectedScenario,
            selectedSegment,
            selectedRiskScore,
            row_id,
            selectedSubScenario,
          })
        );
      }
    }
    dispatch(setSelectedSegmentOverviewUpdates(segmentOverviewUpdates));
  };
  const handleSort = (column) => {
    const newSortOrder =
      sortedColumn === column && sortOrder === "asc" ? "desc" : "asc";
    setSortedColumn(column);
    setSortOrder(newSortOrder);
  };

  const sortRows = (rows, column, order) => {
    const sortedRows = [...rows].sort((a, b) => {
      if (a[column] < b[column]) return order === "asc" ? -1 : 1;
      if (a[column] > b[column]) return order === "asc" ? 1 : -1;
      return 0;
    });
    return sortedRows;
  };

  let rows = optimisationTable.table.map((row, index) => {
    const parameters = optimisationTable.scenario_parameters.reduce(
      (acc, parameter) => ({
        ...acc,
        [parameter]: formatNumber(row.parameters[parameter]),
      }),
      {}
    );

    return {
      id: row.id,
      optimisation_type: row.optimisation_type,
      ...parameters,
      non_relevant_alerts: formatNumber(row.non_relevant_alerts),
      relevant_alerts: formatNumber(row.relevant_alerts),
      sar_alerts: formatNumber(row.sar_alerts),
      efficiency: PercentageDisplay(
        (row.relevant_alerts + row.sar_alerts) /
          (row.non_relevant_alerts + row.relevant_alerts + row.sar_alerts)
    
      ),
      no_sar_loss: row.no_sar_loss,
    };
  });

  if (sortedColumn) {
    rows = sortRows(rows, sortedColumn, sortOrder);
  }

  if (parameterOptimisationIsWaiting) {
    rows.push({
      id: "skeleton",
      optimisation_type: "",
      ...optimisationTable.scenario_parameters.reduce(
        (acc, parameter) => ({
          ...acc,
          [parameter]: "",
        }),
        {}
      ),
      non_relevant_alerts: "",
      relevant_alerts: "",
      sar_alerts: "",
      efficiency: "",
    });
  }

  var totalPagesVar = null;
  var currentRowsVar = null;

  if (rows[rows.length - 1].id === "skeleton") {
    totalPagesVar = Math.ceil((rows.length - 1) / rowsPerPage);
    if (currentPage === totalPagesVar) {
      currentRowsVar = rows.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage + 1
      );
    } else {
      currentRowsVar = rows.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
      );
    }
  } else {
    totalPagesVar = Math.ceil(rows.length / rowsPerPage);
    currentRowsVar = rows.slice(
      (currentPage - 1) * rowsPerPage,
      currentPage * rowsPerPage
    );
  }

  const totalPages = totalPagesVar;
  const currentRows = currentRowsVar;

  useEffect(() => {
    if (tableRef.current) {
      setScrollHeight(tableRef.current.clientHeight);
    }

    console.log('tableRef.current.clientHeight', currentRows);
  }, [tableRef, currentRows]);

  const [noAddedRow, setNoAddedRow] = useState(true);

  useEffect(() => {
    setNoAddedRow(true);
  }, [selectedSegment, selectedRiskScore, selectedSubScenario]);

  useEffect(() => {
    console.log(
      "parameter optimisation waiting changed: ",
      parameterOptimisationIsWaiting
    );
    if (parameterOptimisationIsWaiting) {
      setCurrentPage(totalPages);
      dispatch(setJustAddedNewOptimisationRow(true));
    } else if (justAddedNewOptimisationRow) {
      console.log("ching chang");
      let segmentOverviewUpdates = null;
      const row = currentRows[currentRows.length - 1];
      segmentOverviewUpdates = {
        id: selectedRiskScore.id,
        name: selectedRiskScore.name,
        non_relevant_alerts: row.non_relevant_alerts,
        relevant_alerts: row.relevant_alerts,
        sar_alerts: row.sar_alerts,
      };
      dispatch(setSelectedRowId(row.id));

      const row_id = row.id;

      dispatch(
        fetchSupressedAlerts({
          selectedScenario,
          selectedSegment,
          selectedRiskScore,
          row_id,
          selectedSubScenario,
        })
      );
      dispatch(setSelectedSegmentOverviewUpdates(segmentOverviewUpdates));
    }
  }, [parameterOptimisationIsWaiting]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const createPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;
    let startPage = Math.max(
      1,
      currentPage + 1 - Math.floor(maxPagesToShow / 2)
    );
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    if (endPage - startPage < maxPagesToShow - 1) {
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    if (startPage > 1) {
      pageNumbers.unshift(1, "...");
    }
    if (endPage < totalPages) {
      pageNumbers.push("...", totalPages);
    }

    return pageNumbers;
  };

  function getCurrentParams() {
    return optimisationTable.table.find((item) => item.id === 0).parameters;
  }

  return (
    <div ref={ref} className={styles.optimisationTable}>
      <Scrollbar
        style={{ height: scrollHeight }}
        trackXProps={{
          style: { zIndex: "100", height: "5px", backgroundColor: "#E9D7FE" },
        }}
        thumbXProps={{ style: { backgroundColor: "#D6BBFB" } }}
        trackYProps={{ style: { width: "0px" } }}
        wrapperProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return (
              <span
                {...restProps}
                ref={elementRef}
                style={{
                  marginBottom: "-5px",
                  position: "absolute",
                  inset: "0px 0px 5px 0px",
                  overflow: "hidden",
                  maxHeight: "100%",
                }}
              />
            );
          },
        }}>
        <div style={{ overflowX: "visible", overflowY: "none" }}>
          <table className={styles.table} ref={tableRef}>
            <thead>
              <tr className={styles.row}>
                <th
                  className={` ${styles.stickyColumnHeader}`}
                  style={{ padding: "0" }}
                  onClick={() => handleSort("optimisation_type")}>
                  <div className={styles.borderedCell}>
                   
                    Optimisation Type{" "}
                    {sortedColumn === "optimisation_type" &&
                      (sortOrder === "asc" ? "↑" : "↓")}
                  </div>
                </th>
                {optimisationTable.scenario_parameters.map((parameter) => (
                  <th
                    key={parameter}
                    className={styles.headerCell}
                    style={{}}
                    onClick={() => handleSort(parameter)}>
                      <div title={parameter} className={styles.elipse}>
                           {parameter}{" "} </div>
               
                    {sortedColumn === parameter &&
                      (sortOrder === "asc" ? "↑" : "↓")}
                  </th>
                ))}
                <th
                  className={styles.headerCell}
                  onClick={() => handleSort("non_relevant_alerts")}>
                  Non Relevant{" "}
                  {sortedColumn === "non_relevant_alerts" &&
                    (sortOrder === "asc" ? "↑" : "↓")}
                </th>
                <th
                  className={styles.headerCell}
                  onClick={() => handleSort("relevant_alerts")}>
                  Relevant{" "}
                  {sortedColumn === "relevant_alerts" &&
                    (sortOrder === "asc" ? "↑" : "↓")}
                </th>
                <th
                  className={styles.headerCell}
                  onClick={() => handleSort("sar_alerts")}>
                  SAR{" "}
                  {sortedColumn === "sar_alerts" &&
                    (sortOrder === "asc" ? "↑" : "↓")}
                </th>
                <th
                  className={styles.headerCell}
                  onClick={() => handleSort("efficiency")}>
                  Efficiency{" "}
                  {sortedColumn === "efficiency" &&
                    (sortOrder === "asc" ? "↑" : "↓")}
                </th>
              </tr>
            </thead>
            <TransitionGroup component={null}>
              <tbody style={{display:"grid"}}>
                {isAddingManual && (
                  <ManualRow
                    scenarioParameters={optimisationTable?.scenario_parameters}
                    initValues={getCurrentParams()}
                    thresholdIdMap={optimisationTable?.threshold_id_map || {}}
                    dispatch={dispatch}
                  />
                )}
                {currentRows.map((row, index) => (
                  <CSSTransition
                    key={row.id}
                    timeout={300}
                    classNames={{
                      enter: styles.rowEnter,
                      enterActive: styles.rowEnterActive,
                      exit: styles.rowExit,
                      exitActive: styles.rowExitActive,
                    }}>
                    <tr
                      className={`
            ${styles.row}
            ${index === "skeleton" ? styles.skeletonRow : ""}
            ${row.id === selectedRowId ? styles.selectedRow : styles.normalRow}
            ${!row.efficiency ? styles.uneficientRow : ""}
          `}

                      onClick={() => {
                        handleRowClick(index, row);
                      }}>
                      <td
                        className={` ${
                          row.id === selectedRowId
                            ? styles.stickyColumnSelectedRow
                            : styles.stickyColumn
                        }`}
                        style={{ width: "230px", padding: "0" }}>
                        <div
                          className={`${styles.cell} `}
                          style={{ borderRight: "1px solid #ebe9fe" }}>
                          {row.id === "skeleton" ? (
                            <Skeleton animation="wave" />
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                gap: "8px",
                                alignItems: "center",
                              }}>
                              {row.id !== 0 && !isAddingManual ? (
                                  <>
                                  {row.no_sar_loss &&( <div className={styles.sarBulletPoint}></div>)}
                               
                                <Checkbox
                                  checked={selectedRows.includes(row.id)}
                                  onChange={() => toggleRowSelection(row.id)}
                                />
                                </>
                              ) : (
                                <div style={{ width: 30 }}></div>
                              )}
                              <div style={{ whiteSpace: "nowrap" }}>
                                {row.optimisation_type}
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                      {optimisationTable.scenario_parameters.map(
                        (parameter) => (
                          <td key={parameter} className={styles.cell}>
                            {row.id === "skeleton" ? (
                              <Skeleton animation="wave" />
                            ) : (
                              row[parameter]
                            )}
                          </td>
                        )
                      )}
                      <td className={styles.cell}>
                        {row.id === "skeleton" ? (
                          <Skeleton animation="wave" />
                        ) : (
                          row.non_relevant_alerts
                        )}
                      </td>
                      <td className={styles.cell}>
                        {row.id === "skeleton" ? (
                          <Skeleton animation="wave" />
                        ) : (
                          row.relevant_alerts
                        )}
                      </td>
                      <td className={styles.cell}>
                        {row.id === "skeleton" ? (
                          <Skeleton animation="wave" />
                        ) : (
                          row.sar_alerts
                        )}
                      </td>
                      <td className={styles.cell}>
                        {row.id === "skeleton" ? (
                          <Skeleton animation="wave" />
                        ) : (
                          row.efficiency
                        )}
                      </td>
                    </tr>
                  </CSSTransition>
                ))}
              </tbody>
            </TransitionGroup>
          </table>
        </div>
      </Scrollbar>

      <div
        className={styles.pagination}
        style={{
          paddingBottom: "20px",
          backgroundColor: "var(--Brand-50, #F9F5FF)",
          borderTop: "none",
        }}>

        <button
          className={styles.paginationButton}
          style={{ marginLeft: "24px" }}
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 21"
            fill="none">
            <path
              d="M15.8327 10.1693H4.16602M4.16602 10.1693L9.99935 16.0026M4.16602 10.1693L9.99935 4.33594"
              stroke="#6941C6"
              strokeWidth="1.67"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Previous
        </button>

        <div className={styles.pageNumbers}>
          {createPageNumbers().map((number, index) => (
            <button
              key={index}
              onClick={() => {
                if (number !== "...") {
                  handlePageChange(number);
                }
              }}
              disabled={number === "..."}
              className={
                currentPage === number ? styles.activePage : styles.unactivePage
              }>
              {number}
            </button>
          ))}
        </div>

        <button
          className={styles.paginationButton}
          style={{ marginRight: "24px" }}
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage >= totalPages}>
          Next
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 21"
            fill="none">
            <path
              d="M4.16602 10.1693H15.8327M15.8327 10.1693L9.99935 4.33594M15.8327 10.1693L9.99935 16.0026"
              stroke="#6941C6"
              strokeWidth="1.67"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  );
});

export default OptimisationTable;
