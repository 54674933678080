import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";

import { store } from "./store/store"; // Adjust the import path as needed
import NavBar from "./layout/NavBar";
import AtlTool from "./components/atl_tool/AtlTool";
import ScenarioOverview from "./components/scenario_overview/ScenarioOverview";
import OverlapAnalysis from "./components/overlap_analysis/OverlapAnalysis";
import MiblReport from "./components/mibl_report/miblReport";
import AlertsOverlap from "./components/overlap_analysis/alerts_overlap/AlertsOverlap";
import Configuration from "./components/configuration/Configuration";
import Login from "./components/auth/Login";
import PrivateRoute from "./components/auth/PrivateRoute";
import MatrixScenarios from "./components/overlap_analysis/matrix_scenarios/MatrixScenarios";
import ConfigurationTable from "./components/configuration/configuration_table/ConfigurationTable";
import ReportBuilder from "./components/report_builder/report_builder/ReportBuilder";
import Reports from "./components/report_builder/reports/Reports";
import Scrollbar from "react-scrollbars-custom";
import AlertsList from "./components/all_alerts/AlertsList";
import UserOverview from "./components/user_overview/User";


function App() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return (
    <Router>
      <div className="platform">
        {isAuthenticated && <NavBar />}
        <Scrollbar
          trackYProps={{
            style: {
              right: "5px",
              width: "5px",
              backgroundColor: "#E9D7FE",
            },
          }}
          thumbYProps={{ style: { backgroundColor: "#D6BBFB" } }}
          style={{
            position: "relative",
            height: "100%",
            width: "100%",
            overflowY: "visible",
          }}>


        <Routes>
          {/* Ruta de Inicio de Sesión */}
          <Route path="/login" element={<Login />} />

          {/* Rutas Protegidas */}
          <Route
            path="/"
            element={
              <PrivateRoute>
                <AtlTool />
              </PrivateRoute>
            }
          />
          <Route
            path="/scenario_performance/:preSelectedScenario?"
            element={
              <PrivateRoute>
                <AtlTool />
              </PrivateRoute>
            }
          />
          <Route
            path="/scenario-overview"
            element={
              <PrivateRoute>
                <ScenarioOverview />
              </PrivateRoute>
            }
          />

          <Route
            path="/report/:id?"
            element={
              <PrivateRoute>
                <ReportBuilder/>
              </PrivateRoute>
            }
          />


              <Route
                  path="/reports"
                  element={
                    <PrivateRoute>
                      <Reports />
                    </PrivateRoute>
                  }
                />

              <Route
                path="/configuration/:id?"
                element={
                  <PrivateRoute>
                    <Configuration />
                  </PrivateRoute>
                }
              />
              <Route
                path="/configuration/:id/:logic"
                element={
                  <PrivateRoute>
                    <Configuration />
                  </PrivateRoute>
                }
              />
            <Route
              path="/report"
              element={
                <PrivateRoute>
                  <ReportBuilder />
                </PrivateRoute>
              }
            />

            <Route
              path="/configuration/:id?"
              element={
                <PrivateRoute>
                  <Configuration />
                </PrivateRoute>
              }
            />
            <Route
              path="/configurationTable"
              element={
                <PrivateRoute>
                  <ConfigurationTable />
                </PrivateRoute>
              }
            />

            <Route
              path="/overlap-analysis/"
              element={
                <PrivateRoute>
                  <OverlapAnalysis />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashboard-analytics"
              element={
                <PrivateRoute>
                  <MiblReport />
                </PrivateRoute>
              }
            />
            <Route
              path="/alerts-overlap/:id1/:id2"
              element={
                <PrivateRoute>
                  <AlertsOverlap />
                </PrivateRoute>
              }
            />
            <Route
              path="/scenarios-overlap/:id1/:id2"
              element={
                <PrivateRoute>
                  <MatrixScenarios />
                </PrivateRoute>
              }
            />
            <Route
              path="/all-alerts"
              element={
                <PrivateRoute>
                  <AlertsList />
                </PrivateRoute>
              }
            />
            <Route
              path="/users"
              element={
                <PrivateRoute>
                  <UserOverview />
                </PrivateRoute>
              }
            />
          </Routes>
        </Scrollbar>
      </div>

      <ToastContainer />
    </Router>
  );
}

export default App;
