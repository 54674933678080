import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchAllUsers,
  createUser,
  deleteUsers,
} from "../../features/users/usersSlice.js";
import styles from "../all_alerts/AllAlertList.module.css";
import view_style from "../../layout/Layout.module.css";
import HeaderSection from "../../layout/HeaderSection.js";
import { HeaderClass } from "../../layout/table/Header.js";
import { CellClass, RowCellClass } from "../../layout/table/Cell.js";
import TableGrid from "../../layout/table/Table.js";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../layout/buttons/NormalButton.js";
import ModalPopUp from "../../layout/modal/Modal.js";
import InputComponent from "../atl_tool/alert_table/inputs/InputComponent.js";
import { SelectActionButton } from "../../layout/table/TableTools.js";
import {
  notifyError,
  notifySuccess,
} from "../../layout/ToastNotifications.jsx";

const IconAddUser = (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g id="Add user Icon">
      <path
        id="Vector"
        d="M16.416 21.1698V19.1698C16.416 18.1089 15.9946 17.0915 15.2444 16.3414C14.4943 15.5912 13.4769 15.1698 12.416 15.1698H6.41602C5.35515 15.1698 4.33773 15.5912 3.58759 16.3414C2.83744 17.0915 2.41602 18.1089 2.41602 19.1698V21.1698"
        stroke="#F4F3FF"
        stroke-width="1.67"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_2"
        d="M9.41602 11.1698C11.6252 11.1698 13.416 9.37894 13.416 7.1698C13.416 4.96066 11.6252 3.1698 9.41602 3.1698C7.20688 3.1698 5.41602 4.96066 5.41602 7.1698C5.41602 9.37894 7.20688 11.1698 9.41602 11.1698Z"
        stroke="#F4F3FF"
        stroke-width="1.67"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_3"
        d="M19.416 8.1698V14.1698"
        stroke="#F4F3FF"
        stroke-width="1.67"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_4"
        d="M22.416 11.1698H16.416"
        stroke="#F4F3FF"
        stroke-width="1.67"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);

function TrashIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"></path>
    </svg>
  );
}

function UserOverview() {
  const dispatch = useDispatch();
  const { allUsers } = useSelector((state) => state.users);
  const [showModalAddUser, setShowModalAddUser] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const canCreate =
    userName.length > 0 && password.length > 0 && email.length > 0;

  const headers = [
    new HeaderClass("Username", "username", false, false, {
      minWidth: 210,
      maxWidth: 210,
      with: 200,
    }),
    new HeaderClass("Email", "email", false, false),
  ];

  const rows = allUsers.map((rowData) => {
    const rowId = rowData.id;
    const cells = headers.map((header) => {
      const value = rowData[header.field];
      return new CellClass(value, header.field);
    });

    return new RowCellClass(rowId, cells);
  });

  async function createNewUser() {
    const params = {
      username: userName,
      email: email,
      password: password,
    };
    const response = await dispatch(createUser(params));
    if (!response.error) {
      dispatch(fetchAllUsers());
      cancelCreate();
    } else {
      console.log("ERROR ADDING USER ", response.payload);
    }
  }

  useEffect(() => {
    dispatch(fetchAllUsers());
  }, [dispatch]);

  const addUserContentButton = (
    <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
      {IconAddUser}
      <div>Create</div>
    </div>
  );

  function cancelCreate() {
    setPassword("");
    setEmail("");
    setUserName("");
    setShowModalAddUser(false);
  }

  const addUserModalContent = (
    <div>
      <InputComponent
        label="Username"
        value={userName}
        onChange={(e) => setUserName(e.target.value)}
      />
      <InputComponent
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <InputComponent
        label="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
    </div>
  );

  const modalActionButtons = [
    <SecondaryButton
      content={"Cancel"}
      style={{ flex: 1 }}
      action={cancelCreate}
    />,
    <PrimaryButton
      content={"Create"}
      style={{ flex: 1 }}
      disabled={!canCreate}
      action={createNewUser}
    />,
  ];

  const actionButtons = [
    <PrimaryButton
      content={addUserContentButton}
      action={() => setShowModalAddUser(true)}
    />,
  ];

  async function deleteSelectedUsers(ids, onSuccess) {
    try {
      const result = await dispatch(deleteUsers(ids)).unwrap();
      dispatch(fetchAllUsers());
      onSuccess();
      if (result.length == 0) {
        notifyError("Could not delete users");
      } else {
        notifySuccess(`Deleted ${result.length} users`);
      }
    } catch (error) {
      notifyError("Could not delete users");
    }
  }

  const onSelectActionButtons = [
    new SelectActionButton(
      "delete",
      "user",
      ({ selectedIds, selectAllMode, onSuccess }) =>
        deleteSelectedUsers(selectedIds, onSuccess),
      <TrashIcon />,
      true
    ),
  ];

  return (
    <div className={styles.atlTool}>
      <div className={view_style.sectionWrapper} style={{ display: "block" }}>
        <HeaderSection SectionName="Users Overview" />
        <TableGrid
          rows={rows}
          headers={headers}
          title={"List of users"}
          actionButtonsRight={actionButtons}
          onSelectActionButtons={onSelectActionButtons}
        />
        <ModalPopUp
          handleClose={() => setShowModalAddUser(false)}
          isOpen={showModalAddUser}
          title={"Create new user"}
          icon={IconAddUser}
          content={addUserModalContent}
          actionButtons={modalActionButtons}
        />
      </div>
    </div>
  );
}

export default UserOverview;
