import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../Parameters.module.css";
import tableStyles from "../ParametersTable.module.css";
import InputComponent from "../../../atl_tool/alert_table/inputs/InputComponent";
import LabelList from "../../LabelList";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../../layout/buttons/NormalButton";

import Selector from "../../../atl_tool/visualisation/selector/Selector";
import {
  setSelectedParameterId,
  setShowEdit,
  fetchInfoLists,
  deleteList,
  putNewParameterValue,
  fetchParameterDetails,
  setParametersColumns,
  setColumnUpdated,
  fetchConfigurationParametersList,
  createList,
} from "../../../../features/configuration/configurationSlice";
import FilterSelector from "../../../overlap_analysis/alerts_overlap/selectors/FilterSelector";
import Scrollbar from "react-scrollbars-custom";

import ModalPopUp from "../../../../layout/modal/Modal";

const Overview = ({ configId }) => {
  const dispatch = useDispatch();

  const scrollWrapperRef = useRef(null);
  const editCellDataRef = useRef("");

  const listRef = useRef(null);
  const [listHeight, setListHeight] = useState(0);

  // properties for the label list
  const palette = {
    borderColor: "#FECDD6",
    backgroundColor: "#FECDD6",
    textColor: "#FD6F8E",
    selectedBackgroundColor: "#FD6F8E",
    selectedTextColor: "#fff",
  };

  // get needed data from slice
  const {
    parameters,
    selectedParameterId,
    selectedParameterDetails,
    selectedParameterColumns,
    viewOptions,
    riskLevelsOptions,
    customerSegments,
    showEdit,
    column_updated,
  } = useSelector((state) => state.configuration);

  //  useEffect(() => {
  //   if (!showEdit) {

  //     setTimeout(() => {

  //     dispatch(fetchParameterDetails(selectedParameterId));

  //     }, 1000);
  //   }
  //   }, [showEdit]);

  const [showCreateModal, setShowCreateModal] = useState(false);


  const [newParameterListName, setNewParameterListName] = useState("");
  const [newParameterListDesc, setNewParameterListDesc] = useState("");


  useEffect(() => {
    if (column_updated) {
      dispatch(fetchInfoLists());

      console.log("configId !!!!!!LKlklksxlklsdkl  ", configId);

      dispatch(fetchConfigurationParametersList(configId));

      makeColumnsConfig();

      let dynamicColumns = selectedParameterColumns.map((column) => ({
        field: column.name,
        id: column.id,
        headerName: column.name,
      }));

      // Sort dynamic columns to ensure prioritized fields are first
      dynamicColumns.sort((a, b) => {
        let indexA = prioritizedFields.indexOf(a.field);
        let indexB = prioritizedFields.indexOf(b.field);
        if (indexA > -1 && indexB > -1) return indexA - indexB; // Both are prioritized
        if (indexA > -1) return -1; // Only A is prioritized
        if (indexB > -1) return 1; // Only B is prioritized
        return 0; // Neither is prioritized
      });

      setColumnsConfig(dynamicColumns);
    }

    dispatch(setColumnUpdated(false));
  }, [column_updated]);

  useEffect(() => {
    // Map the list_columns to create dynamic column configurations
    let dynamicColumns = selectedParameterColumns.map((column) => ({
      field: column.name,
      id: column.id,
      headerName: column.name,
    }));

    // Sort dynamic columns to ensure prioritized fields are first
    dynamicColumns.sort((a, b) => {
      let indexA = prioritizedFields.indexOf(a.field);
      let indexB = prioritizedFields.indexOf(b.field);
      if (indexA > -1 && indexB > -1) return indexA - indexB; // Both are prioritized
      if (indexA > -1) return -1; // Only A is prioritized
      if (indexB > -1) return 1; // Only B is prioritized
      return 0; // Neither is prioritized
    });

    setColumnsConfig(dynamicColumns);
  }, [selectedParameterColumns]);

  // columns priority
  const prioritizedFields = ["view", "type", "risklevel"];

  let [columnsConfig, setColumnsConfig] = useState([]);

  const [scrollHeight, setScrollHeight] = useState(0);

  const rows = selectedParameterDetails ? selectedParameterDetails : [];
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });

  const selectorRef = useRef(null);

  useEffect(() => {
    if (scrollWrapperRef.current) {
      setScrollHeight(scrollWrapperRef.current.clientHeight);
    }
  }, [
    scrollWrapperRef,
    columnsConfig,
    selectedParameterDetails,
    selectedParameterColumns,
  ]);

  const [editCell, setEditCell] = useState();

  const setParameters = () => {
    return true;
  };

  const sortedRows = React.useMemo(() => {
    const sortableArray = Object.keys(rows).map((rowId) => ({
      rowId,
      ...rows[rowId],
    }));

    if (sortConfig.key) {
      sortableArray.sort((a, b) => {
        const aValue = a[sortConfig.key] ? a[sortConfig.key].value : "";
        const bValue = b[sortConfig.key] ? b[sortConfig.key].value : "";

        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableArray;
  }, [rows, sortConfig]);

  // when clicking on the plus (adding a label)
  const handlePlusClick = () => {
    handleEditClick();
  };

  // go to the edit container
  const handleEditClick = () => {
    dispatch(setShowEdit(true));
  };

  // select a new label
  const handleLabelClick = (label) => {
    dispatch(setSelectedParameterId(label.id));
  };

  const handleClickOutside = (event) => {
    if (
      editCell &&
      scrollWrapperRef.current &&
      !scrollWrapperRef.current.contains(event.target) &&
      selectorRef.current
    ) {
      if (editCellDataRef.current) {
        handleValueChange(editCellDataRef.current);
        editCellDataRef.current = "";
      }
      setEditCell(null);
    }
  };

  const handleValueChange = (value) => {
    if (!editCell) return;

    const dataType = editCell.data_type;
    let valueToDispatch = value;

    // Convert value based on data type
    if (dataType === "NUMBER") {
      valueToDispatch = parseInt(valueToDispatch);
    } else if (dataType === "BOOLEAN") {
      valueToDispatch = valueToDispatch === "True";
    }

    dispatch(
      putNewParameterValue({
        rowId: editCell.row_id,
        columnId: editCell.column_id,
        value: valueToDispatch,
      })
    );

    dispatch(fetchParameterDetails(selectedParameterId));
  };

  useEffect(() => {
    // Define the handleClickOutside function outside of useEffect
    const timeoutId = setTimeout(() => {
      document.addEventListener("mousedown", handleClickOutside);
    }, 0); // Add a slight delay to ensure the correct order of events

    return () => {
      clearTimeout(timeoutId);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [scrollWrapperRef, editCell]);


  function makeColumnsConfig() {
    if (selectedParameterId > -1) {
      // if valid id, fetch the details for that label
      const columns = parameters.find(
        (parameter) => parameter.id === selectedParameterId
      ).list_columns;

      dispatch(setParametersColumns(columns));
      dispatch(fetchParameterDetails(selectedParameterId));
    }
  }

  useEffect(() => {
    makeColumnsConfig();
    
  }, [selectedParameterId, parameters]);

  // TODO
  const onFilterClick = (index) => {
    const newOpenStates = openStates.slice();
    newOpenStates[index] = !openStates[index];
    setOpenStates(newOpenStates);
  };

  // TODO
  const handleSort = (columnKey) => {
    let direction = "ascending";
    if (sortConfig.key === columnKey && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: columnKey, direction });
  };

  const [openStates, setOpenStates] = useState([]);
  const getInputValue = (row, editCellData) => {
    let returnedValue = editCellData || row["value"] || "";

    return returnedValue;
  };

  // set the state correctly to a list with as many columns as there is in columnsConfig
  var newOpenStates = [];
  if (!(openStates.length === columnsConfig.length)) {
    var newOpenStates = [];
    for (var i = 0; i < columnsConfig.length; i++) {
      var newOpenStates = [...newOpenStates, false];
    }
  } else {
    newOpenStates = openStates;
  }
  if (newOpenStates !== openStates) {
    setOpenStates(newOpenStates);
  }

  const handleLabelDeleteClick = (label) => {
    // if the label is selected, set the first one as selected

    console.log("lklklklklk   ", label);
    dispatch(deleteList({ listId: label.id }));
    // dispatch(fetchInfoLists());
  };

  const handlesetEditCellData = (value) => {
    editCellDataRef.current = value;
    setEditCell((prev) => ({
      ...prev,
      value: value,
    }));

    // handleValueChange(value);
  };

  const allColumns = columnsConfig.map(
    ({ field, headerName, renderCell }, index) => ({
      field,
      headerName,
      headerClassName: tableStyles.headerCell,
      renderHeader: () => (
        <div
          className={`${tableStyles.columnHeader} ${tableStyles.headerCell} ${tableStyles.filterSelectorContainer}`}
          style={{
            borderRight:
              headerName === "Alert ID" ? "1px solid #e0e0e0" : "none",
            minWidth: headerName === "Alert ID" ? "152px" : "none",
          }}
        >
          {headerName}
          <button
            className={tableStyles.filterButton}
            style={{ opacity: openStates[index] ? 0 : 1 }}
            onClick={() => !openStates[index] && onFilterClick(index)}
          >
            <img alt="" src={`${process.env.PUBLIC_URL}/Filters lines.svg`} />
          </button>
          <div
            onClick={() => handleSort(field)}
            className={tableStyles.sortButton}
            style={{ opacity: sortConfig.key === field ? 1 : 0.5 }}
          >
            {sortConfig.key === field
              ? sortConfig.direction === "ascending"
                ? "↑"
                : "↓"
              : "↑"}
          </div>
        </div>
      ),
      renderCell: (row) => {
        if (
          editCell &&
          editCell.row_id === row.row_id &&
          editCell.column_name === row.column_name
        ) {
          const { data_type } = editCell;
          if (editCell.column_name === "view") {
            return (
              <div ref={selectorRef}>
                <Selector
                  options={viewOptions.map((option) => option.name)}
                  selectedOption={row["value"]}
                  onOptionSelect={(selectedOption) => {
                    handlesetEditCellData(selectedOption);
                    handleValueChange(selectedOption);
                    setEditCell(null);
                    editCellDataRef.current = "";
                  }}
                  label=""
                />
              </div>
            );
          } else if (editCell.column_name === "risklevel") {
            return (
              <Selector
                ref={selectorRef}
                options={riskLevelsOptions}
                selectedOption={row["value"]}
                onOptionSelect={(selectedOption) => {
                  handlesetEditCellData(selectedOption);
                  handleValueChange(selectedOption);
                  setEditCell(null);
                  editCellDataRef.current = "";
                }}
                label=""
              />
            );
          } else if (editCell.column_name === "type") {
            return (
              <Selector
                ref={selectorRef}
                options={customerSegments.map((option) => option.name)}
                selectedOption={row["value"]}
                onOptionSelect={(selectedOption) => {
                  handlesetEditCellData(selectedOption);
                  handleValueChange(selectedOption);
                  setEditCell(null);
                  editCellDataRef.current = "";
                }}
                label=""
              />
            );
          } else if (data_type === "NUMBER" || data_type === "TEXT") {
            return (
              // <InputComponent
              // type={data_type === "NUMBER" ? "number" : "normal"}
              // label=""
              // value={getInputValue(row, editCellDataRef.current)}
              // onChange={(e) => handlesetEditCellData(e.target.value)}
              // />

              <InputComponent
                ref={selectorRef}
                type={data_type === "NUMBER" ? "number" : "normal"}
                label=""
                value={getInputValue(row, editCellDataRef.current)}
                onChange={(e) => {
                  const value = e.target.value;
                  handlesetEditCellData(value);
                }}
                onBlur={() => {
                  handleValueChange(editCellDataRef.current);
                  setEditCell(null);
                  editCellDataRef.current = "";
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleValueChange(editCellDataRef.current);
                    setEditCell(null);
                    editCellDataRef.current = "";
                  }
                }}
              />
            );
          } else if (data_type === "BOOLEAN") {
            return (
              <select
                ref={selectorRef}
                value={row["value"]}
                onChange={() => {}}
              >
                {["True", "False"].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            );
          }
        } else {
          return <div className={tableStyles.cell}>{row["value"]}</div>;
        }
      },
    })
  );

  const flexDivRef = useRef(null);

  const handleCellClick = (col) => {
    if (editCell) {
      //if cell is not the current one , change the current cell
      if (
        editCell.column_name !== col.column_name ||
        editCell.row_id !== col.row_id
      ) {
        setEditCell(col);
      }

      if (editCellDataRef.current) {
        handleValueChange(editCellDataRef.current);
        editCellDataRef.current = "";
      }
    } else {
      setEditCell(col);
    }
  };

  const alertOverlapFilters = {};

  const modalIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M6 22C7.66 22 9 20.66 9 19c0-1.66-1.34-3-3-3s-3 1.34-3 3c0 1.66 1.34 3 3 3Z"
        stroke="#905EE2"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 19h8.5c.93 0 1.82-.37 2.48-1.03.66-.66 1.02-1.55 1.02-2.47 0-.93-.37-1.82-1.03-2.48-.66-.66-1.55-1.03-2.47-1.03H6.5c-.93 0-1.82-.37-2.47-1.03C3.37 9.82 3 8.93 3 8c0-.93.37-1.82 1.03-2.48C4.68 5.37 5.57 5 6.5 5H15"
        stroke="#905EE2"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 8c1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3 1.34 3 3 3Z"
        stroke="#905EE2"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const modalsButtons = [
    <SecondaryButton
      style={{flex:0.5}}
      key="cancelButton"
      content="Cancel"
      action={() => setShowCreateModal(false)}
    />,
    <PrimaryButton
      style={{flex:0.5}}
      key="AddButton"
      content="Create"
      action={() => handleCreateClick()}
    />,
  ];


  const handleCreateClick = () => { 
    console.log("create clicked");

    dispatch(createList({ name: newParameterListName, description: newParameterListDesc }));
    
    setShowCreateModal(false);
  };

  const handleAddClick = () => {
    setShowCreateModal(true);
  };

  const handleNewNameInput = (name) => {
    console.log("name clicked", name);
    setNewParameterListName(name);
  };


  const handleNewDescInput = (desc) => {
    console.log("name clicked", desc);
    setNewParameterListDesc(desc);
  };


  const modalContent = (
    <>
      
      <InputComponent
        type="normal"
        label="Name"
        value=""
        onChange={(e) => { handleNewNameInput(e.target.value); }}
      />
      <InputComponent
        type="comment"
        label="Description"
        value=""
        onChange={(e) => {handleNewDescInput(e.target.value); }} 
      />
    </>
  );

  return (
    <div className={styles.container}>

      <ModalPopUp
        buttonsAlign="right"
        title="Create new Parameter List"
        content={modalContent}
        handleClose={() => setShowCreateModal(false)}
        isOpen={showCreateModal}
        actionButtons={modalsButtons}
        inlineTitle={true}
        icon={modalIcon}
      />

      <div className={styles.contentWrapper}>
        <div
          ref={listRef}
          style={{ width: "25%", minWidth: "300px", maxWidth: "400px" }}
        >
          <LabelList
            selectedId={selectedParameterId}
            labels={parameters}
            setLabels={setParameters}
            title={"Parameter List"}
            palette={palette}
            onPlusClick={() => handlePlusClick()}
            handleLabelClick={(label) => handleLabelClick(label)}
            handleDeleteClick={(label) => {
              handleLabelDeleteClick(label);
            }}
            addButtonVisible={true}
            handleAddClick={() => handleAddClick()}
          />
        </div>

        <div style={{ width: "75%" }}>
          <div id="table" className={tableStyles.optimisationTable}>
            <div style={{ height: "100%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  height: "0px",
                }}
                ref={flexDivRef}
              >
                {columnsConfig.map(
                  (
                    { field, headerName, minWidth, maxWidth, setOpen },
                    index
                  ) => (
                    <div
                      key={field}
                      className={`${tableStyles.columnHeader} ${tableStyles.headerCell} ${tableStyles.filterSelectorContainer}`}
                      style={{
                        display: "inline-flex",
                        textWrap: "nowrap",
                        height: 0,
                        left: 0,
                        position:
                          headerName === "Transaction ID"
                            ? "sticky"
                            : "relative",
                        color: "transparent",
                        backgroundColor: "transparent",
                        padding: "12px 24px",
                        minWidth,
                        zIndex: 11,
                      }}
                    >
                      {headerName}
                      <button
                        className={tableStyles.filterButton}
                        style={{ opacity: 0 }}
                        onClick={(e) =>
                          !openStates[index] && onFilterClick(openStates[index])
                        }
                      >
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/Filters lines.svg`}
                        />
                      </button>
                      <div
                        onClick={() => handleSort(field)}
                        className={tableStyles.sortButton}
                        style={{ opacity: 0 }}
                      >
                        {sortConfig.key === field
                          ? sortConfig.direction === "ascending"
                            ? "↑"
                            : "↓"
                          : "↑"}
                      </div>
                      {openStates[index] && (
                        <div
                          style={{
                            display: "inline",
                            position: "absolute",
                            left:
                              field === "Alert ID"
                                ? "0px"
                                : field === "Status"
                                ? "-60px"
                                : "-30px",
                            top: "60px",
                            zIndex: index === 0 ? 11 : "auto",
                          }}
                        >
                          <FilterSelector
                            all_options={
                              alertOverlapFilters[headerName].filterTypes
                            }
                            applyFunction={
                              alertOverlapFilters[headerName].setFilterFunction
                            }
                            title={alertOverlapFilters[headerName].title}
                            selected_options={
                              alertOverlapFilters[headerName].selected_options
                            }
                            setSelectedOptions={
                              alertOverlapFilters[headerName].setSelectedOptions
                            }
                            isFilterOpen={true}
                            applyIsFilterOpenFunction={() =>
                              onFilterClick(index)
                            }
                            overwriteClass={tableStyles.filterSelector}
                          />
                        </div>
                      )}
                    </div>
                  )
                )}
              </div>

              <Scrollbar
                style={{
                  marginTop: "15px",
                  transition: "min-height 0.3s ease",
                  minHeight: "200px",
                  height: listRef.current
                    ? listRef.current.clientHeight - 35
                    : 0,
                  overflowY: "hidden",
                }}
                trackXProps={{
                  style: {
                    zIndex: "100",
                    bottom: "0px",
                    left: "10px",
                    height: "5px",
                    backgroundColor: "#E9D7FE",
                  },
                }}
                thumbXProps={{ style: { backgroundColor: "#D6BBFB" } }}
                trackYProps={{ style: { width: "0px" } }}
                wrapperProps={{
                  renderer: (props) => {
                    const { elementRef, ...restProps } = props;
                    return (
                      <span
                        {...restProps}
                        ref={elementRef}
                        style={{
                          marginBottom: "-5px",
                          position: "absolute",
                          inset: "0px 0px 5px 0px",
                          overflow: "hidden",
                        }}
                      />
                    );
                  },
                }}
              >
                <table ref={scrollWrapperRef} className={tableStyles.table}>
                  <thead style={{ position: "sticky", top: 0 }}>
                    <tr style={{ backgroundColor: "#fafaff" }}>
                      {allColumns.map((col) => (
                        <th
                          key={col.field}
                          style={{ padding: "none", minWidth: col.minWidth }}
                        >
                          {col.renderHeader()}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(sortedRows).flatMap(([key, row]) =>
                      row && typeof row === "object" ? (
                        <tr
                          key={key}
                          className={`${tableStyles.row} ${tableStyles.normalRow}`}
                        >
                          {allColumns.map((col) => (
                            <td
                              key={col.field}
                              onClick={() => handleCellClick(row[col.field])}
                              className={
                                col.field === "Alert ID"
                                  ? styles.fixedColumn
                                  : ""
                              }
                            >
                              <div
                                className={
                                  col.field === "Alert ID"
                                    ? styles.cellfixed
                                    : ""
                                }
                              >
                                {col.renderCell(row[col.field] || "")}
                              </div>
                            </td>
                          ))}
                        </tr>
                      ) : null
                    )}
                  </tbody>
                </table>
              </Scrollbar>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
