import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './AlertsOverlap.module.css';
import view_style from '../../../layout/Layout.module.css';
import { useNavigate, useParams } from "react-router-dom";
import Scrollbar from 'react-scrollbars-custom';
import AlertPopUp from '../alert_popup/AlertPopUp';

import {
    setSelectedOverlap,
    fetchOverlapAnalysis,
    setIsAlertIDFilterOpen,
    setIsScenarioFrequencyFilterOpen,
    setIsScenarioGroupFilterOpen,
    setIsAlertStatusCategoryFilterOpen,
    setIsEventDateFilterOpen,
    setIsYearMonthFilterOpen,
    setIsCustomerIDFilterOpen,
    setIsCustomerTypeFilterOpen,
    setIsRiskLevelFilterOpen,
    setIsOverlapIDFilterOpen,
    setAlertIDFilter,
    setScenarioFrequencyFilter,
    setScenarioGroupFilter,
    setRelevanceFilter,
    setEventDateFilter,
    setYearMonthFilter,
    setCustomerIDFilter,
    setCustomerTypeFilter,
    setRiskLevelFilter,
    setOverlapIDFilter, 

    
} from '../../../features/overlap_analysis/overlapAnalysisSlice';
import FilterSelector from './selectors/FilterSelector';

const AlertsOverlap = () => {
    const dispatch = useDispatch();
    const [scrollHeight, setScrollHeight] = useState(0);
    const scrollerRef = useRef(null);
    
    

    const {
        tableStatus, 

        overlapData,
        isAlertIDFilterOpen,
        isScenarioFrequencyFilterOpen,
        isScenarioGroupFilterOpen,
        isAlertStatusCategoryFilterOpen,
        isEventDateFilterOpen,
        isYearMonthFilterOpen,
        isCustomerIDFilterOpen,
        isCustomerTypeFilterOpen,
        isRiskLevelFilterOpen,
        isOverlapIDFilterOpen,
        alertIDFilter,
        scenarioFrequencyFilter,
        scenarioGroupFilter,
        relevanceFilter,
        eventDateFilter,
        yearMonthFilter,
        customerIDFilter,
        customerTypeFilter,
        riskLevelFilter,
        overlapIDFilter,
        selectedYearMonthPairs,
        totalAlerts
    } = useSelector((state) => state.overlapAnalysis);
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

    const flexDivRef = useRef(null);
    const scrollWrapperRef = useRef(null);

    const pageSize = 10; // Limit to 10 rows per page
    // const scenarioIds = useSelector((state) => state.overlapAnalysis.scenarioIds);
    
    const{id1, id2} = useParams();
    const scenarioIds = [id1, id2];


    useEffect(() => {
        console.log("scenarioIds", scenarioIds);
        dispatch(fetchOverlapAnalysis({
            scenario_id_1: scenarioIds[0],
            scenario_id_2: scenarioIds[1],
            year_month_pairs: selectedYearMonthPairs,
            skip: 0,
            limit: 10
          }));
    }, []);
    


    // Feth the overlap analysis data
    // useEffect(() => {
    //     let skip = Math.max(0, currentPage * pageSize);
    //     console.log("currentPage", currentPage);
    //     console.log("scenarioIds", scenarioIds);
    //     const a = performance.now();
    //     dispatch(fetchOverlapAnalysis({ scenario_id_1: scenarioIds[0], scenario_id_2: scenarioIds[1], year_month_pairs: selectedYearMonthPairs, skip: skip, limit: pageSize }));
    //     const b = performance.now();
    //     console.log('It took ' + (b - a) + ' ms.');

    // }, [currentPage, scenarioIds, selectedYearMonthPairs, dispatch]);

    useEffect(() => {
        let skip = Math.max(0, currentPage * pageSize);
        console.log("currentPage", currentPage);
        console.log("scenarioIds", scenarioIds);
        const a = performance.now();
        dispatch(fetchOverlapAnalysis({
          scenario_id_1: scenarioIds[0],
          scenario_id_2: scenarioIds[1],
          year_month_pairs: selectedYearMonthPairs,
          skip: skip,
          limit: pageSize
        }));
        const b = performance.now();
        console.log('It took ' + (b - a) + ' ms.');
      }, [currentPage, selectedYearMonthPairs]);
      


  



    const handleRowClick = (alert_id) => {
        dispatch(setSelectedOverlap(alert_id));
    }


    useEffect(() => {
        const tableContainer = document.getElementById(`scrollWrapper`);
        const handleScroll = () => {
            dispatch(setIsAlertIDFilterOpen(false));
            dispatch(setIsScenarioFrequencyFilterOpen(false));
            dispatch(setIsScenarioGroupFilterOpen(false));
            dispatch(setIsAlertStatusCategoryFilterOpen(false));
            dispatch(setIsEventDateFilterOpen(false));
            dispatch(setIsYearMonthFilterOpen(false));
            dispatch(setIsCustomerIDFilterOpen(false));
            dispatch(setIsCustomerTypeFilterOpen(false));
            dispatch(setIsRiskLevelFilterOpen(false));
            dispatch(setIsOverlapIDFilterOpen(false));
        };

        const syncScroll = () => {
            if (flexDivRef.current && scrollWrapperRef.current) {
                flexDivRef.current.style.marginLeft = `-${scrollWrapperRef.current.scrollLeft}px`;
            }
        };

        const scrollWrapper = scrollWrapperRef.current;
        if (scrollWrapper) {
            scrollWrapper.addEventListener('scroll', syncScroll);
            scrollWrapper.addEventListener('scroll', handleScroll);
        }

        if (tableContainer) {
            tableContainer.addEventListener('scroll', handleScroll);
        }


        console.log("overlapdcvbcvbmklcjvklbjcklvbjklcjvbklcjkbljData");
      

        return () => {
            if (tableContainer) {
                tableContainer.removeEventListener('scroll', handleScroll);
            }
            if (scrollWrapper) {
                scrollWrapper.removeEventListener('scroll', syncScroll);
                scrollWrapper.removeEventListener('scroll', handleScroll);
            }
        };


    }, []);

    // Used for pagination
    const handlePageChange = (newPage) => {
        console.log("newPage333", newPage);

        if (newPage >= 0 && newPage * pageSize < totalAlerts) {
            console.log("newPages", newPage);
            setCurrentPage(newPage);
        };
    };

    // Used for sorting
    const handleSort = (columnKey) => {
        let direction = 'ascending';
        if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key: columnKey, direction });
    };

   

    const [selectedAlertIDOptions, setSelectedAlertIDOptions] = useState([]);
    const [selectedScenarioFrequencyOptions, setSelectedScenarioFrequencyOptions] = useState([]);
    const [selectedScenarioGroupOptions, setSelectedScenarioGroupOptions] = useState([]);
    const [selectedRelevanceOptions, setSelectedRelevanceOptions] = useState([]);
    const [selectedEventDateOptions, setSelectedEventDateOptions] = useState([]);
    const [selectedYearMonthOptions, setSelectedYearMonthOptions] = useState([]);
    const [selectedCustomerIDOptions, setSelectedCustomerIDOptions] = useState([]);
    const [selectedCustomerTypeOptions, setSelectedCustomerTypeOptions] = useState([]);
    const [selectedRiskLevelOptions, setSelectedRiskLevelOptions] = useState([]);
    const [selectedOverlapIDOptions, setSelectedOverlapIDOptions] = useState([]);


     // Sort the rows
     const sortedRows = React.useMemo(() => {
      
       
        let sortableRows = [...(overlapData || [])];
        
        console.log("overlapData UIOUIUIUIUI", overlapData);
        if (sortConfig.key) {
            sortableRows.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableRows;



        
    }, [overlapData, sortConfig]);

    const alertOverlapFilters = {
        'Alert ID': {
            title: "Alert ID",
            filterTypes: [...new Set((overlapData || []).map(alert => alert['Alert ID']))],
            setFilterFunction: setAlertIDFilter,
            selected_options: selectedAlertIDOptions,
            setSelectedOptions: setSelectedAlertIDOptions,
        },
        'Scenario Frequency': {
            title: "Scenario Frequency",
            filterTypes: [...new Set((overlapData || []).map(alert => alert['Scenario Frequency']))],
            setFilterFunction: setScenarioFrequencyFilter,
            selected_options: selectedScenarioFrequencyOptions,
            setSelectedOptions: setSelectedScenarioFrequencyOptions,
        },
        'Scenario Group': {
            title: "Scenario Group",
            filterTypes: [...new Set((overlapData || []).map(alert => alert['Scenario Group']))],
            setFilterFunction: setScenarioGroupFilter,
            selected_options: selectedScenarioGroupOptions,
            setSelectedOptions: setSelectedScenarioGroupOptions,
        },
        'Alert Status Category': {
            title: "Relevance",
            filterTypes: [...new Set((overlapData || []).map(alert => alert['Alert Status Category']))],
            setFilterFunction: setRelevanceFilter,
            selected_options: selectedRelevanceOptions,
            setSelectedOptions: setSelectedRelevanceOptions,
        },
        'Event Date': {
            title: "Event Date",
            filterTypes: [...new Set((overlapData || []).map(alert => alert['Event Date']))],
            setFilterFunction: setEventDateFilter,
            selected_options: selectedEventDateOptions,
            setSelectedOptions: setSelectedEventDateOptions,
        },
        'Year Month': {
            title: "Year / Month",
            filterTypes: [...new Set((overlapData || []).map(alert => alert['Year Month']))],
            setFilterFunction: setYearMonthFilter,
            selected_options: selectedYearMonthOptions,
            setSelectedOptions: setSelectedYearMonthOptions,
        },
        'Customer ID': {
            title: "Customer ID",
            filterTypes: [...new Set((overlapData || []).map(alert => alert['Customer ID']))],
            setFilterFunction: setCustomerIDFilter,
            selected_options: selectedCustomerIDOptions,
            setSelectedOptions: setSelectedCustomerIDOptions,
        },
        'Customer Type': {
            title: "Customer Type",
            filterTypes: [...new Set((overlapData || []).map(alert => alert['Customer Type']))],
            setFilterFunction: setCustomerTypeFilter,
            selected_options: selectedCustomerTypeOptions,
            setSelectedOptions: setSelectedCustomerTypeOptions,
        },
        'Risk Level': {
            title: "Risk Level",
            filterTypes: [...new Set((overlapData || []).map(alert => alert['Risk Level']))],
            setFilterFunction: setRiskLevelFilter,
            selected_options: selectedRiskLevelOptions,
            setSelectedOptions: setSelectedRiskLevelOptions,
        },
        'Overlap Alert ID': {
            title: "Overlap Alert ID",
            filterTypes: [...new Set((overlapData || []).map(alert => alert['Overlap Alert ID']))],
            setFilterFunction: setOverlapIDFilter,
            selected_options: selectedOverlapIDOptions,
            setSelectedOptions: setSelectedOverlapIDOptions,
        },
    };
    

    // Define the columns
    const columnsConfig = [
        { field: "alert_code", headerName: "Alert ID", isOpen: isAlertIDFilterOpen, setOpen: setIsAlertIDFilterOpen },
        // { field: "Scenario ", headerName: "Scenario Frequency", minWidth: 200, maxWidth: 300, isOpen: isScenarioFrequencyFilterOpen, setOpen: setIsScenarioFrequencyFilterOpen },
        // { field: "Scenario Group", headerName: "Scenario Group", minWidth: 170, isOpen: isScenarioGroupFilterOpen, setOpen: setIsScenarioGroupFilterOpen },
        { field: "alert_relevancy", headerName: "Alert Status Category", minWidth: 210, isOpen: isAlertStatusCategoryFilterOpen, setOpen: setIsAlertStatusCategoryFilterOpen, renderCell: "alert_relevancy" },
        { field: "event_date", headerName: "Event Date", minWidth: 140, isOpen: isEventDateFilterOpen, setOpen: setIsEventDateFilterOpen },
        { field: "year_month", headerName: "Year Month", minWidth: 140, isOpen: isYearMonthFilterOpen, setOpen: setIsYearMonthFilterOpen },
        { field: "risk_score", headerName: "Risk Score", minWidth: 150, isOpen: isCustomerIDFilterOpen, setOpen: setIsCustomerIDFilterOpen, renderCell: "risk_score" },
        // { field: "Customer Type", headerName: "Customer Type", minWidth: 170, isOpen: isCustomerTypeFilterOpen, setOpen: setIsCustomerTypeFilterOpen },
        // { field: "Risk Level", headerName: "Risk Level", minWidth: 150, isOpen: isRiskLevelFilterOpen, setOpen: setIsRiskLevelFilterOpen, renderCell: "riskLevel" },
        { field: "overlap_alert_code", headerName: "Overlap Alert ID", minWidth: 180, isOpen: isOverlapIDFilterOpen, setOpen: setIsOverlapIDFilterOpen },
        // { field: "Overlap Scenario", headerName: "Overlap Scenario", minWidth: 160, isOpen: false, setOpen: () => {return false}, renderCell: "overlapScenario"},
        // { field: "Overlap Scenario Group", headerName: "Overlap Scenario Group", minWidth: 200, isOpen: false, setOpen: () => {return false}, renderCell: "overlapScenarioGroup"}
        { field: "overlap_alert_relevancy", headerName: "Overlap Alert Status Category", minWidth: 210, isOpen: isAlertStatusCategoryFilterOpen, setOpen: setIsAlertStatusCategoryFilterOpen, renderCell: "alert_relevancy" },
        { field: "overlap_event_date", headerName: "Overlap Event Date", minWidth: 140, isOpen: isEventDateFilterOpen, setOpen: setIsEventDateFilterOpen },
        { field: "overlap_year_month", headerName: "Overlap Year Month", minWidth: 140, isOpen: isYearMonthFilterOpen, setOpen: setIsYearMonthFilterOpen },
        { field: "overlap_risk_score", headerName: "Overlap Risk Score", minWidth: 150, isOpen: isCustomerIDFilterOpen, setOpen: setIsCustomerIDFilterOpen, renderCell: "risk_score" },
    ];

    // Construct the columns
    const allColumns = columnsConfig.map(({ field, headerName, minWidth, maxWidth, isOpen, setOpen, renderCell }) => ({
        field,
        headerName,
        minWidth,
        maxWidth,
        headerClassName: styles.headerCell,
        renderHeader: () => (
            (field === 'alert_code') ? 
            <div className={`${styles.columnHeader} ${styles.headerCell} ${styles.filterSelectorContainer}`} style={{ minWidth: minWidth,  borderRight: '1px solid #E5E5E5',padding:"15px" }}>
                
                {headerName}
                <button className={styles.filterButton} style={{ opacity: isOpen ? 0 : 1 }} onClick={(e) => !isOpen ? onFilterClick(isOpen, setOpen, e) : ''}>
                    <img alt=""src={`${process.env.PUBLIC_URL}/Filters lines.svg`}  />
                </button>
                <div onClick={() => handleSort(field)} className={styles.sortButton} style={{ opacity: sortConfig.key === field  ? 1 : 0.5}}>
                    {sortConfig.key === field ? (
                        sortConfig.direction === 'ascending' ? '↑' : '↓'
                    ) : (
                        '↑'
                    )}
                </div>
            </div> 
            :
            <div className={`${styles.columnHeader} ${styles.headerCell} ${styles.filterSelectorContainer}`}>
            {headerName}
            <button className={styles.filterButton} style={{ opacity: isOpen ? 0 : 1 }} onClick={(e) => !isOpen ? onFilterClick(isOpen, setOpen, e) : ''}>
                <img alt=""src={`${process.env.PUBLIC_URL}/Filters lines.svg`}  />
            </button>
            <div onClick={() => handleSort(field)} className={styles.sortButton} style={{ opacity: sortConfig.key === field  ? 1 : 0.5}}>
                    {sortConfig.key === field ? (
                        sortConfig.direction === 'ascending' ? '↑' : '↓'
                    ) : (
                        '↑'
                    )}
            </div>
        </div>
      
            
        ),
        // How to render different cells
        renderCell: (row) => {
            if (renderCell === "alert_relevancy") {
                const cssClass = {
                    "Non Relevant": view_style.nonRelevantBadge,
                    "Relevant": view_style.relevantBadge,
                    "SAR": view_style.sarBadge,
                    "Alert Ongoing": view_style.relevantBadge,
                }[row[field]] || "";
                return (
                    <div className={styles.badgesContainer}>
                        <div className={cssClass}>{row[field]}</div>
                    </div>
                );
            } else if (renderCell === "riskLevel") {
                const riskClass = {
                    "HIGH": styles.highRisk,
                    "MEDIUM": styles.mediumRisk,
                    "STANDARD": styles.standardRisk,
                }[row[field].toUpperCase()] || "";
                return (
                    <div className={`${riskClass} ${styles.cellContent}`}>
                        {row[field].toUpperCase()}
                    </div>
                );
            } else if (renderCell === "risk_score") {
                const riskClass = {
                    "HIGH": styles.highRisk,
                    "MEDIUM": styles.mediumRisk,
                    "LOW": styles.standardRisk,
                }[row[field].toUpperCase()] || "";
                return (
                    <div className={`${riskClass} ${styles.cellContent}`}>
                        {row[field].toUpperCase()}
                    </div>
                );

            } else if (renderCell === "overlapScenario") {
                return (
                    <div className={styles.cellContent}>Placeholder</div>
                )
            } else if (renderCell === "overlapScenarioGroup") {
                return (
                    <div className={styles.cellContent}>Placeholder</div>
                )
            }
            else {
                return <div className={styles.cellContent}>{row[field]}</div>;
            }
        },
    }));

    const overlappingScenariosHeaderTitle = "Overlapping scenarios: " + scenarioIds[0] + " and " + scenarioIds[1]; 

    const filterByAlertID = (row) => !alertIDFilter.length || alertIDFilter.includes(row['Alert ID']);
    const filterByScenarioFrequency = (row) => !scenarioFrequencyFilter.length || scenarioFrequencyFilter.includes(row['Scenario Frequency']);
    const filterByScenarioGroup = (row) => !scenarioGroupFilter.length || scenarioGroupFilter.includes(row['Scenario Group']);
    const filterByRelevance = (row) => !relevanceFilter.length || relevanceFilter.includes(row['Alert Status Category']);
    const filterByEventDate = (row) => !eventDateFilter.length || eventDateFilter.includes(row['Event Date']);
    const filterByYearMonth = (row) => !yearMonthFilter.length || yearMonthFilter.includes(row['Year Month']);
    const filterByCustomerID = (row) => !customerIDFilter.length || customerIDFilter.includes(row['Customer ID']);
    const filterByCustomerType = (row) => !customerTypeFilter.length || customerTypeFilter.includes(row['Customer Type']);
    const filterByRiskLevel = (row) => !riskLevelFilter.length || riskLevelFilter.includes(row['Risk Level']);
    const filterByOverlapAlertID = (row) => !overlapIDFilter.length || overlapIDFilter.includes(row['Overlap Alert ID']);
    const filterBySearchQuery = (row) => Object.values(row).some((value) =>
        String(value).toLowerCase().includes(searchQuery.toLowerCase())
    );

    const combinedFilter = (row) => 
        filterByAlertID(row) &&
        filterByScenarioFrequency(row) &&
        filterByScenarioGroup(row) &&
        filterByRelevance(row) &&
        filterByEventDate(row) &&
        filterByYearMonth(row) &&
        filterByCustomerID(row) &&
        filterByCustomerType(row) &&
        filterByRiskLevel(row) &&
        filterByOverlapAlertID(row) &&
        filterBySearchQuery(row);

    const filteredRows = sortedRows
        .filter((row) => combinedFilter(row))
        .map((row, index) => ({
            id: index,
            'alert_code': row['alert_code'],
            'alert_relevancy': row['alert_relevancy'],
            'event_date': row['event_date'],
            'year_month': row['year_month'],
            'risk_score': row['risk_score'],
            'overlap_alert_code': row['overlap_alert_code'],
            'overlap_alert_relevancy': row['overlap_alert_relevancy'],
            'overlap_event_date': row['overlap_event_date'],
            'overlap_year_month': row['overlap_year_month'],
            'overlap_risk_score': row['overlap_risk_score']
        }));
       
    // Used for scrolling
    useEffect(() => {
        if (scrollerRef.current) {
            setScrollHeight(scrollerRef.current.clientHeight);
        }
    }, [overlapData, filteredRows]);

    const totalPages = Math.ceil(totalAlerts / pageSize);
    console.log("totalPages", totalPages);
    console.log("overlapData", overlapData);
    // Create the page numbers needed
    const createPageNumbers = () => {
        const maxPagesToShow = 5;
        const halfPagesToShow = Math.floor(maxPagesToShow / 2);
        
        let startPage = Math.max(1, currentPage + 1 - halfPagesToShow);
        let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
        
        if (endPage - startPage < maxPagesToShow - 1) {
            startPage = Math.max(1, endPage - maxPagesToShow + 1);
        }

        const pageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
        
        if (startPage > 1) {
            pageNumbers.unshift(1, '...');
        }
        if (endPage < totalPages) {
            pageNumbers.push('...', totalPages);
        }
        console.log("pageNumbers", pageNumbers);
        return pageNumbers;
    };
    
    // Create pages
    const paginatedRows = filteredRows;

    const onFilterClick = (filter, setFilterFunction, event) => {
        const action = setFilterFunction(!filter);
        dispatch(action);
    };

    // To handle the opening of the popup
    const selectedOverlapAlertId = useSelector((state) => state.overlapAnalysis.selectedOverlapAlertId)
    const [isPopUpOpen, setIsPopUpOpen] = useState(false);

    useEffect(() => {
        if (!selectedOverlapAlertId) {
            setIsPopUpOpen(false);
        }
        else {
            setIsPopUpOpen(true)
        }
    }, [selectedOverlapAlertId])

    // Handle click on return arrow in header - return to matrix overlap analysis
    const handleReturnClick = () => {
        navigate("/overlap-analysis")
    }

    // Make sure that popup is closed when entering the page
    useEffect(() => {


        console.log("makaolokokokoko");    
        setIsPopUpOpen(false);
    }, [])

    return (
        <div className={view_style.viewWrapper}>
            <AlertPopUp isOpen={isPopUpOpen} onClose={() => setIsPopUpOpen(false)}/>
            <div className={view_style.sectionWrapper}>

            <div className={view_style.sectionWrapper}>

                <div className={styles.headerSection}>
                    <div className={styles.titleCount}>
                        <div className={styles.arrowBox} onClick={handleReturnClick}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="15" viewBox="0 0 8 15" fill="none">
                                <path d="M7 1.10156L1 7.10156L7 13.1016" stroke="#5E6EFF" strokeOpacity="0.8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                        <div className={styles.kycSegmentRisk}>Alerts Overlap Analysis</div>
                        <div className={styles.count}>{totalAlerts} results</div>
                    </div>
                    <div className={styles.additionalText}>{overlappingScenariosHeaderTitle}</div>
                </div>

                <div className={styles.controls}>
                    <div className={styles.searchContainer}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 21" fill="none">
                            <path
                                d="M17.5 18.3906L13.875 14.7656M15.8333 10.0573C15.8333 13.7392 12.8486 16.724 9.16667 16.724C5.48477 16.724 2.5 13.7392 2.5 10.0573C2.5 6.37539 5.48477 3.39062 9.16667 3.39062C12.8486 3.39062 15.8333 6.37539 15.8333 10.0573Z"
                                stroke="#BDB4FE"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <input
                            type="text"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className={styles.searchInput}
                        />
                    </div>
                </div>
                
                <div className={styles.datasGridContainer}>
                    <div className={styles.tableWrapper} >
                        <div style={{ display: 'flex',  }} ref={flexDivRef}>

                                {columnsConfig.map(({ field, headerName, minWidth, maxWidth, isOpen, setOpen }, index) => (
                                    <div key={field}
                                        className={`${styles.columnHeader} ${styles.headerCell} ${styles.filterSelectorContainer}`} style={{ display: 'inline-flex', textWrap: 'nowrap', height: 0, left:0, position: headerName === "Alert ID" ? 'sticky' : 'relative', color: 'transparent',  backgroundColor: 'transparent', zIndex:  11  }} >
                                        {headerName}
                                        <button className={styles.filterButton} style={{ opacity: 0 }} onClick={(e) => !isOpen ? onFilterClick(isOpen, setOpen, e) : ''}>


                                            <img alt=""src={`${process.env.PUBLIC_URL}/Filters lines.svg`}  />
                                        </button>
                                        {isOpen && (
                                        <div style={{

                                            display: 'inline', 
                                            position: 'absolute', 
                                            left: (headerName === "Alert ID" ? '0px': headerName === "Overlap Alert ID"  ? '-60px' : '-30px'), 
                                            top: '60px', 
                                            zIndex:  11, 
                                        }}>
                                            <FilterSelector
                                            all_options={alertOverlapFilters[headerName].filterTypes}
                                            applyFunction={alertOverlapFilters[headerName].setFilterFunction}
                                            title={alertOverlapFilters[headerName].title}
                                            selected_options={alertOverlapFilters[headerName].selected_options}
                                            setSelectedOptions={alertOverlapFilters[headerName].setSelectedOptions}
                                            isFilterOpen={isOpen}
                                            applyIsFilterOpenFunction={setOpen}
                                            overwriteClass={styles.filterSelector} // Apply the class for custom positioning
                                            />
                                        </div>
                                        )}
                                    </div>
                                ))}
                                
                        </div>



                                                        <div className={styles.loadingCell} style={{ display:tableStatus == "loading" ? "block" : "none" }} >
                                                            <span colSpan={allColumns.length} >
                                                                Loading...
                                                            </span>
                                                        </div>

                                                        

                        <Scrollbar 
                            style={{ height: scrollHeight}}     
                            trackXProps={{ style: { zIndex: '100', bottom: '0px', left: '10px', height: '5px' , backgroundColor: '#E9D7FE' } }} 
                            thumbXProps={{ style: { backgroundColor: '#D6BBFB' }}}
                            trackYProps={{ style: { width: '0px' }}}
                            wrapperProps={{
                                renderer: props => {
                                const { elementRef, ...restProps } = props;
                                return (
                                    <span
                                    {...restProps}
                                    ref={elementRef}
                                    style={{marginBottom: '-5px', position: 'absolute', inset: '0px 0px 5px 0px', overflow:'hidden'}}
                                    />
                                );
                                }
                            }}>

                            <div id="scrollWrapper" ref={scrollWrapperRef} className={styles.thinScrollbar}  style={{ width:"100%", height:"100%", overflowY:"visible" }}>
                                                       
                                            <table ref={scrollerRef} className={styles.customTable} style={{ width:"fill-available!important"  }}>
                                                <thead>
                                                    <tr>
                                                        {allColumns.map((col) => (
                                                            <th key={col.field} style={{ minWidth: col.minWidth }} className={col.field === 'Alert ID' ? styles.fixedHeaderColumn2 : ''}>
                                                                {col.renderHeader()}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {tableStatus === 'loading' ? (
                                                        <tr className={styles.tableRow} >
                                                            <td colSpan={allColumns.length} >
                                                               
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        
                                                        paginatedRows.map((row) => (
                                                            <tr key={row.id} className={styles.tableRow} onClick={() => handleRowClick(row["Alert ID"])}>
                                                                {allColumns.map((col) => (
                                                                    <td key={col.field} className={`${col.field === 'Alert ID' ? styles.fixedColumn : ''}`}>
                                                                        <div className={`${col.field === 'Alert ID' ? styles.cellContentfixed : ''}`}>
                                                                            {col.renderCell(row)}
                                                                        </div>
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                        ))
                                                      
                                                    )}
                                                </tbody>
                                            </table>
                            </div>
                        </Scrollbar>
                        
                    </div>

                    <div className={styles.pagination}>
                        <button
                            className={styles.paginationButton}
                            style={{ marginLeft: "24px" }}
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 0 || tableStatus === 'loading'}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 21" fill="none">
                                <path
                                    d="M15.8327 10.1693H4.16602M4.16602 10.1693L9.99935 16.0026M4.16602 10.1693L9.99935 4.33594"
                                    stroke="#6941C6"
                                    strokeWidth="1.67"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            Previous
                        </button>
                        <div className={styles.pageNumbers}>
                            {createPageNumbers().map((number, index) => (
                                <button
                                    key={index}
                                    onClick={() => {
                                        if (number !== "..." && tableStatus !== 'loading') {
                                            handlePageChange(number - 1);
                                        }
                                    }}
                                    disabled={number === "..." || tableStatus === 'loading'}
                                    className={
                                        currentPage === number - 1
                                            ? styles.activePage
                                            : styles.unactivePage
                                    }
                                >
                                    {number}
                                </button>
                            ))}
                        </div>
                        <button
                            className={styles.paginationButton}
                            style={{ marginRight: "24px" }}
                            onClick={() => handlePageChange(currentPage + 1)}

                            disabled={(currentPage + 1) * pageSize >= totalAlerts || tableStatus === 'loading'}


                        >
                            Next
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 21" fill="none">
                                <path
                                    d="M4.16602 10.1693H15.8327M15.8327 10.1693L9.99935 4.33594M15.8327 10.1693L9.99935 16.0026"
                                    stroke="#6941C6"
                                    strokeWidth="1.67"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
};

export default AlertsOverlap;
