import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {useNavigate} from 'react-router-dom';
import HeaderSection from './header/HeaderSection';
import ScenPerformOverview from './scenarios_table/ScenariosTable';
import SortingSelection from './sorting_selectors/SortingSelection';
import styles from './ScenarioOverview.module.css';
import ModalPopUp from '../../layout/modal/Modal';

import TableSummary from './scenarios_table/TableSummary';

import {setRecommendationMessage, setRequestedAtl, setRequestedBtl, setRequestedLogic, setSelectedScenario} from '../../features/scenario_overview/scenarioOverviewSlice';
import { clearSelectedSegment, fetchSegments, setSelectedSegment, setSelectedSubScenario } from '../../features/atl_tool/atlToolSlice';
import { fetchScenarioDetails } from '../../features/configuration/configurationSlice';

export default function ScenarioOverview() {
    const totalAmountOfDS = useSelector((state) => state.scenarioPerformance.totalScenarioGroups);
    const recommendationMessage = useSelector((state) => state.scenarioPerformance.recommendationMessage);
    const dispatch = useDispatch();
    // State to handle modal viconsibility
    const navigate = useNavigate();
    const requestedAtl = useSelector((state) => state.scenarioPerformance.requestedAtl);
    const requestedBtl = useSelector((state) => state.scenarioPerformance.requestedBtl);
    const requestedLogic = useSelector((state) => state.scenarioPerformance.requestedLogic);
    
    const [isModalATLVisible, setIsModalATLVisible] = useState(false);
    const [isModalBTLVisible, setIsModalBTLVisible] = useState(false);
    const [isModalLOGICVisible, setIsModalLOGICVisible] = useState(false);

    // Function to toggle modal visibility
    const toggleModalAtl = (isVisible) => {
        setIsModalATLVisible(isVisible);
  
        if(!isVisible){
            dispatch(setRecommendationMessage(""));
            dispatch(setRequestedAtl({scenario: 0, customer_segment: null}));
        }
    };

    // Function to toggle modal visibility
    const toggleModalBTL = (isVisible) => {
        setIsModalBTLVisible(isVisible);

        if(!isVisible){
            dispatch(setRecommendationMessage(""));
            dispatch(setRequestedBtl(0));
        }
    };

    // Function to toggle modal visibility
    const toggleModalLogic = (isVisisble) => {
        setIsModalLOGICVisible(isVisisble);

        if(!isVisisble){
            dispatch(setRecommendationMessage(""));
            dispatch(setRequestedLogic(0));
        }
    }
 
    useEffect(() => {
        if (requestedAtl.scenario_id > 0) {
            setIsModalATLVisible(true);
        }
    }, [requestedAtl]);

    useEffect(() => {
        if (requestedBtl > 0) {
            setIsModalBTLVisible(true);
         }
    }, [requestedBtl]);

    useEffect(() => {
        if (requestedLogic.category_id > 0) {
            setIsModalLOGICVisible(true);
        }
    }, [requestedLogic]);

    const goToAtl = () => {
        dispatch(setSelectedSubScenario(null));
        if (requestedAtl.customer_segment !== null){
            dispatch(setSelectedSegment(requestedAtl.customer_segment));
        }
        else {
            dispatch(clearSelectedSegment());
        }
        navigate(`/scenario_performance/${requestedAtl.scenario_id}`);
        dispatch(fetchSegments({selectedScenarioId: requestedAtl.scenario_id, selectedSubScenario: null, selectedYearState: new Date().getFullYear()}));
        dispatch(setRequestedAtl(0));
    }

    const goToBtl = () => {
        navigate(`/configuration/${requestedBtl}`);
        dispatch(setRequestedBtl(0));
    }

    const goToLogic = () => {
        navigate(`/configuration/${requestedLogic.category_id}/1`);
        dispatch(setRequestedLogic(0));
        dispatch(fetchScenarioDetails({scenarioID: requestedLogic.scenario_id}));
    }

    const modal_atl = (
        <ModalPopUp 
            handleClose={() => toggleModalAtl(false)}
            isOpen={true}
            title={"High Level of Non-Relevant Alerts"}
            description={recommendationMessage}
            actionButtons={[
                <div className={`${styles.buttonBox} ${styles.whiteButton}`} onClick={() => toggleModalAtl(false)}>
                    <div className={styles.textCancel}>
                        Cancel
                    </div>
                </div>
                ,
                <div className={`${styles.buttonBox} ${styles.purpleButton}`} onClick={(e) =>{ e.stopPropagation();goToAtl() ; }}>
                    <div className={styles.textEdit}>
                        Confirm
                    </div>
                </div>
            ]}
            icon={
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                    <g id="ATL Icon">
                    <path id="Vector" d="M7.91992 8C8.19606 8 8.41992 7.77614 8.41992 7.5C8.41992 7.22386 8.19606 7 7.91992 7C7.64378 7 7.41992 7.22386 7.41992 7.5C7.41992 7.77614 7.64378 8 7.91992 8Z" fill="#7A5AF8" stroke="#7A5AF8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path id="Vector_2" d="M18.9199 6C19.1961 6 19.4199 5.77614 19.4199 5.5C19.4199 5.22386 19.1961 5 18.9199 5C18.6438 5 18.4199 5.22386 18.4199 5.5C18.4199 5.77614 18.6438 6 18.9199 6Z" fill="#7A5AF8" stroke="#7A5AF8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path id="Vector_3" d="M11.9199 12C12.1961 12 12.4199 11.7761 12.4199 11.5C12.4199 11.2239 12.1961 11 11.9199 11C11.6438 11 11.4199 11.2239 11.4199 11.5C11.4199 11.7761 11.6438 12 11.9199 12Z" fill="#7A5AF8" stroke="#7A5AF8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path id="Vector_4" d="M7.91992 17C8.19606 17 8.41992 16.7761 8.41992 16.5C8.41992 16.2239 8.19606 16 7.91992 16C7.64378 16 7.41992 16.2239 7.41992 16.5C7.41992 16.7761 7.64378 17 7.91992 17Z" fill="#7A5AF8" stroke="#7A5AF8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path id="Vector_5" d="M17.9199 15C18.1961 15 18.4199 14.7761 18.4199 14.5C18.4199 14.2239 18.1961 14 17.9199 14C17.6438 14 17.4199 14.2239 17.4199 14.5C17.4199 14.7761 17.6438 15 17.9199 15Z" fill="#7A5AF8" stroke="#7A5AF8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path id="Vector_6" d="M3.41992 3V19C3.41992 19.5304 3.63064 20.0391 4.00571 20.4142C4.38078 20.7893 4.88949 21 5.41992 21H21.4199" stroke="#7A5AF8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                </svg>
            }
        />
    )

    const modal_btl = (
        <ModalPopUp 
            handleClose={() => toggleModalBTL(false)}
            isOpen={true}
            title={"High Level of SAR Alerts"}
            description={recommendationMessage}
            actionButtons={[
                <div className={`${styles.buttonBox} ${styles.whiteButton}`} onClick={() => toggleModalBTL(false)}>
                    <div className={styles.textCancel}>
                        Cancel
                    </div>
                </div>
                ,
                <div className={`${styles.buttonBox} ${styles.purpleButton}`} onClick={(e) =>{ e.stopPropagation(); goToBtl()}}>
                    <div className={styles.textEdit}>
                        Confirm
                    </div>
                </div>
            ]}
            icon={
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                    <path d="M23 10H14C13.4696 10 12.9609 10.2107 12.5858 10.5858C12.2107 10.9609 12 11.4696 12 12V28C12 28.5304 12.2107 29.0391 12.5858 29.4142C12.9609 29.7893 13.4696 30 14 30H26C26.5304 30 27.0391 29.7893 27.4142 29.4142C27.7893 29.0391 28 28.5304 28 28V15L23 10Z" stroke="#7A5AF8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M22 10V14C22 14.5304 22.2107 15.0391 22.5858 15.4142C22.9609 15.7893 23.4696 16 24 16H28" stroke="#7A5AF8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M16 26V24" stroke="#7A5AF8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M20 26V22" stroke="#7A5AF8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M24 26V20" stroke="#7A5AF8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            }
        />
    );

    const modal_logic = (
        <ModalPopUp 
            handleClose={() => toggleModalLogic(false)}
            isOpen={true}
            title={"Low Volume of Alerts"}
            description={recommendationMessage}
            actionButtons={[
                <div className={`${styles.buttonBox} ${styles.whiteButton}`} onClick={() => toggleModalLogic(false)}>
                    <div className={styles.textCancel}>
                        Cancel
                    </div>
                </div>
                ,
                <div className={`${styles.buttonBox} ${styles.purpleButton}`} onClick={(e) =>{ e.stopPropagation(); goToLogic()}}>
                    <div className={styles.textEdit}>
                        Confirm
                    </div>
                </div>
            ]}
            icon={
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M21.64 3.63998L20.36 2.35998C20.2475 2.2463 20.1135 2.15605 19.9659 2.09446C19.8183 2.03287 19.6599 2.00116 19.5 2.00116C19.34 2.00116 19.1817 2.03287 19.0341 2.09446C18.8864 2.15605 18.7525 2.2463 18.64 2.35998L2.35998 18.64C2.2463 18.7525 2.15605 18.8864 2.09446 19.0341C2.03287 19.1817 2.00116 19.34 2.00116 19.5C2.00116 19.6599 2.03287 19.8183 2.09446 19.9659C2.15605 20.1135 2.2463 20.2475 2.35998 20.36L3.63998 21.64C3.7518 21.7549 3.88552 21.8462 4.03323 21.9086C4.18094 21.971 4.33965 22.0031 4.49998 22.0031C4.66032 22.0031 4.81903 21.971 4.96674 21.9086C5.11445 21.8462 5.24816 21.7549 5.35998 21.64L21.64 5.35998C21.7549 5.24816 21.8462 5.11445 21.9086 4.96674C21.971 4.81903 22.0031 4.66032 22.0031 4.49998C22.0031 4.33965 21.971 4.18094 21.9086 4.03323C21.8462 3.88552 21.7549 3.7518 21.64 3.63998Z" stroke="#905EE2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M14 7L17 10" stroke="#905EE2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M5 6V10" stroke="#905EE2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M19 14V18" stroke="#905EE2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10 2V4" stroke="#905EE2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7 8H3" stroke="#905EE2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M21 16H17" stroke="#905EE2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11 3H9" stroke="#905EE2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            }
        />
    );

    return (
        <>
        <div className={styles.scenOverview}>

            {isModalATLVisible && modal_atl}
            {isModalBTLVisible && modal_btl}
            {isModalLOGICVisible && modal_logic}

            <div className={styles.sectionWrapper} style={{ paddingBottom: '0px' }}>
                <HeaderSection SectionName='Scenario Performance Overview' AmountOfResults={totalAmountOfDS} />

                <div className={styles.dataWrapper}>
                    <SortingSelection />
                    <TableSummary />
                    <ScenPerformOverview />
                </div>
            </div>
        </div>
        </>
    );
}