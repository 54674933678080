import { useState, useEffect, useRef } from "react";
import Scrollbar from "react-scrollbars-custom";

import Pagination from "./Pagination";
import { TableTools, exportToExcel } from "./TableTools";
import { HeaderCell } from "./Header";
import { Cell } from "./Cell";
import styles from "./Table.module.css";
import FilterSelector from "./Filter";

function filterRows({ currentFilters, sortOn, searchTerm, rows }) {
  let filteredRows = rows;
  if (Object.keys(currentFilters).length > 0) {
    filteredRows = filteredRows.filter((row) => {
      return Object.entries(currentFilters).every(([field, filterValues]) => {
        const cell = row.cells.find((cell) => cell.field === field);
        if (cell) {
          return filterValues.includes(cell.value);
        }
        return true;
      });
    });
  }

  if (searchTerm) {
    const lowerSearchTerm = searchTerm.toLowerCase();
    filteredRows = filteredRows.filter((row) => {
      return row.cells.some((cell) => {
        return (
          cell.value &&
          cell.value.toString().toLowerCase().includes(lowerSearchTerm)
        );
      });
    });
  }

  if (sortOn.length === 2) {
    const [sortField, sortDirection] = sortOn;
    filteredRows.sort((a, b) => {
      const aCell = a.cells.find((cell) => cell.field === sortField);
      const bCell = b.cells.find((cell) => cell.field === sortField);
      const aValue = aCell ? aCell.value : "";
      const bValue = bCell ? bCell.value : "";

      if (aValue < bValue) return sortDirection === "ASC" ? -1 : 1;
      if (aValue > bValue) return sortDirection === "ASC" ? 1 : -1;
      return 0;
    });
  }

  return filteredRows;
}

function generateFilterOptions(headers, rows) {
  const filterOptions = {};

  headers.forEach((header) => {
    if (header.filterable) {
      const field = header.field;
      const valuesSet = new Set();

      rows.forEach((row) => {
        const cell = row.cells.find((cell) => cell.field === field);
        if (cell && cell.value != null) {
          valuesSet.add(cell.value);
        }
      });

      filterOptions[field] = Array.from(valuesSet);
    }
  });

  return filterOptions;
}

function prioritizeRows(rows, alwaysFirstId) {
  if (!alwaysFirstId) return rows;
  const firstRow = rows.find((row) => row.id === alwaysFirstId);
  const remainingRows = rows.filter((row) => row.id !== alwaysFirstId);
  return firstRow ? [firstRow, ...remainingRows] : rows;
}

function TableGrid({
  title,
  headers,
  rows,
  pageSize = 10,
  isLoading = false,
  noDataPlaceholder = undefined,
  rowsAmount = rows.length,
  canExport = false,
  onExport = undefined,
  canSearch = true,
  filterOptions = generateFilterOptions(headers, rows),
  fetchData = {},
  onRowClick = undefined,
  onSelectActionButtons = [],
  initFilters = {},
  cellStyle = {},
  initSort = [],
  initSearchTerm = "",
  resetTable = 1,
  resetPagination = 1,
  extraContent = undefined,
  newIds = undefined,
  actionButtonsRight = [],
  actionButtonsLeft = [],
  alwaysFirstId = null,
  highlightRowIds = [],
}) {
  // Gathering pre information
  const {
    fetchFunction = undefined,
    retriggers = [], // Default to empty array if not provided
  } = fetchData;
  const canSelect = onSelectActionButtons.length > 0;

  // Loading
  const tbodyRef = useRef(null);
  const [showLoading, setShowLoading] = useState(false);
  useEffect(() => {
    let timer;
    if (isLoading) {
      timer = setTimeout(() => setShowLoading(true), 300); // Delay showing the loader by 500ms
    } else {
      clearTimeout(timer);
      setShowLoading(false); // Hide immediately if isLoading is false
    }

    return () => clearTimeout(timer);
  }, [isLoading]);

  // Filters, Pagenation, Sorting, Searching
  const [currentPage, setCurrentPage] = useState(1);
  const [currentFilters, setCurrentFilters] = useState(initFilters);

  const [sortOn, setSortOn] = useState(initSort);
  const [searchTerm, setSearchTerm] = useState(initSearchTerm);

  let prioritizedRows = prioritizeRows(rows, alwaysFirstId);

  let filteredRows = prioritizedRows;

  if (!fetchFunction) {
    filteredRows = filterRows({
      currentFilters,
      sortOn,
      searchTerm,
      rows: filteredRows,
    });
    filteredRows = filteredRows.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );
  }

  useEffect(() => {
    if (fetchFunction) {
      fetchFunction(pageSize, currentPage, currentFilters, sortOn, searchTerm);
    }
  }, [currentPage, currentFilters, sortOn, searchTerm, ...retriggers]);

  useEffect(() => {
    if (fetchFunction) {
      setSelectedIds(new Set());
      setSelectAllMode(false);
    }
  }, [currentFilters, sortOn, searchTerm]);

  // Filter popups
  const [filterPosition, setFilterPosition] = useState({ top: 0, left: 0 });
  const [openFilterField, setOpenFilterField] = useState(null);
  const outsideTableRef = useRef(null);

  const setSelectedOptions = (selectedOptions) => {
    setCurrentFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (selectedOptions.length === 0) {
        delete updatedFilters[openFilterField];
      } else {
        updatedFilters[openFilterField] = selectedOptions;
      }

      return updatedFilters;
    });
  };

  const handleOpenFilter = (field, buttonRef) => {
    const buttonRect = buttonRef.current.getBoundingClientRect();
    const tableRect = outsideTableRef.current.getBoundingClientRect();

    setFilterPosition({
      top: buttonRect.bottom - tableRect.top + 60, // Position relative to the table
      left: buttonRect.left - tableRect.left,
    });
    setOpenFilterField(field);
  };

  const handleCloseFilter = () => setOpenFilterField(null);

  // Selecting
  const [selectAllMode, setSelectAllMode] = useState(false);
  const [selectedIds, setSelectedIds] = useState(() => new Set());
  const allSelected =
    rowsAmount > 0 &&
    ((rowsAmount === selectedIds.size && selectAllMode === false) ||
      (selectAllMode == true && selectedIds.size === 0));

  const toggleSelection = (id) => {
    setSelectedIds((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.add(id);
      }
      return newSelected;
    });
  };

  const toggleSelectAll = () => {
    setSelectedIds(new Set());
    setSelectAllMode(!allSelected);
  };

  const resetSelected = () => {
    setSelectedIds(new Set());
    setSelectAllMode(false);
  };

  // Height Animation
  const headerHeight = 80;
  const tableRef = useRef(null);
  const [tableHeight, setTableHeight] = useState(headerHeight);

  useEffect(() => {
    if (tableRef.current) {
      const newHeight = tableRef.current.scrollHeight;
      setTableHeight(newHeight);
    }
  }, [filteredRows]);

  // Resetting Table back to initial
  useEffect(() => {
    if (resetTable > 1) {
      setCurrentPage(1);
      setCurrentFilters(initFilters);
      setSortOn(initSort);
      setSearchTerm(initSearchTerm);
    }
  }, [resetTable]);

  useEffect(() => {
    if (resetPagination > 1) {
      setCurrentPage(1);
    }
  }, [resetPagination]);

  // Exporting
  function exportTable() {
    const headerLabels = headers.map((header) => header.label);

    const columns = rows.map((row) => {
      return row.cells.map((cell) => cell.value);
    });

    exportToExcel(headerLabels, columns, title);
  }

  return (
    <div className={styles.tableContainer}>
      <TableTools
        canSearch={canSearch}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        canSelect={canSelect}
        toggleSelectAll={toggleSelectAll}
        allSelected={allSelected}
        selectAllMode={selectAllMode}
        selectedIds={selectedIds}
        onSelectActionButtons={onSelectActionButtons}
        onExport={canExport ? (onExport ? onExport : exportTable) : undefined}
        rowsAmount={rowsAmount}
        fetchFunction={fetchFunction}
        actionButtonsRight={actionButtonsRight}
        actionButtonsLeft={actionButtonsLeft}
        resetSelected={resetSelected}
        rows={filteredRows}
      />

      <div
        className={styles.tableContentContainer}
        style={{ height: tableHeight }}
        ref={outsideTableRef}>
        <Scrollbar
          noScrollY={true}
          trackXProps={{ style: { height: "5px", backgroundColor: "#E9D7FE" } }}
          thumbXProps={{ style: { backgroundColor: "#D6BBFB" } }}
          className={styles.barChart}
          style={{
            position: "relative",
            height: "100%",
            width: "100%",
            overflowY: "visible",
          }}>
          <table className={styles.table} ref={tableRef}>
            <thead style={{ height: headerHeight }}>
              <tr style={{ position: "relative", zIndex: 4 }}>
                {headers.map((header, index) => (
                  <th key={index} style={header.style}>
                    <div className={styles.headLabel}>
                      <HeaderCell
                        setSortOn={setSortOn}
                        sortOn={sortOn}
                        headerConf={header}
                        openFilterField={openFilterField}
                        handleOpenFilter={handleOpenFilter}
                        handleCloseFilter={handleCloseFilter}
                      />
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody ref={tbodyRef}>
              {rows.length > 0 ? (
                <>
                  {filteredRows.length !== 0 ? (
                    filteredRows.map((row, rowIndex) => (
                      <tr
                        key={rowIndex}
                        className={onRowClick && styles.clickable}
                        onClick={() => {
                          if (onRowClick) {
                            onRowClick(row?.rowData ? row.rowData : row);
                          }
                        }}>
                        {row.cells.map((cell, cellIndex) => (
                          <td
                            key={cellIndex}
                            className={`${
                              newIds && newIds.includes(row.id) && styles.newRow
                            } ${
                              highlightRowIds.includes(row.id) &&
                              styles.highlight
                            }`}>
                            <div className={styles.bodyLabel} style={cellStyle}>
                              <Cell
                                key={cellIndex}
                                cellConf={cell}
                                canSelect={cellIndex === 0 && canSelect}
                                selected={
                                  selectAllMode
                                    ? !selectedIds.has(row.id)
                                    : selectedIds.has(row.id)
                                }
                                setSelected={() => toggleSelection(row.id)}
                              />
                            </div>
                          </td>
                        ))}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        style={{ height: 90 }}
                        colSpan={headers.length}
                        className={styles.placeholder}>
                        No results found
                      </td>
                    </tr>
                  )}
                </>
              ) : (
                <>
                  {noDataPlaceholder && (
                    <tr>
                      <td
                        style={{ height: 90 }}
                        colSpan={headers.length}
                        className={styles.placeholder}>
                        {noDataPlaceholder}
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
          {showLoading && (
            <div
              className={styles.loadingOverlay}
              style={{
                top: tbodyRef.current?.offsetTop,
                height: tbodyRef.current?.offsetHeight,
              }}>
              <div className={styles.spinner}></div>
            </div>
          )}
        </Scrollbar>
        {openFilterField && (
          <div
            style={{
              position: "absolute",
              top: filterPosition.top,
              left: filterPosition.left,
              zIndex: 1000,
            }}>
            <FilterSelector
              options={filterOptions[openFilterField]}
              selectedOptions={currentFilters[openFilterField]}
              isFilterOpen={Boolean(openFilterField)}
              closeFilter={handleCloseFilter}
              title={openFilterField}
              setSelectedOptions={setSelectedOptions}
              outlineRight={openFilterField == headers[0].field}
            />
          </div>
        )}
      </div>
      {extraContent && extraContent}
      <Pagination
        pages={Math.ceil(rowsAmount / pageSize)}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}

export default TableGrid;
