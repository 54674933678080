import React from 'react';
import { useSelector } from 'react-redux';

const BarY = ({ height }) => {
    const chartHeight = useSelector((state) => state.atlTool.chartHeight);

    const determineInterval = (chartHeight) => {

      // Determine an appropriate interval for the chart labels
      if (chartHeight <= 10) return 1;
      if (chartHeight <= 50) return 10;
      if (chartHeight <= 100) return 20;
      if (chartHeight <= 300) return 50;
      if (chartHeight <= 500) return 100;
      return 200;
    };

    const interval = determineInterval(chartHeight);
    const numLabels = Math.floor((chartHeight * 0.95) / interval);

    const labels = [];
    for (let i = 0; i <= numLabels; i++) {
      labels.push(i * interval);
    }

    return (
      <div style={{ height: height, position: 'sticky', left:0,  paddingRight: 30 }}>
        {labels.map((label, index) => (
          <div
            key={index}
            style={{
              position: 'absolute',
              bottom: `${(label / chartHeight) * 100}%`,
              left: 0,
              transform: 'translateY(50%)',
              borderRadius:  '0',
              background:  'transparent',
              padding:  '0', // Adding padding for better visibility
              color: 'inherit', // Change text color to white for better contrast
              marginLeft:  '0px', // Change text color to white for better contrast

            }}
          >
            {label}
          </div>
        ))}
      </div>
    );
};

export default BarY;
