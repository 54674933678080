import React from 'react';
import { VictoryPie } from 'victory';
import styles from '../../atl_tool/segment_overview/SegmentOverview.module.css';

const SimplePieChart = ({ risk_score }) => {
  const data = [
    { x: 'Non Relevant Alerts', y: risk_score.non_relevant_alerts },
    { x: 'Relevant Alerts', y: risk_score.relevant_alerts },
    { x: 'SAR Alerts', y: risk_score.sar_alerts },
    { x: 'Empty', y: 0 }
  ];

  const totalAlerts = risk_score.non_relevant_alerts + risk_score.relevant_alerts + risk_score.sar_alerts;
  const colorScale = ['url(#greenGradient)', 'url(#yellowGradient)', 'url(#redGradient)', 'url(#EmptyGradient)'];

  return (
    <div className={styles.pieChartBox}>
      <div className={styles.riskScoreTagText}>
        <div className={styles.riskScoreTagTextBox} style={{ color: 'rgba(138, 66, 255, 0.6)' }}>
          {risk_score.name}
        </div>
      </div>
      <div className={styles.pieChartSubBox}>
        <div className={styles.alertsLegend}>
          <div className={styles.frameParent}>
            <div className={`${styles.totalBox} ${styles.piechartBoxTextActive}`}>
              {totalAlerts} Alerts
            </div>
            <div className={styles.frameGroup}>
              <img className={styles.color_circle} alt="" src="Color_red.svg" />
              <div className={styles.labelGroup}>
                <div className={styles.alerts_label}>SAR alerts</div>
                <div className={styles.labelContainer}>
                  <div>{risk_score.sar_alerts}</div>
                </div>
              </div>
            </div>
            <div className={styles.frameGroup}>
              <img className={styles.color_circle} alt="" src="Color_yellow.svg" />
              <div className={styles.labelGroup}>
                <div className={styles.alerts_label}>Relevant alerts</div>
                <div className={styles.labelContainer}>
                  <div>{risk_score.relevant_alerts}</div>
                </div>
              </div>
            </div>
            <div className={styles.frameGroup}>
              <img className={styles.color_circle} alt="" src="Color_green.svg" />
              <div className={styles.labelGroup}>
                <div className={styles.alerts_label}>Non relevant alerts</div>
                <div className={styles.labelContainer}>
                  <div>{risk_score.non_relevant_alerts}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.mediumPieChart}>
          <svg style={{ height: 0 }}>
            <defs>
              <linearGradient id="greenGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" style={{ stopColor: '#A5F2A3', stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: '#4DC842', stopOpacity: 1 }} />
              </linearGradient>
              <linearGradient id="yellowGradient" x1="0%" y1="0%" x2="100%" y2="36%">
                <stop offset="0%" style={{ stopColor: '#FEE4A3', stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: '#F6BA5E', stopOpacity: 1 }} />
              </linearGradient>
              <linearGradient id="redGradient" x1="0%" y1="0%" x2="100%" y2="70%">
                <stop offset="0%" style={{ stopColor: '#FDBCBC', stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: '#E46F6F', stopOpacity: 1 }} />
              </linearGradient>
              <linearGradient id="EmptyGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" style={{ stopColor: '#E8EBFE', stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: '#E8EBFE', stopOpacity: 1 }} />
              </linearGradient>
            </defs>
          </svg>
          <VictoryPie
            data={data}
            colorScale={colorScale}
            style={{
              data: {
                fillOpacity: 0.9,
                stroke: '#fff',
                strokeWidth: 0,
                filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1))',
              },
              labels: {
                display: 'none',
              },
            }}
            innerRadius={50}
            cornerRadius={4}
            animate={{ duration: 200 }}
          />
          <div className={styles.frameParent2}>
            <div className={styles.pie_medal}></div>
            <div className={`${styles.div} ${styles.pieChartText}`}>{totalAlerts}</div>
            <div className={`${styles.alerts_label} ${styles.pieChartText}`}>alerts</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimplePieChart;
