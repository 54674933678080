import React from 'react';
import styles from './ReportBuilder.module.css';
import SimplePieChart from './SimplePieChart';
import { useState, useEffect, useRef } from 'react';
import ReportElementWrapper from './ReportElementWrapper';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

const Title = ({ content, onChange, isEditable }) => {
    const [localContent, setLocalContent] = useState(content);
  
    useEffect(() => {
      setLocalContent(content);
    }, [content]);
  
    const handleInputChange = (e) => {
      setLocalContent(e.target.value);
    };
  
    const handleBlur = () => {
      onChange(localContent);
    };
  
    return (
      <div className={styles.elementContainer}>
        <input
          type="text"
          value={localContent}
          onChange={handleInputChange}
          onBlur={handleBlur}
          className={`${styles.titleSpan} ${styles.inputNoBorder}`}
          disabled={!isEditable}
        />
      </div>
    );
  };
  

const Text = ({ content, onChange, isEditable }) => {
    const [localContent, setLocalContent] = useState(content);
    const divRef = useRef(null);
  
    useEffect(() => {
      // Update local content if the content prop changes externally
      setLocalContent(content);
      if (divRef.current) {
        divRef.current.innerHTML = content;
      }
    }, [content]);
  
    const handleInput = (e) => {
      setLocalContent(e.target.innerHTML);
    };
  
    const handleBlur = () => {
      // Notify parent component of the updated content
      onChange(localContent);
    };
  
    return (
      <div className={styles.elementContainer}>
        <div
          ref={divRef}
          contentEditable={isEditable}
          suppressContentEditableWarning={true}
          onInput={handleInput}
          onBlur={handleBlur}
          className={`${styles.textSpan} ${styles.divEditable}`}
        />
      </div>
    );
  };

  const formatNumber = (number) => {
    return new Intl.NumberFormat('es-ES', {
      minimumFractionDigits: number % 1 !== 0 ? 1 : 0, 
      maximumFractionDigits: 3,
    }).format(number);
  };
  

  const Table = ({ title, content, onTitleChange, isEditable }) => (
    <div className={styles.tableContainer}>
      {/* <div className={styles.elementContainer}>
        <input
          type="text"
          value={title}
          onChange={(e) => onTitleChange(e.target.value)}
          className={`${styles.titleSpan} ${styles.inputNoBorder}`}
          disabled={!isEditable}
        />
      </div> */}
      <table className={`${styles.report_table} ${styles.tableReport}`}>
        <thead>
          <tr>
            {content.headers.map((header, index) => (
              <th key={index} className={`${styles.headerCell} ${styles.report_th}`}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {content.rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex} className={styles.report_td}>
                  {typeof cell === 'number' ? formatNumber(cell) : cell}
                 </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
  
  const Image = ({ src }) => (
    <div className={styles.elementContainer}>
      <img src={src} alt="Report Element" className={styles.reportImage} />
    </div>
  );

  const ReportElements = ({ reportJson, isEditable, onUpdate }) => {
    const elements = reportJson.elements || [];

    const handleElementChange = (index, newContent, isTable = false) => {
      const updatedElements = reportJson.elements.map((element, i) => {
        if (i === index) {
          return isTable
            ? { ...element, title: newContent }
            : { ...element, content: newContent };
        }
        return element;
      });
      onUpdate({ ...reportJson, elements: updatedElements });
    };
  
    const handleDeleteElement = (index) => {
        const updatedElements = reportJson.elements.filter((_, i) => i !== index);
        onUpdate({ ...reportJson, elements: updatedElements });
      };
    
    const handleMoveElementUp = (index) => {
        if (index === 0) return; // Ya está en la posición superior
        const updatedElements = [...reportJson.elements];
        const temp = updatedElements[index - 1];
        updatedElements[index - 1] = updatedElements[index];
        updatedElements[index] = temp;
        onUpdate({ ...reportJson, elements: updatedElements });
    };

    const handleMoveElementDown = (index) => {
        if (index === reportJson.elements.length - 1) return; // Ya está en la posición inferior
        const updatedElements = [...reportJson.elements];
        const temp = updatedElements[index + 1];
        updatedElements[index + 1] = updatedElements[index];
        updatedElements[index] = temp;
        onUpdate({ ...reportJson, elements: updatedElements });
    };
    
    const handleInsertElement = (index, newElement) => {
      const updatedElements = [
        ...reportJson.elements.slice(0, index + 1),
        newElement,
        ...reportJson.elements.slice(index + 1),
      ];
      onUpdate({ ...reportJson, elements: updatedElements });
    };
  
    const renderElement = (element, index) => {
      switch (element.type) {
        case 'title':
          return (
            <Title
              key={index}
              content={element.content}
              onChange={(newContent) => handleElementChange(index, newContent)}
              isEditable={isEditable}
            />
          );
        case 'text':
          return (
            <Text
              key={index}
              content={element.content}
              onChange={(newContent) => handleElementChange(index, newContent)}
              isEditable={isEditable}
            />
          );
        case 'table':
          return (
            <Table
              key={index}
              title={element.title}
              content={element.content}
              onTitleChange={(newTitle) => handleElementChange(index, newTitle, true)}
              isEditable={isEditable}
            />
          );
        case 'piechart':
          return (
            <div key={index} className={styles.elementContainer}>
              <div className={styles.subsegments}>
                {element.content.risk_scores.map((risk_score) => (
                  <SimplePieChart key={risk_score.id} risk_score={risk_score} />
                ))}
              </div>
            </div>
          );
        case 'image':
          return <Image key={index} src={element.content.url} />;
        // Add other cases as needed
        default:
          return null;
      }
    };
  
    return (
      <div className={styles.reportViewer}>
        <TransitionGroup>
          {elements.length === 0 && isEditable && (
            <CSSTransition
              key="empty"
              timeout={500}
              classNames="move"
            >
              <ReportElementWrapper
                key="empty"
                element={null}
                index={-1} // Usamos -1 para indicar que es antes del primer elemento
                isEditable={isEditable}
                handleInsertElement={handleInsertElement}
                handleDeleteElement={() => {}}
                handleMoveElementUp={() => {}}
                handleMoveElementDown={() => {}}
                renderElement={() => null}
                totalElements={0}
              />
            </CSSTransition>
          )}
          {elements.map((element, index) => (
            <CSSTransition
              key={element.id || index}
              timeout={500}
              classNames="move"
            >
              <ReportElementWrapper
                key={index}
                element={element}
                index={index}
                isEditable={isEditable}
                handleInsertElement={handleInsertElement}
                handleDeleteElement={handleDeleteElement}
                handleMoveElementUp={handleMoveElementUp}
                handleMoveElementDown={handleMoveElementDown}
                renderElement={renderElement}
                totalElements={elements.length}
              />
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
    );
  };

export default ReportElements;
