import React from 'react';
import styles from './NameBadge.module.css';

const colors = [
    { background: "#EBE9FE", text: "#5B34B1" }, // Purple-100
    { background: "#E0F2FE", text: "#2E73B8" }, // Blue-light-100
    { background: "#FFE4E8", text: "#B83A4B" }, // Ros-100
    { background: "#FDE8E9", text: "#B84245" }, // Light red
    { background: "#E8F0FE", text: "#4A73B8" }, // Very light blue
    { background: "#F1EBFE", text: "#7B41B8" }, // Light purple
    { background: "#FFE9F5", text: "#B83A84" }, // Light pink
    { background: "#E8F6FE", text: "#2E8CB8" }, // Very light cyan
    { background: "#E6F4E9", text: "#3C9658" }, // Light green
    { background: "#FFF5E6", text: "#B8773C" }, // Light peach
    { background: "#F0E6F6", text: "#8C4BB8" }, // Light lavender
    { background: "#EBFAFE", text: "#2EB5B8" }, // Very light turquoise
    { background: "#FFF9E6", text: "#B8A33C" }, // Light yellow
    { background: "#E8EBFE", text: "#4C58B8" }, // Light periwinkle
    { background: "#FEEFEF", text: "#B8444C" }, // Very light rose
    { background: "#E9FEF0", text: "#42B84E" }, // Light mint green
    { background: "#FFF0E8", text: "#B86D3C" }, // Light coral
    { background: "#EBF3FE", text: "#4287B8" }, // Light sky blue
    { background: "#F9EBFE", text: "#954CB8" }, // Light mauve
    { background: "#E8FEFF", text: "#2EAEB8" }, // Light aquamarine
    { background: "#FEF0F0", text: "#B84545" }, // Light blush
    { background: "#E8FEE8", text: "#42B83C" }, // Very light lime
    { background: "#FFE6F5", text: "#B83C9B" }, // Light fuchsia
    { background: "#E8FFF8", text: "#3CB8A7" }, // Very light teal
    { background: "#F6EBFE", text: "#7D42B8" }  // Very light orchid
];

export const getColorFromName = (name) => {
    const firstLetter = name.charAt(0).toLowerCase();
    const alphabet = "abcdefghijklmnopqrstuvwxyz";
    const position = alphabet.indexOf(firstLetter);

    // Ensure the position is within the range of the colors array
    const colorIndex = position % colors.length;
    return colors[colorIndex];
};

const NameBadge = ({ name, size = '40px' }) => {
    let defaultName = name;
    let initials;
    if (name == null) {
        defaultName = "Not Assigned"
        initials = defaultName.substring(0, 2).toUpperCase();
    }

    else if (defaultName.includes(' ')) {
        // If there are spaces, split by space and take the first character of each word
        initials = defaultName
          .split(' ')
          .map(word => word.charAt(0))
          .join('')
          .toUpperCase();
    } 
    else {
        // If no spaces, take the first two characters
        initials = defaultName.substring(0, 2).toUpperCase();
    }
    
    const { background, text } = getColorFromName(defaultName);

    return (
        <div className={styles.nameBadgeContainer}>
          
            <div className={styles.circle} style={{ width: size, height: size, backgroundColor: background, color: text }}>
                {initials}
            </div>
            <span className={styles.fullName}>{defaultName}</span>
        </div>
    );
};
export default NameBadge;

