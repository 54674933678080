import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../axiosConfig";
import qs from "qs";
import { filter } from "jszip";

const mockOverlapAlerts = [
  {
    "Alert ID": "A00000001",
    "Scenario Frequency": "Monthly",
    "Scenario Group": "Cash",
    "Alert Status Category": "Non Relevant",
    "Event Date": "31/1/24",
    "Year Month": "2024/01",
    "Customer ID": "Customer 1",
    "Customer Type": "Private",
    "Risk Level": "STANDARD",
    "Overlap Alert ID": "A00000001",
    "Domain Name": "Domain Name",
    "Segment N": "53",
    "KYC Segment": "Solo Traders",
    "Risk Number": "1",
    "NetReveal Segment": "Segment",
    Pertince: "olivia@untitledui.com",
    "Audit Log": [
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Brian Bolger",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
    ],
  },
  {
    "Alert ID": "A00000002",
    "Scenario Frequency": "Monthly",
    "Scenario Group": "Cash",
    "Alert Status Category": "Relevant",
    "Event Date": "31/1/24",
    "Year Month": "2024/01",
    "Customer ID": "Customer 1",
    "Customer Type": "Professional",
    "Risk Level": "STANDARD",
    "Overlap Alert ID": "A00000002",
    "Domain Name": "Domain Name",
    "Segment N": "53",
    "KYC Segment": "Solo Traders",
    "Risk Number": "1",
    "NetReveal Segment": "Segment",
    Pertince: "olivia@untitledui.com",
    "Audit Log": [
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Brian Bolger",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
    ],
  },
  {
    "Alert ID": "A00000003",
    "Scenario Frequency": "Daily",
    "Scenario Group": "Cash",
    "Alert Status Category": "Non Relevant",
    "Event Date": "31/1/24",
    "Year Month": "2024/02",
    "Customer ID": "Customer 2",
    "Customer Type": "Professional",
    "Risk Level": "MEDIUM",
    "Overlap Alert ID": "A00000004",
    "Domain Name": "Domain Name",
    "Segment N": "53",
    "KYC Segment": "Solo Traders",
    "Risk Number": "1",
    "NetReveal Segment": "Segment",
    Pertince: "olivia@untitledui.com",
    "Audit Log": [
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Brian Bolger",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
    ],
  },
  {
    "Alert ID": "A00000004",
    "Scenario Frequency": "Monthly",
    "Scenario Group": "Cash",
    "Alert Status Category": "SAR",
    "Event Date": "31/1/24",
    "Year Month": "2024/02",
    "Customer ID": "Customer 2",
    "Customer Type": "Professional",
    "Risk Level": "STANDARD",
    "Overlap Alert ID": "A00000005",
    "Domain Name": "Domain Name",
    "Segment N": "53",
    "KYC Segment": "Solo Traders",
    "Risk Number": "1",
    "NetReveal Segment": "Segment",
    Pertince: "olivia@untitledui.com",
    "Audit Log": [
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Brian Bolger",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
    ],
  },
  {
    "Alert ID": "A00000005",
    "Scenario Frequency": "Monthly",
    "Scenario Group": "International",
    "Alert Status Category": "Relevant",
    "Event Date": "31/1/24",
    "Year Month": "2024/03",
    "Customer ID": "Customer 3",
    "Customer Type": "Professional",
    "Risk Level": "HIGH",
    "Overlap Alert ID": "A00000006",
    "Domain Name": "Domain Name",
    "Segment N": "53",
    "KYC Segment": "Solo Traders",
    "Risk Number": "1",
    "NetReveal Segment": "Segment",
    Pertince: "olivia@untitledui.com",
    "Audit Log": [
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Brian Bolger",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
    ],
  },
  {
    "Alert ID": "A00000006",
    "Scenario Frequency": "Monthly",
    "Scenario Group": "International",
    "Alert Status Category": "Non Relevant",
    "Event Date": "31/1/24",
    "Year Month": "2024/03",
    "Customer ID": "Customer 3",
    "Customer Type": "Private",
    "Risk Level": "STANDARD",
    "Overlap Alert ID": "A00000007",
    "Domain Name": "Domain Name",
    "Segment N": "53",
    "KYC Segment": "Solo Traders",
    "Risk Number": "1",
    "NetReveal Segment": "Segment",
    Pertince: "olivia@untitledui.com",
    "Audit Log": [
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Brian Bolger",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
    ],
  },
  {
    "Alert ID": "A00000007",
    "Scenario Frequency": "Monthly",
    "Scenario Group": "International",
    "Alert Status Category": "Non Relevant",
    "Event Date": "31/1/24",
    "Year Month": "2024/01",
    "Customer ID": "Customer 4",
    "Customer Type": "Private",
    "Risk Level": "MEDIUM",
    "Overlap Alert ID": "A00000008",
    "Domain Name": "Domain Name",
    "Segment N": "53",
    "KYC Segment": "Solo Traders",
    "Risk Number": "1",
    "NetReveal Segment": "Segment",
    Pertince: "olivia@untitledui.com",
    "Audit Log": [
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Brian Bolger",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
    ],
  },
  {
    "Alert ID": "A00000008",
    "Scenario Frequency": "Daily",
    "Scenario Group": "Cash",
    "Alert Status Category": "SAR",
    "Event Date": "31/1/24",
    "Year Month": "2024/01",
    "Customer ID": "Customer 4",
    "Customer Type": "Private",
    "Risk Level": "STANDARD",
    "Overlap Alert ID": "A00000009",
    "Domain Name": "Domain Name",
    "Segment N": "53",
    "KYC Segment": "Solo Traders",
    "Risk Number": "1",
    "NetReveal Segment": "Segment",
    Pertince: "olivia@untitledui.com",
    "Audit Log": [
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Brian Bolger",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
    ],
  },
  {
    "Alert ID": "A00000009",
    "Scenario Frequency": "Monthly",
    "Scenario Group": "Cash",
    "Alert Status Category": "Non Relevant",
    "Event Date": "31/1/24",
    "Year Month": "2024/01",
    "Customer ID": "Customer 1",
    "Customer Type": "Private",
    "Risk Level": "STANDARD",
    "Overlap Alert ID": "A00000003",
    "Domain Name": "Domain Name",
    "Segment N": "53",
    "KYC Segment": "Solo Traders",
    "Risk Number": "1",
    "NetReveal Segment": "Segment",
    Pertince: "olivia@untitledui.com",
    "Audit Log": [
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Brian Bolger",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
    ],
  },
  {
    "Alert ID": "A00000010",
    "Scenario Frequency": "Monthly",
    "Scenario Group": "Cash",
    "Alert Status Category": "SAR",
    "Event Date": "31/1/24",
    "Year Month": "2024/01",
    "Customer ID": "Customer 1",
    "Customer Type": "Private",
    "Risk Level": "MEDIUM",
    "Overlap Alert ID": "A00000003",
    "Domain Name": "Domain Name",
    "Segment N": "53",
    "KYC Segment": "Solo Traders",
    "Risk Number": "1",
    "NetReveal Segment": "Segment",
    Pertince: "olivia@untitledui.com",
    "Audit Log": [
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Brian Bolger",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
    ],
  },
  {
    "Alert ID": "A00000011",
    "Scenario Frequency": "Monthly",
    "Scenario Group": "Cash",
    "Alert Status Category": "Non Relevant",
    "Event Date": "31/1/24",
    "Year Month": "2024/01",
    "Customer ID": "Customer 1",
    "Customer Type": "Private",
    "Risk Level": "STANDARD",
    "Overlap Alert ID": "A00000003",
    "Domain Name": "Domain Name",
    "Segment N": "53",
    "KYC Segment": "Solo Traders",
    "Risk Number": "1",
    "NetReveal Segment": "Segment",
    Pertince: "olivia@untitledui.com",
    "Audit Log": [
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Brian Bolger",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
      {
        User: "Filip Verbeke",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      {
        User: "Louis Wittock",
        Comment: "Called the client",
        Date: "1 week ago",
      },
      { User: "Max Doemer", Comment: "Called the client", Date: "1 week ago" },
    ],
  },
];

const mockContributingAlerts = [
  {
    "Transaction ID": 1,
    "Transaction Date": "01/05/2000",
    "Account ID": 1,
    "Debit-Credit": "Credit",
    "Base Amount": 100000,
    "Transaction Type": "VIREMENT",
    "Transaction Chanel": "ALL",
    Description: "Credit Transfer BE49 2220 4445 8432 2128",
  },
  {
    "Transaction ID": 2,
    "Transaction Date": "15/08/2001",
    "Account ID": 2,
    "Debit-Credit": "Debit",
    "Base Amount": 50000,
    "Transaction Type": "PAYMENT",
    "Transaction Chanel": "ONLINE",
    Description: "Online Payment BE34 5678 9012 3456 7890",
  },
  {
    "Transaction ID": 3,
    "Transaction Date": "23/11/2002",
    "Account ID": 3,
    "Debit-Credit": "Credit",
    "Base Amount": 250000,
    "Transaction Type": "DEPOSIT",
    "Transaction Chanel": "ATM",
    Description: "Cash Deposit BE98 1234 5678 9123 4567",
  },
  {
    "Transaction ID": 4,
    "Transaction Date": "10/02/2003",
    "Account ID": 4,
    "Debit-Credit": "Debit",
    "Base Amount": 120000,
    "Transaction Type": "TRANSFER",
    "Transaction Chanel": "BRANCH",
    Description: "Branch Transfer BE12 3456 7890 1234 5678",
  },
  {
    "Transaction ID": 5,
    "Transaction Date": "05/06/2004",
    "Account ID": 5,
    "Debit-Credit": "Credit",
    "Base Amount": 80000,
    "Transaction Type": "VIREMENT",
    "Transaction Chanel": "ALL",
    Description: "Credit Transfer BE65 4321 0987 6543 2109",
  },
  {
    "Transaction ID": 6,
    "Transaction Date": "21/09/2005",
    "Account ID": 6,
    "Debit-Credit": "Debit",
    "Base Amount": 95000,
    "Transaction Type": "WITHDRAWAL",
    "Transaction Chanel": "ATM",
    Description: "ATM Withdrawal BE76 5432 1098 7654 3210",
  },
  {
    "Transaction ID": 7,
    "Transaction Date": "12/12/2006",
    "Account ID": 7,
    "Debit-Credit": "Credit",
    "Base Amount": 450000,
    "Transaction Type": "DEPOSIT",
    "Transaction Chanel": "ONLINE",
    Description: "Online Deposit BE87 6543 2109 8765 4321",
  },
  {
    "Transaction ID": 8,
    "Transaction Date": "30/04/2007",
    "Account ID": 8,
    "Debit-Credit": "Debit",
    "Base Amount": 60000,
    "Transaction Type": "PAYMENT",
    "Transaction Chanel": "BRANCH",
    Description: "Branch Payment BE98 7654 3210 9876 5432",
  },
  {
    "Transaction ID": 9,
    "Transaction Date": "19/01/2008",
    "Account ID": 9,
    "Debit-Credit": "Credit",
    "Base Amount": 300000,
    "Transaction Type": "TRANSFER",
    "Transaction Chanel": "ALL",
    Description: "Credit Transfer BE21 8765 4321 0987 6543",
  },
  {
    "Transaction ID": 10,
    "Transaction Date": "28/07/2009",
    "Account ID": 10,
    "Debit-Credit": "Debit",
    "Base Amount": 70000,
    "Transaction Type": "WITHDRAWAL",
    "Transaction Chanel": "ATM",
    Description: "ATM Withdrawal BE32 9876 5432 1098 7654",
  },
  {
    "Transaction ID": 11,
    "Transaction Date": "16/03/2010",
    "Account ID": 11,
    "Debit-Credit": "Credit",
    "Base Amount": 550000,
    "Transaction Type": "DEPOSIT",
    "Transaction Chanel": "ONLINE",
    Description: "Online Deposit BE43 0987 6543 2109 8765",
  },
  {
    "Transaction ID": 12,
    "Transaction Date": "08/10/2011",
    "Account ID": 12,
    "Debit-Credit": "Debit",
    "Base Amount": 85000,
    "Transaction Type": "PAYMENT",
    "Transaction Chanel": "BRANCH",
    Description: "Branch Payment BE54 1098 7654 3210 9876",
  },
  {
    "Transaction ID": 13,
    "Transaction Date": "27/05/2012",
    "Account ID": 13,
    "Debit-Credit": "Credit",
    "Base Amount": 380000,
    "Transaction Type": "TRANSFER",
    "Transaction Chanel": "ALL",
    Description: "Credit Transfer BE65 2109 8765 4321 0987",
  },
  {
    "Transaction ID": 14,
    "Transaction Date": "14/12/2013",
    "Account ID": 14,
    "Debit-Credit": "Debit",
    "Base Amount": 110000,
    "Transaction Type": "WITHDRAWAL",
    "Transaction Chanel": "ATM",
    Description: "ATM Withdrawal BE76 3210 9876 5432 1098",
  },
  {
    "Transaction ID": 15,
    "Transaction Date": "03/07/2014",
    "Account ID": 15,
    "Debit-Credit": "Credit",
    "Base Amount": 470000,
    "Transaction Type": "DEPOSIT",
    "Transaction Chanel": "ONLINE",
    Description: "Online Deposit BE87 4321 0987 6543 2109",
  },
];

const convertData = (data) => {
  if (data.alerts) {
    const alerts = data.alerts || [];
    const total = data.total || 0;
    console.log("data: ", data);
    // Convert alerts data
    const result = alerts.map((alert) => ({
      id: alert["id"],
      "Alert ID": alert["alert_code"],
      "Assigned To": alert["user"] ? alert["user"]["username"] : "",
      "User ID": alert["user_id"],
      Scenario: alert["scenario"]["name"],
      "Customer Segment": alert["customer_segment"]["name"],
      "Customer Risk": alert["customer_segment"]["id"], // TODO: change to use actual risk when available
      "Creation Date": alert["event_date"],
      Status: alert["alert_status"],
      "Alert Type": alert["alert_type"],
      "Risk Score": alert["risk_score"],
      "Alert Relevancy": alert["alert_relevancy"],
      "Masked Data": alert["masked_data"],
      Comment: alert["alert_comment"],
    }));

    return { alerts: result, total };
  } else {
    const alerts = data || [];
    const total = data.length || 0;
    console.log("data: ", data);
    // Convert alerts data
    const result = alerts.map((alert) => ({
      id: alert["id"],
      "Alert ID": alert["alert_code"],
      "Assigned To": alert["user"]["username"],
      "User ID": alert["user_id"],
      Scenario: alert["scenario"]["name"],
      "Customer Segment": alert["customer_segment"]["name"],
      "Customer Risk": alert["customer_segment"]["id"], // TODO: change to use actual risk when available
      "Creation Date": alert["event_date"],
      Status: alert["alert_status"],
      "Alert Type": alert["alert_type"],
      "Risk Score": alert["risk_score"],
      "Alert Relevancy": alert["alert_relevancy"],
      "Masked Data": alert["masked_data"],
      Comment: alert["alert_comment"],
    }));

    return { alerts: result, total };
  }
};

const convertAuditLogData = (data) => {
  var result = [];
  console.log("auditlog data: ", data);
  data.map((auditLog) =>
    result.push({
      "Audit Log ID": auditLog["id"],
      User: auditLog["user"]["username"],
      Id: auditLog["id"],
      Comment: auditLog["comment"],
      Date: auditLog["update_date"],
    })
  );
  return result;
};

const convertOverlapData = (data) => {
  var result = [];
  data.overlapping_alerts.map((alert) =>
    result.push({
      id: alert["id"],
      "Alert ID": alert["alert_code"],
      "Assigned To": alert["assigned_user"],
      "User ID": alert["user_id"],
      Scenario: alert["scenario"]["name"],
      "Customer Segment": alert["customer_segment"]["name"],
      // TODO: change this customer risk when we actually have it
      "Customer Risk": alert["customer_segment"]["id"],
      "Creation Date": alert["event_date"],
      Status: alert["alert_status"],
      "Alert Type": alert["alert_type"],
      "Risk Score": alert["risk_score"],
      "Alert Relevancy": alert["alert_relevancy"],
      "Masked Data": alert["masked_data"],
      Comment: alert["alert_comment"],
    })
  );
  return result;
};

// filter functionality
function getAlertFilterParams(state, withOrder = true) {
  let filters = { ...state.alertsFilters };;
  if (state.showMyAlerts) {
    filters["user"] = [localStorage.getItem("userName")];
  }
  if (withOrder) {
    filters["order_by"] = state.alertsOrder[0];
    filters["order_direction"] = state.alertsOrder[1];
  }
  if (state.alertsSearch.length > 0) {
    filters["search_term"] = state.alertsSearch;
  }
  filters.alert_origin = state.alertsOrigin;
  return filters
}

// Thunks
export const fetchAllAlerts = createAsyncThunk(
  "allAlerts/fetchAllAlerts",
  async ({ skip = 0, limit = 10 }, { getState }) => {
    const state = getState().allAlerts;
    // Use the mock data instead of making an actual API call for now
    console.log("Fetching all alerts from API...");
    const response = await apiClient.get(
      `alerts?skip=${skip}&limit=${limit}&alert_origin=${state.alertsOrigin}`
    );
    const result = convertData(response.data);
    return result;
  }
);

export const getAlertsFilterValues = createAsyncThunk(
  "allAlerts/getAlertsFilterValues",
  async ({ propertyName }) => {
    try {
      // Make sure propertyName is being passed as a query parameter
      const response = await apiClient.get(`alerts/distinct`, {
        params: {
          property_name: propertyName, // Correct way to pass query parameters
        },
      });

      const result = response.data;
      return result;
    } catch (error) {
      console.error("Error fetching filter values:", error);
      throw error;
    }
  }
);

export const closeAlerts = createAsyncThunk(
  "allAlerts/closeAlerts",
  async ({ alertIds, selectedAll }, { getState }) => {
    const state = getState().allAlerts;
    const filters = getAlertFilterParams(state, false);

    const alertBatch = {
      ids: alertIds,
      all_selected: selectedAll,
    };

    try {
      const response = await apiClient.put(`alerts/close-batch`, alertBatch, {
        params: filters,
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: "repeat" }),
      });

      return response.data;
    } catch (error) {
      console.error("Failed to close one or more alerts:", error);
      throw error;
    }
  }
);

export const fetchAlertsFiltered = createAsyncThunk(
  "allAlerts/fetchAlertsFiltered",
  async (params) => {
    try {
      const response = await apiClient.get(`alerts/filters`, {
        params,
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: "repeat" }),
      });

      const result = convertData(response.data);
      return result;
    } catch (error) {
      console.error("Error fetching filter values:", error);
      throw error;
    }
  }
);

export const fetchFilteredAlerts = createAsyncThunk(
  "allAlerts/fetchFilteredAlerts",
  async (
    { pageNr = 1, pageSize = 10, setAlerts = true, fetchAll = false },
    { dispatch, getState }
  ) => {
    const state = getState().allAlerts;

    const filters = getAlertFilterParams(state);

    if (fetchAll) {
      filters.skip = 0;
      filters.limit = state.totalAlerts;
    } else {
      const skip = Math.max(0, (pageNr - 1) * pageSize);
      filters.skip = skip;
      filters.limit = pageSize;
    }

    if (setAlerts) {
      const response = await dispatch(fetchAlertsFiltered(filters));
      return response.payload;
    } else {
      const response = await apiClient.get(`alerts/filters`, {
        params: filters,
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: "repeat" }),
      });

      const result = convertData(response.data);
      return result;
    }
  }
);

export const fetchContributingTransactions = createAsyncThunk(
  "allAlerts/fetchContributingTransactions",
  async () => {
    // Use the mock data instead of making an actual API call for now
    return mockContributingAlerts;
  }
);

export const fetchAuditLogs = createAsyncThunk(
  "allAlerts/fetchAuditLogs",
  async ({ alertId }) => {
    console.log(
      "Fetching audit logs for alert with id: ",
      alertId,
      " from API..."
    );
    const response = await apiClient.get(`/alerts-audit-log/alerts/${alertId}`);
    console.log("fetched from database audit logs: ", response.data);
    const result = convertAuditLogData(response.data);
    console.log("audit logs after transformation: ", result);
    return result;
  }
);

export const fetchOverlapAlerts = createAsyncThunk(
  "allAlerts/fetchOverlapAlerts",
  async ({ alertId }) => {
    console.log(
      "Fetching overlapping alerts for alert with id: ",
      alertId,
      " from API..."
    );
    const response = await apiClient.get(
      `/overlap-analysis-alerts/alerts/${alertId}`
    );
    console.log("Fetch overlapAlerts response:", response);
    const result = convertOverlapData(response.data);
    return result;
  }
);

export const addComment = createAsyncThunk(
  "allAlerts/addComment",
  async ({ alertId, comment }, { rejectWithValue }) => {
    const payload = {
      alert_id: alertId,
      user_id: localStorage.getItem("userId"),
      comment: comment,
    };
    console.log("payload");
    console.log(payload);
    try {
      const response = await apiClient.post("/alerts-audit-log", payload);
      return response.data;
    } catch (error) {
      console.error("Error during add audit log comment request:", error);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

const allAlertsSlice = createSlice({
  name: "allAlerts",
  initialState: {
    overlapAlerts: mockOverlapAlerts,
    status: "idle",
    error: null,
    auditLogs: [],
    realOverlapAlerts: [],

    // Alerts
    alerts: [],
    alertsLoading: false,
    alertsFilters: {},
    alertsSearch: "",
    alertsOrder: [],
    alertsOrigin: "Production",
    showMyAlerts: false,
    totalAlerts: 0,
    currentPage: 0,
    pageSize: 10,
    columnsFiltersValues: [],

    selectedAlert: null,
    selectedAlertDetails: {},

    contributingTransactions: [],

    // To make sure the right container is shown
    shownContainer: 1,
  },
  reducers: {
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setPageSize(state, action) {
      state.pageSize = action.payload;
    },
    setAlertsFilters(state, action) {
      state.alertsFilters = action.payload;
    },
    setAlertsSearch(state, action) {
      state.alertsSearch = action.payload;
    },
    setAlertsOrder(state, action) {
      state.alertsOrder = action.payload;
    },
    setSelectedAlert(state, action) {
      state.selectedAlert = action.payload;
    },
    setSelectedAlertDetails(state, action) {
      state.selectedAlertDetails = action.payload;
    },
    setTotalAlerts(state, action) {
      state.totalAlerts = action.payload;
    },
    setShownContainer(state, action) {
      state.shownContainer = action.payload;
    },
    setAlertsOrigin(state, action) {
      state.alertsOrigin = action.payload;
    },
    toggleShowMyAlerts(state) {
      state.showMyAlerts = !state.showMyAlerts;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAlertsFiltered.pending, (state) => {
        state.status = "loading";
        state.alertsLoading = true
      })
      .addCase(fetchAlertsFiltered.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.filteredAlertsData = action.payload.alerts;
        state.alerts = action.payload.alerts;
        state.totalAlerts = action.payload.total;
        state.alertsLoading = false

        console.log("alert check dataset", action.payload.alerts);

        console.log("all alerts data: ", action.payload);
      })
      .addCase(fetchAlertsFiltered.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        console.log("ERROR: ", state.error);
        state.alertsLoading = false
      })

      .addCase(getAlertsFilterValues.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAlertsFilterValues.fulfilled, (state, action) => {
        state.status = "succeeded";

        // Extract propertyName from action meta
        const requested_column = action.meta.arg?.propertyName;
        const filters = action.payload;

        // Ensure requested_column is defined
        if (requested_column) {
          // Use Immer-friendly way to update nested properties
          if (!state.columnsFiltersValues) {
            state.columnsFiltersValues = {};
          }

          // Create a copy of the array and assign it back to ensure Immer compatibility
          state.columnsFiltersValues = {
            ...state.columnsFiltersValues,
            [requested_column]: [...filters],
          };
        } else {
          console.warn(
            "Requested column (propertyName) is undefined:",
            action.meta.arg
          );
        }

        // Log filters and action for debugging
        console.log("Filters received:", filters);
        console.log("Action details:", action);
      })

      .addCase(closeAlerts.pending, (state) => { })
      .addCase(closeAlerts.fulfilled, (state) => {
        console.log("Alerts closed successfully");
      })
      .addCase(closeAlerts.rejected, (state, action) => {
        console.error("Failed to close alerts:", action.error);
      })

      .addCase(fetchAllAlerts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllAlerts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allAlertsData = action.payload.alerts;
        state.alerts = action.payload.alerts;
        state.totalAlerts = action.payload.total;
        state.filteredAlertsData = [];
        console.log("all alerts data: ", action.payload);
      })
      .addCase(fetchAllAlerts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        console.log("ERROR: ", state.error);
      })
      .addCase(fetchContributingTransactions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchContributingTransactions.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.contributingTransactions = action.payload;
      })
      .addCase(fetchContributingTransactions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchAuditLogs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAuditLogs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.auditLogs = action.payload;
        console.log("audit log data: ", action.payload);
      })
      .addCase(fetchAuditLogs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        console.log("ERROR: ", state.error);
      })
      .addCase(fetchOverlapAlerts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOverlapAlerts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.realOverlapAlerts = action.payload;
        console.log("Overlap alerts data: ", action.payload);
      })
      .addCase(fetchOverlapAlerts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        console.log("ERROR: ", state.error);
      });
  },
});

export const {
  setAlertsFilters,
  setAlertsSearch,
  setAlertsOrder,
  setSelectedAlert,
  setSelectedAlertDetails,
  setTotalAlerts,
  setAlertsOrigin,
  toggleShowMyAlerts,
  setCurrentPage,
  setPageSize,
  setShownContainer,
} = allAlertsSlice.actions;

export default allAlertsSlice.reducer;
