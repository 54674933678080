import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../axiosConfig";

// Async thunk to fetch all users
export const fetchAllUsers = createAsyncThunk(
  "users/fetchAllUsers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiClient.get("/users/overview");
      return response.data;
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
        if (status === 404) {
          return rejectWithValue(data.detail || "No users found");
        }
      }
      console.log("Failed to fetch the errors");
      return rejectWithValue("Failed to fetch the errors");
    }
  }
);

export const createUser = createAsyncThunk(
  "users/createUser",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await apiClient.post("/users", userData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data; // Return the created user
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
        if (status === 400) {
          return rejectWithValue(data.detail || "Username already registered");
        } else {
          return rejectWithValue(data.detail || "An unexpected error occurred");
        }
      } else if (error.request) {
        return rejectWithValue("No response from server");
      } else {
        return rejectWithValue("Request setup error");
      }
    }
  }
);

export const deleteUsers = createAsyncThunk(
  "users/deleteUsers",
  async (userIds) => {
    const response = await apiClient.post(`users/delete-batch`, {
      batch_ids: userIds,
    });
    return response.data;
  }
);

// Slice for managing the users state
const usersSlice = createSlice({
  name: "users",
  initialState: {
    allUsers: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allUsers = action.payload;
      })
      .addCase(fetchAllUsers.rejected, (state, action) => {
        state.status = "failed";
        console.error("Error fetching users:", action.payload);
      });
  },
});

export default usersSlice.reducer;
