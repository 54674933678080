// components/atl_tool/scenario_selection/ScenarioSelection.js

import React , {useEffect} from 'react';
import { useSelector } from 'react-redux';
import styles from '../AtlTool.module.css';
import ScenarioSelector from './scenario_selector/ScenarioSelector';
import SegmentSelection from './segment_selection/SegmentSelection';
import HeaderSection from '../../../layout/HeaderSection';

const ScenarioSelection = ({ onScenarioChange }) => {
    const selectedScenario = useSelector((state) => state.atlTool.selectedScenario);
    
    useEffect(() => {
        console.log('ScenarioSelection mounted');
        console.log('ScenarioSelection - selectedScenario:', selectedScenario);
        return () => {
          console.log('ScenarioSelection unmounted');
        };
      }, []);

    return (
        <div className={styles.section}>
                <HeaderSection SectionName='Scenario Performance'  CanAddToReport={true} Type="performance_table" AdditionalText={selectedScenario && (selectedScenario.name)}/>
                <div className={styles.scenarioSelectorHolder}>
                    <ScenarioSelector onScenarioChange={onScenarioChange} />
                    {selectedScenario && (
                        <SegmentSelection />
                    )}
                </div>
        </div>
    );
};

export default ScenarioSelection;