import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../axiosConfig";
import qs from "qs";

const INIT_OPTIMISATION_PARAMS = {
  optimisationType: "sar_loss",
  optimisationAmountType: "ratio",
  noSarLoss: false,
  optimisationAmount: 0,
};

const transformSupresedAlert = (inputData, selectedSegment) => {
  return inputData.map((item) => {
    // Crear un objeto transformado para cada item
    let transformedItem = {};

    Object.keys(item).forEach((key) => {
      let formattedKey = key
        .replace(/_/g, " ") // Reemplazar guiones bajos por espacios
        .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalizar cada palabra

      // Convertir valores según la clave, si es necesario
      let value = item[key];
      if (key === "alert_creation_date" || key === "alert_closure_date") {
        value = new Date(value).toLocaleDateString("en-US");
      }

      // Agregar la propiedad transformada al objeto final
      transformedItem[formattedKey] = value;
    });

    // Agregar información adicional que no esté en el objeto original
    transformedItem["Segment"] = selectedSegment.name;
    return transformedItem;
  });
};

const generateScatterData = (jsonData) => {
  try {
    console.log("Generating scatter plot data...");
    const parsedData = jsonData;
    const extractPoints = (data, group) => {
      return data.map((point) => ({
        x: point.x,
        y: point.y,
        group: group,
      }));
    };

    const scatterPlotData = [
      ...extractPoints(parsedData[1]["non_relevant"], "Non Relevant"),
      ...extractPoints(parsedData[1]["relevant"], "Relevant"),
      ...extractPoints(parsedData[1]["SAR"], "SAR"),
    ];

    return scatterPlotData;
  } catch (error) {
    console.error("Error parsing JSON data:", error);
    return [];
  }
};

const transformDataScenario = (data) => {
  return data.map((item) => ({
    id: item.id,
    name: item.name,
    description: item.description,
    frequency: item.frequency,
    ds_id: item.ds_id,
    ds: {
      name: item.ds?.name ?? "Unknown DS",
      category_id: item.ds?.category_id ?? "Unknown Category",
      id: item.ds?.id ?? "Unknown ID",
    },
    customer_segments: item.customer_segments.map((segment) => ({
      name: segment.name,
      id: segment.id,
    })),
    subscenarios: item.subscenarios.map((subscenario) => ({
      id: subscenario.id,
      name: subscenario.account_type,
    })),
  }));
};

const transformDataOptimisationTable = (
  data,
  selectedScenarioId,
  selectedSegmentId,
  selectedRiskScore
) => {
  const firstItemKey = Object.keys(data[0])[0];
  const firstItem = data[0][firstItemKey];
  const thresholdNames = firstItem.thresholds.map(
    (threshold) => threshold.threshold_name
  );
  const thresholdNameToIdMap = firstItem.thresholds.reduce((acc, threshold) => {
    acc[threshold.threshold_name] = threshold.threshold_id;
    return acc;
  }, {});

  return {
    scenario_id: selectedScenarioId,
    customer_segment_id: selectedSegmentId,
    risk_score_id: selectedRiskScore.id,
    scenario_parameters: thresholdNames,
    threshold_id_map: thresholdNameToIdMap,
    rows: data.length,
    table: data.map((item) => {
      const key = Object.keys(item)[0];
      const currentRow = item[key];
      const thresholds = currentRow.thresholds.reduce((acc, threshold) => {
        acc[threshold.threshold_name] = threshold.threshold_value;
        return acc;
      }, {});

      const relevancies = currentRow.relevancies;

      return {
        id: currentRow.optimisation_row_id,
        optimisation_type: key,
        parameters: thresholds,
        non_relevant_alerts: relevancies["Non Relevant"] || 0,
        relevant_alerts: relevancies["Relevant"] || 0,
        sar_alerts: relevancies["SAR"] || 0,

        no_sar_loss: currentRow.no_sar_loss,
      
      };
    }),
  };
};

export const fetchScenarios = createAsyncThunk(
  "atlTool/fetchScenarios",
  async () => {
    console.log("Fetching scenarios from API...");
    const response = await apiClient.get(`/scenarios?&load_subscenarios=true`);
    const newData = transformDataScenario(response.data);
    return newData;
  }
);

export const fetchThresholds = createAsyncThunk(
  "atlTool/fetchThresholds",
  async ({ selectedScenario, selectedSubScenario }) => {
    console.log("Fetching thresholds from API...");
    const subScenarioId = selectedSubScenario?.id ?? 0;
    const response = await apiClient.get(
      `/thresholds/scenarios/${selectedScenario.id}/${subScenarioId}`
    );
    return response.data;
  }
);

export const fetchSegments = createAsyncThunk(
  "atlTool/fetchSegments",
  async ({ selectedScenarioId, selectedSubScenario, selectedYearState }) => {
    console.log(
      `Fetching segments for scenario ${selectedScenarioId} ${selectedSubScenario} from API...`,
      selectedScenarioId,
      selectedSubScenario
    );
    const subScenarioId = selectedSubScenario?.id ?? 0;
    const response = await apiClient.get(
      `segment-relevancy-count/${selectedScenarioId}/${subScenarioId}?year=${selectedYearState}`
    );
    console.log("result from fetching segments", response.data, selectedScenarioId, selectedSubScenario);
    return response.data;
  }
);

export const fetchRiskScores = createAsyncThunk(
  "atlTool/fetchRiskScores",
  async ({
    selectedScenarioId,
    selectedSegmentId,
    selectedSubScenario,
    selectedYearState,
  }) => {
    const subScenarioId = selectedSubScenario?.id || 0;
    const response = await apiClient.get(
      `/scenarios/${selectedScenarioId}/${subScenarioId}/segments/${selectedSegmentId}/risk_scores?&year=${selectedYearState}`
    );
    return response.data;
  }
);

export const fetchOptimisationTable = createAsyncThunk(
  "atlTool/fetchOptimisationTable",
  async ({
    selectedScenario,
    selectedSegment,
    selectedRiskScore,
    selectedSubScenario,
    selectedYearState,
  }) => {
    const subScenarioId = selectedSubScenario?.id || 0;
    const response = await apiClient.get(`scenarios/${selectedScenario.id}/${subScenarioId}/segments/${selectedSegment.id}/risk-scores/${selectedRiskScore.name.toLowerCase()}/optimisation-table?year=${selectedYearState}`);

    const newData = transformDataOptimisationTable(response.data, selectedScenario.id, selectedSegment.id, selectedRiskScore);
    return newData;
  }
);

const handleOptimisationCall = async (url, payload, rejectWithValue) => {
  try {
    const response = await apiClient.post(url, payload);
    return response.data;
  } catch (error) {
    console.error("Error during API request:", error);
    const details = error?.response?.data?.detail || [];
    if (details.length > 0) {
      error.message = details.map((detail) => detail.msg).join(", ");
    }
    return rejectWithValue(error);
  }
};

export const startOptimisation = createAsyncThunk(
  "atlTool/startOptimisation",
  async (
    {
      selectedScenario,
      selectedSubScenario,
      selectedSegment,
      selectedRiskScore,
      optimisationType,
      optimisationAmountType,
      optimisationAmount,
      noSarLoss,
      selectedYearState,
    },
    { rejectWithValue }
  ) => {
    console.log("Starting optimisation request...", selectedYearState);
    const payload = {
      scenario_id: selectedScenario?.id || 0,
      subscenario_id: selectedSubScenario?.id || 0,
      customer_segment_id: selectedSegment?.id || 0,
      risk_score: selectedRiskScore?.name?.toLowerCase() || "",
      optimisation_type: optimisationType,
      amount_type: optimisationAmountType,
      amount: optimisationAmount,
      optimiser: "dual_annealing",
      no_sar_loss: noSarLoss,
      year: selectedYearState,
    };

    return await handleOptimisationCall(
      "/start-optimisation",
      payload,
      rejectWithValue
    );
  }
);

export const startManualOptimisation = createAsyncThunk(
  "atlTool/startManualOptimisation",
  async (
    {
      selectedScenario,
      selectedSubScenario,
      selectedSegment,
      selectedRiskScore,
      selectedYearState,
      manualThresholds,
    },
    { rejectWithValue }
  ) => {
    const payload = {
      scenario_id: selectedScenario?.id || 0,
      subscenario_id: selectedSubScenario?.id || 0,
      customer_segment_id: selectedSegment?.id || 0,
      risk_score: selectedRiskScore?.name?.toLowerCase() || "",
      optimisation_type: "manual",
      amount_type: "manual",
      amount: 0,
      optimiser: "manual",
      no_sar_loss: false,
      year: selectedYearState,
      manual_thresholds: manualThresholds,
    };

    return await handleOptimisationCall(
      "/manual-optimisation",
      payload,
      rejectWithValue
    );
  }
);

export const checkOptimisationStatus = createAsyncThunk(
  "atlTool/checkOptimisationStatus",
  async (jobId) => {
    const response = await apiClient.get(`optimisations/${jobId}/status`);
    return response.data;
  }
);

export const fetchBoxPlotData = createAsyncThunk(
  "atlTool/fetchBoxPlotData",
  async ({
    selectedScenario,
    selectedSegment,
    selectedRiskScore,
    selectedSubScenario,
    selectedYearState,
  }) => {
    const subScenarioId = selectedSubScenario ? selectedSubScenario.id : 0;
    const response = await apiClient.get(
      `/scenarios/${selectedScenario.id}/${subScenarioId}/segments/${selectedSegment.id}/risk-scores/${selectedRiskScore.name.toLowerCase()}/box-plot?year=${selectedYearState}`
    );

    return response.data;
  }
);



export const fetchScatterPlotData = createAsyncThunk(
  "altTool/fetchScatterPlotData",
  async ({
    selectedScenario,
    selectedSegment,
    selectedRiskScore,
    x_threshold_id,
    y_threshold_id,
    x_threshold_name,
    y_threshold_name,
    selectedSubScenario,
    selectedYearState,
  }) => {
    const subScenarioId = selectedSubScenario?.id || 0;
    const response = await apiClient.get(
      `/scenarios/${selectedScenario.id}/${subScenarioId}/segments/${
        selectedSegment.id
      }/risk-scores/${selectedRiskScore.name.toLowerCase()}/scatter-plot/${x_threshold_id}/${y_threshold_id}?year=${selectedYearState}`
    );
    const newData = generateScatterData(response.data);
    return newData;
  }
);

export const fetchSupressedAlerts = createAsyncThunk(
  "atlTool/fetchSupressedAlerts",
  async ({
    selectedScenario,
    selectedSegment,
    selectedRiskScore,
    row_id,
    selectedSubScenario,
  }) => {
    console.log("Fetching suppressed alerts from API...");
    const subScenarioId = selectedSubScenario?.id || 0;
    const response = await apiClient.get(
      `/scenarios/${selectedScenario.id}/${subScenarioId}/segments/${
        selectedSegment.id
      }/risk-scores/${selectedRiskScore.name.toLowerCase()}/optimisation-table/${row_id}/suppressed-alerts`
    );
    const newData = transformSupresedAlert(response.data, selectedSegment);
    return newData;
  }
);

export const deleteRows = createAsyncThunk(
  "atlTool/deleteRows",
  async ({ row_ids }) => {
    const response = await apiClient.delete(`/optimisation-rows`, {
      params: { ids: row_ids },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
    return response.data;
  }
);

const fuseScatterDate = (scatterPlotData, payload, key) => {
  // Create a shallow copy of scatterPlotData to avoid mutating the original object
  const newData = { ...scatterPlotData };

  // Check if the key exists in newData and is non-empty, then clear it
  if (newData[key] && Array.isArray(newData[key])) {
    newData[key] = []; // Clear the existing array
  } else {
    newData[key] = []; // Initialize an empty array if the key doesn't exist
  }

  // Push the new payload into the now-empty array
  newData[key].push(payload);

  // Logging the fused scatter plot data for debugging purposes
  console.log("Fused scatter plot data:");
  console.dir(newData);

  // Return the updated data object
  return newData;
};

const atlToolSlice = createSlice({
  name: "atlTool",
  initialState: {
    navBarIsCollapsed: false,
    scenarios: [],
    selectedScenario: null,
    selectedSubScenario: null,
    selectedSegment: null,
    segments: [],
    selectedRiskScore: null,
    selectedSegmentOverviewUpdates: null,
    riskScores: [],
    chartHeight: 0,
    displayExtraMessage: false, // This is to tell the user to select subscenario when wanting to optimise from 
    optimisationTable: null,
    optimisationType: INIT_OPTIMISATION_PARAMS["optimisationType"],
    optimisationAmountType: INIT_OPTIMISATION_PARAMS["optimisationAmountType"],
    noSarLoss: INIT_OPTIMISATION_PARAMS["noSarLoss"],
    optimisationAmount: INIT_OPTIMISATION_PARAMS["optimisationAmount"],
    optimisationStatus: "idle",
    manualThresholds: [],
    canManualOpt: true,
    loading: false,
    status: "idle",
    statusAlert: "idle",
    statusSegments: "idle",
    error: null,
    alertsTable: null,
    organisedScatterPlotData: [],
    visualisationsThresholds: [],
    selectedSegmentOverview: -1,
    visualisationCreatorActive: false,
    visualisations: [],
    chartType: "Box Plot",
    yAxis: "Total Amount",
    xAxis: "Number of transactions",
    scatterPlotData: [],
    boxPlotData: {},
    statistics: {
      y: {
        min: 28107.01,
        firstQuartile: 40088.33,
        median: 54826.0,
        max: 2203412.0,
      },
      x: {
        min: 28107.01,
        firstQuartile: 40088.33,
        median: 54826.0,
        max: 2203412.0,
      },
    },
    selectedRowId: null,
    parameterOptimisationIsWaiting: false,
    ySelector: {
      yOption: "Total Amount",
      yOptions: ["Total Amount", "Number of transactions"],
    },
    xSelector: {
      xOption: "Number of transactions",
      xOptions: ["Total Amount", "Number of transactions"],
    },
    riskScoreSelector: {
      riskOption: "High",
      riskOptions: ["High", "Medium", "Low"],
    },
    selectedYearState: { id: 2024, name: "2024" },
    addToReportCount: 0,
    reportElements: [],
    reloadOptimisationTable: false,
    justAddedNewOptimisationRow: false,
  },
  reducers: {
    setSelectedYearState(state, action) {
      state.selectedYearState = action.payload;
    },
    addToReport(state, action) {
      state.addToReportCount += 1;
      state.reportElements.push(action.payload);
    },
    setReloadOptimisationTable(state, action) {
      state.reloadOptimisationTable = action.payload;
    },
    setParameterOptimisationIsWaiting(state, action) {
      state.parameterOptimisationIsWaiting = action.payload;
    },
    setVisualisationThresholds(state, action) {
      state.visualisationsThresholds = action.payload;
    },
    setSelectedRowId(state, action) {
      state.selectedRowId = action.payload;
      //If we want the supressed alert table to dissapear again when clicking on row "current":
      //if (action.payload === null){
      //  state.alertstable = null
      //}
    },
    setSelectedSegmentOverviewUpdates(state, action) {
      if (action.payload !== null) {
        action.payload.non_relevant_alerts =
          action.payload.non_relevant_alerts.replace(/\./g, "");
        action.payload.relevant_alerts = action.payload.relevant_alerts.replace(
          /\./g,
          ""
        );
        action.payload.sar_alerts = action.payload.sar_alerts.replace(
          /\./g,
          ""
        );
      }
      state.selectedSegmentOverviewUpdates = action.payload;
    },
    setNavBarIsCollapsed(state, action) {
      state.navBarIsCollapsed = action.payload;
    },
    setVisualisationChartType(state, action) {
      const vis = state.visualisations.find(
        (visualisation) => visualisation.key === action.payload.key
      );
      if (vis) {
        vis.chartType = action.payload.chartType;
      }
    },
    setRiskScoreSelectorOption(state, action) {
      state.riskScoreSelector.riskOption = action.payload;
    },
    setVisualisationXSelectorOption(state, action) {
      const vis = state.visualisations.find(
        (visualisation) => visualisation.key === action.payload.id
      );
      if (vis) {
        vis.xAxis = action.payload.scenario;
        vis.xSelector.xOption = action.payload.scenario;
      }
    },
    setVisualisationYSelectorOption(state, action) {
      const vis = state.visualisations.find(
        (visualisation) => visualisation.key === action.payload.id
      );
      if (vis) {
        vis.yAxis = action.payload.scenario;
        vis.ySelector.yOption = action.payload.scenario;
      }
    },
    setSelectedScenario(state, action) {
      state.selectedScenario = action.payload;
    
      // segment set to null somewhere else; needed for scenario performance to atl link with customer segments
      // state.selectedSegment = null;
      state.selectedRiskScore = null;
      state.optimisationTable = null;
      state.visualisations = [];
      state.scatterPlotData = [];
      state.boxPlotData = {};
      state.alertsTable = null;
    },
    setSelectedSubScenario(state, action) {
      console.log("AAAAA", action.payload);
      state.selectedSubScenario = action.payload;
      state.visualisations = [];

      state.selectedSegmentOverview = -1;
      state.selectedSegmentOverviewUpdates = null;
      state.selectedRowId = null;
      state.selectedRiskScore = null;
      state.optimisationTable = null;
      state.visualisations = [];
      state.scatterPlotData = [];
      state.boxPlotData = {};
      state.alertsTable = null;
    },
    setSelectedSegment(state, action) {
      state.selectedSegmentOverview = -1;
      state.selectedSegmentOverviewUpdates = null;
      state.selectedRowId = null;
      if (action.payload) {
        state.selectedSegment = action.payload;
        state.selectedRiskScore = state.riskScores[0];
      }
      state.optimisationTable = null;
      state.visualisations = [];
      state.scatterPlotData = [];
      state.boxPlotData = {};
      state.alertsTable = null;
    },
    clearSelectedSegment(state) {
      state.selectedSegmentOverview = -1;
      state.selectedSegmentOverviewUpdates = null;
      state.selectedRowId = null;
      state.selectedSegment = null
      state.selectedRiskScore = state.riskScores[0];
      state.optimisationTable = null;
      state.visualisations = [];
      state.scatterPlotData = [];
      state.boxPlotData = {};
      state.alertsTable = null;
    },
    setSelectedRiskScore(state, action) {
      state.selectedRiskScore = action.payload;
      state.selectedSegmentOverview = action.payload.id;
      state.selectedSegmentOverviewUpdates = null;
      state.visualisations = [];
      state.scatterPlotData = [];
      state.boxPlotData = {};
      state.alertsTable = null;
      state.optimisationTable = null;
    },
    setNoSarLoss(state, action) {
      state.noSarLoss = action.payload;
    },
    setManualThresholds(state, action) {
      state.manualThresholds = action.payload;
    },
    setCanManualOpt(state, action) {
      state.canManualOpt = action.payload;
    },
    setOptimisationType(state, action) {
      state.optimisationType = action.payload;
    },
    setOptimisationAmountType(state, action) {
      state.optimisationAmountType = action.payload;
    },
    setOptimisationAmount(state, action) {
      state.optimisationAmount = action.payload;
    },
    setInitOptimisationParams(state) {
      state.optimisationType = INIT_OPTIMISATION_PARAMS["optimisationType"];
      state.optimisationAmountType =
        INIT_OPTIMISATION_PARAMS["optimisationAmountType"];
      state.noSarLoss = INIT_OPTIMISATION_PARAMS["noSarLoss"];
      state.optimisationAmount = INIT_OPTIMISATION_PARAMS["optimisationAmount"];
    },
    setChartHeight(state, action) {
      state.chartHeight = action.payload;
    },
    setSelectedSegmentOverview(state, action) {
      state.selectedSegmentOverview = action.payload;
      state.selectedSegmentOverviewUpdates = null;
      state.selectedRowId = null;
    },
    setChartType(state, action) {
      state.chartType = action.payload;
    },
    setYAxis(state, action) {
      const vis = state.visualisations.find(
        (visualisation) => visualisation.key === action.payload.id
      );
      if (vis) {
        vis.yAxis = action.payload.yAxis;
      }
    },
    setXAxis(state, action) {
      const vis = state.visualisations.find(
        (visualisation) => visualisation.key === action.payload.id
      );
      if (vis) {
        vis.xAxis = action.payload.xAxis;
      }
    },
    switchVisualisationOpenedStatus(state, action) {
      const vis = state.visualisations.find(
        (visualisation) => visualisation.key === action.payload
      );
      if (vis) {
        vis.isExpanded = !vis.isExpanded;
      }
    },
    setVisualisationClosed(state, action) {
      const vis = state.visualisations.find(
        (visualisation) => visualisation.key === action.payload
      );
      if (vis) {
        vis.isExpanded = false;
      }
    },
    setVisualisations(state, action) {
      state.visualisations = action.payload;
    },
    setVisualisationCreatorActive(state, action) {
      state.visualisationCreatorActive = action.payload;
    },
    addNewVisualisation(state, action) {
      const newKey =
        state.visualisations.length > 0
          ? state.visualisations[state.visualisations.length - 1].key + 1
          : 1;

      const newVisualisation = {
        key: newKey,
        isExpanded: true,
        chartType: action.payload.chartType,
        xAxis: action.payload.xAxis,
        yAxis: action.payload.yAxis,
        xSelector: action.payload.xSelector,
        ySelector: action.payload.ySelector,
      };

      state.visualisations.forEach((visualisation) => {
        visualisation.isExpanded = false;
      });

      state.visualisations.push(newVisualisation);
    },
    setJustAddedNewOptimisationRow(state, action) {
      state.justAddedNewOptimisationRow = action.payload;
    },
    setAlertsTable(state, action) {
      state.alertsTable = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchScenarios.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchScenarios.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = "succeeded";
          state.scenarios = action.payload;
          if (action.payload.length > 0) {
            // state.selectedScenario = action.payload[0];
            const firstScenario = action.payload[0];

            // If the first scenario has subscenarios, select the first one
            if (firstScenario.subscenarios && firstScenario.subscenarios.length > 0) {

              console.log("fetching scenarios compleeted, selected first subscenario")
              console.log("firstScenario", firstScenario)
              // console.log("current scenario", state.selectedScenario.name)
              
              //setSelectedScenario(state, firstScenario);

              console.log("BBBBB", firstScenario.subscenarios[0]);
              //state.selectedSubScenario = firstScenario.subscenarios[0];
              // When setting the subscenario, clear the selected customer segment
              //clearSelectedSegment(state);
            }
          }
        }
      })
      .addCase(fetchScenarios.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchSegments.pending, (state) => {
        state.statusSegments = "loading";
      }) 
      .addCase(fetchSegments.fulfilled, (state, action) => {
        state.statusSegments = "succeeded";
        state.segments = action.payload.segments;
        let maxAlerts = 0;
        for (const segment of action.payload.segments) {
          const totalAlerts =
            segment.non_relevant_alerts +
            segment.relevant_alerts +
            segment.sar_alerts;
          if (totalAlerts > maxAlerts) {
            maxAlerts = totalAlerts;
          }
        }
        state.chartHeight = maxAlerts * 1.1;
        console.log('fetchSegments.fulfilled', action.payload.segments)
      })
    .addCase(fetchSegments.rejected, (state, action) => {
      state.statusSegments = 'failed';
      state.error = action.error.message;
    })
    .addCase(fetchRiskScores.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchRiskScores.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.riskScores = action.payload.risk_scores;
    })
    .addCase(fetchRiskScores.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })
    .addCase(fetchOptimisationTable.pending, (state) => {
      // state.optimisationStatus = 'loading';
    })
    .addCase(fetchOptimisationTable.fulfilled, (state, action) => {

    
     
      state.optimisationTable = action.payload;
      // state.alertsTable = action.payload; // must be removed
    })
    .addCase(fetchOptimisationTable.rejected, (state, action) => {
   
      state.error = action.error.message;
    })

    .addCase(fetchSupressedAlerts.pending, (state) => {
      state.statusAlert = 'loading';
    })
    .addCase(fetchSupressedAlerts.fulfilled, (state, action) => {
      state.statusAlert = 'succeeded';
      state.alertsTable = action.payload; 
    })
    .addCase(fetchSupressedAlerts.rejected, (state, action) => {
      state.statusAlert = 'failed';
      state.error = action.error.message;
    })


    .addCase(fetchThresholds.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchThresholds.fulfilled, (state, action) => {
      if (action.payload) {
        state.status = 'succeeded';
        state.visualisationsThresholds = action.payload.thresholds;
      }
    })
    .addCase(fetchThresholds.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })
    .addCase(fetchBoxPlotData.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchBoxPlotData.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.boxPlotData = action.payload;
    })
    .addCase(fetchBoxPlotData.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message
    })
    .addCase(fetchScatterPlotData.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchScatterPlotData.fulfilled, (state, action) => {
      state.status = 'succeeded';

        // // Generate a unique key based on xOption and yOption
        const key = `${action.meta.arg.x_threshold_name}_${action.meta.arg.y_threshold_name}`;

        // // Ensure scatterPlotData is initialized as an object
        // if (!state.scatterPlotData) {
        //   state.scatterPlotData = [];
        // }

        const newData = fuseScatterDate(
          state.scatterPlotData,
          action.payload,
          key
        );
        state.scatterPlotData = newData;
      })
      .addCase(fetchScatterPlotData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(startOptimisation.pending, (state) => {
        state.loading = true;
      })
      .addCase(startOptimisation.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(startOptimisation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(checkOptimisationStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(checkOptimisationStatus.fulfilled, (state, action) => {
        state.loading = false;
        console.log("Optimisation status:", action.payload);
        if (action.payload.status === "completed") {
          state.optimisationTable = action.payload.optimisationTable;
          state.optimisationStatus = "succeeded";
        } else if (action.payload.status === "processing") {
          state.optimisationStatus = "loading";
        } else if (action.payload.status === "failed") {
          state.optimisationStatus = "failed job";
          state.loading = false;
          state.parameterOptimisationIsWaiting = false;
        }
      })
      .addCase(checkOptimisationStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteRows.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteRows.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.optimisationTable = action.payload.optimisationTable;
      })
      .addCase(deleteRows.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  addToReport,

  setParameterOptimisationIsWaiting,
  setVisualisationThresholds,
  setSelectedRowId,
  setSelectedSegmentOverviewUpdates,
  setNavBarIsCollapsed,
  setVisualisationChartType,
  setRiskScoreSelectorOption,
  setVisualisationXSelectorOption,
  setVisualisationYSelectorOption,
  setSelectedScenario,
  setSelectedSubScenario,
  setSelectedSegment,
  setSelectedRiskScore,
  setOptimisationType,
  setNoSarLoss,
  setManualThresholds,
  setCanManualOpt,
  setOptimisationAmountType,
  setOptimisationAmount,
  setInitOptimisationParams,
  setChartHeight,
  setSelectedSegmentOverview,
  setChartType,
  setYAxis,
  setXAxis,
  switchVisualisationOpenedStatus,
  setVisualisationClosed,
  setVisualisations,
  setVisualisationCreatorActive,
  addNewVisualisation,
  setReloadOptimisationTable,
  setSelectedYearState,
  setJustAddedNewOptimisationRow,
  setAlertsTable,
  clearSelectedSegment
} = atlToolSlice.actions;

export default atlToolSlice.reducer;
