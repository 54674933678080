import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import styles from './FakeProgressBar.module.css';

const FakeProgressBar = forwardRef((props, ref) => {
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    startProgress,
    completeProgress
  }));

  useEffect(() => {
    let progressInterval;

    const incrementProgress = () => {
      setProgress((prev) => {
        const increment = Math.max((100 - prev) * 0.01, 0.002); // Decrease increment size as it progresses
        return Math.min(prev + increment, 96.9); // Ensure it never exceeds 99.9%
      });
    };

    if (loading) {
      if (progress === 0) {
        setProgress(1); // Start from 1% when loading starts
      }
      progressInterval = setInterval(incrementProgress, 100);
    } else {
      clearInterval(progressInterval);
      setProgress(100); // Complete the progress bar when loading is false
    }

    return () => clearInterval(progressInterval);
  }, [loading]);

  useEffect(() => {
    if (!loading && progress === 100) {
      setTimeout(() => setProgress(0), 500); // Reset progress after a short delay
    }
  }, [loading, progress]);

  const startProgress = () => {
    setLoading(true);
  };

  const completeProgress = () => {
    setLoading(false);
  };

  return (
    <div className={styles.progressBarContainer}>
      <div
        className={styles.progressBar}
        style={{ width: `${Math.max(progress, 0)}%` }} // Ensure width is never negative
      ></div>
    </div>
  );
});

export default FakeProgressBar;
