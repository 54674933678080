import { useState, useEffect, Fragment, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import layout_styles from "../../../layout/Layout.module.css";
import styles from "./ConfigurationTable.module.css";
import HeaderSection from "../../../layout/HeaderSection";
import {
  loadConfigurations,
  updateConfiguration,
  uploadFiles,
  setUploadStatus,
  resetUploadDetails,
  setSelectedConfigurationDetails,
  setConfigActiveTab,
} from "../../../features/configuration/configurationSlice";
import TableGrid from "../../../layout/table/Table";
import { CellClass, RowCellClass } from "../../../layout/table/Cell";
import { HeaderClass } from "../../../layout/table/Header";
import SliderButton from "../../../layout/buttons/SliderButton";
import FileUploadModal from "../modals/FileUploadModal";
import {
  SecondaryButton,
  PrimaryDropDownButton,
} from "../../../layout/buttons/NormalButton";

function CustomizeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none">
      <path
        d="M21.1739 6.81238C21.7026 6.2838 21.9997 5.56685 21.9998 4.81923C21.9999 4.07162 21.703 3.35459 21.1744 2.82588C20.6459 2.29717 19.9289 2.00009 19.1813 2C18.4337 1.99991 17.7166 2.2968 17.1879 2.82538L3.84193 16.1744C3.60975 16.4059 3.43805 16.6909 3.34193 17.0044L2.02093 21.3564C1.99509 21.4429 1.99314 21.5347 2.01529 21.6222C2.03743 21.7097 2.08285 21.7896 2.14673 21.8534C2.21061 21.9172 2.29055 21.9624 2.37809 21.9845C2.46563 22.0065 2.55749 22.0044 2.64393 21.9784L6.99693 20.6584C7.3101 20.5631 7.59511 20.3925 7.82693 20.1614L21.1739 6.81238Z"
        stroke="#D9D6FE"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 5L19 9"
        stroke="#D9D6FE"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

function ImportIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none">
      <path
        d="M12 13V21"
        stroke="#D9D6FE"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.00034 14.8993C3.25738 14.1402 2.69691 13.222 2.36137 12.2142C2.02584 11.2064 1.92405 10.1355 2.0637 9.08256C2.20335 8.02962 2.5808 7.02226 3.16743 6.1368C3.75407 5.25133 4.53452 4.51098 5.44967 3.97182C6.36482 3.43265 7.39067 3.10882 8.44951 3.02484C9.50835 2.94087 10.5724 3.09895 11.5611 3.48712C12.5498 3.8753 13.4372 4.48337 14.1561 5.2653C14.8749 6.04723 15.4065 6.9825 15.7103 8.00026H17.5003C18.4659 8.00015 19.4058 8.31059 20.1813 8.8857C20.9569 9.46082 21.5269 10.2701 21.8071 11.1941C22.0874 12.118 22.063 13.1076 21.7377 14.0166C21.4123 14.9257 20.8032 15.706 20.0003 16.2423"
        stroke="#D9D6FE"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 17L12 13L16 17"
        stroke="#D9D6FE"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

function AddConfigButton({ setUploadVisible }) {
  const content = (
    <div style={{ display: "flex", gap: 8, padding: 8, alignItems: "center" }}>
      <div className={styles.plusIcon}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none">
          <path
            d="M3.7998 8.00098H12.1998"
            stroke="#8174FE"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.99902 3.80078V12.2008"
            stroke="#8174FE"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div className={styles.text}>New Configuration</div>
    </div>
  );

  return (
    <PrimaryDropDownButton
      content={content}
      action={[
        {
          name: "Customize",
          action: () => {},
          icon: <CustomizeIcon />,
        },
        {
          name: "Import",
          action: () => setUploadVisible(true),
          icon: <ImportIcon />,
        },
      ]}
    />
  );
}

function StatusLabel({ status, dispatch, configActiveTab, configId }) {
  async function updateConfig() {
    const statusToSet =
      status === "In production" ? "Previously in production" : "In production";
    let newData = { status: statusToSet };
    if (status == "In production") {
      newData["left_production"] = new Date().toISOString();
    } else {
      newData["entered_production"] = new Date().toISOString();
    }
    const resultAction = await dispatch(
      updateConfiguration({ configId, newData })
    );
    if (updateConfiguration.fulfilled.match(resultAction)) {
      if (status === "In production") {
        dispatch(setConfigActiveTab("Development"));
      } else {
        dispatch(setConfigActiveTab("Production"));
      }
      dispatch(loadConfigurations());
    } else {
      console.error("Update configuration failed:", resultAction.payload);
    }
  }

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <div
          className={`${styles.bullet} ${
            status == "In production"
              ? styles.green
              : status == "Previously in production"
              ? styles.yellow
              : styles.red
          }`}
        />
        <div style={{ whiteSpace: "nowrap" }}>{status}</div>
      </div>
      <SecondaryButton
        content={
          configActiveTab === "Development" ||
          configActiveTab === "Previously in production"
            ? "run"
            : "stop"
        }
        style={{ padding: "7px 14px", fontSize: 12 }}
        action={updateConfig}
      />
    </div>
  );
}

function ConfigurationTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { configurations, uploadStatus, uploadDetails, configActiveTab } =
    useSelector((state) => state.configuration);
  const [uploadVisible, setUploadVisible] = useState(false);
  useEffect(() => {
    dispatch(loadConfigurations());
  }, []);

  const headers = [
    new HeaderClass("Name", "name", true, true, {
      minWidth: 250,
      maxWidth: 250,
      width: 250,
    }),
    new HeaderClass("Status", "status", true, true, {
      minWidth: 280,
      maxWidth: 280,
      width: 280,
    }),
    new HeaderClass("Domain", "domain", true, true, {
      minWidth: 140,
    }),
    new HeaderClass("Modification Date", "modification_date", true, false, {
      minWidth: 150,
    }),
    new HeaderClass("Entered Production", "entered_production", true, false, {
      minWidth: 150,
    }),
    new HeaderClass("Left Production", "left_production", true, false, {
      minWidth: 150,
    }),
  ];

  const filteredConfigurations = configurations.filter((row) => {
    if (configActiveTab === "Production") {
      return row.status && row.status == "In production";
    } else {
      return row.status && row.status !== "In production";
    }
  });

  const hideModal = () => {
    setUploadVisible(false);
    dispatch(resetUploadDetails());
  };

  const rows = filteredConfigurations.map((rowData) => {
    const configId = rowData.id;
    const cells = headers.map((header) => {
      const value = rowData[header.field];
      if (header.field === "status") {
        return new CellClass(value, header.field, () => (
          <StatusLabel
            configId={configId}
            status={value}
            configActiveTab={configActiveTab}
            dispatch={dispatch}
          />
        ));
      }
      if (
        header?.field === "modification_date" ||
        header?.field === "entered_production" ||
        header?.field === "left_production"
      ) {
        if (value) {
          const dateOnly = value.split("T")[0];
          return new CellClass(dateOnly, header.field);
        }
      }
      return new CellClass(value, header.field);
    });

    return new RowCellClass(configId, cells, rowData);
  });

  let onRowClick = undefined
  if (configActiveTab === "Development"){
    onRowClick = (row) => {
      navigate("/configuration/" + row.id);
      setSelectedConfigurationDetails(row);
    }
  }

  return (
    <div className={styles.atlTool}>
      <div className={layout_styles.sectionWrapper}>
        <HeaderSection
          SectionName="Configuration"
          AdditionalText="Configuration Overview"
        />
        {uploadVisible && (
          <FileUploadModal
            hideModalFunction={hideModal}
            dispatchUploadFunction={uploadFiles}
            uploadStatus={uploadStatus}
            dispatchUploadStatus={setUploadStatus}
            uploadDetails={uploadDetails}
          />
        )}
        <TableGrid
          title={"Configurations"}
          headers={headers}
          rows={rows}
          canExport={false}
          onRowClick={onRowClick}
          noDataPlaceholder={"No Configuration"}
          actionButtonsLeft={[
            <SliderButton
              left={"Production"}
              right={"Development"}
              leftActive={configActiveTab == "Production"}
              onClick={(tab) => dispatch(setConfigActiveTab(tab))}
            />,
          ]}
          actionButtonsRight={[
            <AddConfigButton setUploadVisible={setUploadVisible} />,
          ]}
        />
      </div>
    </div>
  );
}

export default ConfigurationTable;
