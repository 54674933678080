// /src/components/StatusButton/StatusButton.js
import React from 'react';
import styles from './StatusButton.module.css';

const StatusButton = ({ status = null}) => {

    let label = "CLOSED";
    let className = styles.closed;//styles.open;
    let typeclass = styles.button;

    if (status) 
        {
        if (status.toLowerCase().includes("reported")) {
            label = "REPORTED";
            className = styles.reported;
            typeclass = styles.label;
        } else if (status.toLowerCase() === "closed") {
            label = "CLOSED";
            className = styles.closed;
            typeclass = styles.label;
        }
 
    }
    else
    {
        label = "UNDEFINED";
        className = styles.closed;
        typeclass = styles.label;
    }
    

    return <div className={`${typeclass} ${className}`}>{label}</div>;
};


export default StatusButton;
