import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setAlertInfoType, setSelectedOverlap } from '../../../features/overlap_analysis/overlapAnalysisSlice.js';
import styles from './AlertPopUp.module.css';
import InputComponent from '../../atl_tool/alert_table/inputs/InputComponent.js';
import CommentTable from './CommentTable.js';


const AlertPopUp = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const { overlapData, alertInfoType } = useSelector((state) => state.overlapAnalysis);
  const popupRef = useRef();

  // Get the selected overap alert id
  const selectedOverlapAlertId = useSelector((state) => state.overlapAnalysis.selectedOverlapAlertId)
  const selectedOverlap = (overlapData || []).find((overlap) => overlap['Alert ID'] === selectedOverlapAlertId)

  // Handle the hovered effect of the popup - when clicked outside the box, close the popup
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';

      const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
          handleClose();
        }
      };

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        document.body.style.overflow = 'auto';
      };
    }
  }, [isOpen]);

  const handleAlertInfoTypeClick = (type) => {
    dispatch(setAlertInfoType(type));
  }

  // Handle closure of the popup
  const handleClose = () => {
    dispatch(setSelectedOverlap(null));
    onClose();
  };

  // Get the color for status component
  const getColor = (text) => {
    if (text.toLowerCase() === 'non relevant'){
      return 'green';
    }
    else if (text.toLowerCase() === 'relevant'){
      return 'yellow';
    }
    else {
      return 'red';
    }
  }

  // If the popup should not show, return null
  if (!isOpen || !selectedOverlap) return null;

  return (
    <div className={styles.overlay}>
      <div className={styles.popup} ref={popupRef}>
        <div className={styles.header}>

          <div className={styles.headerTitle}>
            {selectedOverlap["Alert ID"]}
          </div>

          <div className={styles.selectionBox}> 
            <div className={styles.chartTypeSelectionHolder}>
              <div className={`${styles.chartTypeSelectionButton} ${alertInfoType === 'Alert Details' ? styles.chartTypeSelectionButtonActive : ''}`} onClick={() => handleAlertInfoTypeClick('Alert Details')}>
                Alert Details
              </div>
              <div className={`${styles.chartTypeSelectionButton} ${alertInfoType === 'Audit Log' ? styles.chartTypeSelectionButtonActive : ''}`} onClick={() => handleAlertInfoTypeClick('Audit Log')}>
                Audit Log
              </div>
            </div>
          
            <svg style={{cursor: 'pointer'}} onClick={handleClose} xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
              <path d="M18 6.60156L6 18.6016" stroke="#AFB5D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M6 6.60156L18 18.6016" stroke="#AFB5D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>

          </div>
        </div>
      
        {alertInfoType === 'Alert Details' && (
          <div className={styles.contentWrapper}>
            <div className={styles.content}>
              <InputComponent type="normal" label="Alert ID" value={selectedOverlap["Alert ID"]} onChange={() => {}} />
              <InputComponent type="normal" label="Creation Time" value={selectedOverlap["Event Date"]} onChange={() => {}} />
              <InputComponent type="normal" label="Domain name" value={selectedOverlap["Domain Name"]} onChange={() => {}} />
              <InputComponent type="normal" label="Segment n°" value={selectedOverlap["Segment N"]} onChange={() => {}} />
              <InputComponent type="normal" label="KYC Segment" value={selectedOverlap["KYC Segment"]} onChange={() => {}} />
              <InputComponent type="normal" label="Risk Level" value={selectedOverlap["Risk Level"]} onChange={() => {}} />
              <InputComponent type="normal" label="Risk Number" value={selectedOverlap["Risk Number"]} onChange={() => {}} />
              <InputComponent type="info" label="NetReveal Segment" value={selectedOverlap["NetReveal Segment"]} infoText="This is the segment of the alert." currencyOptions={[]} onChange={() => {}} />
              <InputComponent type="status" label="Status" badges={[{ label: selectedOverlap['Alert Status Category'], color: getColor(selectedOverlap['Alert Status Category'])}]} />
            </div>
            <div className={styles.textAreaBox}>
              <InputComponent type="comment" label="Reason" 
                value={"AML31-2: The transaction of 50415.04 euro exceeds the average 4 week credit amount of 380.86 and the total number of external credit transactions over the past 4 weeks is 250 which exceeds the threshold of 40.00. The average four week credit amount of 380.86 euro is lower than 1000.00 euro."}
              />
            </div>
            <div className={styles.textAreaBox}>
              <InputComponent type="comment" label="Comment" value={"Enter a description..."} />
            </div>
          </div>
        )}

        {alertInfoType === 'Audit Log' && (
          <CommentTable/>
        )}
      </div>
    </div>)}

export default AlertPopUp;
