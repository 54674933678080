import { Fragment, useEffect } from "react";

import styles from "./Table.module.css";

const MAX_PAGES_TO_SHOW = 5;

function Pagination({ pages, currentPage, setCurrentPage }) {
  const createPagination = (currentPage, pages) => {
    const halfPagesToShow = Math.floor(MAX_PAGES_TO_SHOW / 2);

    let startPage = Math.max(1, currentPage - halfPagesToShow);
    let endPage = Math.min(pages, currentPage + halfPagesToShow);

    // Adjust startPage and endPage to always show exactly MAX_PAGES_TO_SHOW pages
    if (endPage - startPage + 1 < MAX_PAGES_TO_SHOW) {
      if (startPage === 1) {
        endPage = Math.min(pages, startPage + MAX_PAGES_TO_SHOW - 1);
      } else if (endPage === pages) {
        startPage = Math.max(1, endPage - MAX_PAGES_TO_SHOW + 1);
      }
    }

    const pageNumbers = [];

    if (startPage > 1) {
      pageNumbers.push(1);
      if (startPage > 2) {
        pageNumbers.push("...");
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    if (endPage < pages) {
      if (endPage < pages - 1) {
        pageNumbers.push("...");
      }
      pageNumbers.push(pages);
    }

    return pageNumbers;
  };

  useEffect(() => {
    if (currentPage > pages && pages !== 0) {
      setCurrentPage(pages);
    }
  }, [pages]);

  const pageNumbers = createPagination(currentPage, pages);

  return (
    <Fragment>
      {pages > 0 && (
        <div className={styles.pagination}>
          <button
            className={styles.paginationButton}
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 21"
              fill="none">
              <path
                d="M15.8327 10.1693H4.16602M4.16602 10.1693L9.99935 16.0026M4.16602 10.1693L9.99935 4.33594"
                stroke="#9E77ED"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Previous
          </button>
          <div className={styles.pageNumbers}>
            {pageNumbers.map((page, index) => (
              <button
                key={index}
                onClick={() => {
                  if (page !== "...") {
                    setCurrentPage(page);
                  }
                }}
                className={
                  currentPage === page ? styles.activePage : styles.unactivePage
                }
                disabled={page === "..."}>
                {page}
              </button>
            ))}
          </div>
          <button
            className={styles.paginationButton}
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage >= pages}>
            Next
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 21"
              fill="none">
              <path
                d="M4.16602 10.1693H15.8327M15.8327 10.1693L9.99935 4.33594M15.8327 10.1693L9.99935 16.0026"
                stroke="#9E77ED"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      )}
    </Fragment>
  );
}

export default Pagination;
