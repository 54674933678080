import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './SegmentSelection.module.css';
import BarComponent from './BarComponent';
import BarY from './BarY';
import { setChartHeight } from '../../../../features/atl_tool/atlToolSlice';

import { Scrollbar } from 'react-scrollbars-custom';

const SegmentSelection = () => {
    const dispatch = useDispatch();
    const { segments, selectedRowId } = useSelector((state) => state.atlTool);
    const height = 200;

    const [showSAR, setShowSAR] = useState(true);
    const [showRelevant, setShowRelevant] = useState(true);
    const [showNonRelevant, setShowNonRelevant] = useState(true);
    const [showSupressedAlerts, setShowSupressedAlerts] = useState(false);

    useEffect(() => {
        console.log('SegmentSelection mounted');
        console.log('SegmentSelection - segments:', segments);
        return () => {
            console.log('SegmentSelection unmounted');
        };
    }, [segments]);

         //calculate the height of the chart based on the number of alerts depending on the toggles of the legend
         const calculateChartHeight = () => {
            let chartHeight = 0;
           
            for (const segment of segments) {
              let totalAlerts =0;
              if(showSAR)
              {
                totalAlerts += segment.sar_alerts;
              }
                if(showRelevant)
                {
                    totalAlerts += segment.relevant_alerts;
                }
                if(showNonRelevant)
                {
                    totalAlerts += segment.non_relevant_alerts;
                }
                
              if (totalAlerts > chartHeight) {
                chartHeight = totalAlerts;
              }
            }
            return chartHeight  * 1.1;
        };

    useEffect(() => {
   
        dispatch(setChartHeight(calculateChartHeight()));
        
    }, [showSAR, showRelevant, showNonRelevant]);

    const toggleShowSAR = () => setShowSAR(prev => !prev);
    const toggleShowRelevant = () => setShowRelevant(prev => !prev);
    const toggleShowNonRelevant = () => setShowNonRelevant(prev => !prev);
    const toggleShowSupressedAlerts = () => setShowSupressedAlerts(prev => !prev);

    // Handle edge case of no segments in a scenario (should never happen in real use)
    if (segments.length === 0){
        return (
            <div className={styles.barChart1}>
                <div className={styles.legend}>
                    <div className={styles.sarAlerts} onClick={toggleShowSAR} style={{ cursor: 'pointer', opacity: showSAR ? 1 : 0.5 }}>
                        <img className={styles.colorIcon} alt="" src={`${process.env.PUBLIC_URL}/Color_red.svg`}/>
                        <div className={styles.text}>SAR alerts</div>
                    </div>
                    <div className={styles.sarAlerts} onClick={toggleShowRelevant} style={{ cursor: 'pointer', opacity: showRelevant ? 1 : 0.5 }}>
                        <img className={styles.colorIcon} alt="" src={`${process.env.PUBLIC_URL}/Color_yellow.svg`}  />
                        <div className={styles.text}>Relevant alerts</div>
                    </div>
                    <div className={styles.sarAlerts} onClick={toggleShowNonRelevant} style={{ cursor: 'pointer', opacity: showNonRelevant ? 1 : 0.5 }}>
                        <img className={styles.colorIcon} alt="" src={`${process.env.PUBLIC_URL}/Color_green.svg`} />
                        <div className={styles.text}>Non relevant alerts</div>
                    </div>
                </div>

                <div style={{ display: "flex", width: "100%" }}>
                    <div style={{ backgroundColor: "white", position: "sticky", left: "0px", height: "100%" }}>
                        <div className={styles.alertsNumber} style={{ display: "inline-flex", bottom: 0, height: "200px", marginLeft: "10px", backgroundColor: "white" }}>
                            <BarY height={height} />
                        </div>
                    </div>
                
                    <div className={styles.barChart} style={{ display: "inline", overflowX: "auto", overflowY: "hidden" }}>
                    <Scrollbar 
                        trackXProps={{ style: { height: '5px' , backgroundColor: '#E9D7FE' } }} 
                        thumbXProps={{ style: { backgroundColor: '#D6BBFB' }}} 
                        className={styles.barChart} 
                        style={{ position: "relative", height: "280px", width: "100%",  overflowY: "visible"}}
                        trackYProps={{ style: {display: 'none'}}}
                        wrapperProps={{
                        renderer: props => {
                            const { elementRef, ...restProps } = props;
                            return (
                            <span
                                {...restProps}
                                ref={elementRef}
                                style={{marginBottom: '-5px', position: 'absolute', inset: '0px 0px 5px 0px', overflow:'hidden'}}
                            />
                            );
                        }
                    }}>
                        <div className={styles.barChart2}>
                            <BarComponent
                                key={-1}
                                segment={"No Alerts"}
                                height={height}
                                showSAR={true}
                                showRelevant={true}
                                showNonRelevant={true}
                                showGrayBar={true}
                            />
                        </div>
                        </Scrollbar>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={styles.barChart1}>
            <div className={styles.legend}>
                <div className={styles.sarAlerts} onClick={toggleShowSAR} style={{ cursor: 'pointer', opacity: showSAR ? 1 : 0.5 }}>
                    <img className={styles.colorIcon} alt="" src={`${process.env.PUBLIC_URL}/Color_red.svg`}/>
                    <div className={styles.text}>SAR alerts</div>
                </div>
                <div className={styles.sarAlerts} onClick={toggleShowRelevant} style={{ cursor: 'pointer', opacity: showRelevant ? 1 : 0.5 }}>
                    <img className={styles.colorIcon} alt="" src={`${process.env.PUBLIC_URL}/Color_yellow.svg`}  />
                    <div className={styles.text}>Relevant alerts</div>
                </div>
                <div className={styles.sarAlerts} onClick={toggleShowNonRelevant} style={{ cursor: 'pointer', opacity: showNonRelevant ? 1 : 0.5 }}>
                    <img className={styles.colorIcon} alt="" src={`${process.env.PUBLIC_URL}/Color_green.svg`} />
                    <div className={styles.text}>Non relevant alerts</div>
                </div>
                {selectedRowId && 
                <div className={styles.sarAlerts} onClick={toggleShowSupressedAlerts} style={{ cursor: 'pointer', opacity: 1 }}>
                        <img className={styles.colorIcon} alt="" src={`${process.env.PUBLIC_URL}/Color_gray.svg`} />
                        <div className={styles.text}>Supressed Alerts</div>
                </div>
                }
            </div>

            <div style={{ display: "flex", width: "100%" }}>
                <div style={{ backgroundColor: "white", position: "sticky", left: "0px", height: "100%" }}>
                    <div className={styles.alertsNumber} style={{ display: "inline-flex", bottom: 0, height: "200px", marginLeft: "10px", backgroundColor: "white" }}>
                        <BarY height={height} />
                    </div>
                </div>
              
                <div className={styles.barChart} style={{ display: "inline", overflowX: "auto", overflowY: "hidden" }}>
                <Scrollbar 
                    trackXProps={{ style: { height: '5px' , backgroundColor: '#E9D7FE' } }} 
                    thumbXProps={{ style: { backgroundColor: '#D6BBFB' }}} 
                    className={styles.barChart} 
                    style={{ position: "relative", height: "280px", width: "100%",  overflowY: "visible"}}
                    trackYProps={{ style: {display: 'none'}}}
                    wrapperProps={{
                    renderer: props => {
                        const { elementRef, ...restProps } = props;
                        return (
                        <span
                            {...restProps}
                            ref={elementRef}
                            style={{marginBottom: '-5px', position: 'absolute', inset: '0px 0px 5px 0px', overflow:'hidden'}}
                        />
                        );
                    }
                }}>
                    <div className={styles.barChart2}>
                        {segments.map((segment) => (
                            <BarComponent
                                key={segment.id}
                                segment={segment}
                                height={height}
                                showSAR={showSAR}
                                showRelevant={showRelevant}
                                showNonRelevant={showNonRelevant}
                            />
                        ))}
                    </div>
                    </Scrollbar>
                </div>
            </div>
        </div>
    );
};

export default SegmentSelection;
