import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import  apiClient from '../../axiosConfig';
import JSZip from 'jszip';


const convertDataLabelDetails = (details) => {
  return {
    data_source: details.data_source,
    data_source_id: details.data_source_id,
    definition: details.definition,
    description: details.description, 
    id: details.id,
    list: details.list ? [details.list] : [],
    name: details.name,
  }
}

const convertScenarioDetails = (details) => {
  return {
    definition: details.definition,
      label_condition: details.label_condition,
    frequency: details.frequency,
    info: details.info,
      description: details.description, 
    scenario_lists: details.scenario_lists ? [details.scenario_lists] : [],
    scenario_datafields: details.scenario_datafields ? [details.scenario_datafields] : [],
    scenario_profiles: details.scenario_profiles ? [details.scenario_profiles] : [],
    name: details.name,
    id: details.id,
  }
}


export const fetchDataLabels = createAsyncThunk(
  'configuration/fetchDataLabels',
  async () => {
    console.log('Fetching data labels from API...');
    const response = await apiClient.get('/datalabels');
    return response.data;
  }
);

export const loadConfigurations = createAsyncThunk(
  'configuration/loadConfigurations',
  async () => {
    const limit = "inf";
    const response = await apiClient.get(`configurations`, {
      params: { limit },
    });
    return response.data;
  }
);




export const fetchCustomerSegments = createAsyncThunk(
  'configuration/fetchCustomerSegments',
  async () => {
    console.log('Fetching customer segments from API...');
    const response = await apiClient.get('/customer-segments');
    return response.data;
  }
);



export const fetchViewOptions = createAsyncThunk(
  'configuration/views',
  async () => {
    console.log('Fetching data labels from API...');
    const response = await apiClient.get('/views');
    return response.data;
  }
);




export const fetchRiskLevelOptions = createAsyncThunk(
  'configuration/risk-levels',
  async () => {
    console.log('Fetching data labels from API...');
    const response = await apiClient.get('/risk-levels');
    return response.data;
  }
);


export const fetchDataSources = createAsyncThunk(
  'configuration/fetchDataSources',
  async () => {
    console.log('Fetching data sources from API...');
    const response = await apiClient.get('/data-sources');
    return response.data;
  }
);

export const fetchDataLabelDetails = createAsyncThunk(
  'configuration/fetchDataLabelDetails',
  async ({ dataLabelId }) => {
    console.log('Fetching data label details for label with id: ', dataLabelId, ' from AP...');
    const response = await apiClient.get(`/datalabels/${dataLabelId}`);
    const result = convertDataLabelDetails(response.data);
    return result;
  }
)

export const fetchInfoLists = createAsyncThunk(
  'configuration/fetchInfoLists',
  async () => {
    console.log('Fetching info lists from API...');
    const response = await apiClient.get('/lists');
    return response.data;
  }
)

export const fetchProfiles = createAsyncThunk(
  'configuration/fetchProfiles',
  async () => {
    console.log('Fetching profiles from API...');
    const response = await apiClient.get('profiles');
    return response.data;
  }
)

export const fetchProfileDetails = createAsyncThunk(
  'configuration/fetchProfileDetails',
  async ({ profileId }) => {
    console.log('Fetching profile details for profiel with id ', profileId, ' from API...');
    const response = await apiClient.get(`profiles/${profileId}`);
    return response.data;
  }
)

export const fetchTimeBaseOptions = createAsyncThunk(
  'configuration/fetchTimeBaseOptions',
  async () => {
    console.log('Fetching time base options from API...');
    const response = await apiClient.get('frequencies');
    return response.data;
  }
)

export const putNewParameterValue = createAsyncThunk(
  'configuration/putNewParameterValue',
  async ( {rowId, columnId, value} ) => {
    console.log('Putting new parameter value to API...');
  

    const response = await apiClient.put('/list-values/update', {
      row_id: rowId,
      column_id: columnId,
      value: value.toString()
    });
    return response.data;
  }
);


export const updateParameterListColumn = createAsyncThunk(
  'configuration/updateParameterListColumn',
  async ( {editedRow} ) => {
    console.log('Updating parameter column value from API...', editedRow);
    const response = await apiClient.put(`/parameter-list-columns/${editedRow.list_id}?list_column_id=${editedRow.id}`, {
      name: editedRow.name,
      data_type: editedRow.data_type.toUpperCase(),
      description: editedRow.description
    });
    return response.data;
  }
);


export const createParameterListColumn = createAsyncThunk(
  'configuration/createParameterListColumn',
  async ({ name, description, dataType, listId }) => {
    const payload = [
      {
        name: name,
        description: description,
        data_type: dataType,
        list_id: listId
      }
    ];
    const response = await apiClient.post(`/parameter-list-columns`, payload);
    return response.data;
  }
);



export const createList = createAsyncThunk(
  'configuration/createList',
  async ( {name, description} ) => {
    console.log('Creating list from API...');
    const response = await apiClient.post('/parameter-lists', {"parameter_list": {"name": name, "description": description, "type": "Parameter list"}});
    return response.data;
  }
);



export const deleteList = createAsyncThunk(
  'configuration/deleteList',
  async ( {listId} ) => {
    console.log('Deleting list from API...');
    const response = await apiClient.delete(`/lists/${listId}`);
    return response.data;
  }
);

export const setScenarioActivation = createAsyncThunk(
  'configuration/setScenarioActivation',

  

  async ( {scenario_id, active, viewOptions} , { dispatch, rejectWithValue }) => {
    console.log('Setting scenario activation from API...');
    viewOptions.map(option => {
      if(option.scenario_view_activation)
      {
         option.scenario_view_activation.map(scenario => {
          if(scenario.scenario_id === scenario_id)
          {
           
              apiClient.put('/scenario-view-activation/update', {      
                scenario_id: scenario_id,
                view_id: scenario.view_id,
                active: active,
              }
            );
        
            
          }
        })
      }
    });
    dispatch(fetchScenarios());
    dispatch(fetchViewOptions());
  }
);

// Helper function to fetch all unique category names in one go
const fetchCategoryNames = async (uniqueCategoryIds) => {
  try {
    // Fetch all categories in a single batch request using the category IDs
    const categoryPromises = uniqueCategoryIds.map(id => 
      apiClient.get(`/scenarios-categories/${id}`)
    );
    
    // Wait for all API calls to complete
    const categoryResponses = await Promise.all(categoryPromises);

    // Create a dictionary { category_id: category_name }
    const categoryDictionary = {};
    categoryResponses.forEach((response, index) => {
      const categoryId = uniqueCategoryIds[index];
      categoryDictionary[categoryId] = response.data.label; // Assuming the category name is in response.data.name
    });

    return categoryDictionary;
  } catch (error) {
    console.error('Failed to fetch category names', error);
    return {};
  }
};

export const fetchScenarios = createAsyncThunk(
  'configuration/fetchScenarios',
  async () => {
    console.log('Fetching scenarios from API...');
    const response = await apiClient.get('/scenarios');
    console.log("Fetching scenarios from API response", response);

    // Extract unique category IDs from the scenarios
    const uniqueCategoryIds = [...new Set(response.data.map(scenario => scenario.ds?.category_id))].filter(Boolean); // Filter out undefined/null values

    // Fetch the category names for these unique category IDs
    const categoryDictionary = await fetchCategoryNames(uniqueCategoryIds);

    const newData  = response.data.map((scenario) => {  
      return {
        Scenario: scenario.name,
        DS: scenario.ds ? scenario.ds.name : "DS11",
        Id : scenario.id,

        Frequency: scenario.frequency,
        Group: !scenario.ds ?  "Weath Management , Retail Markets"   : scenario.customer_segments.map(seg => seg.name).join(", "), // Concatenate all group names
        Status:  scenario.label_condition || "Undefined" , // Example status
        // Status:  "Undefined" , // Example status
        Definition: scenario.definition,
        Category_id: scenario.ds.category_id,
        Category_name: categoryDictionary[scenario.ds?.category_id] || "Unknown Category" // Assign category name from dictionary
    };
  })
    return newData;
  }
);


export const fetchScenarioDetails = createAsyncThunk(
  'configuration/fetchScenarioDetails',
  async ({ scenarioID }) => {
    // console.log('fetchScenarioDetails: ', scenarioID, ' from AP...');
    const response = await apiClient.get(`/btl-scenarios/${scenarioID}`);
    console.log("response scenario details",response);

    const result = convertScenarioDetails(response.data);
    console.log("fetching scenario details",result);
    return result;
  }

)


// export const uploadFiles = createAsyncThunk(
//   'fileUpload/uploadFiles',
//   async ({ files }) => {
//     try {
//       // Create FormData object
//       const formData = new FormData();

//       // Append all the selected files
//       files.forEach((file, index) => {
//         formData.append('files', file.file);  // file.file refers to the actual file object
//       });

//       // Send a POST request to the /upload endpoint with multiple files
//       const response = await apiClient.post('/upload', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });

//       console.log("File upload response", response.data);
//       return response.data;
//     } catch (error) {
//       console.error("Error during file upload", error);
//       throw error;
//     }
//   }
// );



export const uploadFiles = createAsyncThunk(
  'fileUpload/uploadFiles',
  async ({ files }) => {
    try {
      const formData = new FormData();

      if (files.length === 1) {
        const file = files[0].file;
        const fileName = file.name;

        // Check if the single file is a zip file
        if (fileName.toLowerCase().endsWith('.zip')) {
          // Rename the file to 'Configuration.zip'
          const renamedFile = new File([file], 'Configuration.zip', { type: file.type });

          // Append the renamed file to FormData
          formData.append('files', renamedFile);
        } else {
          // The single file is not a zip; zip it into 'Configuration.zip'
          const zip = new JSZip();
          zip.file(fileName, file);

          // Generate the zip file as a Blob
          const zipBlob = await zip.generateAsync({ type: 'blob' });

          // Create a File object for the zip file
          const zipFile = new File([zipBlob], 'Configuration.zip', { type: 'application/zip' });

          // Append the zip file to FormData
          formData.append('files', zipFile);
        }
      } else if (files.length > 1) {
        // Multiple files selected; zip them into 'Configuration.zip'
        const zip = new JSZip();

        // Add each file to the zip archive
        files.forEach((fileItem) => {
          const file = fileItem.file;
          const fileName = file.name;
          zip.file(fileName, file);
        });

        // Generate the zip file as a Blob
        const zipBlob = await zip.generateAsync({ type: 'blob' });

        // Create a File object for the zip file
        const zipFile = new File([zipBlob], 'Configuration.zip', { type: 'application/zip' });

        // Append the zip file to FormData
        formData.append('files', zipFile);
      } else {
        // No files selected; handle accordingly (e.g., show an error or return)
        console.error('No files selected for upload.');
        return;
      }

      // Send a POST request to the /upload endpoint with the FormData
      const response = await apiClient.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('File upload response', response.data);
      return response.data;
    } catch (error) {
      console.error('Error during file upload', error);
      throw error;
    }
  }
);

function parseUploadResponse(response) {
  const parsedDetails = response.details.map(detail => {
    // Define the object structure for each file
    let result = {
      fileName: "",
      uploadStatus: "",
      uploadMsg: ""
    };

    // Check if the detail contains "uploaded successfully"
    if (detail.includes("uploaded successfully")) {
      // Extract the file name and mark status as "success"
      const fileName = detail.match(/File (.*) uploaded successfully/)[1];
      result.fileName = fileName;
      result.uploadStatus = "success";
      result.uploadMsg = "File uploaded successfully";
    } else if (detail.includes("Error uploading")) {
      // Extract the file name and error message
      const fileName = detail.match(/Error uploading (.*):/)[1];
      const errorMsg = detail.match(/Error uploading .*: (.*)/)[1];
      result.fileName = fileName;
      result.uploadStatus = "error";
      result.uploadMsg = errorMsg;
    }

    return result;
  });

  return {
    status: response.status,
    details: parsedDetails
  };
}

export const fetchParameterDetails = createAsyncThunk(
  'configuration/fetchParameterDetails',   
  async (selectedParameterId) => {
    const response = await apiClient.get(`/lists/${selectedParameterId}`);
    return response.data;
  }
)

export const fetchConfigurationDataLabels = createAsyncThunk(
  'configuration/fetchConfigurationDataLabels',
  async (configId) => {
    const response = await apiClient.get(`/configurations/${configId}/datalabels`);
    console.log("fetching data labels::", response.data);
    return response.data;
  }
)

export const createDataLabel = createAsyncThunk(
  'configuration/createDataLabel',
  async ( {dataLabel} ) => {
    console.log('Creating data label from API...');
    const response = await apiClient.post(`/datalabels`, dataLabel);
    return response.data;
  }
);

export const updateDataLabel = createAsyncThunk(
  'configuration/updateDataLabel',
  async ( {dataLabel, id} ) => {
    console.log('Updating data label from API...');
    const response = await apiClient.put(`/datalabels/${id}`, dataLabel);
    return response.data;

  }
);

export const createProfile = createAsyncThunk(
  'configuration/createProfile',
  async ( {profile} ) => {
    console.log('Creating profile from API...');
    const response = await apiClient.post(`/profiles`, profile);
    return response.data;
  }
);

export const updateProfile = createAsyncThunk(
  'configuration/updateProfile',
  async ( {profile, id} ) => {
    console.log('Updating profile from API...');
    const response = await apiClient.put(`/profiles/${id}`, profile);
    return response.data;
  }
);

export const createScenario = createAsyncThunk(
  'configuration/createScenario',
  async ( {scenario, configId} ) => {
    console.log('Creating scenario from API...');
    const response = await apiClient.post(`/scenarios?configuration_id=${configId}`, scenario);
    return response.data;
  }
);


export const updateScenario = createAsyncThunk(
  'configuration/updateScenario',
  async ( {scenario, id} ) => {
    console.log('Updating scenario from API...');
    const response = await apiClient.put(`/scenarios/${id}`, scenario);
    return response.data;
  }
);

export const linkDataLabel = createAsyncThunk(
  'configuration/linkDataLabel',
  async ( {dataLabel, config} ) => {
    console.log('Linking data label from API...');
    const response = await apiClient.post(`/configuration-datalabels`, {datalabel_id: dataLabel, configuration_id: config});
    return response.data;
  }
);

export const linkProfile = createAsyncThunk(
  'configuration/linkProfile',
  async ( {profile, config} ) => {
    console.log('Linking profile from API...');
    const response = await apiClient.post(`/configuration-profiles`, {profile_id: profile, configuration_id: config});
    return response.data;
  }
);

export const fetchConfigurationProfiles = createAsyncThunk(
  'configuration/fetchConfigurationProfiles',
  async (configId) => {
    const response = await apiClient.get(`/configurations/${configId}/profiles`);
    return response.data;
  }
)

export const fetchConfigurationParametersList = createAsyncThunk(
  'configuration/fetchConfigurationParametersList',
  async (configId) => {
    const response = await apiClient.get(`/configurations/${configId}/lists`);
    return response.data;
  }
)

export const fetchConfigurationDetails = createAsyncThunk(
  'configuration/fetchConfigurationDetails',
  async (configId) => {
    const response = await apiClient.get(`/configurations/${configId}`);
    return response.data;
  }
)


export const updateConfiguration = createAsyncThunk(
  'configuration/updateConfiguration',
  async ({ configId, newData }, { rejectWithValue }) => {
    try {
      const response = await apiClient.put(`/configurations/${configId}`, newData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchConfigurationScenarios = createAsyncThunk(
  'configuration/fetchConfigurationScenarios',
    async (configId) => {
            const response = await apiClient.get(`/configurations/${configId}/scenarios`);

            const uniqueCategoryIds = [...new Set(response.data.map(scenario => scenario.ds?.category_id))].filter(Boolean); // Filter out undefined/null values

            const categoryDictionary = await fetchCategoryNames(uniqueCategoryIds);
            const newData  = response.data.map((scenario) => {  
              return {
                Scenario: scenario.name,
                DS: scenario.ds ? scenario.ds.name : "DS11",
                Id : scenario.id,
        
                Frequency: scenario.frequency,
                 Group: !scenario.ds ?  "Weath Management , Retail Markets"   : scenario.customer_segments.map(seg => seg.name).join(", "), // Concatenate all group names
                Status:  scenario.label_condition || "Undefined" , // Example status
                // // Status:  "Undefined" , // Example status
                Definition: scenario.definition,
                Category_id: scenario.ds?.category_id,
                Category_name: categoryDictionary[scenario.ds?.category_id] || "Unknown Category" // Assign category name from dictionary

            };
          })
                       
            //  console.log("fetchConfigurationScenarios",newData);
          return newData;
          }
)









const configurationSlice = createSlice({
  name: 'configuration',
  initialState: {
    // If true, we are in the slided screen
    showEdit: false,

    configActiveTab:'Production',

    activeTab: 'Label', // Possibilities: 'Label', 'Profile', 'Parameters', 'Scenario'

    // Scenarios view
    scenarios: [],
    scenarioDetails: [],

    fetchViewOptions: [],

    isScenarioFilterOpen : false, 
    isDSFilterOpen: false,
    isFrequencyFilterOpen: false,
    isGroupFilterOpen: false,
    isStatusFilterOpen: false,
    isCategoryNameOpen: false,

    ScenarioFilter: [],
    DSFilter: [],
    FrequencyFilter: [],
    GroupFilter: [],
    StatusFilter: [],
    CategoryNameFilter: [],

    shownContainer: 0,
    customerSegments: [], 

    // Data Labels view
    dataLabels: [],
    selectedDataLabelId: -1,
    selectedDataLabelDetails: null,
    dataSources: [],
    infoLists: [],

    selectedProfileData : [], 
    profileSources: [],
    
    // Profiles view
    profiles: [],
    selectedProfileId: -1,
    selectedProfileDetails: null,
    timeBaseOptions: [],

    // Parameters view
    parameters: [],
    selectedParameterId: -1,
    selectedParameterDetails: null,
    selectedParameterColumns: [],

    selectedScenarioId: -1,
    selectedScenarioDetails: null,
    dataSourceFields:[],

    scenarioActiveInfoLists : [],
    scenarioActiveProfiles : [],
    scenarioActiveDataLabels : [],
    scenarioActiveDataSources : [],

    viewOptions: [],
    riskLevelsOptions: [],
    configurations: [],
    selectedConfigurationDetails: {},

    uploadStatus: 'idle', //idle, processing , success , error
    uploadDetails: null,

    dataLabelToLink: null,
    profileToLink: null,
    scenarioToLink: null,




    column_updated : false,


  },
  reducers: {
    setActiveTab(state, action) {

      state.activeTab = action.payload;
    },
    setConfigActiveTab(state, action){
      state.configActiveTab = action.payload;
    },
    setColumnUpdated(state, action){
      state.column_updated = action.payload;
    },
    setSelectedConfigurationDetails(state, action){
      state.selectedConfigurationDetails = action.payload;
    },
    resetUploadDetails(state){
      state.uploadDetails = null;
    },
    setUploadStatus(state, action) {
      state.uploadStatus = action.payload;
    },
    setActiveDataSources(state, action){
      state.scenarioActiveDataSources = action.payload;
    },
   setScenarioActiveInfoLists(state, action){
      state.scenarioActiveInfoLists = action.payload;
    },
    setScenarioActiveProfiles(state, action){
      state.scenarioActiveProfiles = action.payload;
    },
    setScenarioActiveDataLabels(state, action){
      state.scenarioActiveDataLabels = action.payload;
    },
    setProfileSources(state, action){ 
      state.profileSources = action.payload;
    },
    setParametersColumns(state, action){
      state.selectedParameterColumns = action.payload;
    },

setScenarioFilterIsOpen(state, action){ 
    state.isScenarioFilterOpen = action.payload;
},

setShownContainer(state, action){
  state.shownContainer = action.payload;
}, 

emptyScenarioDetails(state){
  state.scenarioDetails = [
    {
      definition: "",
      label_condition: "",
      frequency: "",
      info: "",
      description: "", 
      scenario_lists: [],
      scenario_datafields: [],
      scenario_profiles: [],
      name: "",
    }
  ];
},


setDSFilterIsOpen(state, action){
  state.isDSFilterOpen = action.payload;
},
setFrequencyFilterIsOpen(state, action){
  state.isFrequencyFilterOpen = action.payload;
},
setGroupFilterIsOpen(state, action){
  state.isGroupFilterOpen = action.payload;
},
setStatusFilterIsOpen(state, action){
  state.isStatusFilterOpen = action.payload;
},
setCategoryNameFilterIsOpen(state, action){
  state.isCategoryNameOpen = action.payload;
},


setScenarioFilter(state, action){
  state.ScenarioFilter = action.payload;
},
setDSFilter(state, action){
  state.DSFilter = action.payload;
},
setFrequencyFilter(state, action){
  state.FrequencyFilter = action.payload;
},
setGroupFilter(state, action){
  state.GroupFilter = action.payload;
},
setStatusFilter(state, action){
  state.StatusFilter = action.payload;
},
setCategoryNameFilter(state, action){
  state.CategoryNameFilter = action.payload;
},

    setSelectedDataLabelId(state, action){
      state.selectedDataLabelId = action.payload;
    },
    setSelectedDataLabelsDetails(state, action){
      state.selectedDataLabelDetails = action.payload;
    },
    setShowEdit(state, action){
      state.showEdit = action.payload;
    },
    setSelectedProfileId(state, action){
      state.selectedProfileId = action.payload;
    },
    setSelectedProfileDetails(state, action){
      state.selectedProfileDetails = action.payload;
    },
    setSelectedScenario(state, action){
      state.scenarioDetails = action.payload;
    },
    setSelectedParameterId(state, action){
      state.selectedParameterId = action.payload;
    },
    setSelectedScenarioId(state, action){
      state.selectedScenarioId = action.payload;
    },
    setSelectedScenarioDetails(state, action){
      state.selectedScenarioDetails = action.payload;
    },
    setProfileData(state, action){
      state.selectedProfileData = action.payload;
    },
    createNewDataLabelDetails(state, action){
      state.selectedDataLabelDetails = {
        data_source: {
          id: 99999,
          datafields: [],
          info: "",
          name: "",
          time_field_id: 1,
        },
        data_source_id: 99999,
        definition: "",
        description: "", 
        id: 99999,
        list: [],
        name: "",
      }
    }
 
  },
  extraReducers: (builder) => {
    builder

    .addCase(createParameterListColumn.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(createParameterListColumn.fulfilled, (state, action) => {
      state.status = 'succeeded';
      console.log("createParameterListColumn.fulfilled");
      console.log(action.payload);
    })
    .addCase(createParameterListColumn.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })

    .addCase(createList.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(createList.fulfilled, (state, action) => {
      state.status = 'succeeded';
      console.log("createList.fulfilled");
      console.log(action.payload);
    })
    .addCase(createList.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })

    .addCase(updateParameterListColumn.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(updateParameterListColumn.fulfilled, (state, action) => {
      state.status = 'succeeded';
      console.log("updateParameterListColumn.fulfilled");
      console.log(action.payload);
      state.column_updated = true;
    })
    .addCase(updateParameterListColumn.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })


    .addCase(updateScenario.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(updateScenario.fulfilled, (state, action) => {
      state.status = 'succeeded';
      console.log("updateScenario.fulfilled");
      console.log(action.payload);
    })
    .addCase(updateScenario.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })


    .addCase(updateProfile.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(updateProfile.fulfilled, (state, action) => {
      state.status = 'succeeded';
      console.log("updateProfile.fulfilled");
      console.log(action.payload);
    })
    .addCase(updateProfile.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })

    .addCase(updateDataLabel.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(updateDataLabel.fulfilled, (state, action) => {
      state.status = 'succeeded';
      console.log("updateDataLabel.fulfilled");
      console.log(action.payload);
    })
    .addCase(updateDataLabel.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })


    .addCase(createScenario.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(createScenario.fulfilled, (state, action) => {
      state.status = 'succeeded';
      console.log("createScenario.fulfilled");
      console.log(action.payload);
      state.scenarioToLink = action.payload.id;
    })
    .addCase(createScenario.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })


    .addCase(linkProfile.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(linkProfile.fulfilled, (state, action) => {
      state.status = 'succeeded';
      console.log("linkProfile.fulfilled");
      console.log(action.payload);
      state.profileToLink = null;
    })
    .addCase(linkProfile.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
      state.profileToLink = null;
    })

    .addCase( createProfile.pending, (state) => {
      state.status = 'loading';
    })
    .addCase( createProfile.fulfilled, (state, action) => {
      state.status = 'succeeded';
      console.log("createProfile.fulfilled");
      state.profileToLink = action.payload.id;
      console.log(action.payload);
    })
    .addCase( createProfile.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
      state.profileToLink = null;
    })

    .addCase(linkDataLabel.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(linkDataLabel.fulfilled, (state, action) => {
      state.status = 'succeeded';
      console.log("linkDataLabel.fulfilled");
      state.dataLabelToLink = null;
      console.log(action.payload);
    })
    .addCase(linkDataLabel.rejected, (state, action) => {
      state.status = 'failed';
      state.dataLabelToLink = null;
      state.error = action.error.message;
    })

    .addCase(createDataLabel.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(createDataLabel.fulfilled, (state, action) => {
      state.status = 'succeeded';
      console.log("createDataLabel.fulfilled");
      console.log(action.payload);

      state.dataLabelToLink = action.payload.id;
    })
    .addCase(createDataLabel.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })

    .addCase(uploadFiles.pending, (state) => {
      state.status = 'loading';
      state.uploadStatus = 'processing';
    })
    .addCase(uploadFiles.fulfilled, (state, action) => {
      state.status = 'succeeded';
      console.log("uploadFile.fulfilled");
      console.log(action.payload);

      state.uploadDetails = parseUploadResponse(action.payload);
      state.uploadStatus = 'success';

    })
    .addCase(uploadFiles.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
      state.uploadStatus = 'error';
    })

.addCase(fetchConfigurationDataLabels.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchConfigurationDataLabels.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.dataLabels = action.payload;
    })
    .addCase(fetchConfigurationDataLabels.rejected, (state, action) => {
      state.status = 'failed';
    })
    .addCase(fetchConfigurationProfiles.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchConfigurationProfiles.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.profiles = action.payload;
    })
    .addCase(fetchConfigurationProfiles.rejected, (state, action) => {
      state.status = 'failed';
    })
    .addCase(fetchConfigurationParametersList.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchConfigurationParametersList.fulfilled, (state, action) => {
      state.status = 'succeeded'; 
      // state.parameters = action.payload;

      state.infoLists = action.payload;
      console.log("fetching info lists"); 
     
      let newList = []; 
      action.payload.forEach(element => {
          if(element.type == "Parameter list"){
              newList.push(element); 
          }
      });
      state.parameters = newList;
    })
    .addCase(fetchConfigurationParametersList.rejected, (state, action) => {
      state.status = 'failed';
    })
    .addCase(fetchConfigurationDetails.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchConfigurationDetails.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.selectedConfigurationDetails = action.payload;
    })
    .addCase(fetchConfigurationDetails.rejected, (state, action) => {
      state.status = 'failed';
    })
    .addCase(fetchConfigurationScenarios.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchConfigurationScenarios.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.scenarios = action.payload;
    })
    .addCase(fetchConfigurationScenarios.rejected, (state, action) => {
      state.status = 'failed';
    })


    .addCase(loadConfigurations.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(loadConfigurations.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.configurations = action.payload;
    })
    .addCase(fetchViewOptions.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchViewOptions.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.viewOptions = action.payload;
    })
    .addCase(fetchViewOptions.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })
    .addCase(setScenarioActivation.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(setScenarioActivation.fulfilled, (state, action) => {
      state.status = 'succeeded';
      console.log("setScenarioActivation.fulfilled");
      console.log(action.payload);
    })
    .addCase(setScenarioActivation.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })

    .addCase(fetchCustomerSegments.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchCustomerSegments.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.customerSegments = action.payload;
    })
    .addCase(fetchCustomerSegments.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })

    .addCase(deleteList.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(deleteList.fulfilled, (state, action) => {
      state.status = 'succeeded';
      console.log("deleteList.fulfilled");
      console.log(action.payload);
    })
    .addCase(deleteList.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })


    .addCase(putNewParameterValue.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(putNewParameterValue.fulfilled, (state, action) => {
      state.status = 'succeeded';
      console.log("putNewParameterValue.fulfilled");
      console.log(action.payload);
    })
    .addCase(putNewParameterValue.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })


     .addCase(fetchScenarios.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchScenarios.fulfilled, (state, action) => {
      if (action.payload) {
        state.status = 'succeeded';
        state.scenarios = action.payload;
      }
    })
    .addCase(fetchScenarios.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })

    .addCase(fetchScenarioDetails.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchScenarioDetails.fulfilled, (state, action) => {
      if (action.payload) {
        state.status = 'succeeded';
        state.scenarioDetails = action.payload;
      }
    })
    .addCase(fetchScenarioDetails.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })
      .addCase(fetchDataLabels.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDataLabels.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.dataLabels = action.payload;
      })
      .addCase(fetchDataLabels.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

   

      .addCase(fetchRiskLevelOptions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRiskLevelOptions.fulfilled, (state, action) => {
        state.status = 'succeeded';
        console.log("fetching risk levels", action.payload);
        state.riskLevelsOptions = action.payload;
      })
      .addCase(fetchRiskLevelOptions.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })



      .addCase(fetchDataLabelDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDataLabelDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.selectedDataLabelDetails = action.payload;
      })
      .addCase(fetchDataLabelDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchInfoLists.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDataSources.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDataSources.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.dataSources = action.payload;
      })
      .addCase(fetchDataSources.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchInfoLists.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.infoLists = action.payload;
        console.log("fetching info lists"); 
       
        let newList = []; 
        action.payload.forEach(element => {
            if(element.type == "Parameter list"){
                newList.push(element); 
            }
        });
        state.parameters = newList;
       
      })
      .addCase(fetchInfoLists.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchProfiles.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProfiles.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.profiles = action.payload;
      })
      .addCase(fetchProfiles.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchProfileDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProfileDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.selectedProfileDetails = action.payload;
      })
      .addCase(fetchProfileDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchTimeBaseOptions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTimeBaseOptions.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.timeBaseOptions = action.payload;
      })
      .addCase(fetchTimeBaseOptions.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchParameterDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchParameterDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
    
        console.log("fetching parameter details");
        console.log(action.payload);
    
        // Step 1: Initialize column metadata
        const columnMetadata = action.payload.list_columns.reduce((acc, column) => {

          console.log("column meta make");
          console.log(column);
         
            acc[column.name] = {
                column_id: column.id,
                data_type: column.data_type,
                column_name: column.name
            };
            return acc;
        }, {});
    
        // Step 2: Build rows
          // Helper function to initialize a row with metadata and null values
          function initializeRow(columnMetadata, rowId) {
            return Object.keys(columnMetadata).reduce((rowAcc, colName) => {
                rowAcc[colName] = {
                    value: null,
                    row_id: rowId,
                    column_id: null, // Initialize with null, will be updated if there's an actual value
                    data_type: columnMetadata[colName].data_type,
                    column_name: columnMetadata[colName].column_name
                };
                return rowAcc;
            }, {});
          }

          // Helper function to update a row with actual values
          function updateRowWithValue(row, column, value) {
            row[column.name] = {
                value: value.value,
                row_id: value.row_id,
                column_id: value.column_id,
                data_type: column.data_type,
                column_name: column.name
            };
          }

          const rows = action.payload.list_columns.reduce((acc, column) => {
            if (column.list_values) {
                column.list_values.forEach(value => {
                    // Initialize the row if it doesn't exist
                    if (!acc[value.row_id]) {
                        acc[value.row_id] = initializeRow(columnMetadata, value.row_id);
                    }
                    
                    // Update the row with the actual value
                    updateRowWithValue(acc[value.row_id], column, value);
                });
            }
            return acc;
          }, {});
      
        console.log("rows with all columns");
        console.log(rows);
    
        state.selectedParameterDetails = rows;
    })
    
      .addCase(fetchParameterDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

  },
});

export const {
  
  setSelectedConfigurationDetails,

  setConfigActiveTab,

  resetUploadDetails,
  setUploadStatus,
  setShownContainer,
  setColumnUpdated, 

  setScenarioFilter,
  setDSFilter,
  setFrequencyFilter,
  setGroupFilter,
  setStatusFilter,
  setCategoryNameFilter,
  setProfileData,
  setProfileSources, 
  emptyScenarioDetails,

  setActiveDataSources, 
  setScenarioActiveInfoLists,
  setScenarioActiveProfiles,
  setScenarioActiveDataLabels,

  setScenarioFilterIsOpen,
  setDSFilterIsOpen,
  setFrequencyFilterIsOpen,
  setGroupFilterIsOpen,
  setStatusFilterIsOpen,
  setCategoryNameFilterIsOpen,

  setParametersColumns, 
  setSelectedDataLabelId,
  setSelectedDataLabelsDetails,
  setShowEdit,
  setSelectedProfileId,
  setSelectedProfileDetails,
  setSelectedParameterId,

  setSelectedScenarioId,
  setSelectedScenarioDetails,

  setSelectedScenario,
  createNewDataLabelDetails,
  setActiveTab,

} = configurationSlice.actions;

export default configurationSlice.reducer;
