import React, { useEffect, useState, useRef } from "react";
import view_style from "../../../layout/Layout.module.css";
import HeaderSection from "../../../layout/HeaderSection";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOverlapAnalysisScenariosMatrix,
  setScenarioIds,
  setSelectedYearMonthPairs,
} from "../../../features/overlap_analysis/overlapAnalysisSlice";
import Heatmap from "../matrix_overlap/Heatmap";
import { Scrollbar } from "react-scrollbars-custom";
import styles from "./MatrixScenarios.module.css";
import SearchSelector from "../matrix_overlap/selectors/SearchSelector";


import { useParams, useNavigate } from "react-router-dom";

  

const MatrixScenarios = () => {

    const navigate = useNavigate();
    
    const{id1, id2} = useParams();

    const dispatch = useDispatch();

    const [allScenarios, setAllScenarios] = useState([]);



  const selectedYearMonthPairs = useSelector((state) => state.overlapAnalysis.selectedYearMonthPairs);
  const [selectedYearMonthPairsState, setSelectedYearMonthPairsState] = useState(selectedYearMonthPairs || []);



  useEffect(() => {
    console.log('selectedYearMonthPairs222', selectedYearMonthPairs);
  }, [selectedYearMonthPairs])
  
   
    // represent the ds's of which we see the overlaped scenarios
    const {ds_id_1, ds_id_2, ds_name_1, ds_name_2} = useSelector((state) => state.overlapAnalysis);
     // fetch the data with the api
    useEffect(() => {
        dispatch(fetchOverlapAnalysisScenariosMatrix({ ds_id_1: ds_id_1, ds_id_2: ds_id_2, year_month_pairs: selectedYearMonthPairs }));
    }, [])

    useEffect(() => {
        dispatch(fetchOverlapAnalysisScenariosMatrix({ 
          ds_id_1: id1, 
          ds_id_2: id2, 
          year_month_pairs: selectedYearMonthPairs 
        }));
      }, [id1, id2, selectedYearMonthPairs, dispatch]);
      

    // the matrix data
    const scenariosOverlapData = useSelector((state) => state.overlapAnalysis.overlapScenariosData)
    const containerDiv = React.createRef();

    // used for custom scrollbar
    const [scrollHeight, setScrollHeight] = useState(0);
    
    const [scrollWidth, setScrollWidth] = useState(0);

    // get a list of the keys with the given data
    const extractKeysFromData = (data) => {
        var keys = [];
        data.map((point) => {
            keys.push(point.name)
        })
        console.log("put keys", keys)
        return keys;

    }
  // }, [scenariosOverlapData]);

  // set the height correctly
  // useEffect(() => {
  //   if (scenariosOverlapDataFiltered.length > 0) {
  //     console.log("scenariosOverlapDataFiltered", scenariosOverlapDataFiltered);
  //     setKeys(extractKeysFromData(scenariosOverlapDataFiltered));
  //     var totalHeight = 0;
  //     totalHeight += 7 + 70 * scenariosOverlapDataFiltered.length;
  //     setScrollHeight(containerDiv.current.clientHeight);
  //     // setScrollWidth(containerDiv.current.width);


    // list of all the scenarios
    const [keys, setKeys] = useState([])

    const [scenariosOverlapDataFiltered, setScenariosOverlapDataFiltered] = useState(scenariosOverlapData)

    useEffect(() => {
        if (scenariosOverlapData.length > 0){
          setAllScenarios(extractKeysFromData(scenariosOverlapData));
          setScenariosOverlapDataFiltered(scenariosOverlapData);
        }
      }, [scenariosOverlapData]);
      

    // set the height correctly
    useEffect(() => {
        if (scenariosOverlapDataFiltered.length > 0){
            console.log('scenariosOverlapDataFiltered', scenariosOverlapDataFiltered)
            setKeys(extractKeysFromData(scenariosOverlapDataFiltered))
            var totalHeight = 0;
            totalHeight += 7 + 70 * scenariosOverlapDataFiltered.length
            setScrollHeight(containerDiv.current.clientHeight);
            // setScrollWidth(containerDiv.current.width);

            console.log('yolo' ,containerDiv.current.clientWidth);
        }
        console.log('scenariosOverlapDataFiltered keys', keys);
    }, [scenariosOverlapDataFiltered])



  //   console.log("scenariosOverlapDataFiltered keys", keys);
  // }, [scenariosOverlapDataFiltered]);

  const scenarioIds = useSelector((state) => state.overlapAnalysis.scenarioIds);
  // handle a click in the heatmap
  const handleHeatmapClick = async (params) => {
    console.log("params here", params);
    // NEED row_id, col_id and year_month_pairs
    console.log("row_id", params.data.row_id);
    console.log("col_id", params.data.col_id);
     dispatch(setScenarioIds([params.data.row_id, params.data.col_id]));
    console.log("bruh", scenarioIds);
    console.log("yearmonthpairs", selectedYearMonthPairs);
    // dispatch();

    console.log("params", params);
    if (params.value === 0) return;
    navigate(`/alerts-overlap/${params.data.row_id}/${params.data.col_id}`);
  };

  // lists of selected options of the filters
  const [selectedScenario, setSelectedScenario] = useState([]);
  // const [selectedYearMonth, setSelectedYearMonth] = useState([]);

  useEffect(() => {
    if (selectedScenario.length !== 0) {
      setScenariosOverlapDataFiltered(
        scenariosOverlapData.filter((data) =>
          selectedScenario.includes(data.name)
        )
      );
    } else {
      setScenariosOverlapDataFiltered(scenariosOverlapData);
    }
  }, [scenariosOverlapData, selectedScenario]);

  // handle applying a filter
  const handleApplyFunction = () => {
    dispatch(setSelectedYearMonthPairs(selectedYearMonthPairsState));
    dispatch(
      fetchOverlapAnalysisScenariosMatrix({
        ds_id_1: ds_id_1,
        ds_id_2: ds_id_2,
        year_month_pairs: selectedYearMonthPairsState,
      })
    );
  };


  // generate all year month pairs with start and end given
  const generateYearMonthPairs = (startYear, startMonth, endYear, endMonth) => {
    const yearMonthPairs = [];
    let year = startYear;
    let month = startMonth;

    while (year < endYear || (year === endYear && month <= endMonth)) {
      // Add the year-month pair to the array, ensuring two-digit month format
      const formattedMonth = month < 10 ? `0${month}` : `${month}`;
      yearMonthPairs.push(`${year}-${formattedMonth}`);

      // Increment the month, adjust year if necessary
      month++;
      if (month > 12) {
        month = 1;
        year++;
      }
    }

    return yearMonthPairs;
  };

  return (
    <div className={view_style.viewWrapper}>
      <div className={view_style.sectionWrapper}>
        <HeaderSection
          SectionName="Matrix Scenarios Analysis"
          AdditionalText=""
        />

        {scenariosOverlapDataFiltered.length > 0 && (
          <div>
            <div className={styles.gridContainer}>
              <SearchSelector
                key={1}
                all_options={allScenarios}
                title={"Scenario"}
                applyFunction={handleApplyFunction}
                selected_options={selectedScenario}
                setSelectedOptions={setSelectedScenario}
                localFilter={true}
              />

              <SearchSelector
                key={2}
                all_options={generateYearMonthPairs(2022, 1, 2024, 8)}
                title={"Year-Month"}
                applyFunction={handleApplyFunction}
                selected_options={selectedYearMonthPairsState}
                setSelectedOptions={setSelectedYearMonthPairsState}
                localFilter={true}
              />
            </div>

            <Scrollbar
              style={{
                height: scrollHeight,
                transition: "height 0.4s ease",
                overflowY: "hidden",
              }}
              trackXProps={{
                style: {
                  bottom: "0px",
                  left: "10px",
                  height: "5px",
                  backgroundColor: "#E9D7FE",
                },
              }}
              thumbXProps={{ style: { backgroundColor: "#D6BBFB" } }}
              trackYProps={{ style: { width: "0px" } }}
            >
              <div
                ref={containerDiv}
              >
                <div className={styles.headerBox}>
                  <div className={`${styles.headerText} ${styles.dsNamesBox}`}>
                    <div>{ds_name_1}</div>
                    <div>{ds_name_2}</div>
                  </div>
                  {keys.map((key, index) => {
                    return (
                      <div
                        className={`${styles.columnHeader} ${styles.headerText}`}
                        key={`${key}-${index}`}
                      >
                        <span title={key} style={{ textWrap:"nowrap", textOverflow:"ellipsis", width:"100%" , overflow:"hidden"}}>{key}</span>
                        
                      </div>
                    );
                  })}
                </div>


                {/* <Scrollbar
                        style={{ height: scrollHeight, transition: 'height 0.4s ease', overflow: 'visible'}} 
                        trackXProps={{ style: { bottom: '0px', left: '10px', height: '5px' , backgroundColor: '#E9D7FE' } }} 
                        thumbXProps={{ style: { backgroundColor: '#D6BBFB' }}}
                        trackYProps={{ style: { width: '0px' }}}
                        wrapperProps={{
                        renderer: props => {
                            const { elementRef, ...restProps } = props;
                            return (
                            <span
                                {...restProps}
                                ref={elementRef}
                                style={{minHeight: {scrollHeight} , marginBottom: '-5px', position: 'absolute', inset: '0px 0px 5px 0px', overflow: 'visible' }}
                            />
                            );
                        }
                        }}
                        scrollerProps={{
                            renderer: props => {
                                const { elementRef, ...restProps } = props;
                                return (
                                    <span
                                        {...restProps}
                                        ref={elementRef}
                                        style={{ overflow: 'visible'}}
                                    />
                                )
                            }
                        }}
                        > */}

                {/*TODO: change to use the toggle when it is made  */}
                <Heatmap
                  data={scenariosOverlapDataFiltered}
                  keys={keys}
                  isPercentage={false}
                  handleClick={(params) => handleHeatmapClick(params)}
                />
              </div>
            </Scrollbar>
          </div>
        )}
      </div>
    </div>
  );
};


export default MatrixScenarios;
