import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../axiosConfig";

const initialState = {
  updateStatus: "idle",
  addToReportCount: 0,
  currentReportId: null,
  currentReport: null,
  selectedReport: null,
  reportElements: [],
  groupedAlerts: null,
  alertStatistics: null,
  reports: [],
  loading: false,
  error: null,
};
export const fetchRiskScores = createAsyncThunk(
  "atlTool/fetchRiskScores",
  async ({
    selectedScenarioId,
    selectedSegmentId,
    selectedSubScenario,
    selectedYearState,
  }) => {
    const subScenarioId = selectedSubScenario?.id || 0;
    const response = await apiClient.get(
      `/scenarios/${selectedScenarioId}/${subScenarioId}/segments/${selectedSegmentId}/risk_scores?&year=${selectedYearState}`
    );

    return response.data;
  }
);

export const fetchRiskScoresForReport = createAsyncThunk(
  "reportBuilder/fetchRiskScoresForReport",
  async (
    {
      title,
      selectedScenarioId,
      selectedSegmentId,
      selectedSubScenario,
      selectedYearState,
    },
    { dispatch, getState }
  ) => {
    try {
      // Llamada al endpoint para obtener los puntajes de riesgo
      const result = await dispatch(
        fetchRiskScores({
          selectedScenarioId,
          selectedSegmentId,
          selectedSubScenario,
          selectedYearState,
        })
      );

      if (fetchRiskScores.fulfilled.match(result)) {
        const riskScoresData = result.payload.risk_scores;

        // Crear un solo `piechart` que contenga todos los `risk_scores`
        // const piechart = {
        //   type: "piechart",
        //   title,
        //   apiEndpoint: `/scenarios/${selectedScenarioId}/${selectedSubScenario?.id ?? 0}/segments/${selectedSegmentId}/risk_scores?year=${selectedYearState}`,
        //   content: {
        //     labels: riskScoresData.map((riskScore) => riskScore.name),
        //     values: riskScoresData.map((riskScore) => ({
        //       non_relevant: riskScore.non_relevant_alerts,
        //       relevant: riskScore.relevant_alerts,
        //       sar: riskScore.sar_alerts
        //     })),
        //   }
        // };

        const piechart = {
          type: "piechart",
          title,
          apiEndpoint: `/scenarios/${selectedScenarioId}/${
            selectedSubScenario?.id ?? 0
          }/segments/${selectedSegmentId}/risk_scores?year=${selectedYearState}`,
          content: {
            risk_scores: riskScoresData,
          },
        };
        // Obtener el `currentReport` desde el estado de Redux
        const state = getState();
        const currentReport = state.reportBuilder.currentReport;
        const scenarioIds = currentReport.report_scenarios.map(
          (scenario) => scenario.scenario_id
        );

        // Actualizar `report_json` con el nuevo `piechart`
        const updatedReport = {
          status: currentReport.status,
          user_id: currentReport.user_id,
          name: currentReport.name,
          updated_at: new Date().toISOString(),
          analysis_daterange_id: currentReport.analysis_daterange_id,
          report_json: {
            ...currentReport.report_json,
            elements: [
              ...(currentReport.report_json?.elements || []),
              piechart,
            ],
          },
        };

        // Enviar el `updatedReport` al servidor
        const updateResult = await dispatch(
          updateReport({
            reportId: currentReport.id,
            scenarioIds,
            reportData: updatedReport,
          })
        );

        // Verificar si la actualización fue exitosa
        if (updateReport.fulfilled.match(updateResult)) {
          return piechart;
        } else {
          throw new Error("Update Report Failed");
        }
      } else {
        throw new Error("Error fetching risk scores");
      }
    } catch (error) {
      console.error("Error in fetchRiskScoresForReport:", error);
      throw error;
    }
  }
);

export const addImageToReport = createAsyncThunk(
  "reportBuilder/addImageToReport",
  async ({ title, imageUrl }, { dispatch, getState }) => {
    try {
      // Build the image element
      const imageElement = {
        type: "image",
        title: title,
        content: {
          url: imageUrl,
        },
      };

      // Get the current report from state
      const state = getState();
      const currentReport = state.reportBuilder.currentReport;

      // Extract scenario_ids from report_scenarios
      const scenarioIds = currentReport.report_scenarios.map(
        (scenario) => scenario.scenario_id
      );

      // Update the report_json with the new image element
      const updatedReport = {
        status: currentReport.status,
        user_id: currentReport.user_id,
        name: currentReport.name,
        updated_at: new Date().toISOString(),
        analysis_daterange_id: currentReport.analysis_daterange_id,
        report_json: {
          ...currentReport.report_json,
          elements: [
            ...(currentReport.report_json?.elements || []),
            imageElement,
          ],
        },
      };

      // Dispatch updateReport to save the changes on the server
      const updateResult = await dispatch(
        updateReport({
          reportId: currentReport.id,
          scenarioIds,
          reportData: updatedReport,
        })
      );

      // Check if update was successful
      if (updateReport.fulfilled.match(updateResult)) {
        return imageElement;
      } else {
        throw new Error("Update Report Failed");
      }
    } catch (error) {
      console.error("Error in addImageToReport:", error);
      throw error;
    }
  }
);
export const fetchTableForReport = createAsyncThunk(
  "reportBuilder/fetchTableForReport",
  async (
    { title, selectedScenarioId, selectedSubScenario, selectedYearState },
    { dispatch, getState }
  ) => {
    try {
      // Fetch data to build the tables
      const result = await dispatch(
        fetchAlertStatistics({
          selectedScenarioId,
          selectedSubScenario,
          selectedYearState,
        })
      );

      if (fetchAlertStatistics.fulfilled.match(result)) {
        const data = result.payload;
        const groupedAlerts = data.grouped_alerts;

        const createTable = (type, groupedAlerts) => {
          const baseHeaders = [
            "Customer Segment",
            "Risk Score",
            "Non Relevant",
            "Relevant",
            "SAR",
            "Total",
            "Noise",
            "Efficiency",
            "SAR%",
          ];

          // Collect dynamic headers
          let dynamicHeadersSet = new Set();
          Object.values(groupedAlerts).forEach((segmentData) => {
            const segmentType = segmentData[type];
            Object.values(segmentType).forEach((values) => {
              values.thresholds.forEach((threshold) => {
                const thresholdName = threshold.threshold_name.split(".").pop();
                dynamicHeadersSet.add(thresholdName);
              });
            });
          });

          const dynamicHeaders = Array.from(dynamicHeadersSet);
          const headers = [
            ...baseHeaders.slice(0, 2),
            ...dynamicHeaders,
            ...baseHeaders.slice(2),
          ];

          // Initialize totals
          const rows = [];
          let totalNonRelevant = 0;
          let totalRelevant = 0;
          let totalSAR = 0;
          let totalAlerts = 0;

          const formatPercentage = (value) => {
            const number = parseFloat(value);
            return isNaN(number) ? "0.0%" : `${number.toFixed(1)}%`;
          };

          Object.entries(groupedAlerts).forEach(([segment, segmentData]) => {
            const segmentType = segmentData[type];
            Object.entries(segmentType).forEach(([riskScore, values]) => {
              const thresholdValues = dynamicHeaders.reduce((acc, header) => {
                const threshold = values.thresholds.find(
                  (th) => th.threshold_name.split(".").pop() === header
                );
                acc[header] = threshold ? threshold.threshold_value : "";
                return acc;
              }, {});

              const nonRelevant = values.alert_counts["Non Relevant"] ?? 0;
              const relevant = values.alert_counts["Relevant"] ?? 0;
              const sar = values.alert_counts["SAR"] ?? 0;
              const total = values.total_alerts;

              totalNonRelevant += nonRelevant;
              totalRelevant += relevant;
              totalSAR += sar;
              totalAlerts += total;

              const row = [
                segment.charAt(0).toUpperCase() + segment.slice(1),
                riskScore.toUpperCase(),
                ...dynamicHeaders.map((header) => thresholdValues[header]),
                nonRelevant,
                relevant,
                sar,
                total,
                formatPercentage(values.noise),
                formatPercentage(values.efficiency),
                formatPercentage(values.sar_percentage),
              ];
              rows.push(row);
            });
          });

          const totalNoise =
            totalAlerts > 0 ? (totalNonRelevant / totalAlerts) * 100 : 0.0;
          const totalEfficiency =
            totalAlerts > 0 ? (totalRelevant / totalAlerts) * 100 : 0.0;
          const totalSARPercentage =
            totalAlerts > 0 ? (totalSAR / totalAlerts) * 100 : 0.0;

          const totalRow = Array(headers.length).fill("");
          totalRow[0] = "Total";
          const nonRelevantIndex = 2 + dynamicHeaders.length;
          const relevantIndex = nonRelevantIndex + 1;
          const sarIndex = relevantIndex + 1;
          const totalIndex = sarIndex + 1;
          const noiseIndex = totalIndex + 1;
          const efficiencyIndex = noiseIndex + 1;
          const sarPercentageIndex = efficiencyIndex + 1;

          totalRow[nonRelevantIndex] = totalNonRelevant;
          totalRow[relevantIndex] = totalRelevant;
          totalRow[sarIndex] = totalSAR;
          totalRow[totalIndex] = totalAlerts;
          totalRow[noiseIndex] = formatPercentage(totalNoise);
          totalRow[efficiencyIndex] = formatPercentage(totalEfficiency);
          totalRow[sarPercentageIndex] = formatPercentage(totalSARPercentage);

          rows.push(totalRow);

          return {
            type: "table",
            title: `${title} (${type})`,
            apiEndpoint: `/scenarios/alert-statistics/${selectedScenarioId}/${
              selectedSubScenario?.id ?? 0
            }?year=${selectedYearState}`,
            content: {
              headers,
              rows,
            },
          };
        };

        // Create tables for PRI and PRO
        const priTable = Object.values(groupedAlerts).some((segment) =>
          Object.keys(segment.PRI || {}).length
        )
          ? createTable("PRI", groupedAlerts)
          : null;

        const proTable = Object.values(groupedAlerts).some((segment) =>
          Object.keys(segment.PRO || {}).length
        )
          ? createTable("PRO", groupedAlerts)
          : null;

        // Get the current report from the state
        const state = getState();
        const currentReport = state.reportBuilder.currentReport;

        const scenarioIds = currentReport.report_scenarios.map(
          (scenario) => scenario.scenario_id
        );

        // Update report_json with the new tables in elements
        const updatedReport = {
          ...currentReport,
          updated_at: new Date().toISOString(),
          report_json: {
            ...currentReport.report_json,
            elements: [
              ...(currentReport.report_json?.elements || []),
              ...(priTable ? [priTable] : []),
              ...(proTable ? [proTable] : []),
            ],
          },
        };

        const updateResult = await dispatch(
          updateReport({
            reportId: currentReport.id,
            scenarioIds,
            reportData: updatedReport,
          })
        );

        if (updateReport.fulfilled.match(updateResult)) {
          return { priTable, proTable };
        } else {
          throw new Error("Update Report Failed");
        }
      } else {
        throw new Error("Error fetching alert statistics");
      }
    } catch (error) {
      console.error("Error in fetchTableForReport:", error);
      throw error;
    }
  }
);

// export const fetchTableForReport = createAsyncThunk(
//   "reportBuilder/fetchTableForReport",
//   async (
//     { title, selectedScenarioId, selectedSubScenario, selectedYearState },
//     { dispatch, getState }
//   ) => {
//     try {
//       // Fetch data to build the table
//       const result = await dispatch(
//         fetchAlertStatistics({
//           selectedScenarioId,
//           selectedSubScenario,
//           selectedYearState,
//         })
//       );

//       if (fetchAlertStatistics.fulfilled.match(result)) {
//         const data = result.payload;
//         const groupedAlerts = data.grouped_alerts;

//         // Fixed headers for the table
//         const baseHeaders = [
//           "Customer Segment",
//           "Risk Score",
//           "Non Relevant",
//           "Relevant",
//           "SAR",
//           "Total",
//           "Noise",
//           "Efficiency",
//           "SAR%",
//         ];

//         // Dynamic headers from threshold names
//         let dynamicHeadersSet = new Set();
//         Object.values(groupedAlerts).forEach((riskData) => {
//           Object.values(riskData).forEach((values) => {
//             values.thresholds.forEach((threshold) => {
//               // Extract substring after the dot
//               const thresholdName = threshold.threshold_name.split(".").pop();
//               dynamicHeadersSet.add(thresholdName);
//             });
//           });
//         });

//         const dynamicHeaders = Array.from(dynamicHeadersSet);
//         const headers = [
//           ...baseHeaders.slice(0, 2),
//           ...dynamicHeaders,
//           ...baseHeaders.slice(2),
//         ];

//         // Initialize totals
//         const rows = [];
//         let totalNonRelevant = 0;
//         let totalRelevant = 0;
//         let totalSAR = 0;
//         let totalAlerts = 0;

//         // Helper function to format percentages
//         function formatPercentage(value) {
//           const number = parseFloat(value);
//           if (isNaN(number)) return "0.0%";
//           return `${number.toFixed(1)}%`;
//         }

//         // Building the table rows
//         Object.entries(groupedAlerts).forEach(([segment, riskData]) => {
//           Object.entries(riskData).forEach(([riskScore, values]) => {
//             const thresholdValues = dynamicHeaders.reduce((acc, header) => {
//               const threshold = values.thresholds.find(
//                 (th) => th.threshold_name.split(".").pop() === header
//               );
//               acc[header] = threshold ? threshold.threshold_value : "";
//               return acc;
//             }, {});

//             const nonRelevant = values.alert_counts["Non Relevant"] ?? 0;
//             const relevant = values.alert_counts["Relevant"] ?? 0;
//             const sar = values.alert_counts["SAR"] ?? 0;
//             const total = values.total_alerts;

//             // Accumulate totals
//             totalNonRelevant += nonRelevant;
//             totalRelevant += relevant;
//             totalSAR += sar;
//             totalAlerts += total;

//             const row = [
//               segment.charAt(0).toUpperCase() + segment.slice(1),
//               riskScore.toUpperCase(),
//               ...dynamicHeaders.map((header) => thresholdValues[header]),
//               nonRelevant,
//               relevant,
//               sar,
//               total,
//               formatPercentage(values.noise),
//               formatPercentage(values.efficiency),
//               formatPercentage(values.sar_percentage),
//             ];
//             rows.push(row);
//           });
//         });

//         // Compute overall percentages
//         const totalNoise =
//           totalAlerts > 0 ? (totalNonRelevant / totalAlerts) * 100 : 0.0;
//         const totalEfficiency =
//           totalAlerts > 0 ? (totalRelevant / totalAlerts) * 100 : 0.0;
//         const totalSARPercentage =
//           totalAlerts > 0 ? (totalSAR / totalAlerts) * 100 : 0.0;

//         // Build the total row
//         const totalRow = Array(headers.length).fill("");

//         totalRow[0] = "Total";
//         // Indices for the counts
//         const nonRelevantIndex = 2 + dynamicHeaders.length;
//         const relevantIndex = nonRelevantIndex + 1;
//         const sarIndex = relevantIndex + 1;
//         const totalIndex = sarIndex + 1;
//         const noiseIndex = totalIndex + 1;
//         const efficiencyIndex = noiseIndex + 1;
//         const sarPercentageIndex = efficiencyIndex + 1;

//         totalRow[nonRelevantIndex] = totalNonRelevant;
//         totalRow[relevantIndex] = totalRelevant;
//         totalRow[sarIndex] = totalSAR;
//         totalRow[totalIndex] = totalAlerts;
//         totalRow[noiseIndex] = formatPercentage(totalNoise);
//         totalRow[efficiencyIndex] = formatPercentage(totalEfficiency);
//         totalRow[sarPercentageIndex] = formatPercentage(totalSARPercentage);

//         // Append the total row to the rows
//         rows.push(totalRow);

//         // Build the table object
//         const table = {
//           type: "table",
//           title,
//           apiEndpoint: `/scenarios/alert-statistics/${selectedScenarioId}/${
//             selectedSubScenario?.id ?? 0
//           }?year=${selectedYearState}`,
//           content: {
//             headers,
//             rows,
//           },
//         };

//         // Get the current report from the state
//         const state = getState();
//         const currentReport = state.reportBuilder.currentReport;

//         // Extract scenario_ids from report_scenarios
//         const scenarioIds = currentReport.report_scenarios.map(
//           (scenario) => scenario.scenario_id
//         );

//         // Update the report_json with the new table in elements
//         const updatedReport = {
//           status: currentReport.status,
//           user_id: currentReport.user_id,
//           name: currentReport.name,
//           updated_at: new Date().toISOString(),
//           analysis_daterange_id: currentReport.analysis_daterange_id,
//           report_json: {
//             ...currentReport.report_json,
//             elements: [...(currentReport.report_json?.elements || []), table],
//           },
//         };

//         // Dispatch updateReport to save the changes on the server with scenario_ids in the URL
//         const updateResult = await dispatch(
//           updateReport({
//             reportId: currentReport.id,
//             scenarioIds,
//             reportData: updatedReport,
//           })
//         );

//         // Check if update was successful
//         if (updateReport.fulfilled.match(updateResult)) {
//           return table;
//         } else {
//           throw new Error("Update Report Failed");
//         }
//       } else {
//         throw new Error("Error fetching alert statistics");
//       }
//     } catch (error) {
//       console.error("Error in fetchTableForReport:", error);
//       throw error;
//     }
//   }
// );

export const fetchOptimisationTableForReport = createAsyncThunk(
  "reportBuilder/fetchOptimisationTableForReport",
  async (
    {
      title,
      selectedScenarioId,
      selectedSubScenario,
      selectedSegmentId,
      selectedSegmentName, // Agregamos el nombre del segmento
      selectedRiskScore,
      selectedYearState,
    },
    { dispatch, getState }
  ) => {
    try {
      // Construye el endpoint de la API
      const subScenarioId = selectedSubScenario?.id ?? 0;
      const apiEndpoint = `/scenarios/${selectedScenarioId}/${subScenarioId}/segments/${selectedSegmentId}/risk-scores/${selectedRiskScore.toLowerCase()}/optimisation-table?year=${selectedYearState}`;

      // Realiza la llamada a la API
      const response = await apiClient.get(apiEndpoint);
      const data = response.data;

      // Verifica que los datos sean válidos
      if (data && data.length > 0) {
        // Preparar los encabezados de la tabla
        // Asumimos que los thresholds son los mismos para todas las filas
        // Podemos extraer los thresholds de la primera fila
        const firstRowData = Object.values(data[0])[0];
        const thresholds = firstRowData.thresholds;

        // Construye los encabezados de los thresholds
        const thresholdHeaders = thresholds.map((th) => {
          const thresholdNameParts = th.threshold_name.split(".");
          return thresholdNameParts[thresholdNameParts.length - 1].trim();
        });

        // Encabezados base
        const baseHeaders = [
          "Customer Segment",
          "Optimisation Type",
          ...thresholdHeaders,
          "Relevant",
          "Non Relevant",
          "SAR",
          "Alert Ongoing",
          "Efficiency",
          // 'Year',
          "No SAR Loss",
        ];

        // Inicializa el array de filas
        const rows = [];

        // Itera sobre los datos para construir las filas
        for (const item of data) {
          // Cada item es un objeto con una única clave y valor
          const [optimisationType, rowData] = Object.entries(item)[0];

          const thresholds = rowData.thresholds;
          const thresholdValues = thresholds.map((th) => th.threshold_value);

          const relevancies = rowData.relevancies;

          const row = [
            selectedSegmentName, // Customer Segment
            optimisationType, // Optimisation Type
            ...thresholdValues,
            relevancies["Relevant"] ?? 0,
            relevancies["Non Relevant"] ?? 0,
            relevancies["SAR"] ?? 0,
            relevancies["Alert Ongoing"] ?? 0,
            rowData.efficiency !== "N/A"
              ? parseFloat(rowData.efficiency).toFixed(2)
              : "N/A",
            // rowData.year,
            rowData.no_sar_loss,
          ];

          rows.push(row);
        }

        // Construye el objeto de la tabla
        const table = {
          type: "table",
          title,
          apiEndpoint,
          content: {
            headers: baseHeaders,
            rows,
          },
        };

        // Obtén el reporte actual del estado
        const state = getState();
        const currentReport = state.reportBuilder.currentReport;

        // Extrae scenario_ids de report_scenarios
        const scenarioIds = currentReport.report_scenarios.map(
          (scenario) => scenario.scenario_id
        );

        // Actualiza report_json con la nueva tabla en elements
        const updatedReport = {
          status: currentReport.status,
          user_id: currentReport.user_id,
          name: currentReport.name,
          updated_at: new Date().toISOString(),
          analysis_daterange_id: currentReport.analysis_daterange_id,
          report_json: {
            ...currentReport.report_json,
            elements: [...(currentReport.report_json?.elements || []), table],
          },
        };

        // Despacha updateReport para guardar los cambios en el servidor con scenario_ids en la URL
        const updateResult = await dispatch(
          updateReport({
            reportId: currentReport.id,
            scenarioIds,
            reportData: updatedReport,
          })
        );

        // Verifica si la actualización fue exitosa
        if (updateReport.fulfilled.match(updateResult)) {
          return table;
        } else {
          throw new Error("Update Report Failed");
        }
      } else {
        throw new Error("Invalid data format from API");
      }
    } catch (error) {
      console.error("Error in fetchOptimisationTableForReport:", error);
      throw error;
    }
  }
);

// Fetch grouped alerts by scenario and subscenario
export const fetchGroupedAlerts = createAsyncThunk(
  "reportBuilder/fetchGroupedAlerts",
  async ({ selectedScenarioId, selectedSubScenario }) => {
    const subScenarioId = selectedSubScenario?.id ?? 0;
    const response = await apiClient.get(
      `grouped-alerts/scenarios/${selectedScenarioId}/${subScenarioId}`
    );
    return response.data;
  }
);

// Fetch alert statistics by scenario and subscenario
export const fetchAlertStatistics = createAsyncThunk(
  "reportBuilder/fetchAlertStatistics",
  async ({ selectedScenarioId, selectedSubScenario, selectedYearState }) => {
    const subScenarioId = selectedSubScenario?.id ?? 0;
    const response = await apiClient.get(
      `scenarios/alert-statistics/${selectedScenarioId}/${subScenarioId}?year=${selectedYearState}`
    );
    return response.data;
  }
);

// Fetch all reports
export const fetchReports = createAsyncThunk(
  "reportBuilder/fetchReports",
  async () => {
    const limit = "inf";
    const response = await apiClient.get(`reports`, {
      params: { limit },
    });
    return response.data;
  }
);

export const deleteReports = createAsyncThunk(
  "reportBuilder/deleteReports",
  async (reportIds) => {
    const response = await apiClient.post(`reports/delete-batch`, {
      batch_ids: reportIds,
    });
    return response.data;
  }
);

// Fetch reports with filters
export const fetchReportsWithFilters = createAsyncThunk(
  "reportBuilder/fetchReportsWithFilters",
  async (filters) => {
    const response = await apiClient.get("/reports/filters", {
      params: filters,
    });
    return response.data;
  }
);

export const fetchCurrentReports = createAsyncThunk(
  "reportBuilder/fetchCurrentReports",
  async (user_id) => {
    const response = await apiClient.get(`/current-reports/user/${user_id}`);
    console.log("current report", response.data);
    return response.data;
  }
);

// Create a new report
export const createReport = createAsyncThunk(
  "reportBuilder/createReport",
  async ({ reportData, scenarioIds }, { rejectWithValue }) => {
    try {
      // Construye la cadena de parámetros para scenario_ids
      const params = new URLSearchParams();
      if (scenarioIds && scenarioIds.length > 0) {
        scenarioIds.forEach((id) => params.append("scenario_ids", id));
      } else {
        // Si no se proporcionan scenario_ids, puedes manejarlo según tus necesidades
        params.append("scenario_ids", "0");
      }

      const response = await apiClient.post(
        `reports?${params.toString()}`,
        reportData
      );
      return response.data;
    } catch (error) {
      console.error("Error creating report:", error);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Fetch a report by ID
export const fetchReportById = createAsyncThunk(
  "reportBuilder/fetchReportById",
  async (reportId) => {
    const response = await apiClient.get(`reports/${reportId}`);
    return response.data;
  }
);

// Update a report by ID
export const updateReport = createAsyncThunk(
  "reportBuilder/updateReport",
  async ({ reportId, reportData, scenarioIds }) => {
    const response = await apiClient.put(`reports/${reportId}`, reportData, {
      params: { scenario_ids: scenarioIds.join(",") },
    });
    return response.data;
  }
);

export const setCurrentReportDB = createAsyncThunk(
  "reportBuilder/setCurrentReport",
  async ({ reportId }) => {
    const response = await apiClient.post(`current-reports`, {
      report_id: reportId,
      user_id: localStorage.getItem("userId"),
      status: "string",
    });
    console.log(response);
    return response.data;
  }
);

const reportBuilderSlice = createSlice({
  name: "reportBuilder",
  initialState,
  reducers: {
    addToReport(state, action) {
      state.addToReportCount += 1;
    },
    cleanReport(state, action) {
      state.addToReportCount = 0;
    },
    setSelectedReport(state, action) {
      state.selectedReport = action.payload;
    },
    setCurrentReportId(state, action) {
      state.currentReportId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addImageToReport.fulfilled, (state, action) => {
        if (state.currentReport) {
          state.currentReport = {
            ...state.currentReport,
            report_json: {
              ...state.currentReport.report_json,
              elements: [
                ...(state.currentReport.report_json?.elements || []),
                action.payload,
              ],
            },
          };
        }
      })
      // Fetch table for report
      .addCase(fetchTableForReport.fulfilled, (state, action) => {
        if (state.currentReport) {
          // Update the current report with the new table
          state.currentReport = {
            ...state.currentReport,
            report_json: {
              ...state.currentReport.report_json,
              elements: [
                ...(state.currentReport.report_json?.elements || []),
                action.payload,
              ],
            },
          };
        }
      })

      // Fetch risk scores for report
      .addCase(fetchRiskScoresForReport.fulfilled, (state, action) => {
        if (state.currentReport) {
          state.currentReport = {
            ...state.currentReport,
            report_json: {
              ...state.currentReport.report_json,
              elements: [
                ...(state.currentReport.report_json?.elements || []),
                action.payload,
              ],
            },
          };
        }
      })

      // Fetch grouped alerts
      .addCase(fetchGroupedAlerts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchGroupedAlerts.fulfilled, (state, action) => {
        state.loading = false;
        state.groupedAlerts = action.payload;
      })
      .addCase(fetchGroupedAlerts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch alert statistics
      .addCase(fetchAlertStatistics.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAlertStatistics.fulfilled, (state, action) => {
        state.loading = false;
        state.alertStatistics = action.payload;
      })
      .addCase(fetchAlertStatistics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch all reports
      .addCase(fetchReports.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchReports.fulfilled, (state, action) => {
        state.loading = false;
        state.reports = action.payload;
      })
      .addCase(fetchReports.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Create a new report
      .addCase(createReport.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createReport.fulfilled, (state, action) => {
        state.loading = false;
        state.reports.push(action.payload);
      })
      .addCase(createReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch report by ID
      .addCase(fetchReportById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchReportById.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedReport = action.payload;
      })
      .addCase(fetchReportById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Update report by ID
      .addCase(updateReport.pending, (state) => {
        state.loading = true;
        state.updateStatus = "loading";
        state.error = null;
      })
      .addCase(updateReport.fulfilled, (state, action) => {
        state.loading = false;
        state.updateStatus = "succeeded";
        // Update the report in the list and selectedReport
        const index = state.reports.findIndex(
          (report) => report.id === action.payload.id
        );
        if (index !== -1) {
          state.reports[index] = action.payload;
        }
        if (
          state.selectedReport &&
          state.selectedReport.id === action.payload.id
        ) {
          state.selectedReport = action.payload;
        }
      })
      .addCase(updateReport.rejected, (state, action) => {
        state.loading = false;
        state.updateStatus = "failed";
        state.error = action.error.message;
      })

      // Fetch reports with filters
      .addCase(fetchReportsWithFilters.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchReportsWithFilters.fulfilled, (state, action) => {
        state.loading = false;
        state.reports = action.payload;
      })
      .addCase(fetchReportsWithFilters.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Fetch current reports (change in future to tkae into cosideration the user_id)
      .addCase(fetchCurrentReports.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCurrentReports.fulfilled, (state, action) => {
        state.loading = false;
        state.currentReport = action.payload.report; // Asigna el reporte actual recibido
        state.currentReportId = action.payload.report?.id;
      })
      .addCase(fetchCurrentReports.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.currentReport = null;
        state.currentReportId = null;
      });
  },
});

export const {
  addToReport,
  cleanReport,
  setSelectedReport,
  setCurrentReportId,
} = reportBuilderSlice.actions;

export default reportBuilderSlice.reducer;
