import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { HeaderClass } from "../../../layout/table/Header";
import TableGrid from "../../../layout/table/Table";
import { CellClass, RowCellClass } from "../../../layout/table/Cell";
import NameBadge from "../name_badge/NameBadge";
import StatusButton from "../status_button/StatusButton";

import {
  setSelectedAlert,
  setSelectedAlertDetails,
  setShownContainer,
} from "../../../features/all_alerts/allAlertsSlice";

function AlertsOverlap() {
  const dispatch = useDispatch();
  const { realOverlapAlerts, selectedAlertDetails, selectedAlert } =
    useSelector((state) => state.allAlerts);
  const [rerenderTable, setRerenderTable] = useState(1);

  useEffect(() => {
    setRerenderTable(rerenderTable + 1);
  }, [selectedAlert]);

  const headers = [
    new HeaderClass("Alert ID", "id", true, false, { minWidth: 210 }),
    new HeaderClass("Assigned To", "user", true, true, {
      minWidth: 200,
      maxWidth: 200,
      width: 200,
    }),
    new HeaderClass("Scenario", "scenario", true, true, {
      minWidth: 200,
      maxWidth: 200,
      width: 200,
    }),
    new HeaderClass("Customer Segment", "customer_segment", true, true, {
      minWidth: 210,
    }),
    new HeaderClass("Customer Risk", "customer_risk", true, true, {
      minWidth: 210,
    }),
    new HeaderClass("Creation Date", "creation_date", true, true, {
      minWidth: 140,
    }),
    new HeaderClass("Status", "status", true, true, {
      minWidth: 150,
    }),
  ];

  function onRowClick(row) {
    const { id, ...alertDetails } = row;
    dispatch(setSelectedAlert(id));
    dispatch(setSelectedAlertDetails(alertDetails));
    dispatch(setShownContainer(2));
  }

  const rows = realOverlapAlerts.map((rowData) => {
    const allertId = rowData.id;
    const cells = headers.map((header) => {
      const value = rowData[header.label];

      if (header.label === "Assigned To") {
        return new CellClass(value, header.field, () => (
          <NameBadge name={value !== "" ? value : "Unassigned"} />
        ));
      }
      if (header.label === "Status") {
        return new CellClass(value, header.field, () => (
          <div style={{ textAlign: "center" }}>
            <StatusButton status={value} />
          </div>
        ));
      }

      return new CellClass(value, header.field);
    });

    return new RowCellClass(allertId, cells, rowData);
  });

  return (
    <TableGrid
      title={`Overlap Alerts of ${selectedAlertDetails["Alert ID"]}`}
      headers={headers}
      rows={rows}
      onRowClick={onRowClick}
      resetTable={rerenderTable}
      noDataPlaceholder={"There are no overlapping alerts"}
    />
  );
}

export default AlertsOverlap;
