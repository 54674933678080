import React from "react";
import { useDispatch } from "react-redux";
import styles from "./Badge.module.css";


const Badge = ( {text, color , bgColor, hasBullet , hasBorder=false,...attributes }  ) => {


  const dispatch = useDispatch();

  const lightenColor = (color, percent) => {
    // Remove the hash if it's there
    let hex = color.startsWith('#') ? color.slice(1) : color;

    // If the color is shorthand (e.g., #03F), convert it to full form (#0033FF)
    if (hex.length === 3) {
      hex = hex.split('').map(char => char + char).join('');
    }

    // Convert the color to RGB
    const num = parseInt(hex, 16);
    const r = (num >> 16) + Math.round(2.55 * percent);
    const g = (num >> 8 & 0x00FF) + Math.round(2.55 * percent);
    const b = (num & 0x0000FF) + Math.round(2.55 * percent);

    // Ensure the values are within the RGB range [0, 255]
    const newR = Math.min(255, Math.max(0, r));
    const newG = Math.min(255, Math.max(0, g));
    const newB = Math.min(255, Math.max(0, b));

    // Convert the new RGB values back to HEX
    const newHex = ((1 << 24) + (newR << 16) + (newG << 8) + newB).toString(16).slice(1).toUpperCase();

    return `#${newHex}`;
  };

  return (
    <span  {...attributes}  className={styles.badgeContainer} style={{ color:color , backgroundColor:bgColor, borderColor:hasBorder ? color : "transparent" }}>
      {(hasBullet) && <div className={styles.bullet} style={{ backgroundColor:lightenColor(color,30) }}></div>}
      <span  className={styles.badgeText} style={{ color:color }} >
        {text}
      </span>
    </span>


  );
};

export default Badge;
