import Checkbox from "../buttons/Checkbox";

export class RowCellClass {
  constructor(id, cells, rowData = undefined) {
    this.id = id;
    this.cells = cells;
    this.rowData = rowData
  }
}

export class CellClass {
  constructor(value, field, render = null) {
    this.field = field;
    this.value = value;
    this.render = render;
  }
}

export function Cell({ cellConf, canSelect, selected, setSelected }) {
  return cellConf.render ? (
    cellConf.render()
  ) : (
    <div
      style={{
        display: canSelect ? "flex" : "block",
        gap: "8px",
        alignItems: "center",
      }}>
      {canSelect && <Checkbox checked={selected} onChange={setSelected} />}
      <div>{cellConf.value}</div>
    </div>
  );
}
