import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../features/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import styles from './Login.module.css';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, isAuthenticated } = useSelector((state) => state.auth);

  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  const [showPassword, setShowPassword] = useState(false);  

  const { username, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    await dispatch(loginUser({ username, password }));
  };
  console.log('auth error:', error);
   
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/'); 
    }
  }, [isAuthenticated, navigate]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={styles.container}>
      <div className={styles.reformLogo}>
        <img className={styles.reformIcon} alt="" src={`${process.env.PUBLIC_URL}/logo.svg`} />
        <div>reform</div>
      </div>

      <div className={styles.sectionWrapper}>
        <p className={styles.headerTitle}>Enter your Credentials</p>
        <form onSubmit={onSubmit}>
          <div className={styles.inputContainer}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 21" fill="none">
              <path
                stroke="#BDB4FE"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
              />
            </svg>

            <input
              type="text"
              placeholder="Username"
              name="username"
              value={username}
              className={styles.inputStyle}
              onChange={onChange}
              required
            />
          </div>

          <div className={styles.inputContainer}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              width="20"
              height="20"
              onClick={togglePasswordVisibility}
              style={{ cursor: 'pointer' }} // Cambia el cursor para indicar que es clicable
            >
              {showPassword ? (
                // Icono para mostrar contraseña
                <path
                  stroke="#BDB4FE"
                  strokeLinecap="round"
                  strokeWidth="1.66667"
                  strokeLinejoin="round"
                  d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                />
              ) : (
                // Icono para ocultar contraseña
                <path
                  stroke="#BDB4FE"
                  strokeLinecap="round"
                  strokeWidth="1.5"
                  strokeLinejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178ZM15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              )}
            </svg>

            <input
              placeholder="Password"
              type={showPassword ? 'text' : 'password'} // Cambia el tipo de input según el estado
              name="password"
              value={password}
              onChange={onChange}
              required
              className={styles.inputStyle}
            />
          </div>
          {loading && <p>Cargando...</p>}
          {error && 
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' , marginBottom:10}}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          stroke="red"
          fill="none"
          viewBox="0 0 24 24"
          width="20"
          height="20"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
          />
        </svg>
        <p className={styles.error}>{error}</p>
        </div>
      
       
          }
          <div className={styles.buttonContainer}>
            <button type="submit" className={styles.buttonBase}>Sign in</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
