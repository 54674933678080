import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import  { apiClient, apiClient2 } from '../../axiosConfig';

// Mock data for Monthly Overview on Incoming Transactions (MIBL - transaction monitoring)
const mockMonthlyOverviewData = [
  {
    "Sender Correspondent Bank": "Liberty Federal Bank",
    "Currency": "RUB",
    "Originator Country Risk": "HIGH",
    "Originator Country": "Russia",
    "Month": "Jan",
    "Incoming Transactions": "10",
    "Total Transactions Amount": "1137498",
    "T. Red Flags": "2"
  },
  {
    "Sender Correspondent Bank": "Dragon Gate Banking Corporation",
    "Currency": "CNY",
    "Originator Country Risk": "HIGH",
    "Originator Country": "China",
    "Month": "Feb",
    "Incoming Transactions": "6",
    "Total Transactions Amount": "1029336",
    "T. Red Flags": "3"
  },
  {
    "Sender Correspondent Bank": "Oro Capital Bank",
    "Currency": "MXN",
    "Originator Country Risk": "HIGH",
    "Originator Country": "Mexico",
    "Month": "March",
    "Incoming Transactions": "8",
    "Total Transactions Amount": "975172",
    "T. Red Flags": "4"
  },
  {
    "Sender Correspondent Bank": "Eastern Harmony Trust",
    "Currency": "CNY",
    "Originator Country Risk": "MEDIUM",
    "Originator Country": "China",
    "Month": "Jan",
    "Incoming Transactions": "6",
    "Total Transactions Amount": "960848",
    "T. Red Flags": "6"
  },
  {
    "Sender Correspondent Bank": "Moscow Unity Bank",
    "Currency": "RUB",
    "Originator Country Risk": "MEDIUM",
    "Originator Country": "Russia",
    "Month": "Feb",
    "Incoming Transactions": "6",
    "Total Transactions Amount": "923824",
    "T. Red Flags": "3"
  },
  {
    "Sender Correspondent Bank": "Eagle Crest Financial",
    "Currency": "USD",
    "Originator Country Risk": "STANDARD",
    "Originator Country": "USA",
    "Month": "March",
    "Incoming Transactions": "8",
    "Total Transactions Amount": "899390",
    "T. Red Flags": "4"
  },
  {
    "Sender Correspondent Bank": "Mexican Heritage Bank",
    "Currency": "MXN",
    "Originator Country Risk": "STANDARD",
    "Originator Country": "Mexico",
    "Month": "Jan",
    "Incoming Transactions": "7",
    "Total Transactions Amount": "840772",
    "T. Red Flags": "3"
  },
  {
    "Sender Correspondent Bank": "Patriot Community Bank",
    "Currency": "USD",
    "Originator Country Risk": "STANDARD",
    "Originator Country": "USA",
    "Month": "Jan",
    "Incoming Transactions": "6",
    "Total Transactions Amount": "838074",
    "T. Red Flags": "6"
  },
  {
    "Sender Correspondent Bank": "Zagrebacka Banka DD",
    "Currency": "EUR",
    "Originator Country Risk": "STANDARD",
    "Originator Country": "Croatia",
    "Month": "Feb",
    "Incoming Transactions": "8",
    "Total Transactions Amount": "806558",
    "T. Red Flags": "3"
  },
  {
    "Sender Correspondent Bank": "Siberian Financial Corporation",
    "Currency": "RUB",
    "Originator Country Risk": "STANDARD",
    "Originator Country": "Russia",
    "Month": "March",
    "Incoming Transactions": "6",
    "Total Transactions Amount": "753040",
    "T. Red Flags": "2"
  },
  {
    "Sender Correspondent Bank": "Volga National Bank",
    "Currency": "RUB",
    "Originator Country Risk": "STANDARD",
    "Originator Country": "Russia",
    "Month": "Jan",
    "Incoming Transactions": "7",
    "Total Transactions Amount": "741718",
    "T. Red Flags": "5"
  },
];

const currencies = {
    "ALL": {
      "name": "Albania Lek",
      "symbol": "Lek"
    },
    "AFN": {
      "name": "Afghanistan Afghani",
      "symbol": "؋"
    },
    "ARS": {
      "name": "Argentina Peso",
      "symbol": "$"
    },
    "AWG": {
      "name": "Aruba Guilder",
      "symbol": "ƒ"
    },
    "AUD": {
      "name": "Australia Dollar",
      "symbol": "$"
    },
    "AZN": {
      "name": "Azerbaijan Manat",
      "symbol": "₼"
    },
    "BSD": {
      "name": "Bahamas Dollar",
      "symbol": "$"
    },
    "BBD": {
      "name": "Barbados Dollar",
      "symbol": "$"
    },
    "BYN": {
      "name": "Belarus Ruble",
      "symbol": "Br"
    },
    "BZD": {
      "name": "Belize Dollar",
      "symbol": "BZ$"
    },
    "BMD": {
      "name": "Bermuda Dollar",
      "symbol": "$"
    },
    "BOB": {
      "name": "Bolivia Bolíviano",
      "symbol": "$b"
    },
    "BAM": {
      "name": "Bosnia and Herzegovina Convertible Mark",
      "symbol": "KM"
    },
    "BWP": {
      "name": "Botswana Pula",
      "symbol": "P"
    },
    "BGN": {
      "name": "Bulgaria Lev",
      "symbol": "лв"
    },
    "BRL": {
      "name": "Brazil Real",
      "symbol": "R$"
    },
    "BND": {
      "name": "Brunei Darussalam Dollar",
      "symbol": "$"
    },
    "KHR": {
      "name": "Cambodia Riel",
      "symbol": "៛"
    },
    "CAD": {
      "name": "Canada Dollar",
      "symbol": "$"
    },
    "KYD": {
      "name": "Cayman Islands Dollar",
      "symbol": "$"
    },
    "CLP": {
      "name": "Chile Peso",
      "symbol": "$"
    },
    "CNY": {
      "name": "China Yuan Renminbi",
      "symbol": "¥"
    },
    "COP": {
      "name": "Colombia Peso",
      "symbol": "$"
    },
    "CRC": {
      "name": "Costa Rica Colon",
      "symbol": "₡"
    },
    "CUP": {
      "name": "Cuba Peso",
      "symbol": "₱"
    },
    "CZK": {
      "name": "Czech Republic Koruna",
      "symbol": "Kč"
    },
    "DKK": {
      "name": "Denmark Krone",
      "symbol": "kr"
    },
    "DOP": {
      "name": "Dominican Republic Peso",
      "symbol": "RD$"
    },
    "XCD": {
      "name": "East Caribbean Dollar",
      "symbol": "$"
    },
    "EGP": {
      "name": "Egypt Pound",
      "symbol": "£"
    },
    "SVC": {
      "name": "El Salvador Colon",
      "symbol": "$"
    },
    "EUR": {
      "name": "Euro Member Countries",
      "symbol": "€"
    },
    "FKP": {
      "name": "Falkland Islands (Malvinas) Pound",
      "symbol": "£"
    },
    "FJD": {
      "name": "Fiji Dollar",
      "symbol": "$"
    },
    "GHS": {
      "name": "Ghana Cedi",
      "symbol": "¢"
    },
    "GIP": {
      "name": "Gibraltar Pound",
      "symbol": "£"
    },
    "GTQ": {
      "name": "Guatemala Quetzal",
      "symbol": "Q"
    },
    "GGP": {
      "name": "Guernsey Pound",
      "symbol": "£"
    },
    "GYD": {
      "name": "Guyana Dollar",
      "symbol": "$"
    },
    "HNL": {
      "name": "Honduras Lempira",
      "symbol": "L"
    },
    "HKD": {
      "name": "Hong Kong Dollar",
      "symbol": "$"
    },
    "HUF": {
      "name": "Hungary Forint",
      "symbol": "Ft"
    },
    "ISK": {
      "name": "Iceland Krona",
      "symbol": "kr"
    },
    "INR": {
      "name": "India Rupee",
      "symbol": "₹"
    },
    "IDR": {
      "name": "Indonesia Rupiah",
      "symbol": "Rp"
    },
    "IRR": {
      "name": "Iran Rial",
      "symbol": "﷼"
    },
    "IMP": {
      "name": "Isle of Man Pound",
      "symbol": "£"
    },
    "ILS": {
      "name": "Israel Shekel",
      "symbol": "₪"
    },
    "JMD": {
      "name": "Jamaica Dollar",
      "symbol": "J$"
    },
    "JPY": {
      "name": "Japan Yen",
      "symbol": "¥"
    },
    "JEP": {
      "name": "Jersey Pound",
      "symbol": "£"
    },
    "KZT": {
      "name": "Kazakhstan Tenge",
      "symbol": "лв"
    },
    "KPW": {
      "name": "Korea (North) Won",
      "symbol": "₩"
    },
    "KRW": {
      "name": "Korea (South) Won",
      "symbol": "₩"
    },
    "KGS": {
      "name": "Kyrgyzstan Som",
      "symbol": "лв"
    },
    "LAK": {
      "name": "Laos Kip",
      "symbol": "₭"
    },
    "LBP": {
      "name": "Lebanon Pound",
      "symbol": "£"
    },
    "LRD": {
      "name": "Liberia Dollar",
      "symbol": "$"
    },
    "MKD": {
      "name": "Macedonia Denar",
      "symbol": "ден"
    },
    "MYR": {
      "name": "Malaysia Ringgit",
      "symbol": "RM"
    },
    "MUR": {
      "name": "Mauritius Rupee",
      "symbol": "₨"
    },
    "MXN": {
      "name": "Mexico Peso",
      "symbol": "$"
    },
    "MNT": {
      "name": "Mongolia Tughrik",
      "symbol": "₮"
    },
    "MAD": {
      "name": "Moroccan-dirham",
      "symbol": "د.إ"
    },
    "MZN": {
      "name": "Mozambique Metical",
      "symbol": "MT"
    },
    "NAD": {
      "name": "Namibia Dollar",
      "symbol": "$"
    },
    "NPR": {
      "name": "Nepal Rupee",
      "symbol": "₨"
    },
    "ANG": {
      "name": "Netherlands Antilles Guilder",
      "symbol": "ƒ"
    },
    "NZD": {
      "name": "New Zealand Dollar",
      "symbol": "$"
    },
    "NIO": {
      "name": "Nicaragua Cordoba",
      "symbol": "C$"
    },
    "NGN": {
      "name": "Nigeria Naira",
      "symbol": "₦"
    },
    "NOK": {
      "name": "Norway Krone",
      "symbol": "kr"
    },
    "OMR": {
      "name": "Oman Rial",
      "symbol": "﷼"
    },
    "PKR": {
      "name": "Pakistan Rupee",
      "symbol": "₨"
    },
    "PAB": {
      "name": "Panama Balboa",
      "symbol": "B/."
    },
    "PYG": {
      "name": "Paraguay Guarani",
      "symbol": "Gs"
    },
    "PEN": {
      "name": "Peru Sol",
      "symbol": "S/."
    },
    "PHP": {
      "name": "Philippines Peso",
      "symbol": "₱"
    },
    "PLN": {
      "name": "Poland Zloty",
      "symbol": "zł"
    },
    "QAR": {
      "name": "Qatar Riyal",
      "symbol": "﷼"
    },
    "RON": {
      "name": "Romania Leu",
      "symbol": "lei"
    },
    "RUB": {
      "name": "Russia Ruble",
      "symbol": "₽"
    },
    "SHP": {
      "name": "Saint Helena Pound",
      "symbol": "£"
    },
    "SAR": {
      "name": "Saudi Arabia Riyal",
      "symbol": "﷼"
    },
    "RSD": {
      "name": "Serbia Dinar",
      "symbol": "Дин."
    },
    "SCR": {
      "name": "Seychelles Rupee",
      "symbol": "₨"
    },
    "SGD": {
      "name": "Singapore Dollar",
      "symbol": "$"
    },
    "SBD": {
      "name": "Solomon Islands Dollar",
      "symbol": "$"
    },
    "SOS": {
      "name": "Somalia Shilling",
      "symbol": "S"
    },
    "KRW": {
      "name": "South Korean Won",
      "symbol": "₩"
    },
    "ZAR": {
      "name": "South Africa Rand",
      "symbol": "R"
    },
    "LKR": {
      "name": "Sri Lanka Rupee",
      "symbol": "₨"
    },
    "SEK": {
      "name": "Sweden Krona",
      "symbol": "kr"
    },
    "CHF": {
      "name": "Switzerland Franc",
      "symbol": "CHF"
    },
    "SRD": {
      "name": "Suriname Dollar",
      "symbol": "$"
    },
    "SYP": {
      "name": "Syria Pound",
      "symbol": "£"
    },
    "TWD": {
      "name": "Taiwan New Dollar",
      "symbol": "NT$"
    },
    "THB": {
      "name": "Thailand Baht",
      "symbol": "฿"
    },
    "TTD": {
      "name": "Trinidad and Tobago Dollar",
      "symbol": "TT$"
    },
    "TRY": {
      "name": "Turkey Lira",
      "symbol": "₺"
    },
    "TVD": {
      "name": "Tuvalu Dollar",
      "symbol": "$"
    },
    "UAH": {
      "name": "Ukraine Hryvnia",
      "symbol": "₴"
    },
    "AED": {
      "name": "UAE-Dirham",
      "symbol": "د.إ"
    },
    "GBP": {
      "name": "United Kingdom Pound",
      "symbol": "£"
    },
    "USD": {
      "name": "United States Dollar",
      "symbol": "$"
    },
    "UYU": {
      "name": "Uruguay Peso",
      "symbol": "$U"
    },
    "UZS": {
      "name": "Uzbekistan Som",
      "symbol": "лв"
    },
    "VEF": {
      "name": "Venezuela Bolívar",
      "symbol": "Bs"
    },
    "VND": {
      "name": "Viet Nam Dong",
      "symbol": "₫"
    },
    "YER": {
      "name": "Yemen Rial",
      "symbol": "﷼"
    },
    "ZWD": {
      "name": "Zimbabwe Dollar",
      "symbol": "Z$"
    }
};

// Thunks

export const fetchMonthlyOverview = createAsyncThunk(
  'miblReport/fetchMonthlyOverview',
  // TODO check what parameters needed if needed at all
  async () => {
    // Use the mock data instead of making an actual API call
    return mockMonthlyOverviewData;
  }
);

const miblReportSlice = createSlice({
  name: 'miblReport',
  initialState: {

    status: 'idle',
    statusAlert: 'idle',
    error: null,
    incomingTXTable: [],
  },
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMonthlyOverview.pending, (state) => {
        state.statusAlert = 'loading';
      })
      .addCase(fetchMonthlyOverview.fulfilled, (state, action) => {
        state.statusAlert = 'succeeded';
        state.incomingTXTable = action.payload; 
      })
      .addCase(fetchMonthlyOverview.rejected, (state, action) => {
        state.statusAlert = 'failed';
        state.error = action.error.message;
      })
  },
});

export const {

} = miblReportSlice.actions;

export default miblReportSlice.reducer;