import axios from 'axios';
import { store } from './store/store';
import { refreshToken, logout } from './features/auth/authSlice';
import { env } from './env'

const BASE_URL = env.REACT_APP_BASE_URL;

const apiClient = axios.create({
  baseURL: BASE_URL,
});

apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => {
    console.log('Response received:', response);
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Check if error is 401 Unauthorized
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      // Check if the failed request was to /token or /refresh-token
      if (
        originalRequest.url.includes('/token') ||
        originalRequest.url.includes('/refresh-token')
      ) {
        // The token or refresh token is invalid -> logout the user
        store.dispatch(logout());
        return Promise.reject(error);
      }

      try {
        // Attempt to refresh the token
        const refreshedToken = await store.dispatch(refreshToken()).unwrap();

        // Update the Authorization header and retry the original request
        apiClient.defaults.headers.common['Authorization'] = `Bearer ${refreshedToken}`;
        originalRequest.headers['Authorization'] = `Bearer ${refreshedToken}`;

        return apiClient(originalRequest);
      } catch (err) {
        console.error('Token refresh error:', err);
        // If refreshing the token fails -> logout the user
        store.dispatch(logout());
        return Promise.reject(err);
      }
    }

    return Promise.reject(error);
  }
);

export default apiClient;
