import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import view_style from "../../layout/Layout.module.css";
import styles from "./AllAlertList.module.css";
import HeaderSection from "../../layout/HeaderSection";
import AlertsTable from "./alerts_table/AlertsTable";
import AlertOverview from "./alert_overview/AlertOverview.js";
import ContributingTable from "./contributing_transactions/ContributingTable.js";
import AlertsOverlap from "./alerts_overlap/AlertsOverlap.js";
import SliderButton from "../../layout/buttons/SliderButton.js";
import { RoundBackButton } from "../../layout/buttons/RoundIconButton.js";

import {
  setShownContainer,
  fetchOverlapAlerts,
  fetchContributingTransactions,
  fetchAuditLogs,
} from "../../features/all_alerts/allAlertsSlice";

function AlertsList() {
  const dispatch = useDispatch();
  const { shownContainer, selectedAlertDetails, selectedAlert } = useSelector(
    (state) => state.allAlerts
  );

  const [alertInfoType, setAlertInfoType] = useState("Alert Details");

  useEffect(() => {
    if (selectedAlert !== null) {
      dispatch(fetchOverlapAlerts({ alertId: selectedAlert }));
      dispatch(fetchContributingTransactions());
      dispatch(fetchAuditLogs({ alertId: selectedAlert }));
    }
  }, [selectedAlert]);

  const handleArrowClick = () => {
    if (shownContainer === 4) {
      handleAlertInfoTypeClick("Alert Details");
      dispatch(setShownContainer(2));
    } else {
      dispatch(setShownContainer(shownContainer - 1));
    }
  };

  const handleAlertInfoTypeClick = (type) => {
    setAlertInfoType(type);
  };

  return (
    <div className={styles.atlTool}>
      <div className={view_style.sectionWrapper} style={{ display: "block" }}>
        <div
          className={`${styles.headerSectionBox} ${
            shownContainer === 2
              ? styles.slide1
              : shownContainer === 3
              ? styles.slide2
              : shownContainer === 4
              ? styles.slide3
              : ""
          }`}>
          <div style={{ minWidth: "25%" }}>
            <HeaderSection SectionName="Alerts List" />
          </div>
          <div className={styles.header}>
            <div className={styles.headerTitle}>
              <RoundBackButton action={handleArrowClick} />
              {selectedAlertDetails ? selectedAlertDetails["Alert ID"] : ""}
            </div>
            <div className={styles.selectionBox}>
              <SliderButton
                left={"Alert Details"}
                right={"Audit Log"}
                leftActive={alertInfoType === "Alert Details"}
                onClick={(origin) => handleAlertInfoTypeClick(origin)}
              />
            </div>
          </div>
          <div className={styles.header}>
            <div className={styles.headerTitle}>
              <RoundBackButton action={handleArrowClick} />
              Contributing Transaction of Alert:{" "}
              {selectedAlertDetails ? selectedAlertDetails["Alert ID"] : ""}
            </div>
            <div>Transaction List</div>
          </div>
          <div className={styles.header}>
            <div className={styles.headerTitle}>
              <RoundBackButton action={handleArrowClick} />
              Overlapping Alerts for alert:{" "}
              {selectedAlertDetails ? selectedAlertDetails["Alert ID"] : ""}
            </div>
            <div>Overlapping List</div>
          </div>
        </div>
        <div
          className={`${styles.containerWrapper} ${
            shownContainer === 2
              ? styles.slide1
              : shownContainer === 3
              ? styles.slide2
              : shownContainer === 4
              ? styles.slide3
              : ""
          }`}>
          <div
            id="container1"
            className={`${styles.container} ${
              shownContainer !== 1 ? styles.hidden : ""
            }`}>
            <AlertsTable />
          </div>
          <div
            id="container2"
            className={`${styles.container} ${
              shownContainer !== 2 ? styles.hidden : ""
            }`}>
            <AlertOverview alertInfoType={alertInfoType} />
          </div>

          <div
            id="container3"
            className={`${styles.container} ${
              shownContainer !== 3 ? styles.hidden : ""
            }`}>
            <ContributingTable />
          </div>

          <div
            id="container4"
            className={`${styles.container} ${
              shownContainer !== 4 ? styles.hidden : ""
            }`}>
            <AlertsOverlap />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlertsList;
