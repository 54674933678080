import React, { useState, useEffect, useRef } from 'react';
import styles from './HorizonBarComp.module.css';
import { useSelector } from 'react-redux';

export default function HorizonBarComp({ isOpened, alerts, highestAlertCount , highestScaled}) {
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
  }, [containerRef.current]);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const non_relevant_alerts = alerts[2];
  const relevant_alerts = alerts[1];
  const sar_alerts = alerts[0];
  
  const total_alerts = non_relevant_alerts + relevant_alerts + sar_alerts;

  // The amount of each type of alert, percentage wise
  const sar_alerts_percent = total_alerts ? Math.floor((sar_alerts / total_alerts) * 100) : 0;
  const relevant_alerts_percent = total_alerts ? Math.floor((relevant_alerts / total_alerts) * 100) : 0;
  const non_relevant_alerts_percent = 100 - sar_alerts_percent - relevant_alerts_percent;

  // The actual width of the bars
  const [nonRelevantWidth, setNonRelevantWidth] = useState(0);
  const [relevantWidth, setRelevantWidth] = useState(0);
  const [sarWidth, setSarWidth] = useState(0);

  useEffect(() => {
    if (!isOpened) {
      setNonRelevantWidth(0);
      setRelevantWidth(0);
      setSarWidth(0);
    } else {
      setTimeout(() => {
        const biggerALertCount = Math.max(non_relevant_alerts, relevant_alerts, sar_alerts);
  
        // Helper function to calculate width and ensure a minimum size
        const calculateWidth = (count, total, containerWidth, minWidth = 10) => {
          const width = Math.floor(containerWidth * (count / (highestScaled === true ? highestAlertCount : total)));
          return width < minWidth ? minWidth : width;
        };
  
        setNonRelevantWidth(calculateWidth(non_relevant_alerts, total_alerts, containerWidth));
        setTimeout(() => {
          setRelevantWidth(calculateWidth(relevant_alerts, total_alerts, containerWidth));
          setTimeout(() => {
            setSarWidth(calculateWidth(sar_alerts, total_alerts, containerWidth));
          }, 150);
        }, 150);
      }, 50);
    }
  }, [isOpened, containerWidth, highestAlertCount]);
  
  // When sorting the table of ds, make the bars grow again.
  const selectedSortingRule = useSelector((state) => state.selectedSortingRule);
  const sortingRuleReverted = useSelector((state) => state.scenarioPerformance.sortingRuleReverted);
  
  useEffect(() => {
    if (isOpened === -1) {
      setTimeout(() => {
        const calculateWidth = (count, containerWidth, minWidth = 10) => {
          const width = Math.floor(containerWidth * (count / highestAlertCount));
          return width < minWidth ? minWidth : width;
        };
  
        setNonRelevantWidth(calculateWidth(non_relevant_alerts, containerWidth));
        setTimeout(() => {
          setRelevantWidth(calculateWidth(relevant_alerts, containerWidth));
          setTimeout(() => {
            setSarWidth(calculateWidth(sar_alerts, containerWidth));
          }, 150);
        }, 150);
      }, 50);
    }
  }, [selectedSortingRule, sortingRuleReverted, containerWidth, highestAlertCount]);
  
  // To handle empty
  const isEmpty = total_alerts === 0;
  const emptyHeight = isEmpty ? containerWidth - 20 : 0;

  return (
    <div className={styles.barComponentWrapper}>
      <div className={styles.barComponent}>
        <div ref={containerRef} className={styles.line}>
          <div style={{ height: 15, width: containerWidth, position: 'relative' }}>
            <div style={{ height: 15, width: emptyHeight, left: containerWidth - emptyHeight, top: 0, position: 'absolute', background: '#E8EBFE', borderRadius: 11, transition: `all 1s ease` }} />
            <div style={{ height: 15, width: sarWidth + relevantWidth + nonRelevantWidth, left: 0, top: 0, position: 'absolute', background: 'linear-gradient(100deg, #FDBCBC 0%, #E46F6F 100%, #F58484 100%)', borderRadius: 11, transition: `all 0.6s ease` }} />
            <div style={{ height: 15, width: relevantWidth + nonRelevantWidth, left: 0, top: 0, position: 'absolute', background: 'linear-gradient(180deg, #FEE4A3 0%, #F6BA5E 100%)', borderRadius: 11, transition: `all 0.6s ease` }} />
            <div style={{ height: 15, width: nonRelevantWidth, left: 0, top: 0, position: 'absolute', background: 'linear-gradient(90deg, #A5F2A3 0%, #4DC842 100%)', borderRadius: 11, transition: `all 0.6s ease` }} />
          </div>

          <div className={styles.sublegend}>
            <div className={styles.alertPercentage}>
              <img className={styles.colorIcon} alt="" src={`${process.env.PUBLIC_URL}/Color_green.svg`} />
              <div className={styles.alert_amount_text}>{non_relevant_alerts_percent}%</div>
            </div>
            <div className={styles.alertPercentage}>
              <img className={styles.colorIcon} alt="" src={`${process.env.PUBLIC_URL}/Color_yellow.svg`} />
              <div className={styles.alert_amount_text}>{relevant_alerts_percent}%</div>
            </div>
            <div className={styles.alertPercentage}>
              <img className={styles.colorIcon} alt="" src={`${process.env.PUBLIC_URL}/Color_red.svg`} />
              <div className={styles.alert_amount_text}>{sar_alerts_percent}%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
