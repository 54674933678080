import React, { useEffect, useState } from 'react';
import styles from '../Labels.module.css';
import RegularInput from '../../inputs/RegularInput';
import Selector from '../../selector/Selector';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedDataLabelId,
  setSelectedDataLabelsDetails,
  setShowEdit,
  fetchDataSources,
  fetchDataLabels,
  createDataLabel,
  linkDataLabel,
  fetchConfigurationDataLabels,
  updateDataLabel
  
} from '../../../../features/configuration/configurationSlice';

const Edit = ({configId}) => {
  const dispatch = useDispatch();

  // Get the needed data from the Redux store
  const {
    dataLabels,
    infoLists,
    selectedDataLabelDetails,
    dataSources,
    selectedDataLabelId,
    dataLabelToLink
  } = useSelector((state) => state.configuration);

  // Local state for the form fields
  const [currentName, setCurrentName] = useState('');
  const [currentDescription, setCurrentDescription] = useState('');
  const [currentDefinition, setCurrentDefinition] = useState('');
  const [currentDataSource, setCurrentDataSource] = useState(null);
  const [currentInfoList, setCurrentInfoList] = useState([]);

  // Initialize local state when selectedDataLabelDetails changes
  useEffect(() => {
    if (selectedDataLabelDetails) {
      setCurrentName(selectedDataLabelDetails.name || '');
      setCurrentDescription(selectedDataLabelDetails.description || '');
      setCurrentDefinition(selectedDataLabelDetails.definition || '');
      setCurrentDataSource(selectedDataLabelDetails.data_source || null);
      setCurrentInfoList(selectedDataLabelDetails.list || []);
      // Remove the dispatch here to prevent infinite loop
      // dispatch(setSelectedDataLabelId(selectedDataLabelDetails.id || -1));
    } else {
      // Clear all fields
      resetFields();
    }
    // Only depend on selectedDataLabelDetails
  }, [selectedDataLabelDetails]);

  // Function to reset all fields
  const resetFields = () => {
    setCurrentName('');
    setCurrentDescription('');
    setCurrentDefinition('');
    setCurrentDataSource(null);
    setCurrentInfoList([]);
  };



  const resetView = () =>{


    if(configId > 0)
        {
            dispatch(linkDataLabel({dataLabel:dataLabelToLink, config:configId}));
            dispatch(fetchConfigurationDataLabels(configId));
        }
        else
        {
            dispatch(fetchDataLabels());
        }


  }

  useEffect(() => {
    if (dataLabelToLink) {

      
       
        resetView();

        dispatch(setShowEdit(false));
        if (dataLabels.length > 0) {
          dispatch(setSelectedDataLabelId(dataLabels[0].id));
         
        }

    }
    }, [dataLabelToLink]);

  // Functions to handle changes in the editable fields
  const handleNameChange = (newValue) => {
    setCurrentName(newValue);
  };

  const handleDescriptionChange = (newValue) => {
    setCurrentDescription(newValue);
  };

  const handleDefinitionChange = (newValue) => {
    setCurrentDefinition(newValue);
  };

  const handleCancelClick = () => {
    dispatch(setShowEdit(false));
  };

  // Properties for the name badge
  const nameBadgeProps = [
    {
      type: 'text',
      text: currentName,
      color: '#5925DC',
      bgColor: 'rgba(181, 120, 255, 0.20)',
      hasBullet: false,
      onChange: handleNameChange,
    },
  ];

  // Properties for the data source badge
  const dataSourceProps = currentDataSource
    ? [
        {
          type: 'badge',
          text: currentDataSource.name,
          color: '#027A48',
          bgColor: '#ECFDF3',
          hasBullet: true,
        },
      ]
    : [
        {
          type: 'text',
          text: 'Not Selected',
        },
      ];

  // Parse the info lists to useful data
  const generateInfoListProps = (list) => {
    if (!list || list.length === 0) {
      return [
        {
          type: 'text',
          text: '',
        },
      ];
    } else {
      return list.map((item) => ({
        type: 'badge',
        text: item.name,
        color: '#B42318',
        bgColor: '#FEF3F2',
        hasBullet: true,
      }));
    }
  };

  // Properties of the info lists
  const infoListBadgeProps = generateInfoListProps(currentInfoList);

  // Properties for the description badge
  const descriptionBadgeProps = [
    {
      type: 'text',
      text: currentDescription,
      placeholder: 'Enter description',
      onChange: handleDescriptionChange,
    },
  ];

  // Properties for the definition badge
  const definitionBadgeProps = [
    {
      type: 'text',
      text: currentDefinition,
      color: '#B42318',
      bgColor: '#FEF3F2',
      hasBullet: false,
      onChange: handleDefinitionChange,
    },
  ];

  // Options for the data source selector
  const dataSourceOptions = dataSources.map((source) => ({
    id: source.id,
    name: source.name,
  }));

  // Fetch data sources when the component mounts
  useEffect(() => {
    dispatch(fetchDataSources());
  }, [dispatch]);

  // Handle selection changes
  const handleOptionSelect = (selector, option) => {
    if (selector === 'Data Source') {
      setCurrentDataSource(option);
    } else if (selector === 'Info List') {
      toggleSelection(currentInfoList, setCurrentInfoList, option);
    }
  };

  const toggleSelection = (list, setList, option) => {
    const exists = list.some((item) => item.id === option.id);
    const updatedList = exists
      ? list.filter((item) => item.id !== option.id)
      : [...list, option];
    setList(updatedList);
  };

  // Save the changes made to the fields
  const handleSaveClick = () => {
    const updatedDataLabelDetails = {
      ...selectedDataLabelDetails,
      name: currentName,
      description: currentDescription,
      definition: currentDefinition,
      data_source: currentDataSource,
      data_source_id: currentDataSource?.id,
      list: currentInfoList,
    };



    if (!selectedDataLabelDetails) {


        const data_source_ids = currentDataSource ? currentDataSource.id : 0;
        const list_ids = currentInfoList ?  currentInfoList.map((item) => item.id) : [];
        const data = {datalabel:{name:currentName,description:currentDescription,definition:currentDefinition,data_source_id:data_source_ids},list_column_ids:list_ids}
        

        dispatch(createDataLabel({dataLabel:data}));
    }
    else
    {
        dispatch(updateDataLabel({dataLabel:updatedDataLabelDetails, id:selectedDataLabelDetails.id}));
        dispatch(setShowEdit(false));
        resetView();


    } 






    // dispatch(setSelectedDataLabelsDetails(updatedDataLabelDetails));
    // dispatch(setShowEdit(false));
    // if (dataLabels.length > 0) {
    //   dispatch(setSelectedDataLabelId(dataLabels[0].id));
    // }
  };




  
  // useEffect(() => {

  //   console.log("data we passe: ", infoListBadgeProps, dataSourceProps); 
  // }, [infoListBadgeProps, dataSourceProps])





  const isDisabled = !currentDataSource || !currentName;

  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <div className={styles.leftContainer}>
          <div className={styles.text}>Create new label</div>
          <Selector
            color="#027A48"
            bgColor="#ECFDF3"
            options={dataSourceOptions}
            selectedOption={dataSourceProps}
            hasBullet={true}
            onOptionSelect={(option) => handleOptionSelect('Data Source', option)}
            label="Data Source"
          />
          <Selector
            color="#B42318"
            bgColor="#FEF3F2"
            options={infoLists}
            hasBullet={true}
            selectedOption={infoListBadgeProps}
            onOptionSelect={(option) => handleOptionSelect('Info List', option)}
            label="Info List"
          />
        </div>
        <div className={styles.rightContainer}>
          <RegularInput
            title="Name"
            withBackground={true}
            isEditable={true}
            isBadgeEditor={false}
            content={nameBadgeProps}
          />
          {currentInfoList && currentInfoList.length > 0 && (
            <RegularInput
              title="Info List"
              withBackground={true}
              content={infoListBadgeProps}
            />
          )}
          <RegularInput
            title="Description"
            withBackground={true}
            isEditable={true}
            content={descriptionBadgeProps}
          />
          <RegularInput
            title="Definition"
            withBackground={true}
            isEditable={true}
            badgesSelection={[...(infoListBadgeProps || []), ...(dataSourceProps || [])]}

            isBadgeEditor={true}
            content={definitionBadgeProps}
            definitionCallback={handleDefinitionChange}
          />
        </div>
      </div>
      <div className={styles.footer}>
        <div
          className={`${styles.buttonBox} ${styles.whiteButton}`}
          onClick={handleCancelClick}
        >
          <div className={styles.textCancel}>Cancel</div>
        </div>
        <div
         className={`${styles.buttonBox} ${styles.purpleButton} ${isDisabled ? styles.disabledButton : ''}`}
      onClick={isDisabled ? null : handleSaveClick}
        >
          <div className={styles.textEdit}  >{selectedDataLabelDetails ? "Edit" : "Create"}</div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
