import styles from './SortingSelection.module.css'
import { useSelector, useDispatch } from 'react-redux';
import React, { useState } from 'react';
import {
    fetchScenarioOverview,
    setSearchQuery,
    setSelectedSortingRule,
    setSelectedYearMonthPairs,
    fetchRecommendations,
    clearRecommendations
  } from '../../../features/scenario_overview/scenarioOverviewSlice';
import SearchSelector from '../../overlap_analysis/matrix_overlap/selectors/SearchSelector';


const SortingSelection = () => {

    //Get the neccesary information from slice
    const searchQuery = useSelector((state) => state.scenarioPerformance.searchQuery)
    const sortingRule = useSelector((state) => state.scenarioPerformance.selectedSortingRule)
    const sortingRuleReverted = useSelector((state) => state.scenarioPerformance.sortingRuleReverted)
    const selectedYearMonthPairs = useSelector((state) => state.scenarioPerformance.selectedYearMonthPairs);
    const openedGroups = useSelector((state) => state.scenarioPerformance.openedGroups);
    const scenarioGroups = useSelector((state) => state.scenarioPerformance.scenarioGroups);

    const [selectedYearMonthPairsState, setSelectedYearMonthPairsState] = useState(selectedYearMonthPairs || []);

    const handleApplyMonthYearFilter = (year) => {
        dispatch(setSelectedYearMonthPairs(year));
        dispatch(fetchScenarioOverview({year_month_pairs: year}));

        // Remove previously fetched recommendations, these are not relavant anymore
        dispatch(clearRecommendations());

        // Fetch recommendations for the newly selected year_month_pairs
        scenarioGroups.forEach((group) => {
            if (openedGroups.find((openedGroup) => openedGroup === group.id)) {
                group.childs.forEach((scenario) => {
                    dispatch(fetchRecommendations({ scenario_id: scenario.child_id, subscenario_id: 0, year_month_pairs: year }));
                });
            }
        });
    };

    const dispatch = useDispatch();

    //Set the sorting rule when a click on an icon happens
    const handleIconClick = (string) => {
        dispatch(setSelectedSortingRule(string));
    }

    const generateYearMonthPairs = (startYear, startMonth, endYear, endMonth) => {
        const yearMonthPairs = [];
        let year = startYear;
        let month = startMonth;
      
        while (year < endYear || (year === endYear && month <= endMonth)) {
          const formattedMonth = month < 10 ? `0${month}` : `${month}`;
          yearMonthPairs.push(`${year}-${formattedMonth}`);
          month++;
          if (month > 12) {
            month = 1;
            year++;
          }
        }
        return yearMonthPairs;
      };

    return (
        <div className={styles.selectionWrapper}>

            {/* Searcher */}
            <div className={styles.searcherBox}>
                <div style={{display: 'flex', width: '19.49%'}}>

                    {/* Month-Year Selector */}
                    <SearchSelector 
                        key={1}
                        all_options={generateYearMonthPairs(2022, 1, 2024, 8)} 
                        title={"Month/Year"} 
                        selected_options={selectedYearMonthPairsState} 
                        setSelectedOptions={setSelectedYearMonthPairsState}
                        applyFunction={handleApplyMonthYearFilter}
                        localFilter={true}
                    />

                </div>
                <div style={{display: 'flex', padding: '12px', width: '47.93%', height: '10px'}}></div>

                <div className={styles.searchInputBox} style={{width: '23.42%'}}>

                    <div style={{minWidth: '20px'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z" stroke="#BDB4FE" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>

                    <input
                        type="text"
                        placeholder="Search"
                        value={searchQuery}
                        onChange={(e) => dispatch(setSearchQuery(e.target.value))}
                        className={styles.searchInput}
                    />

                </div>
            </div>

            {/* Filters & Sorters */}
            <div className={styles.sortingSelectionWrapper}>

                {/* Name filter */}
                <div className={`${styles.textWrapper} ${(sortingRule === 'name') ? styles.selected : ''}`} style={{width: '19.49%'}}>

                    Detecting Solution Name

                    <div style={{minWidth: '21px'}}>
                        {
                            (sortingRule === 'name' && sortingRuleReverted  &&
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none" onClick={() => handleIconClick('name')} className={(sortingRule === 'name' ? styles.selected : '')}>
                                    <path d="M2.9082 6.89147L6.35436 3.44531L9.80051 6.89147" strokeWidth="1.72308" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M6.35547 3.44531V17.2299" strokeWidth="1.72308" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M13.248 3.44531H17.5557L13.248 8.61454H17.5557" strokeWidth="1.72308" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M13.248 17.2317V14.2163C13.248 13.6451 13.475 13.0973 13.8789 12.6933C14.2828 12.2894 14.8307 12.0625 15.4019 12.0625C15.9731 12.0625 16.521 12.2894 16.9249 12.6933C17.3288 13.0973 17.5557 13.6451 17.5557 14.2163V17.2317" strokeWidth="1.72308" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M17.5557 15.5078H13.248" strokeWidth="1.72308" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            )
                        }
                        {
                            ((!(sortingRule === 'name') || !sortingRuleReverted) &&
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none" onClick={() => handleIconClick('name')} className={(sortingRule === 'name' ? styles.selected : '')}>
                                    <path d="M2.58398 13.7852L6.03014 17.2313L9.47629 13.7852" strokeWidth="1.72308" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M6.03125 17.2299V3.44531" strokeWidth="1.72308" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M17.2315 6.89062H12.9238" strokeWidth="1.72308" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M12.9238 8.61454V5.59916C12.9238 5.02792 13.1508 4.48008 13.5547 4.07616C13.9586 3.67223 14.5064 3.44531 15.0777 3.44531C15.6489 3.44531 16.1967 3.67223 16.6007 4.07616C17.0046 4.48008 17.2315 5.02792 17.2315 5.59916V8.61454" strokeWidth="1.72308" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M12.9238 12.0625H17.2315L12.9238 17.2317H17.2315" strokeWidth="1.72308" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            )
                        }
                    </div>
                </div>

                {/* Alert Relevancy filter */}
                <div className={`${styles.textWrapper} ${(sortingRule === 'alerts') ? styles.selected : ''}`} style={{width: '47.93%'}}> 
                    <div>
                        Alert Relevancy
                    </div>
                    <div className={styles.iconsWrapper}>   
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M7.57484 7.5013C7.77076 6.94436 8.15746 6.47472 8.66647 6.17558C9.17547 5.87643 9.77392 5.76708 10.3558 5.8669C10.9377 5.96671 11.4655 6.26924 11.8457 6.72091C12.226 7.17258 12.4341 7.74424 12.4332 8.33464C12.4332 10.0013 9.93317 10.8346 9.93317 10.8346M9.99984 14.168H10.0082M18.3332 10.0013C18.3332 14.6037 14.6022 18.3346 9.99984 18.3346C5.39746 18.3346 1.6665 14.6037 1.6665 10.0013C1.6665 5.39893 5.39746 1.66797 9.99984 1.66797C14.6022 1.66797 18.3332 5.39893 18.3332 10.0013Z" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        
                        {
                            (sortingRule === 'alerts' && sortingRuleReverted  &&
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" onClick={() => handleIconClick('alerts')}>
                                    <path d="M2.5 6.66536L5.83333 3.33203L9.16667 6.66536" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M5.83398 3.33203V16.6654" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M9.16602 10H12.4993" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M9.16602 13.332H14.9993" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M9.16602 16.668H17.4993" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            )
                        }
                        {
                            ((!(sortingRule === 'alerts') || !sortingRuleReverted) &&
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" onClick={() => handleIconClick('alerts')}>
                                    <path d="M2.5 13.332L5.83333 16.6654L9.16667 13.332" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M5.83398 16.6654V3.33203" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M9.16602 3.33203H17.4993" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M9.16602 6.66797H14.9993" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M9.16602 10H12.4993" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            )
                        }

                    </div>
                </div>

                {/* Total Alerts filter */}
                <div className={`${styles.textWrapper} ${(sortingRule === 'total') ? styles.selected : ''}`} style={{width: '23.42%'}}>
                    Total Alerts

                    <div style={{minWidth: '21px'}}>
                        {
                            (sortingRule === 'total' && sortingRuleReverted  &&
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none" onClick={() => handleIconClick('total')}>
                                    <path d="M2.72266 6.96195L6.20414 3.48047L9.68562 6.96195" strokeWidth="1.74074" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M6.20312 3.48047V17.4064" strokeWidth="1.74074" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M14.9068 8.70269V3.48047H13.166" strokeWidth="1.74074" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M13.166 8.70312H16.6475" strokeWidth="1.74074" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M16.6475 13.9243C16.6475 12.9629 15.8681 12.1836 14.9068 12.1836C13.9454 12.1836 13.166 12.9629 13.166 13.9243V15.6651C13.166 16.6265 13.9454 17.4058 14.9068 17.4058C15.8681 17.4058 16.6475 16.6265 16.6475 15.6651V13.9243Z" strokeWidth="1.74074" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            )
                        }
                        {
                            ((!(sortingRule === 'total') || !sortingRuleReverted) &&
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none" onClick={() => handleIconClick('total')}>
                                    <path d="M2.61133 13.9258L6.09281 17.4073L9.57429 13.9258" strokeWidth="1.74074" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M6.0918 17.4064V3.48047" strokeWidth="1.74074" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M16.5362 5.22121C16.5362 4.25982 15.7568 3.48047 14.7954 3.48047C13.834 3.48047 13.0547 4.25982 13.0547 5.22121V6.96195C13.0547 7.92334 13.834 8.70269 14.7954 8.70269C15.7568 8.70269 16.5362 7.92334 16.5362 6.96195V5.22121Z" strokeWidth="1.74074" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M14.7954 17.4058V12.1836H13.0547" strokeWidth="1.74074" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M13.0547 17.4062H16.5362" strokeWidth="1.74074" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            )
                        }
                    </div>

                </div>

            </div>
        </div>
    )

}


export default SortingSelection;