import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../Scenarios.module.css';
import FilterSelector from '../../../overlap_analysis/alerts_overlap/selectors/FilterSelector';
import Scrollbar from 'react-scrollbars-custom';
import view_style from '../../../../layout/Layout.module.css';

import Selector from "../../../atl_tool/visualisation/selector/Selector";

import {
    fetchDataLabelDetails,
    fetchScenarioDetails,
    setSelectedDataLabelId,
    setShowEdit,
    fetchViewOptions, 

    setShownContainer, 
    setScenarioFilterIsOpen,
    setDSFilterIsOpen,
    setFrequencyFilterIsOpen,
    setGroupFilterIsOpen,
    setStatusFilterIsOpen,
    setCategoryNameFilterIsOpen,

    setScenarioFilter,
    setDSFilter,
    setFrequencyFilter,
    setGroupFilter,
    setStatusFilter,
    setCategoryNameFilter,

    setScenarioActivation, 
    emptyScenarioDetails,

} from '../../../../features/configuration/configurationSlice';
import ToggleButton from '../../../../layout/buttons/ToggleButton';
import PropTypes from 'prop-types';

const ToggleSwitch = ({ isChecked, onToggle }) => (
    <div style={{ display: 'flex', alignItems: 'center', width: '50px' }}>
      <ToggleButton checked={isChecked} onChange={onToggle}/>
    </div>
  );

  ToggleSwitch.propTypes = {
    isChecked: PropTypes.bool.isRequired,  // Ensures isChecked is a required boolean
    onToggle: PropTypes.func.isRequired    // Ensures onToggle is a required function
};


const Overview = ({configId}) => {

    const dispatch = useDispatch();

    // fetch needed data from database
    useEffect(() => {
        // dispatch(fetchInfoLists());
       // dispatch(fetchScenarios());
            //  dispatch(fetchScenarioDetails({scenarioID: 7}));
       
    }, [])

    const {
        allAlertsData,
        dataLabels, 
        scenarios,

        isScenarioFilterOpen, 
        isDSFilterOpen,
        isFrequencyFilterOpen,
        isGroupFilterOpen,
        isStatusFilterOpen,
        isCategoryNameOpen,

        ScenarioFilter,
        DSFilter,
        FrequencyFilter,
        GroupFilter,
        StatusFilter,
        CategoryNameFilter,
        viewOptions,

        shownContainer,
    } = useSelector((state) => state.configuration);

 const [viewFiltered, setViewFiltered] = useState([]);
 const [selectedViewFilter , setSelectedViewFilterState] = useState('All');
    // by default, set the first item from the list as the selected one
    useEffect(() => {
      if(dataLabels.length > 0){
        dispatch(setSelectedDataLabelId(dataLabels[0].id));
      }
    }, [dataLabels])



    // useEffect(() => {
    //     if(configId > 0)
    //         {
    //             dispatch(fetchConfigurationDataLabels(configId));
    //             dispatch(fetchConfigurationProfiles(configId));
    //             dispatch(fetchConfigurationParametersList(configId));
    //             dispatch(fetchTimeBaseOptions());
    //             dispatch(fetchConfigurationScenarios(configId));
    //         }
    //         else
    //         {
    //             dispatch(fetchScenarios());
    //         }
    // }, [showEdit])
    
    useEffect(() => {
        if(!viewOptions){
            dispatch(fetchViewOptions());
        }
    }, [viewOptions])

    // get needed data from slice
    const {selectedDataLabelId} = useSelector((state) => state.configuration);

    // if valid id, fetch the details for that label
    useEffect(() => {
        if (selectedDataLabelId > -1){
            dispatch(fetchDataLabelDetails({dataLabelId: selectedDataLabelId}));
        }
    }, [selectedDataLabelId])


    // go to the edit container
    const handleEditClick = (() => {
        dispatch(setShowEdit(true))
        dispatch(emptyScenarioDetails())

        // dispatch(fetchScenarioDetails({scenarioID: 7}));
    })

 

    const [currentPage, setCurrentPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

    const flexDivRef = useRef(null);
    const scrollWrapperRef = useRef(null);

    const pageSize = 10; // Limit to 10 rows per page
  
    useEffect(() => {
        const tableContainer = document.querySelector(`.${styles.tableWrapper}`);
        const handleScroll = () => {
            dispatch(setScenarioFilterIsOpen(false));
            dispatch(setDSFilterIsOpen(false));
            dispatch(setFrequencyFilterIsOpen(false));
            dispatch(setGroupFilterIsOpen(false));
            dispatch(setStatusFilterIsOpen(false));
        };

        const syncScroll = () => {
            if (flexDivRef.current && scrollWrapperRef.current) {
                flexDivRef.current.style.marginLeft = `-${scrollWrapperRef.current.scrollLeft}px`;
            }
        };

        const scrollWrapper = scrollWrapperRef.current;
        if (scrollWrapper) {
            scrollWrapper.addEventListener('scroll', syncScroll);
            scrollWrapper.addEventListener('scroll', handleScroll);
        }

        if (tableContainer) {
            tableContainer.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (tableContainer) {
                tableContainer.removeEventListener('scroll', handleScroll);
            }
            if (scrollWrapper) {
                scrollWrapper.removeEventListener('scroll', syncScroll);
                scrollWrapper.removeEventListener('scroll', handleScroll);
            }
        };
    }, [dispatch]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSort = (columnKey) => {
        let direction = 'ascending';
        if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }

        setSortConfig({ key: columnKey, direction });
    };

    // const handleArrowClick = () => {
    //     dispatch(setShownContainer(shownContainer - 1));
    // }

    const sortedRows = React.useMemo(() => {
        let sortableRows = [...scenarios];
        if (sortConfig.key) {
            sortableRows.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableRows;
    }, [allAlertsData, sortConfig, scenarios]);


    const [selectedScenariosOptions, setSelectedScenarioOptions] = useState([]);
    const [selectedDSOptions, setSelectedDSOptions ] = useState([]);
    const [selectedFrequencyOptions, setSelectedFrequencyOptions] = useState([]);
    const [selectedGroupOptions, setSelectedGroupOptions] = useState([]);
    const [selectedStatusOptions, setSelectedStatusOptions] = useState([]);
    const [selectedCategoryNameOptions, setSelectedCategoryNameOptions] = useState([]);

    const [scrollHeight, setScrollHeight] = useState(0);
    

    const alertOverlapFilters = {
        'Scenario': {
            title: "Scenario",
            filterTypes: [...new Set(scenarios.map(alert => alert['Scenario']))],
            setFilterFunction: setScenarioFilter,
            selected_options: selectedScenariosOptions,
            setSelectedOptions: setSelectedScenarioOptions,
        },
        'DS': {
            title: "DS",
            filterTypes: [...new Set(scenarios.map(alert => alert['DS']))],
            setFilterFunction: setDSFilter,
            selected_options: selectedDSOptions,
            setSelectedOptions: setSelectedDSOptions,
        },
        'Frequency': {
            title: "Frequency",
            filterTypes: [...new Set(scenarios.map(alert => alert['Frequency']))],
            setFilterFunction: setFrequencyFilter,
            selected_options: selectedFrequencyOptions,
            setSelectedOptions:    setSelectedFrequencyOptions,
        },
        'Group': {
            title: "Group",
            filterTypes: [...new Set(scenarios.map(alert => alert['Group']))],
            setFilterFunction: setGroupFilter,
            selected_options: selectedGroupOptions,
            setSelectedOptions: setSelectedGroupOptions,
        },
        'Status': {
            title: "Status",
            filterTypes: [...new Set(scenarios.map(alert => alert['Status']))],
            setFilterFunction: setStatusFilter,
            selected_options: selectedStatusOptions,
            setSelectedOptions: setSelectedStatusOptions,
        },
        'Category_name': {
            title: "Category Name",
            filterTypes: [...new Set(scenarios.map(alert => alert['Category_name']))],
            setFilterFunction: setCategoryNameFilter,
            selected_options: selectedCategoryNameOptions,
            setSelectedOptions: setSelectedCategoryNameOptions,
        }
    };

    const checkScenarioIsActive = (id) => {
        return viewOptions.some((view) => 
          view.scenario_view_activation.some((scenario) => 

            
            scenario.scenario_id === id && scenario.active
          )
        );
      };
      
    const columnsConfig = [
        { field: "Scenario", headerName: "Scenario",minWidth: 200, isOpen: isScenarioFilterOpen , setOpen: setScenarioFilterIsOpen, renderCell: "Scenario" },
        { field: "DS", headerName: "DS", minWidth: 200, maxWidth: 300, isOpen: isDSFilterOpen, setOpen: setDSFilterIsOpen, renderCell: "DS" },
        { field: "Frequency", headerName: "Frequency", minWidth: 170, isOpen: isFrequencyFilterOpen, setOpen: setFrequencyFilterIsOpen, renderCell: "Frequency" },
        { field: "Group", headerName: "Group", maxWidth: 300, isOpen: isGroupFilterOpen, setOpen: setGroupFilterIsOpen, renderCell: "Group" },
        { field: "Status", headerName: "ON/OFF", maxWidth: 50, isOpen: isStatusFilterOpen, setOpen: setStatusFilterIsOpen, renderCell: "Status" },
        { field: "Category_name", headerName: "Category_name", maxWidth: 300, isOpen: isCategoryNameOpen, setOpen: setCategoryNameFilterIsOpen, renderCell: "CategoryName"}
    ];

    const allColumns = columnsConfig.map(({ field, headerName, minWidth, maxWidth, isOpen, setOpen, renderCell }) => ({
        field,
        headerName,
        minWidth,
        maxWidth,
        headerClassName: styles.headerCell,
        renderHeader: () => (
            <div className={`${styles.columnHeader} ${styles.headerCell} ${styles.filterSelectorContainer}`} style={{ minWidth, maxWidth, borderRight: headerName === "Alert ID" ? "1px solid  #e0e0e0" : "none", minWidth: headerName === "Alert ID" ? "152px" : "none" }}>
                {headerName}
                <button className={styles.filterButton} style={{ opacity: isOpen ? 0 : 1 }} onClick={(e) => !isOpen ? onFilterClick(isOpen, setOpen, e) : ''}>

                    <img alt="" src={`${process.env.PUBLIC_URL}/Filters lines.svg`} />

                </button>
                <div onClick={() => handleSort(field)} className={styles.sortButton} style={{ opacity: sortConfig.key === field  ? 1 : 0.5}}>
                    {sortConfig.key === field ? (
                        sortConfig.direction === 'ascending' ? '↑' : '↓'
                    ) : (
                        '↑'
                    )}
                </div>
            </div>
        ),
        renderCell: (row) => {

             if (field === 'Scenario') {
                return (
                    <div className={styles.cellContentfixed} style={{ marginLeft: "12px" }}>
                        <span className={ styles.cellContentfixedspan}>{row[field]}</span>
                    </div>
                );
            }

           else if (renderCell === "Status") {
           
            const isChecked = checkScenarioIsActive(row['Id']);

                return <div><ToggleSwitch isChecked={isChecked} onToggle={(e) =>{ e.stopPropagation(); setToggleState(row, !isChecked)}} /></div>;
 
            }

            else if (renderCell === "Group") {
                return  <p style={{width:"250px",  display:"block",  overflow:"hidden",  textOverflow:"ellipsis", whiteSpace:"nowrap"}}>{row[field]}</p>
            }

            else if (renderCell === "CategoryName") {
                return <p style={{width:"250px", display:"block", overflow:"hidden", textOverflow:"ellipsis", whiteSpace:"nowrap"}}>{row[field]}</p>
            }

            else if (field === 'Alert ID') {
                return (
                    <div className={styles.cellContentfixed} style={{ marginLeft: "12px" }}>
                       
                        <span>{row[field]}</span>
                    </div>
                );
            }
            else
                return <div className={styles.cellContent}>{row[field]}</div>;
        },
    }));

   

    const filterByScenario = (item) => !ScenarioFilter.length || ScenarioFilter.includes(item.Scenario);
    const filterByDS = (item) => !DSFilter.length || DSFilter.includes(item.DS);
    const filterByFrequency = (item) => !FrequencyFilter.length || FrequencyFilter.includes(item.Frequency);
    const filterByGroup = (item) => !GroupFilter.length || GroupFilter.includes(item.Group);
    const filterByStatus = (item) => !StatusFilter.length || StatusFilter.includes(item.Status);
    const filterByCategoryName = (item) => !CategoryNameFilter.length || CategoryNameFilter.includes(item.Category_name)
    const filterBySearchQuery = (item) => !searchQuery || Object.values(item).some(value => String(value).toLowerCase().includes(searchQuery.toLowerCase()));
    
    const filterByView = (item) => !viewFiltered.length || viewFiltered.includes(item.Scenario.id);

    const combinedFilter = (item) =>
        filterByScenario(item) &&
        filterByDS(item) &&
        filterByFrequency(item) &&
        filterByGroup(item) &&
        filterByStatus(item) &&
        filterBySearchQuery(item) &&
        filterByCategoryName(item);
    let filteredRows = sortedRows.filter(combinedFilter).filter(filterByView);

    useEffect(() => {
        if(scrollWrapperRef.current){
            setScrollHeight(scrollWrapperRef.current.clientHeight);
        }
    }, [shownContainer, scrollWrapperRef, filteredRows]);

    const createPageNumbers = () => {
        const totalPages = Math.ceil(filteredRows.length / pageSize);
        const maxPagesToShow = 5;
        const halfPagesToShow = Math.floor(maxPagesToShow / 2);

        let startPage = Math.max(1, currentPage + 1 - halfPagesToShow);
        let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

        if (endPage - startPage < maxPagesToShow - 1) {
            startPage = Math.max(1, endPage - maxPagesToShow + 1);
        }

        const pageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);

        if (startPage > 1) {
            pageNumbers.unshift(1, '...');
        }
        if (endPage < totalPages) {
            pageNumbers.push('...', totalPages);
        }

        return pageNumbers;
    };

   
    const paginatedRows = filteredRows.slice(currentPage * pageSize, (currentPage + 1) * pageSize).reverse();

    const totalPages = Math.ceil(filteredRows.length / pageSize);

    const onFilterClick = (filter, setFilterFunction, event) => {
        const action = setFilterFunction(!filter);
        dispatch(action);
    };


    const handleRowClick = (scenario) => {
        dispatch(fetchScenarioDetails({scenarioID: scenario.Id}));
        dispatch(setShowEdit(true));
    }

    const setToggleState = (state, isChecked ) => {
        dispatch(setScenarioActivation({
            scenario_id: state.Id,
            active: isChecked, 
            viewOptions : viewOptions
        }));
        
    }

    const setSelectedViewFilter = (option) => {
        setSelectedViewFilterState(option);
        // If "All" is selected, return an empty array
        let viewFiltered = [];
        if (option === 'All' || option === 'All options') {
            viewFiltered =  [];
            setViewFiltered(viewFiltered);
            return;
        }
      
        // Find the view with the matching name
        const selectedView = viewOptions.find((view) => view.name === option);
      
        // If the view is not found, return an empty array
        if (!selectedView) {
            viewFiltered =  [];
            setViewFiltered(viewFiltered);
            return;
        }
      
        // Extract the scenario IDs where active is true
        const activeScenarioIds = selectedView.scenario_view_activation
          .filter((scenario) => scenario.active)
          .map((scenario) => scenario.scenario_id);
      
        // Return the array of active scenario IDs
        viewFiltered =  activeScenarioIds;

        setViewFiltered(viewFiltered);
      };
      
    const [tableHeight, setTableHeight] = useState(78 * paginatedRows.length + 83);

    useEffect(() => {
        setTableHeight(78 * paginatedRows.length + 83);
    }, [paginatedRows])

    return (
        <div className={styles.atlTool} style={{ paddingBottom: '5px'
         }}>
          
            <div className={view_style.sectionWrapper} style={{ paddingBottom: '0px' }}>
         

                <div className={`${styles.containerWrapper} ${shownContainer === 2 ? styles.slide1 : shownContainer === 3 ? styles.slide2 : ''}`}>
                   
                   
                    <div id="container1" className={`${styles.container} ${shownContainer !== 1 ? styles.hidden : ''}`}>
                        <div  style={{ display: "flex", justifyContent: "space-between", overflowY: "visible"}}>
                          
                            <div className={styles.controls}>

                            <div onClick={() => handleEditClick()} style={{
    width: 24,
    height: 24,
    padding: 8,
    float: 'left',
    background: '#D9D6FE',
    borderRadius: '50%',  // Ensures the div is a perfect circle
    display: 'flex',
    alignItems: 'center',  // Centers the content vertically
    justifyContent: 'center',  // Centers the content horizontally
}}>
    <div style={{
        position: 'relative',
        width: 14,  // Width of the plus sign
        height: 14,  // Height of the plus sign
    }}>
        <div style={{
            position: 'absolute',
            width: 14,
            height: 2,  // Height of the horizontal line (thickness)
            top: '50%',  // Centers the line vertically
            left: 0,  // Starts from the very left of the parent div
            transform: 'translateY(-50%)',  // Ensures the line is perfectly centered
            backgroundColor: '#7A5AF8'
        }}></div>
        <div style={{
            position: 'absolute',
            width: 2,  // Width of the vertical line (thickness)
            height: 14,
            left: '50%',  // Centers the line horizontally
            top: 0,  // Starts from the very top of the parent div
            transform: 'translateX(-50%)',  // Ensures the line is perfectly centered
            backgroundColor: '#7A5AF8'
        }}></div>
    </div>

    
</div>

<div className={styles.searchContainerselect} >
    
<Selector options={['All', ...viewOptions.map(option => option.name)]}  selectedOption={selectedViewFilter} onOptionSelect={setSelectedViewFilter} />

</div>

                                <div className={styles.searchContainer} style={{ justifyContent: 'flex-end' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 21" fill="none">
                                        <path
                                            d="M17.5 18.3906L13.875 14.7656M15.8333 10.0573C15.8333 13.7392 12.8486 16.724 9.16667 16.724C5.48477 16.724 2.5 13.7392 2.5 10.0573C2.5 6.37539 5.48477 3.39062 9.16667 3.39062C12.8486 3.39062 15.8333 6.37539 15.8333 10.0573Z"
                                            stroke="#667085"
                                            strokeWidth="1.66667"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                        className={styles.searchInput}
                                    />
                                </div>

                            </div>
                        </div>


                        <div className={styles.datasGridContainer}>
                            <div className={styles.tableWrapper}>
                                <div style={{ display: 'flex', justifyContent: 'space-evenly'}} ref={flexDivRef}>
                                    {columnsConfig.map(({ field, headerName, minWidth, maxWidth, isOpen, setOpen }, index) => (
                                        <div
                                             key={field}
                                            className={`${styles.columnHeader} ${styles.headerCell} ${styles.filterSelectorContainer}`}
                                            style={{
                                                display: 'inline-flex',
                                                textWrap: 'nowrap',
                                                height: 0,
                                                left: 0,
                                                position: headerName === "Scenario" ? 'sticky' : 'relative',
                                                color: 'transparent',
                                                backgroundColor: 'transparent',
                                                padding: '12px 24px',
                                                minWidth,
                                                zIndex: 11
                                            }}
                                        >
                                            {headerName}
                                            <button
                                                className={styles.filterButton}
                                                style={{ opacity: 0 }}
                                                onClick={(e) => !isOpen ? onFilterClick(isOpen, setOpen, e) : ''}
                                            >

                                                <img alt="" src={`${process.env.PUBLIC_URL}/Filters lines.svg`}   />

                                            </button>
                                            <div onClick={() => handleSort(field)} className={styles.sortButton} style={{ opacity:0}}>
                                                {sortConfig.key === field ? (
                                                    sortConfig.direction === 'ascending' ? '↑' : '↓'
                                                ) : (
                                                    '↑'
                                                )}
                                            </div>
                                            {isOpen && (
                                                <div style={{
                                                    display: 'inline',
                                                    position: 'absolute',
                                                    left: field === "Scenario" ? '0px' : field === "Status" ? '-60px' : '-30px',
                                                    top: '60px',
                                                    zIndex: index === 0 ? 11 : 'auto'
                                                }}>
                                                    <FilterSelector
                                                        all_options={alertOverlapFilters[headerName].filterTypes}
                                                        applyFunction={alertOverlapFilters[headerName].setFilterFunction}
                                                        title={alertOverlapFilters[headerName].title}
                                                        selected_options={alertOverlapFilters[headerName].selected_options}
                                                        setSelectedOptions={alertOverlapFilters[headerName].setSelectedOptions}
                                                        isFilterOpen={isOpen}
                                                        applyIsFilterOpenFunction={setOpen}
                                                        overwriteClass={styles.filterSelector} // Apply the class for custom positioning
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div className={styles.tableContentWrapper} >
                                    <Scrollbar 
                                        style={{ transition: 'min-height 0.3s ease', minHeight: scrollHeight, height: scrollHeight, overflowY: 'hidden'}} 
                                        trackXProps={{ style: { zIndex: '100', bottom: '0px', left: '10px', height: '5px' , backgroundColor: '#E9D7FE' } }} 
                                        thumbXProps={{ style: { backgroundColor: '#D6BBFB' }}}
                                        trackYProps={{ style: { width: '0px' }}}
                                        wrapperProps={{
                                            renderer: props => {
                                            const { elementRef, ...restProps } = props;
                                            return (
                                                <span
                                                {...restProps}
                                                ref={elementRef}
                                                style={{marginBottom: '-5px', position: 'absolute', inset: '0px 0px 5px 0px', overflow:'hidden'}}
                                                />
                                            );
                                            }
                                    }}>
                                        <table ref={scrollWrapperRef} className={styles.customTable} style={{ overflowX: 'visible', overflowY: 'none'}}>
                                            <thead>
                                                <tr style={{ borderBottom: "1px solid #e0e0e0" }}>
                                                    {allColumns.map((col) => (
                                                        <th key={col.field} style={{ minWidth: col.minWidth }} className={col.field === 'Scenario' ? styles.fixedHeaderColumn2 : ''}>
                                                            {col.renderHeader()}
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {paginatedRows.map((row) => (
                                                    <tr key={row.id} className={styles.tableRow} onClick={() => handleRowClick(row)}>
                                                        {allColumns.map((col) => (
                                                            <td key={col.field} className={`${col.field === 'Scenario' ? styles.fixedColumn : ''}`}>
                                                                <div className={`${col.field === 'Scenario' ? styles.cellfixed : ''}`}>
                                                                    {col.renderCell(row)}
                                                                </div>
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}

                                                {paginatedRows.length === 0 && (
                                                    <tr>
                                                        <td colSpan={allColumns.length} style={{ textAlign: 'center', padding: '24px 0' }}>
                                                            No scenarios found
                                                        </td>
                                                    </tr>
                                                )}

                                            </tbody>
                                        </table>
                                    </Scrollbar>
                                </div>
                            </div>
                        </div>

                        <div className={styles.pagination}>
                                <button
                                    className={styles.paginationButton}
                                    style={{ marginLeft: "24px" }}
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 0}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 21" fill="none">
                                        <path
                                            d="M15.8327 10.1693H4.16602M4.16602 10.1693L9.99935 16.0026M4.16602 10.1693L9.99935 4.33594"
                                            stroke="#6941C6"
                                            strokeWidth="1.67"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                    Previous
                                </button>
                                <div className={styles.pageNumbers}>
                                    {createPageNumbers().map((number, index) => (
                                        <button
                                            key={index+number}
                                            onClick={() => {
                                                if (number != "...") {
                                                    handlePageChange(number - 1);
                                                }
                                            }}
                                            disabled={number == "..."}
                                            className={
                                                currentPage === number - 1
                                                    ? styles.activePage
                                                    : styles.unactivePage
                                            }
                                        >
                                            {number}
                                        </button>
                                    ))}
                                </div>
                                <button
                                    className={styles.paginationButton}
                                    style={{ marginRight: "24px" }}
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage >= totalPages - 1}
                                >
                                    Next
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 21" fill="none">
                                        <path
                                            d="M4.16602 10.1693H15.8327M15.8327 10.1693L9.99935 4.33594M15.8327 10.1693L9.99935 16.0026"
                                            stroke="#6941C6"
                                            strokeWidth="1.67"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </button>
                            </div>
                    </div>

                 

                </div>
            </div>
        </div>
    );
};

export default Overview;




