import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {setSelectedScenario, 
    setSelectedSubScenario, 
    setSelectedYearState,
    fetchRiskScores, 
    fetchOptimisationTable, 
    fetchSegments, 
    fetchBoxPlotData, 
    fetchThresholds,
    fetchScatterPlotData,
    clearSelectedSegment} 
from '../../../../features/atl_tool/atlToolSlice';
import styles from './ScenarioSelector.module.css';
import Scrollbar from 'react-scrollbars-custom';



const ScenarioSelector = ({ onScenarioChange }) => {
    const dispatch = useDispatch();
    const {segments, visualisationsThresholds, scenarios, selectedScenario, selectedYearState, optimisationTable, selectedRiskScore , selectedSegment, selectedSubScenario, visualisations} = useSelector((state) => state.atlTool);
    
    const statusSegments = useSelector((state) => state.atlTool.statusSegments);
    
    const [isOpen, setIsOpen] = useState(false);
    const [isSubOpen, setSubIsOpen] = useState(false);
    const [isYearOpen, setYearIsOpen] = useState(false);
    const [selectedYear, setSelectedYear] = useState(selectedYearState);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchYearTerm, setSearchYearTerm] = useState('');
    const [displayedScenario, setDisplayedScenario] = useState(selectedScenario);
    const [isSubscenariosVisible, setIsSubscenariosVisible] = useState(false);
    const [searchSubTerm, setSearchSubTerm] = useState('');
    const [selectedSubscenarioLocal, setSelectedSubscenarioLocal] = useState(selectedSubScenario);
    const scenarioSelectorRef = useRef();
    const searchInputRef = useRef();
    const subscenarioSelectorRef = useRef();
    const yearSelectorRef = useRef();

    const subSearchInputRef = useRef(); // Ref for the subscenario search input
    const yearSearchInputRef = useRef(); // Ref for year search input

    const { preSelectedScenario } = useParams(); // Obtener el parámetro de la URL
    const navigate = useNavigate(); // Para navegar programáticamente

    const [scrollHeight, setScrollHeight] = useState('0px');

    const startYear = 2020;
    const currentYear = new Date().getFullYear();

    const yearOptions = [];

    for (let year = startYear; year <= currentYear; year++) {
        yearOptions.push({
            id: year,
            name: year.toString()
        });
    }
    yearOptions.reverse();
    useEffect(() => {
        if (selectedScenario) {
          dispatch(fetchThresholds({ selectedScenario, selectedSubScenario }));
        }
      }, [selectedScenario, selectedSubScenario]);
    useEffect(() => {
        dispatch(setSelectedSubScenario(selectedSubscenarioLocal));
    }, [selectedSubscenarioLocal])

    useEffect(() => {
        setSelectedYear(yearOptions[0])
        dispatch(setSelectedYearState(yearOptions[0]))

        if (optimisationTable) {
            dispatch(fetchOptimisationTable({selectedScenario, selectedSegment, selectedSubscenarioLocal, selectedYearState: selectedYearState.id}));
        }
        if (selectedRiskScore) {
            dispatch(fetchRiskScores({selectedScenario, selectedSegment, selectedSubscenarioLocal, selectedYearState: selectedYearState.id}));
        }
    }, [])

    useEffect(() => {
      if (preSelectedScenario) {
        const foundScenario = scenarios.find(
            (scenario) => String(scenario.id) === String(preSelectedScenario)
        );
        if (foundScenario) {
            selectScenario(foundScenario);
            setSelectedSubscenarioLocal(selectedSubScenario);
        }
      }
    }, [preSelectedScenario, scenarios]);

    useEffect(() => {
        if (selectedSubScenario?.id !== selectedSubscenarioLocal?.id) {
            setSelectedSubscenarioLocal(selectedSubScenario);
        }
    }, [selectedSubScenario])

    useEffect(() => {
        if (selectedScenario) {
          navigate(`/scenario_performance/${selectedScenario.id}`);
        }
    }, [selectedScenario, navigate]);

    useEffect(() => {
        if (statusSegments === 'succeeded') {
            if (!segments.find((seg) => seg.id === selectedSegment?.id)){
                dispatch(clearSelectedSegment());
            }
        }
    }, [segments])

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        if (!isOpen) {
            searchInputRef.current.focus();
        } else {
            setSearchTerm('');
        }
    };

    const toggleDropdownSubscenarios = () => {
        setSubIsOpen(!isSubOpen);
        if (!isSubOpen) {
            subSearchInputRef.current.focus(); // Focus the subscenario search input
        } else {
            setSearchSubTerm('');
        }
    };

    const toggleDropdownYear = () => {
        setYearIsOpen(!isYearOpen);
        if (!isYearOpen) {
            yearSearchInputRef.current.focus(); // Focus the year search input
        } else {
            setSearchYearTerm('');
        }
    };

    const selectScenario = (scenario, selectFirstSubScen=false) => {
        if (!selectFirstSubScen) {
            setSelectedSubscenarioLocal(null);
        }
        setDisplayedScenario(scenario);
        setSearchTerm('');
        setIsOpen(false);
        setIsSubscenariosVisible(scenario.subscenarios.length > 0);
    
        onScenarioChange(scenario);
        navigate(`/scenario_performance/${scenario.id}`);
    };

    const handleScenarioSelector = (scenario) => {
        var newSubScenario = null;
        if (scenario?.subscenarios?.length > 0) {
            newSubScenario = scenario.subscenarios[0];
        }
        setSelectedSubscenarioLocal(newSubScenario);
        dispatch(setSelectedScenario(scenario));
        dispatch(clearSelectedSegment());

        dispatch(fetchSegments({ selectedScenarioId: scenario.id, selectedSubScenario: newSubScenario, selectedYearState: selectedYear.id }));
    }

    const handleSubScenarioSelector = (subscenario) => {
        setSelectedSubscenarioLocal(subscenario);
        setSubIsOpen(false);
        dispatch(fetchSegments({ selectedScenarioId: selectedScenario.id, selectedSubScenario: subscenario, selectedYearState: selectedYear.id }));
    }

    const getThresholdIdFromName = (name) => {
        return visualisationsThresholds.find(threshold => threshold.threshold_name === name)?.threshold_id;
      };

    const setYear = (year) => {
        dispatch(setSelectedYearState(year))
        setSelectedYear(year);
        setYearIsOpen(false);

        if (selectedRiskScore) {
            dispatch(fetchOptimisationTable({ selectedScenario, selectedSegment, selectedRiskScore, selectedSubscenarioLocal, selectedYearState:year.id }));
        }
        if (selectedSegment) {
            dispatch(fetchRiskScores({ selectedScenarioId: selectedScenario.id, selectedSegmentId: selectedSegment.id, selectedSubscenarioLocal, selectedYearState:year.id }));
        }
        if (selectedScenario) {
            dispatch(fetchSegments({ selectedScenarioId: selectedScenario.id, selectedSubScenario , selectedYearState:year.id }));
        }
   
        if (visualisations.length > 0) {

            visualisations.map((visualisation) => {
                const xOption = visualisation.xSelector.xOption; // treshold_name
                const yOption = visualisation.ySelector.yOption; // treshold_name

                const xSelector = visualisation.xSelector;
                const ySelector = visualisation.ySelector;

                dispatch(fetchScatterPlotData({selectedScenario, selectedSegment, selectedRiskScore, x_threshold_id: getThresholdIdFromName(xOption),
                y_threshold_id: getThresholdIdFromName(yOption),
                x_threshold_name: xSelector.x_option,
                y_threshold_name: ySelector.y_option,  selectedSubscenarioLocal, selectedYearState: year.id}));
            
                dispatch(fetchBoxPlotData({selectedScenario, selectedSegment, selectedRiskScore, selectedSubscenarioLocal, selectedYearState: year.id}));
            });

        }
    }

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSubSearchChange = (e) => {
        setSearchSubTerm(e.target.value);
    };

    const clearSearchTerm = () => {
        setSearchTerm('');
        searchInputRef.current.focus();
    };

    const clearSubscenario = () => {
        setSelectedSubscenarioLocal(null);
        setSearchSubTerm('');
        subSearchInputRef.current.focus();
        dispatch(fetchSegments({selectedScenarioId: selectedScenario.id, selectedSubScenario: null, selectedYearState: selectedYear.id}));
    };

    const clearYear = () => {
        setSelectedYear(null);
        setSearchYearTerm('');
        yearSearchInputRef.current.focus();
    }

    const filteredScenarios = scenarios.filter((scenario) =>
        scenario.name.toLowerCase().includes(searchTerm.toLowerCase())
    ); 

    useEffect(() => {
        if (filteredScenarios.length > 0) {
            setScrollHeight('200px');
        } else {
            setScrollHeight('0px');
        }
    }, [filteredScenarios]);

    const filteredSubscenarios = displayedScenario?.subscenarios.filter((subscenario) =>
        subscenario.name.toLowerCase().includes(searchSubTerm.toLowerCase())
    ) || [];

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                scenarioSelectorRef.current && 
                !scenarioSelectorRef.current.contains(event.target)
            ) {
                setIsOpen(false);
                if (searchTerm === '') {
                    setDisplayedScenario(selectedScenario);
                }
            }

            if (subscenarioSelectorRef.current &&
                !subscenarioSelectorRef.current.contains(event.target)
            ) {
                setSubIsOpen(false);
            }

            if (yearSelectorRef.current &&
                !yearSelectorRef.current.contains(event.target)
            ) {
                setYearIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [searchTerm, selectedScenario]);

    return (
        <div className={styles.selectorsContainer}>
            {/* Scenario Selector */}
            <div ref={scenarioSelectorRef} className={styles.inputWithLabel}>
                <div className={styles.label}>Choose a scenario</div>
                <div className={styles.input} onClick={toggleDropdown}>
                    <div className={styles.title}>
                        {isOpen || !selectedScenario ? (
                            <img className={styles.searchIcon} alt="" src={`${process.env.PUBLIC_URL}/Search Icon.svg`}   />
                        ) : (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className={styles.searchIcon}
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                onClick={() => {clearSearchTerm();} }
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        )}
                        <input
                            type="text"
                            className={styles.content}
                            value={isOpen ? searchTerm : displayedScenario?.name || ''}
                            onChange={handleSearchChange}
                            placeholder="Search scenarios..."
                            ref={searchInputRef}
                        />
                    </div>
                    <img className={styles.dropdownIcon} alt=""  src={`${process.env.PUBLIC_URL}/Dropdown Icon.svg`}  />
                </div>
                {isOpen && (
                    <div className={`${styles.dropdownContent} ${isOpen ? styles.show : ''}`}>
                        {filteredScenarios.length === 0 && (
                            <button className={styles.dropdownItem}>
                                No scenarios found
                            </button>
                        )}

                            <Scrollbar 
                                style={{ transition: 'min-height 0.3s ease', minHeight: scrollHeight, height: scrollHeight, overflowX: 'hidden'}} 
                                trackYProps={{ style: { zIndex: '100', bottom: '0px', right: '0px', width: '5px' , backgroundColor: '#E9D7FE' } }} 
                                thumbYProps={{ style: { backgroundColor: '#D6BBFB' }}}
                                trackXProps={{ style: { height: '0px' }}}
                                wrapperProps={{
                                    renderer: props => {
                                    const { elementRef, ...restProps } = props;
                                    return (
                                        <span
                                        {...restProps}
                                        ref={elementRef}
                                        style={{marginBottom: '-5px', position: 'absolute', inset: '0px 0px 5px 0px', }}
                                        />
                                    );
                                    }
                            }}>

                        {filteredScenarios.map((scenario) => (
                            <button
                                key={scenario.id}
                                className={`${styles.dropdownItem} ${
                                scenario.id === selectedScenario?.id ? styles.selectedItem : ''
                                }`}
                                onClick={(e) => {handleScenarioSelector(scenario); e.stopPropagation();}}
                                style={{
                                width: '100%',
                                overflow: 'hidden',
                                width: '-webkit-fill-available',
                                textWrap: 'nowrap',
                                textOverflow: 'ellipsis',
                                }}
                            >
                                {scenario.name}
                            </button>
                            ))}


                        </Scrollbar>
                    </div>
                )}
            </div>

            {/* Subscenario Selector */}
            {isSubscenariosVisible && displayedScenario?.subscenarios.length > 0 && (
                <div ref={subscenarioSelectorRef} className={styles.inputWithLabel}>
                    <div className={styles.label}>Choose a subscenario</div>
                    <div className={styles.input} onClick={toggleDropdownSubscenarios}>
                        <div className={styles.title}>
                            {isSubOpen || !selectedSubscenarioLocal ? (
                                <img className={styles.searchIcon} alt="" src="/Search Icon.svg" />
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className={styles.searchIcon}
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    onClick={clearSubscenario}
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            )}
                            <input
                                type="text"
                                className={styles.content}
                                value={isSubOpen ? searchSubTerm : selectedSubscenarioLocal?.name || ''}
                                onChange={handleSubSearchChange}
                                placeholder="Search subscenarios..."
                                ref={subSearchInputRef} // Ref for the subscenario search input
                            />
                        </div>
                        <img className={styles.dropdownIcon} alt="" src={`${process.env.PUBLIC_URL}/Dropdown Icon.svg`}  />
                    </div>
                    {isSubOpen && (
                        <div className={`${styles.dropdownContent} ${isSubOpen ? styles.show : ''}`}>
                           {filteredSubscenarios.map((subscenario) => (
                                <button
                                    key={subscenario.id}
                                    className={`${styles.dropdownItem} ${
                                    subscenario.id === selectedSubscenarioLocal?.id ? styles.selectedItem : ''
                                    }`}
                                    onClick={() => {handleSubScenarioSelector(subscenario)}}
                                    style={{ width: '100%' }}
                                >
                                    {subscenario.name}
                                </button>
                                ))}

                        </div>
                    )}
                </div>
            )}


            {/* Year Selector */}
            {displayedScenario && (
                <div ref={yearSelectorRef} className={styles.inputWithLabel}>
                    <div className={styles.label}>Choose a year</div>
                    <div className={styles.input} onClick={toggleDropdownYear}>
                        <div className={styles.title}>
                            {isYearOpen || !selectedYear ? (
                                <img className={styles.searchIcon} alt="" src="/Search Icon.svg" />
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className={styles.searchIcon}
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    onClick={clearYear}
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            )}
                            <input
                                type="text"
                                className={styles.content}
                                value={isYearOpen ? searchYearTerm : selectedYear?.name || ''}
                                onChange={() => {}}
                                placeholder="Search year"
                                ref={yearSearchInputRef}
                            />
                        </div>
                        <img className={styles.dropdownIcon} alt="" src={`${process.env.PUBLIC_URL}/Dropdown Icon.svg`}  />
                    </div>
                    {isYearOpen && (
                        <div className={`${styles.dropdownContent} ${isYearOpen ? styles.show : ''}`}>
                           {yearOptions.map((year) => (
                                <button
                                    key={year.id}
                                    className={`${styles.dropdownItem} ${
                                        year.id === selectedYear?.id ? styles.selectedItem : ''
                                    }`}
                                    onClick={() => setYear(year)}
                                    style={{ width: '100%' }}
                                >
                                    {year.name}
                                </button>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default ScenarioSelector;