import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../axiosConfig';

const parseYearMonthPairs = (inputArray) => {
  console.log("inputarray: ", inputArray)

  // Check if the input is a non-empty array
  console.log("inputarray: ", inputArray)
  if (!Array.isArray(inputArray) || inputArray.length === 0) {
    return "";
  }

  // Join the array elements with the desired format
  const parsedString = inputArray.map(pair => `year_month_pairs=${pair}`).join("&");

  // Prepend the "?" to the resulting string
  return parsedString;
}

const transformData = (data) => {
  return data.map((item, index) => ({
    id: item.id,
    Title: `${item.name} ${item.name.includes('DS') ? '' : ''}`,
    isUsingSegment: true,
    alerts: [
      item.alert_relevancies["SAR"],
      item.alert_relevancies["Relevant"],
      item.alert_relevancies["Non Relevant"],
    ],
    total_alerts: item.total_count,
    category_id: item.category_id,
    childs: item.scenarios.map((scenario, childIndex) => ({
      child_id: scenario.scenario_id,
      Title: scenario.name,
      isUsingSegment: true,
      alerts: [
        scenario.alert_relevancies["SAR"],
        scenario.alert_relevancies["Relevant"],
        scenario.alert_relevancies["Non Relevant"],
      ],
      total_alerts: scenario.total_count,
      customer_segments: scenario.customer_segments.map((segment, segmentIndex) => ({
        id: segment.customer_segment_id,
        Title: segment.name,
        alerts: [
          segment.alert_relevancies["SAR"],
          segment.alert_relevancies["Relevant"],
          segment.alert_relevancies["Non Relevant"],
        ],
        total_alerts: segment.alert_relevancies["SAR"] + segment.alert_relevancies["Relevant"] + segment.alert_relevancies["Non Relevant"],
      })),
    }))
  }));
};

export const fetchScenarioOverview = createAsyncThunk(
  'atlTool/fetchScenarioOverview',
  async ({year_month_pairs}) => {
    const year_month_pairs_string = parseYearMonthPairs(year_month_pairs);
    const response = await apiClient.get(`ds-relevancy-counts${year_month_pairs_string === "" ? "" : "?"}${year_month_pairs_string}`);
    return transformData(response.data);
  }
);

export const fetchRecommendations = createAsyncThunk(
  'atlTool/fetchRecommendations',
  async ({scenario_id, subscenario_id, year_month_pairs}) => {
    const year_month_pairs_string = parseYearMonthPairs(year_month_pairs);
    const response = await apiClient.get(`/scenario-performance-suggestion/${scenario_id}/${subscenario_id}?${year_month_pairs_string}${year_month_pairs_string === "" ? "" : "&"}percentage_threshold=0.15`);
    return response.data;
  }
);

const scenarioOverviewSlice = createSlice({
  name: 'scenarioOverview',
  initialState: {
    scenarioGroups: [],
    openedGroups: Array(),
    openedScenarios: Array(),
    selectedScenario: null,
    status: 'idle',
    error: null,
    searchQuery: '',
    selectedSortingRule: 'none',
    sortingRuleReverted: false,
    totalScenarioGroups: 0,
    totalScenarios: 0,
    currentPage: 0,

    requestedAtl: 0,
    requestedBtl: 0,
    requestedLogic: 0,
    recommendationMessage: "",

    selectedYearMonthPairs: ["2022-01", "2022-02", "2022-03"],

    goingAtl: false,

    recommendations: [],
  },
  reducers: {

    setRequestedAtl(state, action) {
      state.requestedAtl = action.payload; // should be {scenario: scenario_id, customer_segment: customer_segment_id}
    },
    setRequestedBtl(state, action) {
      state.requestedBtl = action.payload;
    },
    setRequestedLogic(state, action) {
      state.requestedLogic = action.payload;
    },
    setRecommendationMessage(state, action) {
      state.recommendationMessage = action.payload;
    },
    setSelectedScenario(state, action) {
      state.selectedScenario = action.payload;
    },
    switchOpenedStatus(state, action) {
      if (!state.openedGroups.includes(action.payload)) {
        state.openedGroups.push(action.payload)
      }
      else {
        state.openedGroups.splice(state.openedGroups.indexOf(action.payload), 1);
      }
    },
    switchOpenedScenarioStatus(state, action) {
      if (!state.openedScenarios.includes(action.payload)) {
        state.openedScenarios.push(action.payload)
      }
      else {
        state.openedScenarios.splice(state.openedScenarios.indexOf(action.payload), 1);
      }
    },
    setOpenedGroups(state, action) {
      state.openedGroups = action.payload;
    },
    setOpenedScenarios(state, action) {
      state.openedScenarios = action.payload;
    },
    setSearchQuery(state, action) {
      state.searchQuery = action.payload;
    },
    setSelectedSortingRule(state, action) {
      if (state.selectedSortingRule === action.payload && state.sortingRuleReverted) {
        state.selectedSortingRule = 'none';
        state.sortingRuleReverted = false;
      }
      else if (state.selectedSortingRule === action.payload) {
        state.selectedSortingRule = action.payload;
        state.sortingRuleReverted = true;
      }
      else {
        state.selectedSortingRule = action.payload;
      }
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setTotalScenariosGroups(state, action) {
      state.totalScenarioGroups = action.payload;
    },
    setTotalScenarios(state, action) {
      state.totalScenarios = action.payload;
    },
    clearRecommendations(state) {
      state.recommendations = [];
    },
    setSelectedYearMonthPairs(state, action) {
      console.log("action.payload: ", action.payload);
      console.log("state.selectedYearMonthPairs: ", state.selectedYearMonthPairs);
      state.selectedYearMonthPairs = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchScenarioOverview.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchScenarioOverview.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.scenarioGroups = action.payload;
      })
      .addCase(fetchScenarioOverview.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchRecommendations.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRecommendations.fulfilled, (state, action) => {
        state.recommendations = state.recommendations.concat([action.payload]);
      })
      .addCase(fetchRecommendations.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      ;
  },
});

export const {
  setSelectedScenario,
  switchOpenedStatus,
  switchOpenedScenarioStatus,
  setSearchQuery,
  setRequestedAtl,
  setRequestedBtl,
  setRequestedScenario,
  setRequestedLogic,
  setSelectedSortingRule,
  setCurrentPage,
  setTotalScenariosGroups,
  setTotalScenarios,
  setOpenedGroups,
  clearRecommendations,
  setRecommendationMessage,
  setOpenedScenarios,
  setSelectedYearMonthPairs
} = scenarioOverviewSlice.actions;

export default scenarioOverviewSlice.reducer;