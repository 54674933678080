import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./AlertOverview.module.css";
import InputComponent from "../../atl_tool/alert_table/inputs/InputComponent.js";
import CommentTable from "./CommentTable.js";

import {
  setShownContainer,
} from "../../../features/all_alerts/allAlertsSlice.js";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../layout/buttons/NormalButton.js";

function capitalizeFirstLetter(string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return string;
}

function AlertOverview({alertInfoType}) {
  const dispatch = useDispatch();

  const { selectedAlertDetails } = useSelector(
    (state) => state.allAlerts
  );

  const handleOverlappingAlertsClick = async () => {
    dispatch(setShownContainer(4));
  };

  const getColor = (text) => {
    if (text === null) {
      return "green";
    }
    if (text.toLowerCase() === "non relevant" || text === "low") {
      return "green";
    } else if (text.toLowerCase() === "relevant" || text === "medium") {
      return "yellow";
    } else {
      return "red";
    }
  };

  return (
    <div className={styles.overlay}>
      <div className={styles.popup}>
        <div className={alertInfoType !== "Alert Details" ? styles.hidden : ""}>
          <div className={styles.contentWrapper}>
            <div className={styles.content}>
              {selectedAlertDetails && (
                <Fragment>
                  <InputComponent
                    type="normal"
                    label="Alert ID"
                    value={selectedAlertDetails?.["Alert ID"]}
                    onChange={() => {}}
                    isEditable={false}
                  />
                  <InputComponent
                    type="normal"
                    label="Masked Data"
                    value={selectedAlertDetails?.["Masked Data"]}
                    onChange={() => {}}
                    isEditable={false}
                  />
                  <InputComponent
                    type="normal"
                    label="Creation Time"
                    value={selectedAlertDetails?.["Creation Date"]}
                    onChange={() => {}}
                    isEditable={false}
                  />
                  <InputComponent
                    type="normal"
                    label="Customer Segment"
                    value={selectedAlertDetails?.["Customer Segment"]}
                    onChange={() => {}}
                    isEditable={false}
                  />
                  <InputComponent
                    type="normal"
                    label="Scenario"
                    value={selectedAlertDetails?.["Scenario"]}
                    onChange={() => {}}
                    isEditable={false}
                  />
                  <InputComponent
                    type="normal"
                    label="Alert Type"
                    value={selectedAlertDetails?.["Alert Type"]}
                    onChange={() => {}}
                    isEditable={false}
                  />
                  <InputComponent
                    type="status"
                    label="Risk Score"
                    isEditable={false}
                    badges={[
                      {
                        label: capitalizeFirstLetter(
                          selectedAlertDetails?.["Risk Score"]
                        ),
                        color: getColor(
                          selectedAlertDetails?.["Risk Score"] || ""
                        ),
                      },
                    ]}
                  />
                  <InputComponent
                    type="status"
                    isEditable={false}
                    label="Relevancy"
                    badges={[
                      {
                        label: selectedAlertDetails?.["Alert Relevancy"],
                        color: getColor(
                          selectedAlertDetails?.["Alert Relevancy"] || ""
                        ),
                      },
                    ]}
                  />
                  <InputComponent
                    type="normal"
                    label="Assigned to"
                    value={
                      selectedAlertDetails?.["Assigned To"] || "Not Assigned"
                    }
                    onChange={() => {}}
                    isEditable={false}
                  />
                  <InputComponent
                    type="normal"
                    label="Status"
                    value={selectedAlertDetails?.["Status"]}
                    onChange={() => {}}
                    isEditable={false}
                  />
                </Fragment>
              )}
            </div>
            <div className={styles.textAreaBox}>
              {selectedAlertDetails && (
                <InputComponent
                  type="comment"
                  label="Comment"
                  value={selectedAlertDetails?.["Comment"] || ""}
                  isEditable={false}
                />
              )}
            </div>
            <div className={styles.buttonsBox}>
              <SecondaryButton
                content={"Contibuting Transactions"}
                action={() => dispatch(setShownContainer(3))}
              />
              <PrimaryButton
                content={"Overlapping Alerts"}
                action={handleOverlappingAlertsClick}
              />
            </div>
          </div>
        </div>
        <div className={alertInfoType !== "Audit Log" ? styles.hidden : ""}>
          <CommentTable />
        </div>
      </div>
    </div>
  );
}

export default AlertOverview;
