import { useRef } from "react";

import { FilterButton, SortButton } from "../buttons/IconButton";

export class HeaderClass {
  constructor(label, field, sortable = false, filterable = false, style = {}) {
    this.label = label;
    this.field = field;
    this.sortable = sortable;
    this.filterable = filterable;
    this.style = style;
  }
}

export function HeaderCell({
  headerConf,
  sortOn,
  setSortOn,
  handleOpenFilter,
  handleCloseFilter,
  openFilterField,
}) {
  const { label, field, sortable, filterable } = headerConf;

  const isSorting = sortOn[0] === field;
  const handleSort = () => {
    if (isSorting) {
      const newOrder = sortOn[1] === "ASC" ? "DESC" : "ASC";
      setSortOn([field, newOrder]);
    } else {
      setSortOn([field, "DESC"]);
    }
  };

  const buttonRef = useRef(null);
  const isFilterOpen = openFilterField === field;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}>
      <div>{label}</div>
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          height: "fit-content",
        }}>
        {filterable && (
          <div style={{ position: "relative" }} ref={buttonRef}>
            <FilterButton
              disabled={isFilterOpen}
              onClick={() => {
                if (isFilterOpen) {
                  handleCloseFilter();
                } else {
                  handleOpenFilter(field, buttonRef);
                }
              }}
            />
          </div>
        )}
        {sortable && (
          <SortButton
            disabled={!sortable}
            descending={isSorting ? (sortOn[1] == "DESC" ? true : false) : true}
            active={isSorting}
            onClick={handleSort}
          />
        )}
      </div>
    </div>
  );
}
