import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  VictoryChart,
  VictoryBoxPlot,
  VictoryAxis,
  VictoryTheme,
  VictoryLine,
  VictoryLabel,
  VictoryScatter,
  VictoryZoomContainer,
} from 'victory';
import styles from './../Visualisation.module.css';

const BoxPlotChart = ({ id, showOutliers, xAxis, yAxisLegend }) => {
  const boxPlotData = useSelector((state) => state.atlTool.boxPlotData);
  const visualisations = useSelector((state) => state.atlTool.visualisations);
  const visualisation = visualisations.find((vis) => vis.key === id);
  const thresholdRule = visualisation?.xAxis || "";

  // Helper functions
  const removeTH = (name) => (name.endsWith('_TH') ? name.slice(0, -3) : name);
  const normalizeLabel = (name) =>
    name.replace(/_/g, ' ').replace(/([a-z])([A-Z])/g, '$1 $2').replace(/\b\w/g, (char) => char.toUpperCase());

  const correctThresholdName = removeTH(thresholdRule);
  const data = boxPlotData.find((d) => removeTH(d.threshold_name).toLowerCase() === correctThresholdName.toLowerCase());

  // Hooks for dimensions and zoom
  const chartRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [zoomDomain, setZoomDomain] = useState(null);

  // Update dimensions on resize
  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current) {
        setDimensions({
          width: chartRef.current.offsetWidth,
          height: chartRef.current.offsetHeight - 150,
        });
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Reset zoom when showOutliers changes
  useEffect(() => {
    setZoomDomain(null);
  }, [showOutliers]);

  if (!data) {
    console.warn("pas de data pour l threshold:", boxPlotData);
    return <div>No data for threshold <br></br>correctThresholdName = {correctThresholdName}
    <br></br> thresholdRule = {thresholdRule}
     </div>;
  }

  // Data preparation
  const iqr_list = [
    Math.abs(data.non_relevant_alerts.q3 - data.non_relevant_alerts.q1),
    Math.abs(data.relevant_alerts.q3 - data.relevant_alerts.q1),
    Math.abs(data.sar_alerts.q3 - data.sar_alerts.q1),
  ].filter((v) => v > 0);
  const smallest_zoom = Math.min(...iqr_list);

  const outliersData = [
    ...data.non_relevant_alerts.outliers.map((y) => ({ x: 'Non relevant alerts', y })),
    ...data.relevant_alerts.outliers.map((y) => ({ x: 'Relevant alerts', y })),
    ...data.sar_alerts.outliers.map((y) => ({ x: 'SAR alerts', y })),
  ];

  const threshold = data.threshold_value.value;

  return (
    <div ref={chartRef} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '399px' }}>
      <div style={{ width: '100%', height: '100%' }}>
        <svg style={{ height: 0 }}>
          <defs>
            <linearGradient id="colorGroup1" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" style={{ stopColor: '#A5F2A3', stopOpacity: 1 }} />
              <stop offset="80%" style={{ stopColor: '#4DC842', stopOpacity: 1 }} />
            </linearGradient>
            <linearGradient id="colorGroup2" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" style={{ stopColor: '#FEE4A3', stopOpacity: 1 }} />
              <stop offset="100%" style={{ stopColor: '#F6BA5E', stopOpacity: 1 }} />
            </linearGradient>
            <linearGradient id="colorGroup3" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="6.2%" style={{ stopColor: '#FDBCBC', stopOpacity: 1 }} />
              <stop offset="100%" style={{ stopColor: '#E46F6F', stopOpacity: 1 }} />
            </linearGradient>
          </defs>
        </svg>

        <VictoryChart
          horizontal
          theme={VictoryTheme.material}
          domainPadding={{ x: 80, y: 20 }}
          padding={{ top: -30, bottom: 43, left: 80, right: 69 }}
          width={dimensions.width}
          height={350}
          containerComponent={
            <VictoryZoomContainer
              zoomDomain={zoomDomain}
              onZoomDomainChange={(domain) => setZoomDomain(domain)}
              minimumZoom={{ x: 1, y: smallest_zoom }}
              zoomDimension="y"
            />
          }
        >
          <VictoryAxis
            dependentAxis
            style={{
              axis: { stroke: '#000', strokeWidth: 0.2 },
              tickLabels: { fill: 'var(--Blue-gray-400, #717BBC)', fontSize: 9.109, fontFamily: 'Poppins' },
              grid: { stroke: '#E4E4E4', strokeDasharray: 'none' },
            }}
          />
          <VictoryAxis
            style={{
              axis: { stroke: '#000', strokeWidth: 0.2 },
              ticks: { stroke: 'transparent' },
              tickLabels: { fontSize: 10, padding: 5, fill: 'transparent' },
              grid: { stroke: 'none' },
            }}
          />
          <VictoryBoxPlot
            horizontal
            boxWidth={60}
            whiskerWidth={15}
            animate={{ duration: 100, onLoad: { duration: 100 }, onEnter: { duration: 100 } }}
            data={[
              { x: 'Non relevant alerts', y: 1, ...data.non_relevant_alerts },
              { x: 'Relevant alerts', y: 2, ...data.relevant_alerts },
              { x: 'SAR alerts', y: 3, ...data.sar_alerts },
            ]}
            style={{
              min: { stroke: ({ datum }) => (datum.x === 'Non relevant alerts' ? '#A5F2A3' : datum.x === 'Relevant alerts' ? '#FEE4A3' : '#FDBCBC'), strokeWidth: 2 },
              max: { stroke: ({ datum }) => (datum.x === 'Non relevant alerts' ? '#A5F2A3' : datum.x === 'Relevant alerts' ? '#FEE4A3' : '#FDBCBC'), strokeWidth: 2 },
              q1: { fill: ({ datum }) => (datum.x === 'Non relevant alerts' ? 'url(#colorGroup1)' : datum.x === 'Relevant alerts' ? 'url(#colorGroup2)' : 'url(#colorGroup3)'), stroke: 'none', strokeWidth: 1 },
              q3: { fill: ({ datum }) => (datum.x === 'Non relevant alerts' ? 'url(#colorGroup1)' : datum.x === 'Relevant alerts' ? 'url(#colorGroup2)' : 'url(#colorGroup3)'), stroke: 'none', strokeWidth: 1 },
              median: { stroke: 'white', strokeWidth: 2 },
            }}
          />

          {showOutliers && (
            <VictoryScatter
              data={outliersData}
              animate={{ duration: 100, onLoad: { duration: 100 } }}
              style={{
                data: {
                  fill: ({ datum }) =>
                    datum.x === 'Non relevant alerts'
                      ? 'url(#colorGroup1)'
                      : datum.x === 'Relevant alerts'
                      ? 'url(#colorGroup2)'
                      : 'url(#colorGroup3)',
                  strokeWidth: 2,
                },
              }}
            />
          )}
          <VictoryLine
            style={{ data: { stroke: '#2E90FA', strokeWidth: 2, strokeDasharray: '5,5' } }}
            data={[
              { x: 0, y: threshold },
              { x: dimensions.width, y: threshold },
            ]}
            labels={threshold}
            labelComponent={
              <VictoryLabel
                renderInPortal
                textAnchor="middle"
                dx={-5}
                text={threshold}
                y={0}
                dy={-45}
                style={{ fontFamily: 'Rubik', fontSize: 10, fill: 'rgba(128, 128, 128, 0.8)' }}
              />
            }
          />
          <VictoryLabel
            x={dimensions.width / 2}
            y={342}
            textAnchor="middle"
            text={normalizeLabel(xAxis)}
            style={{ fontFamily: 'Inter', fontSize: 12, fill: 'rgba(128, 128, 128, 0.9)' }}
            className={styles.Scatter_axis_legend}
          />
          <VictoryLabel
            x={20}
            y={dimensions.height / 2}
            textAnchor="middle"
            text={normalizeLabel(yAxisLegend)}
            style={{ fontFamily: 'Inter', fontSize: 12, fill: 'rgba(128, 128, 128, 0.9)' }}
            className={styles.Scatter_axis_legend}
            angle={-90}
          />
        </VictoryChart>
      </div>
    </div>
  );
};

export default BoxPlotChart;
