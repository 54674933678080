import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import styles from "./SearchSelector.module.css"; // Ensure you have the correct path to your CSS file
import Checkbox from "../../../../layout/buttons/Checkbox.js";

// NOTE: localFilter represents whether the function should be dispatched (if false) or just called (if true)
const SearchSelector = ({
  all_options,
  applyFunction,
  title,
  selected_options,
  setSelectedOptions,
  localFilter = false,
}) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [tempSelectedOptions, setTempSelectedOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef(null);

  const toggleOptimisationTypeDropdown = () => {
    if (!isOpen) {
      // Opening the dropdown, initialize tempSelectedOptions
      setTempSelectedOptions(selected_options);
    } else {
      // Closing the dropdown without applying, discard tempSelectedOptions
      setTempSelectedOptions([]);
    }
    setIsOpen(!isOpen);
  };

  const handleOptionChange = (opt_type) => {
    setTempSelectedOptions((prev) =>
      prev.includes(opt_type)
        ? prev.filter((option) => option !== opt_type)
        : [...prev, opt_type]
    );
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredOptions = all_options.filter((opt_type) =>
    opt_type.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const selectAll = () => {
    setTempSelectedOptions([...all_options]);
  };

  const clearAll = () => {
    setTempSelectedOptions([]);
  };

  const applyChanges = () => {
    // Update the parent's selected_options
    setSelectedOptions(tempSelectedOptions);
    // Dispatch your changes or apply the necessary logic here
    if (localFilter) {
      applyFunction(tempSelectedOptions);
    } else {
      dispatch(applyFunction(tempSelectedOptions));
    }
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      // When the dropdown is closed without applying, discard tempSelectedOptions
      setTempSelectedOptions([]);
      setSearchTerm("");
    }
  }, [isOpen]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={styles.dropDownWrapper}
      style={{ width: "260px" }}
      ref={dropdownRef}>
      <div className={styles.dropDown} onClick={toggleOptimisationTypeDropdown}>
        <div className={styles.table_text}>
          <div className={styles.title}>{title}</div>
          <div
            className={
              selected_options.length === 0
                ? styles.inactiveBadge
                : styles.activeBadge
            }>
            {selected_options.length + " selected" || "Select options"}
          </div>
        </div>
        <img
          className={styles.dropDownIcon}
          alt=""
          src={`${process.env.PUBLIC_URL}/chevron-down.svg`}
        />
      </div>
      {isOpen && (
        <div
          className={`${styles.dropdownContent} ${isOpen ? styles.show : ""}`}
          style={{ width: "260px" }}>
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search..."
            className={styles.searchBar}
          />

          <div className={styles.dropdownActions}>
            <div className={styles.selectionActionHolder}>
              <div onClick={selectAll} className={styles.actionButton}>
                Select All
              </div>
              <div onClick={clearAll} className={styles.actionButton}>
                Clear All
              </div>
            </div>
            <div className={styles.searchActionHolder}>
              <div onClick={applyChanges} className={styles.actionButton}>
                Apply
              </div>
            </div>
          </div>

          <div className={styles.dropDownLabel}>{title}</div>

          <div className={styles.selectionHolder}>
            {filteredOptions.map((opt_type, i) => (
              <div key={i} className={styles.dropdownItem}>
                <Checkbox
                  checked={tempSelectedOptions.includes(opt_type)}
                  onChange={() => handleOptionChange(opt_type)}
                  label={opt_type}
                />

              </div>
            ))}
            {filteredOptions.length === 0 && (
              <div className={styles.noResults}>No results found</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchSelector;
