import React, { useEffect, useState , useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../Scenarios.module.css';
import RegularInput from '../../inputs/RegularInput';
import Selector from '../../selector/Selector';
import {
  setSelectedDataLabelId,
  setShowEdit,
  setScenarioActiveDataLabels,
  setScenarioActiveInfoLists,
  setScenarioActiveProfiles,
  setActiveDataSources,
  setSelectedScenario,
  createScenario,
  updateScenario,
  fetchConfigurationScenarios, 
  fetchScenarios, 
} from '../../../../features/configuration/configurationSlice';

const Edit = ({configId}) => {
  const dispatch = useDispatch();

  // Extract necessary data from Redux store
  const {
    dataLabels,
    infoLists,
    scenarioDetails,
    dataSources,
    profiles,
    showEdit,
    timeBaseOptions,
  } = useSelector((state) => state.configuration);

  // Local state for current selections
  const [currentTimeBase, setCurrentTimeBase] = useState(null);
  const [currentDataSource, setCurrentDataSource] = useState(null);
  const [currentInfoList, setCurrentInfoList] = useState([]);
  const [currentDataLabels, setCurrentDataLabels] = useState([]);

  const [badgeSelection, setBadgeSelection] = useState([]);

  const [currentProfiles, setCurrentProfiles] = useState([]);
  const [currentDefinition, setCurrentDefinition] = useState('');
  const [currentName, setCurrentName] = useState([
    {
      type: 'text',
      text: '',
      color: '#5925DC',
      bgColor: 'rgba(181, 120, 255, 0.20)',
      onChange: handleNameChange,
    },
  ]);
  const [currentDescription, setCurrentDescription] = useState([
    {
      type: 'text',
      text: '',
      placeholder: 'Enter description',
      onChange: handleDescriptionChange,
    },
  ]);

  // Option lists for selectors
  const timeBaseOptionsList = timeBaseOptions.map((option, index) => ({
    id: index,
    name: option,
    hasBullet: false,
  }));

  const dataLabelsOptions = dataLabels.map((label) => ({
    id: label.id,
    name: label.name,
  }));

  const profileOptions = profiles.map((profile) => ({
    id: profile.id,
    name: profile.name,
  }));

  // Handler functions
  function handleNameChange(newName) {
    setCurrentName([
      {
        type: 'text',
        text: newName || '',
        color: '#5925DC',
        bgColor: 'rgba(181, 120, 255, 0.20)',
        onChange: handleNameChange,
      },
    ]);
  }

  function handleDescriptionChange(newDescription) {
    setCurrentDescription([
      {
        type: 'text',
        text: newDescription || '',
        placeholder: 'Enter description',
        onChange: handleDescriptionChange,
      },
    ]);
  }

  function handleDefinitionChange(newDefinition) {
    setCurrentDefinition(newDefinition);
  }

  function handleOptionSelect(selector, option) {
    switch (selector) {
      case 'Time Base':
        setCurrentTimeBase(option.name);
        break;
      case 'Data Source':
        setCurrentDataSource(option);
        dispatch(setActiveDataSources([option]));
        break;
      case 'Info List':
        toggleSelection(currentInfoList, setCurrentInfoList, option);
        break;
      case 'Data Label':
        toggleSelection(currentDataLabels, setCurrentDataLabels, option);
        break;
      case 'Profile':
        toggleSelection(currentProfiles, setCurrentProfiles, option);
        break;
      default:
        break;
    }
  }

  function toggleSelection(list, setList, option) {
    const updatedList = list.some((item) => item.id === option.id)
      ? list.filter((item) => item.id !== option.id)
      : [...list, option];
    setList(updatedList);
  }


  const resetView = () => {
    if(configId)
    {
        dispatch(fetchConfigurationScenarios(configId));
    }
    else
    {
        dispatch(fetchScenarios());
    }
    dispatch(setShowEdit(false));
    }


  function handleSaveClick() {

    // Dispatch updated scenario details
    // dispatch(
    //   setSelectedScenario({
    //     ...scenarioDetails,
    //     name: currentName[0]?.text || '',
    //     description: currentDescription[0]?.text || '',
    //     // Include other fields as necessary
    //   })
    // );


        const frequency = currentTimeBase;
        const definition = currentDefinition;
        const info = currentDescription[0].text;
        const weight = "1";
        const label_condition = currentDataLabels[0]?.name;
        const ds_id = currentDataSource?.id;

        const profile_ids =currentProfiles ?  currentProfiles.map((profile) => profile.id) : [];
        const datafield_ids = currentDataLabels ? currentDataLabels.map((label) => label.id) : [];
        const list_ids = currentInfoList ?  currentInfoList.map((item) => item.id) : [];
        const label_ids = currentDataLabels ? currentDataLabels.map((label) => label.id) : [];
        const name = currentName[0].text;



        console.log("values chech   " , name, frequency, definition, info, weight, label_condition, ds_id, profile_ids, datafield_ids, list_ids, label_ids);
        const view_id_activation  =  [
            [
              0,
              false
            ]
          ]; 

       
          console.log("jkfdkljhgldjklgjkljL" , scenarioDetails);

          
        if(scenarioDetails)
        {
            const data = { name: name, frequency: frequency,  definition : definition, info:info, weight:weight, label_condition:label_condition, ds_id:ds_id ,  profile_ids:profile_ids, datafield_ids:datafield_ids, list_ids:list_ids, label_ids:label_ids, view_id_activation:view_id_activation}; 
            dispatch(updateScenario({scenario:data, id:scenarioDetails.id})); // Dispatch the new scenario object to the store
        }
        else
        {
            const newScenario = {scenario:{ name: name, frequency: frequency,  definition : definition, info:info, weight:weight, label_condition:label_condition, ds_id:ds_id },  profile_ids:profile_ids, datafield_ids:datafield_ids, list_ids:list_ids, label_ids:label_ids, view_id_activation:view_id_activation}; // Create a new scenario object
            dispatch(createScenario({scenario:newScenario , configId: configId})); // Dispatch the new scenario object to the store
        }

        resetView();


    if (dataLabels.length > 0) {
      dispatch(setSelectedDataLabelId(dataLabels[0].id));
    }
  }

  // Generate badge props
  const dataLabelConditionProps = generateBadgeProps(
    currentDataLabels,
    '#027A48',
    '#ECFDF3',
    false
  );

  const infoListConditionProps = generateBadgeProps(
    currentInfoList,
    '#B42318',
    '#FEF3F2',
    true
  );

  const profileConditionProps = generateBadgeProps(
    currentProfiles,
    '#026aa2',
    'rgba(2, 106, 162, 0.2)',
    false
  );

  const definitionBadgeProps = currentDefinition
    ? [
        {
          type: 'text',
          text: currentDefinition,
          color: '#B42318',
          bgColor: '#FEF3F2',
          hasBullet: false,
          onChange: handleDefinitionChange,
        },
      ]
    : [];

  // Helper function to generate badge props
  function generateBadgeProps(items, color, bgColor, hasBullet) {
    if (!items || items.length === 0) {
      return [{ type: 'text', text: '' }];
    }
    return items.map((item) => ({
      type: 'badge',
      text: item.name,
      color,
      bgColor,
      hasBullet,
    }));
  }

  // Update badge props and dispatch selections
  useEffect(() => {
    dispatch(setScenarioActiveInfoLists(currentInfoList));
    dispatch(setScenarioActiveProfiles(currentProfiles));
    dispatch(setScenarioActiveDataLabels(currentDataLabels));
  }, [currentDataLabels, currentInfoList, currentProfiles]);

  // Load data when component mounts or scenarioDetails changes
  useEffect(() => {
    if (scenarioDetails) {
      // Set initial values based on scenarioDetails
      setCurrentTimeBase(scenarioDetails.frequency || null);
      setCurrentName([
        {
          type: 'text',
          text: scenarioDetails.name || '',
          color: '#5925DC',
          bgColor: 'rgba(181, 120, 255, 0.20)',
          onChange: handleNameChange,
        },
      ]);
      setCurrentDescription([
        {
          type: 'text',
          text: scenarioDetails.description || '',
          placeholder: 'Enter description',
          onChange: handleDescriptionChange,
        },
      ]);
      setCurrentDefinition(scenarioDetails.definition || '');

      // Set selected data source
      const selectedDataSource = dataSources.find(
        (ds) => ds.id === scenarioDetails.data_source?.id
      );
      setCurrentDataSource(selectedDataSource);
      dispatch(setActiveDataSources([selectedDataSource]));

      // Set selected info lists
      const selectedInfoLists = extractItems(
        scenarioDetails.scenario_lists,
        'list'
      );
      setCurrentInfoList(selectedInfoLists);

      // Set selected profiles
      const selectedProfiles = extractItems(
        scenarioDetails.scenario_profiles,
        'profile_field'
      );
      setCurrentProfiles(selectedProfiles);

      // Set selected data labels
      const selectedDataLabels = dataLabels.filter((label) =>
        scenarioDetails.datalabels?.some((dl) => dl.id === label.id)
      );
      setCurrentDataLabels(selectedDataLabels);
    } else {
      // Reset state when scenarioDetails is null
      resetState();
    }
  }, [scenarioDetails, dataSources]);

  // Reset state when showEdit changes
  useEffect(() => {
    if (!showEdit) {
      resetState();
    }
  }, [showEdit]);

  // Helper functions
  function extractItems(scenarioItems, key) {
    if (!Array.isArray(scenarioItems)) return [];
    return scenarioItems.flatMap((itemList) =>
      Array.isArray(itemList)
        ? itemList.flatMap((item) =>
            item?.[key] ? [{ id: item[key].id, name: item[key].name }] : []
          )
        : []
    );
  }

  function resetState() {
    setCurrentTimeBase(null);
    setCurrentDataSource(null);
    setCurrentInfoList([]);
    setCurrentDataLabels([]);
    setCurrentProfiles([]);
    setCurrentDefinition('');
    setCurrentName([
      {
        type: 'text',
        text: '',
        color: '#5925DC',
        bgColor: 'rgba(181, 120, 255, 0.20)',
        onChange: handleNameChange,
      },
    ]);
    setCurrentDescription([
      {
        type: 'text',
        text: '',
        placeholder: 'Enter description',
        onChange: handleDescriptionChange,
      },
    ]);
    dispatch(setSelectedScenario(null));
  }

  
  function makeDataLabelBadges(datalabels)
  {

    console.log("badgesSelection  2323", datalabels);

    if(!datalabels || datalabels.length === 0)
    {
      return [];
    }
    const dataLabels = datalabels.map((label) => ({
      id: label.id,
      text: label.name,
      color: '#027A48',
      bgColor: '#ECFDF3',
      hasBullet: true,

    }));
    console.log("badgesSelection 242424", dataLabels);

    return dataLabels;
  }


  const data = useMemo(() => [
    ...(currentDataSource ? [{
      type: 'badge',
      text: currentDataSource.name,
      color: '#027A48',
      bgColor: '#ECFDF3',
      hasBullet: true,
    }] : []),
    ...(infoListConditionProps || []),
    ...(currentDataLabels || []),
    ...(dataLabelConditionProps ? dataLabelConditionProps.map((label) => ({
      id: label.id,
      text: label.text,
      color: '#027A48',
      bgColor: '#ECFDF3',
      hasBullet: true,
    })) : []),
    ...(profileConditionProps || []),
  ], []);
  
  
useEffect(() => {


  const filteredData = data.filter((badge) => badge.text.trim() !== '' && badge.text.trim() !== 'undefined');

  setBadgeSelection(filteredData);
}, []);
  


  
  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <div className={styles.leftContainer}>
          <div className={styles.text}>Edit Scenario</div>

          <Selector
            color="#027A48"
            bgColor="#ECFDF3"
            options={dataSources.map((source) => ({
              id: source.id,
              name: source.name,
            }))}
            selectedOption={
              currentDataSource
                ? [
                    {
                      type: 'badge',
                      text: currentDataSource.name,
                      color: '#027A48',
                      bgColor: '#ECFDF3',
                      hasBullet: true,
                    },
                  ]
                : [{ type: 'text', text: 'Not Selected' }]
            }
            onOptionSelect={(option) => handleOptionSelect('Data Source', option)}
            label="Data Source"
            hasBullet={true}
          />

          <Selector
            color="#B42318"
            bgColor="#FEF3F2"
            options={infoLists.map((list) => ({
              id: list.id,
              name: list.name,
            }))}
            selectedOption={infoListConditionProps}
            onOptionSelect={(option) => handleOptionSelect('Info List', option)}
            hasBullet={true}
            label="Parameter List"
          />

          <Selector
            color="#027A48"
            bgColor="#ECFDF3"
            options={dataLabelsOptions}
            selectedOption={dataLabelConditionProps}
            hasBullet={false}
            onOptionSelect={(option) => handleOptionSelect('Data Label', option)}
            label="Data Labels"
          />

          <Selector
            color="rgba(94, 110, 255, 0.80)"
            bgColor="transparent"
            options={timeBaseOptionsList}
            hasBullet={false}
            selectedOption={
              currentTimeBase
                ? [
                    {
                      type: 'badge',
                      text: currentTimeBase,
                      color: 'rgba(94, 110, 255, 0.80)',
                      bgColor: 'transparent',
                    },
                  ]
                : [{ type: 'text', text: '' }]
            }
            onOptionSelect={(option) => handleOptionSelect('Time Base', option)}
            label="Time Base"
          />

          <Selector
            color="#026aa2"
            bgColor="rgba(2, 106, 162, 0.2)"
            options={profileOptions}
            hasBullet={false}
            selectedOption={profileConditionProps}
            onOptionSelect={(option) => handleOptionSelect('Profile', option)}
            label="Profiles"
          />
        </div>

        <div className={styles.rightContainer}>
          <RegularInput
            title="Name"
            withBackground={true}
            isEditable={true}
            isBadgeEditor={false}
            content={currentName}
          />

          <RegularInput
            title="Data Labels"
            withBackground={true}
            isEditable={true}
            content={dataLabelConditionProps}
          />

          <RegularInput
            title="Info Lists"
            withBackground={true}
            isEditable={true}
            content={infoListConditionProps}
          />

          <RegularInput
            title="Profiles"
            withBackground={true}
            isEditable={true}
            content={profileConditionProps}
          />

          <RegularInput
            title="Description"
            withBackground={true}
            isEditable={true}
            content={currentDescription}
          />

          <RegularInput
            title="Definition"
            withBackground={true}
            isEditable={true}
            isBadgeEditor={true}
            isScenario={true}
            badgesSelection={badgeSelection}
            content={definitionBadgeProps}
          />
        </div>
      </div>

      <div className={styles.footer}>
        <div
          className={`${styles.buttonBox} ${styles.whiteButton}`}
          onClick={() => dispatch(setShowEdit(false))}
        >
          <div className={styles.textCancel}>Cancel</div>
        </div>

        <div
          className={`${styles.buttonBox} ${styles.purpleButton}`}
          onClick={handleSaveClick}
        >
          <div className={styles.textEdit}>Save</div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
