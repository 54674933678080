import styles from "./Checkbox.module.css";

function Checkbox({
  checked,
  onChange,
  disabled = false,
  label = undefined,
  labelStyle = undefined,
}) {
  const handleClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className={`${styles.checkboxLabelContainer} ${disabled && styles.disabled}`} onClick={handleClick}>
      <label className={styles.checkboxContainer}>
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          className={styles.checkbox}
        />
        <span>
          {checked && (
            <img
              className={styles.checkIcon}
              alt=""
              src={`${process.env.PUBLIC_URL}/check.svg`}
            />
          )}
        </span>
      </label>
      {label && (
        <div style={labelStyle} className={styles.label}>
          {label}
        </div>
      )}
    </div>
  );
}

export default Checkbox;
