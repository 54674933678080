// HeaderSection.js
import React from 'react';
import styles from './HeaderSection.module.css'; // Ensure you have the correct path to your CSS module
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image-more';
import OptionButton from './buttons/OptionButton';



//  import { useDispatch } from 'react-redux';
const HeaderSection = ({ SectionName, Count,Type, AdditionalText ,CanAddToReport , CaptureRef,CaptureSectionName}) => {
  return (
    <div className={styles.headerSection}>
       <div className={styles.titleCount}>
          <div className={styles.kycSegmentRisk}>{SectionName}</div>
          {Count > 0 &&   <div className={styles.count}>{Count} results</div> }
      </div>
     

      {AdditionalText && <div className={styles.additionalText}>

        <span style={{ position:"relative", alignSelf:"center", justifySelf:"center" }}>
          { AdditionalText }
        </span>
        {CanAddToReport && 
        <OptionButton CanAddToReport={CanAddToReport} Type={Type} CaptureRef={CaptureRef} CaptureSectionName={CaptureSectionName} />
          }
     
        </div>}
    </div>
  );
};

export default HeaderSection;
