import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { HeaderClass } from "../../../layout/table/Header";
import TableGrid from "../../../layout/table/Table";
import { CellClass, RowCellClass } from "../../../layout/table/Cell";

const ContributingTable = () => {
  const { contributingTransactions, selectedAlert, selectedAlertDetails } =
    useSelector((state) => state.allAlerts);

  const [rerenderTable, setRerenderTable] = useState(1);

  useEffect(() => {
    setRerenderTable(rerenderTable + 1);
  }, [selectedAlert]);

  const headers = [
    new HeaderClass("Transaction ID", "Transaction ID", true, false, {
      minWidth: 200,
    }),
    new HeaderClass("Transaction Date", "Transaction Date", true, true, {
      minWidth: 140,
      maxWidth: 140,
      width: 140,
    }),
    new HeaderClass("Account ID", "Account ID", true, true, {
      minWidth: 140,
      maxWidth: 140,
      width: 140,
    }),
    new HeaderClass("Debit/Credit", "Debit-Credit", true, true, {
      minWidth: 140,
      maxWidth: 140,
      width: 140,
    }),
    new HeaderClass("Base Amount", "Base Amount", true, true, {
      minWidth: 140,
    }),
    new HeaderClass("Transaction Type", "Transaction Type", true, true, {
      minWidth: 140,
    }),
    new HeaderClass("Transaction Chanel", "Transaction Chanel", true, true, {
      minWidth: 150,
    }),
    new HeaderClass("Description", "Description", true, true, {
      minWidth: 250,
      maxWidth: 250,
    }),
  ];

  const rows = contributingTransactions.map((rowData) => {
    const allertId = rowData.id;
    const cells = headers.map((header) => {
      const value = rowData[header.field];
      return new CellClass(value, header.field);
    });

    return new RowCellClass(allertId, cells);
  });

  return (
    <TableGrid
      title={`Contributing transactions of ${selectedAlertDetails["Alert ID"]}`}
      headers={headers}
      rows={rows}
      resetTable={rerenderTable}
      cellStyle={{ minHeight: 50 }}
    />
  );
};

export default ContributingTable;
