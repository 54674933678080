import React, { useRef } from 'react';
import { HeatMap } from '@nivo/heatmap';
import style from './MatrixOverlap.module.css';
import { useNavigate } from "react-router-dom";

const Heatmap = ({ data, keys, isPercentage, handleClick }) => {
  const navigate = useNavigate();


  // Create a new data structure where y is dynamically set to abs or percentage
  const processedData = data.map(d => ({
    name: d.name,  // Use the row name here
    id: d.name,    // Set the 'id' to the row name

    data: d.data.map(item => ({
      ...item,
      y: isPercentage ? item.percentage : item.abs, // Set y based on isPercentage flag
    }))
  }));

  const ids = processedData.map(item => item.name);
  const containerRef = useRef(null);
  // TODO: fix hover with duplicate keys
  // console.log('heatmapp ids: ', ids)
  
  // console.log('heatmapp keys: ', keys)
  
  const allValues = processedData.flatMap(d => d.data.map(item => item.y));
  const minValue = Math.min(...allValues);
  const maxValue = Math.max(...allValues);

  const calculateOpacity = (value) => {
    if (maxValue === minValue) {
      return 1;
    }
    return 0.1 + ((value - minValue) / (maxValue - minValue)) * 0.6;
  };

  const colorFunction = ({ value }) => {
    if (value === 0) {
      return 'rgba(255, 255, 255, 1)';
    }
    const opacity = calculateOpacity(value);
    return `rgba(170, 149, 199, ${opacity})`;
  };

   


  


  return (
    <div ref={containerRef} style={{ display: 'flex' }}>
      <div>
        {ids.map((id, index) => (
          <div key={index} className={style.headerTextVertical} style={{borderTop:"1px solid #D9D6FE",borderBottom:"1px solid #D9D6FE",borderLeft:"0px solid #D9D6FE!important", borderRight:"0px solid #D9D6FE!important",    height: '70px', width: '250px', display: 'flex', alignItems: 'center', justifyContent: 'center',  borderBottom: '1px solid var(--Brand-200, #E9D7FE)' }}>
            {id}
          </div>
        ))}
      </div>
      <div style={{ width: `${keys.length * 200}px`, height: `${data.length * 70}px` }}>


        <HeatMap
          width={keys.length * 200}
          height={data.length * 71}
          data={processedData}
          keys={keys}
          indexBy="id"
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          valueFormat={isPercentage ? "> .2%" : ""}
          axisTop={null}
          axisRight={null}
          axisBottom={null}
          axisLeft={null}
          colors={colorFunction}
          emptyColor="#ffffff"
          cellTextColor="#6941C6"
       
         
          onClick={(params) => handleClick(params)}
          legends={[]}
          labelTextColor="#6941C6"
          theme={{
            labels: {
              text: {
                fill: '#6941C6',
              },
            },
           
              
         
          
          }}
        />
      </div>
    </div>
  );
};

export default Heatmap;
