import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../Labels.module.css';
import LabelList from '../../LabelList';
import RegularInput from '../../inputs/RegularInput';
// import CustomEditor from '../../inputs/CustomEditor';
import BadgeEditor from '../../inputs/BadgeEditor';

import {
    fetchDataLabelDetails,
    fetchInfoLists,
    setSelectedDataLabelId,
    setSelectedDataLabelsDetails,
    setShowEdit,
    createNewDataLabelDetails, 
    fetchViewOptions, 
    fetchCustomerSegments, 

    fetchRiskLevelOptions, 
} from '../../../../features/configuration/configurationSlice';

const Overview = () => {

    const dispatch = useDispatch();

    // fetch needed data from database
    useEffect(() => {
        // dispatch(fetchInfoLists());
        // dispatch(fetchViewOptions());
        // dispatch(fetchRiskLevelOptions());
        // dispatch(fetchCustomerSegments());

    }, [])

    const {dataLabels} = useSelector((state) => state.configuration)

    const setLabels = () => {
      return true;
    }

    // by default, set the first item from the list as the selected one
    useEffect(() => {
      if(dataLabels.length > 0){
        dispatch(setSelectedDataLabelId(dataLabels[0].id));
      }
    }, [dataLabels])

    // get needed data from slice
    const {selectedDataLabelId, selectedDataLabelDetails} = useSelector((state) => state.configuration);

    // if valid id, fetch the details for that label
    useEffect(() => {
      if (selectedDataLabelId > -1){
        dispatch(fetchDataLabelDetails({dataLabelId: selectedDataLabelId}));
      }

    }, [selectedDataLabelId])

    // properties for the name badge
    const nameBadgeProps = [
      selectedDataLabelDetails ? {
        type: "badge",
        text: `${selectedDataLabelDetails.name}`,
        color: "#5925DC",
        bgColor: "rgba(181, 120, 255, 0.20)",
        hasBullet: false,
      } : {
        type: "text",
        text: "",
        placeholder: "",
      }
    ];

    // properties for the data source badge
    const dataSourceProps = [
      selectedDataLabelDetails ?{
        type: "badge",
        text: `${selectedDataLabelDetails.data_source.name}`,
        color: "#027A48",
        bgColor: "#ECFDF3",
        hasBullet: selectedDataLabelDetails,
      } : {
        type: "text",
        text: "",
        placeholder: "",
      }
    ]

    // parse the info lists to usefull data
    const generateInfoListProps = (list) => {
      // console.log("listlist: ", list)
      // console.log("this is list: ", list)
      if (!list || list.length === 0){
          return [{
              type: 'text',
              text: '',
          }]
      }

      else {
          var result = [];
          list.map((label) => {
              result.push({
                  type: 'badge',
                  text: label.name,
                  color: "#B42318",
                  bgColor: "#FEF3F2",
                  hasBullet: 'false',
              })
          })

          return result;
      }
  };

  // properties of the info lists
  const [infoListBadgeProps, setInfoListBadgeProps] = useState([])

  useEffect(() => {
    if (selectedDataLabelDetails){
      var newInfoList = generateInfoListProps(selectedDataLabelDetails.list);
      setInfoListBadgeProps(newInfoList);
    }
  }, [selectedDataLabelDetails])

    // properties for the description badge
    const descriptionBadgeProps = [
      selectedDataLabelDetails ? {
        type: "text",
        text: `${selectedDataLabelDetails ? selectedDataLabelDetails.description : ''}`,
        placeholder: "Enter description",
        onChange: (e) => console.log(e.target.value),
      } : {
        type: "text",
        text: "",
        placeholder: "",
      }
    ]

    // TODO: make definition nice when editing works
    // properties for definition badge
    const definitionBadgeProps = [
      selectedDataLabelDetails ? {
        type: "text",
        text: `${selectedDataLabelDetails.definition}`,
        color: "#B42318",
        bgColor: "#FEF3F2",
        hasBullet: false,
      }
      :
      {
        type: "text",
        text: "",
        placeholder: "",
      }
    ]

    // when clicking on the plus (adding a label)
    const handlePlusClick = (() => {
        dispatch(setSelectedDataLabelId(-1));
        dispatch(setSelectedDataLabelsDetails(null))
        dispatch(setShowEdit(true));
    })

    // go to the edit container
    const handleEditClick = (() => {
        dispatch(setShowEdit(true))
    })

    // select a new label
    const handleLabelClick = (label) => {
        dispatch(setSelectedDataLabelId(label.id))
    };

    // properties for the label list
    const palette = {
      borderColor: '#8A42FF',
      backgroundColor: 'rgba(181, 120, 255, 0.20)',
      textColor: '#7F56D9',
      selectedBackgroundColor: 'rgba(181, 120, 255, 0.50)'
    };

    

    return (
        <div className={styles.container}>

            <div className={styles.contentWrapper}>

                <div style={{ width: '25%', minWidth: '300px', maxWidth: '400px' }}>
                  <LabelList
                      selectedId={selectedDataLabelId}
                      labels={dataLabels}
                      setLabels={setLabels}
                      title={"Labels List"}

                      palette={palette}
                      onPlusClick={() => handlePlusClick()}


                      handleLabelClick={(label) => handleLabelClick(label)}
                  />
                </div>

                <div style={{ width: '75%' }}>
                  <div className={styles.rightContainer}>
                      <RegularInput   title={"Name"}    withBackground={true}   content={nameBadgeProps} />
                      <RegularInput   title={"Data Source"}    withBackground={false}   content={dataSourceProps} />
                      {selectedDataLabelDetails && selectedDataLabelDetails.list.length > 0 && (
                        <RegularInput title={"Info List"}    withBackground={false}   content={infoListBadgeProps} />
                      )}
                      <RegularInput   title={"Description"}    withBackground={true} isEditable={false}   content={descriptionBadgeProps} />
                      <RegularInput   title={"Definition"}    withBackground={true} isEditable={false} isBadgeEditor={true}  content={definitionBadgeProps} />
                      {/* <BadgeEditor key={selectedDataLabelDetails?.id ?? -1} isEdit={false}/> */}

                  </div>
                </div>

            </div>
            
            <div className={styles.footer}>

                {/* <div className={`${styles.buttonBox} ${styles.whiteButton}`}>
                    <div className={styles.textCancel}>
                        Cancel
                    </div>
                </div> */}

                <div className={`${styles.buttonBox} ${styles.purpleButton}`} onClick={() => handleEditClick()}>
                    <div className={styles.textEdit}>
                        Edit
                    </div>
                </div>

            </div>

        </div>
    );
};

export default Overview;
