import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchReportById, updateReport , cleanReport} from '../../../features/report_builder/reportBuilderSlice';
import styles from './ReportBuilder.module.css';
import common_styles from "../../atl_tool/AtlTool.module.css";
// import HeaderSection from "../../../layout/HeaderSection";
import html2pdf from 'html2pdf.js';
import { useNavigate } from 'react-router-dom';
import ReportElements from './ReportElements';
import {
  notifySuccess,
  notifyError,
} from "../../../layout/ToastNotifications.jsx";
import EditableTitle from './EditableTitle';

const ReportBuilder = () => {
  const dispatch = useDispatch();
  const { id } = useParams(); 
  const selectedReport = useSelector((state) => state.reportBuilder.selectedReport);
  const navigate = useNavigate();
  const [localReportJson, setLocalReportJson] = useState(null);
  const [localReportName, setLocalReportName] = useState(''); // Añadido
  const titleRef = useRef(null);

  useEffect(() => {
    if (!id) {
      navigate('/reports');
    } else if (id && (!selectedReport || selectedReport.id !== parseInt(id))) {
      dispatch(fetchReportById(id));
    } else if (selectedReport) {
      setLocalReportJson(selectedReport.report_json);
      setLocalReportName(selectedReport.name); // Añadido
    }

    dispatch(cleanReport());
  }, [id, selectedReport, dispatch, navigate]);

  const handleUpdateReportJson = (updatedJson) => {
    setLocalReportJson(updatedJson);
  };
  
  const handleTitleInput = (e) => {
    setLocalReportName(e.target.innerText);
  };

  const handleSave = () => {
    if (!selectedReport) return;
  
    const currentTitle = titleRef.current ? titleRef.current.getTitle() : localReportName;
  
    dispatch(updateReport({
      reportId: selectedReport.id,
      reportData: {
        ...selectedReport,
        report_json: localReportJson,
        name: currentTitle,
      },
      scenarioIds: selectedReport.report_scenarios.map(scenario => scenario.scenario_id),
    }))
    .unwrap()
    .then(() => {
      notifySuccess("Report saved successfully.");
    })
    .catch((error) => {
      notifyError("Failed to save report.");
    });
  };
  
  


  const handleExportToPDF = () => {
    document.querySelectorAll('.no-print').forEach(element => {
        element.style.display = 'none';
    });

    const element = document.getElementById('reportContent');
    const opt = {
      margin: [5, 5, 5, 5],
      filename: `Report_${new Date().toISOString().split('T')[0]}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2.5, useCORS: true },
      jsPDF: { unit: 'mm', format: 'a3', orientation: 'landscape' },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'], before: '.page-break', avoid: '.avoid-page-break' },
    };

    // Crear el PDF y agregar paginación centrada en el pie de página
    html2pdf().set(opt).from(element).toPdf().get('pdf').then((pdf) => {
      const totalPages = pdf.internal.getNumberOfPages();

      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(10);

        const pageWidth = pdf.internal.pageSize.getWidth();
        const text = `Page ${i} of ${totalPages}`;
        const textWidth = pdf.getTextWidth(text);

        // Centrar el texto horizontalmente
        pdf.text(text, (pageWidth - textWidth) / 2, pdf.internal.pageSize.getHeight() - 10);
      }
    }).save().then(() => {
      document.querySelectorAll('.no-print').forEach(element => {
        element.style.display = '';
      });
    });
};

  
  if (!selectedReport) {
    return <div>Loading report...</div>;
  }
    return (

        <div className={styles.atlTool} id="reportContent">
          <div  className='no-print'>
                
            <div className={styles.reportActionContainer}>

                <div onClick={()=>{handleSave()}} className={styles.reportButton}>
                  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24.4989 27.0664L15.4989 21.9236L6.49887 27.0664V6.49501C6.49887 5.81303 6.76979 5.15897 7.25202 4.67674C7.73426 4.1945 8.38831 3.92358 9.0703 3.92358H21.9274C22.6094 3.92358 23.2635 4.1945 23.7457 4.67674C24.228 5.15897 24.4989 5.81303 24.4989 6.49501V27.0664Z" stroke="#E9D7FE" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>

                <div onClick={()=>{handleExportToPDF()}} className={styles.reportButton} >
                  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_3452_12079)">
                      <path d="M10.356 21.9234L15.4989 27.0663M15.4989 27.0663L20.6418 21.9234M15.4989 27.0663V15.4949M26.916 23.3249C28.0338 22.5388 28.8721 21.4171 29.3091 20.1223C29.7462 18.8276 29.7593 17.4273 29.3466 16.1246C28.9338 14.822 28.1167 13.6847 27.0138 12.8779C25.911 12.071 24.5797 11.6366 23.2132 11.6377H21.5932C21.2065 10.1307 20.483 8.73103 19.4771 7.5441C18.4711 6.35717 17.2091 5.41391 15.7859 4.78531C14.3627 4.15672 12.8154 3.85918 11.2606 3.91509C9.70574 3.97101 8.18384 4.37892 6.80947 5.10812C5.43509 5.83732 4.24404 6.8688 3.326 8.12492C2.40795 9.38104 1.78682 10.8291 1.50938 12.36C1.23193 13.8909 1.3054 15.4648 1.72424 16.9632C2.14309 18.4616 2.8964 19.8454 3.92747 21.0106" stroke="#E9D7FE" stroke-width="1.39" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                  <defs>
                    <clipPath id="clip0_3452_12079">
                      <rect width="30.8571" height="30.8571" fill="white" transform="translate(0.0703125 0.0664062)"/>
                    </clipPath>
                  </defs>
                  </svg>
                </div>

                <div className={styles.reportButton}>
                    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.92746 7.78076H27.0703" stroke="#E9D7FE" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M24.4994 7.78076V25.7808C24.4994 27.0665 23.2137 28.3522 21.928 28.3522H9.07082C7.7851 28.3522 6.49939 27.0665 6.49939 25.7808V7.78076" stroke="#E9D7FE" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M10.3565 7.78275V5.21132C10.3565 3.92561 11.6423 2.63989 12.928 2.63989H18.0708C19.3565 2.63989 20.6423 3.92561 20.6423 5.21132V7.78275" stroke="#E9D7FE" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
        </div>
 
        <div className={common_styles.sectionWrapper}>
            <EditableTitle
              ref={titleRef}
              initialTitle={localReportName}
            />
          
            {localReportJson && (
              <ReportElements
                reportJson={localReportJson}
                isEditable={true}
                onUpdate={handleUpdateReportJson}
              />
          )}

        </div>
       
      </div>

        
    );
}
 
export default ReportBuilder;