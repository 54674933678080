import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../Parameters.module.css";
import tableStyles from "../ParametersTable.module.css";
import ModalPopUp from "../../../../layout/modal/Modal";
import InputComponent from "../../../atl_tool/alert_table/inputs/InputComponent";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../../layout/buttons/NormalButton";
import { RoundBackButton } from "../../../../layout/buttons/RoundIconButton";

import Selector from "../../selector/Selector";
import {
  setColumnUpdated,
  setShowEdit,
  updateParameterListColumn,
  createParameterListColumn,
} from "../../../../features/configuration/configurationSlice";
import FilterSelector from "../../../overlap_analysis/alerts_overlap/selectors/FilterSelector";
import Badge from "../../badges/Badge";

import Scrollbar from "react-scrollbars-custom";

const Overview = () => {
  const dispatch = useDispatch();
  // get needed data from slice
  const { parameters, selectedParameterId, selectedParameterDetails, selectedParameterColumns: rows } =
    useSelector((state) => state.configuration);

  const scrollWrapperRef = useRef(null);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });

  const sortedRows = React.useMemo(() => {
    if (!sortConfig.key) return rows;

    return [...rows].sort((a, b) => {
      const order = a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
      return sortConfig.direction === "ascending" ? order : -order;
    });
  }, [rows, sortConfig]);

  useEffect(() => {
    if (scrollWrapperRef.current) {
      // setScrollHeight(scrollWrapperRef.current.clientHeight);
    }
  }, [scrollWrapperRef.current]);

  // go back to overview container
  const handleSaveClick = () => {
    console.log("selectedRow:", selectedRow);

    const updatedRow = {
      ...selectedRow,
      name: editedName.trim() || selectedRow.name,
      data_type: editedType?.[0]?.text || selectedRow.data_type,
      description: editedDescription.trim() || selectedRow.description,
    };

    console.log("updatedRow:", updatedRow);

    dispatch(updateParameterListColumn({ editedRow: updatedRow }));
    dispatch(setShowEdit(false));
    resetEditedValues();
  };

  const [newParameterColumnListName, setNewParameterColumnListName] =
    useState("");
  const [newParameterColumnListDesc, setNewParameterColumnListDesc] =
    useState("");
  const [newParameterColumnListType, setNewParameterColumnListType] = useState([
    { text: "" },
  ]);

  const canCreate =
    newParameterColumnListName.length > 0 &&
    (newParameterColumnListType.length === 1 &&
      newParameterColumnListType[0].text !== "");

  const resetEditedValues = () => {
    setEditedName("");
    setEditedType("");
    setEditedDescription("");
    setSelectedRow(null);
  };

  const handleColumnCreateCancel = () => {
    setNewParameterColumnListName("");
    setNewParameterColumnListDesc("");
    setNewParameterColumnListType([{ text: "" }]);
    setIsAddingRow(false);
  };

  const handleColumnCreate = async () => {
    const type = translateTypeDict[newParameterColumnListType[0].text]
    const response = await dispatch(
      createParameterListColumn({
        name: newParameterColumnListName,
        description: newParameterColumnListDesc,
        dataType: type,
        listId: selectedParameterId,
      })
    );
    if (!response.error){
      dispatch(setColumnUpdated(true))
      handleColumnCreateCancel();
    }

  };

  const [isAddingRow, setIsAddingRow] = useState(false);
  const onAddClick = () => {
    setIsAddingRow(true);
    console.log("add clicked");
  };

  const [selectedRow, setSelectedRow] = useState(null);

  const getSelectedOption = (row) => {
    console.log("getselectedoption:", row);
    console.log("selectionsOptions:", selectionsOptions);

    const foundOption = selectionsOptions.find(
      (option) =>
        option.name.toLocaleUpperCase() === row["data_type"].toLocaleUpperCase()
    );

    if (!foundOption) return null;

    return [
      {
        type: "badge",
        text: foundOption.name,
        color: "#b54708",
        bgColor: "#fffaeb",
        hasBullet: false,
      },
    ];
  };

  const handleRowClick = (row) => {
    setEditedName("");
    setEditedType("");
    setEditedDescription("");

    const isSameRow = row === selectedRow;
    setSelectedRow(isSameRow ? null : row);

    if (!isSameRow) {
      setDataLabelConditionProps(getSelectedOption(row));
    }
    console.log("selected row: ", row);
    console.log(selectionsOptions);
  };

  const onFilterClick = (index) => {
    const newOpenStates = openStates.slice();
    newOpenStates[index] = !openStates[index];
    setOpenStates(newOpenStates);
  };

  function capitalizeWords(text) {
    return text
      .split(" ")
      .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  // sort depending on sortconfig which is set by clicking the arrows in the headercells
  const handleSort = (columnKey) => {
    const direction =
      sortConfig.key === columnKey && sortConfig.direction === "ascending"
        ? "descending"
        : "ascending";
    setSortConfig({ key: columnKey, direction });
  };

  const columnsConfig = [
    { field: "name", headerName: "Name" },
    { field: "type", headerName: "Type", renderCell: "Badge" },
    { field: "description", headerName: "Description" },
  ];

  const [openStates, setOpenStates] = useState([]);

  // set the state correctly to a list with as many columns as there is in columnsConfig

  let newOpenStates = [];

  if (openStates.length !== columnsConfig.length) {
    console.log("effect used");
    newOpenStates = Array(columnsConfig.length).fill(false);
  } else {
    newOpenStates = openStates;
  }

  if (newOpenStates !== openStates) {
    setOpenStates(newOpenStates);
  }

  const selectionsOptions = [
    { id: 1, name: "Text"},
    { id: 2, name: "Number" },
    { id: 3, name: "Boolean"},
  ];

  const translateTypeDict = {
    'Text': "TEXT",
    'Number': "NUMBER",
    'Boolean': "BOOLEAN"
  }

  // properties of the datalabels
  const [dataLabelConditionProps, setDataLabelConditionProps] = useState([]);

  const [editedName, setEditedName] = useState("");
  const [editedType, setEditedType] = useState("");
  const [editedDescription, setEditedDescription] = useState("");

  console.log("openStates: ", openStates);

  const allColumns = columnsConfig.map(
    ({ field, headerName, renderCell }, index) => ({
      field,
      headerName,
      headerClassName: tableStyles.headerCell,
      renderHeader: () => {
        const isAlertID = headerName === "Alert ID";
        const isSortActive = sortConfig.key === field;
        const sortDirection =
          isSortActive && sortConfig.direction === "ascending" ? "↓" : "↑";
        const filterButtonOpacity = openStates[index] ? 0 : 1;
        const borderStyle = isAlertID ? "1px solid #e0e0e0" : "none";
        const minWidthStyle = isAlertID ? "152px" : "none";
        const sortButtonOpacity = isSortActive ? 1 : 0.5;
        const sortDirectionStyle =
          sortConfig.direction === "ascending" ? "descending" : "ascending";
        const sortAriaLabel = `Sort by ${field} ${
          isSortActive ? sortDirectionStyle : "ascending"
        }`;

        return (
          <div
            className={`${tableStyles.columnHeader} ${tableStyles.headerCell} ${tableStyles.filterSelectorContainer}`}
            style={{ borderRight: borderStyle, minWidth: minWidthStyle }}>
            {headerName}
            <button
              className={tableStyles.filterButton}
              style={{ opacity: filterButtonOpacity }}
              onClick={() => !openStates[index] && onFilterClick(index)}>
              <img alt="" src={`${process.env.PUBLIC_URL}/Filters lines.svg`} />
            </button>
            <button
              onClick={() => handleSort(field)}
              className={tableStyles.sortButton}
              style={{
                opacity: sortButtonOpacity,
                background: "none",
                border: "none",
                cursor: "pointer",
              }}
              aria-label={sortAriaLabel}>
              {sortDirection}
            </button>
          </div>
        );
      },
      renderCell: (row) => {
        if (renderCell === "Badge") {
          return (
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <Badge
                text={capitalizeWords(row["data_type"])}
                color={"#B54708"}
                bgColor={"#FFFAEB"}
                hasBullet={false}
              />
            </div>
          );
        } else {
          return <div className={tableStyles.cell}>{row[field]}</div>;
        }
      },
    })
  );

  const flexDivRef = useRef(null);

  // TODO: filters
  const alertOverlapFilters = {
    Segmentation: {
      title: "Segmentation",
      filterTypes: [...new Set(rows.map((row) => row["Segmentation"]))],
      setFilterFunction: () => {},
      selected_options: [],
      setSelectedOptions: () => {},
    },
    "Credit Month": {
      title: "Credit Month",
      filterTypes: [...new Set(rows.map((row) => row["Credit Month"]))],
      setFilterFunction: () => {},
      selected_options: [],
      setSelectedOptions: () => {},
    },
    "Credit Amount": {
      title: "Credit Amount",
      filterTypes: [...new Set(rows.map((row) => row["Credit Amount"]))],
      setFilterFunction: () => {},
      selected_options: [],
      setSelectedOptions: () => {},
    },
  };

  const handleOptionSelect = (selector, option) => {
    console.log("selector: ", selector);
    console.log("option:dsdsdsdsdsdsds ", option, dataLabelConditionProps);

    if (selector === "type") {
      const typebadge = [
        {
          type: "badge",
          text: option.name,
          color: "#b54708",
          bgColor: "#fffaeb",
          hasBullet: false,
        },
      ];

      setEditedType(typebadge);
    }
  };

  const handleOptionSelectModal = (selector, option) => {
    console.log("selector: ", selector);
    console.log("option:dsdsdsdsdsdsds ", option, dataLabelConditionProps);

    if (selector === "type") {
      const typebadge = [
        {
          type: "badge",
          text: option.name,
          color: "#b54708",
          bgColor: "#fffaeb",
          hasBullet: false,
        },
      ];

      setNewParameterColumnListType(typebadge);
    }
  };

  const handleInputChange = (field, value) => {
    console.log("field: ", field);
    console.log("value: ", value);

    if (field === "name") {
      setEditedName(value);
    } else if (field === "type") {
      setEditedType(value);
    } else if (field === "description") {
      setEditedDescription(value);
    }
  };

  const modalIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none">
      <path
        d="M6 22C7.66 22 9 20.66 9 19c0-1.66-1.34-3-3-3s-3 1.34-3 3c0 1.66 1.34 3 3 3Z"
        stroke="#905EE2"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 19h8.5c.93 0 1.82-.37 2.48-1.03.66-.66 1.02-1.55 1.02-2.47 0-.93-.37-1.82-1.03-2.48-.66-.66-1.55-1.03-2.47-1.03H6.5c-.93 0-1.82-.37-2.47-1.03C3.37 9.82 3 8.93 3 8c0-.93.37-1.82 1.03-2.48C4.68 5.37 5.57 5 6.5 5H15"
        stroke="#905EE2"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 8c1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3 1.34 3 3 3Z"
        stroke="#905EE2"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const modalsButtons = [
    <SecondaryButton
      style={{ flex: 0.5 }}
      key="cancelButton"
      content="Cancel"
      action={() => handleColumnCreateCancel()}
    />,
    <PrimaryButton
      style={{ flex: 0.5 }}
      key="AddButton"
      content="Create"
      disabled={!canCreate}
      action={() => handleColumnCreate()}
    />,
  ];

  const modalContent = (
    <>
      <InputComponent
        type="normal"
        label="Name"
        value={newParameterColumnListName}
        onChange={(e) => {
          setNewParameterColumnListName(e.target.value);
        }}
      />
      <Selector
        color={"#B54708"}
        bgColor={"#FFFAEB"}
        options={selectionsOptions}
        selectedOption={newParameterColumnListType}
        onOptionSelect={(option) => handleOptionSelectModal("type", option)}
        label={""}
        width={"100%"}
        height={"45px"}
      />
      <InputComponent
        type="comment"
        label="Description"
        value={newParameterColumnListDesc}
        onChange={(e) => {
          setNewParameterColumnListDesc(e.target.value);
        }}
      />
    </>
  );

  return (
    <div className={styles.container}>
      <ModalPopUp
        isOpen={isAddingRow}
        handleClose={() => setIsAddingRow(false)}
        title={"Create new Parameter Column"}
        content={modalContent}
        inlineTitle={true}
        actionButtons={modalsButtons}
        buttonsAlign={"right"}
        icon={modalIcon}
      />
      <div
        className={styles.contentWrapper}
        style={{ flexDirection: "column", padding: "0px", gap: "0px" }}>
        <div className={styles.header}>
          <div
            style={{
              width: 651,
              height: 40,
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 24,
              display: "inline-flex",
            }}>
            <RoundBackButton action={() => dispatch(setShowEdit(false))} />
            <button
              style={{
                width: 40,
                height: 40,
                padding: 4,
                opacity: 0.5,
                background: "#D9D6FE",
                borderRadius: "50%",
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "none",
                cursor: "pointer",
              }}
              onClick={onAddClick}
              aria-label="Add">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none">
                <path
                  d="M5 12H19"
                  stroke="#7A5AF8"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 5V19"
                  stroke="#7A5AF8"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>

          <div className={styles.searchBarWrapper}>
            <div className={styles.searchBarBox}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none">
                <path
                  d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                  stroke="#BDB4FE"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <input
                type="text"
                placeholder="Search"
                className={styles.searchText}></input>
            </div>
          </div>
        </div>

        <div id="table" className={tableStyles.optimisationTable}>
          <div style={{ height: "100%" }}>
            <Scrollbar
              style={{
                marginTop: "15px",
                transition: "min-height 0.3s ease",
                minHeight: "200px",
                height: "400px",
                overflowY: "hidden",
              }}
              trackYProps={{
                style: {
                  zIndex: "100",
                  bottom: "0px",
                  right: "5px",
                  width: "5px",
                  backgroundColor: "#E9D7FE",
                },
              }}
              thumbYProps={{ style: { backgroundColor: "#D6BBFB" } }}
              trackXProps={{ style: { height: "0px" } }}
              wrapperProps={{
                renderer: (props) => {
                  const { elementRef, ...restProps } = props;
                  return (
                    <span
                      {...restProps}
                      ref={elementRef}
                      style={{
                        marginBottom: "-5px",
                        position: "absolute",
                        inset: "0px 0px 5px 0px",
                        overflow: "hidden",
                      }}
                    />
                  );
                },
              }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  height: "0px",
                }}
                ref={flexDivRef}>
                {columnsConfig.map(
                  (
                    { field, headerName, minWidth, maxWidth, setOpen },
                    index
                  ) => {
                    const isSortActive = sortConfig.key === field;
                    const nextSortDirection =
                      isSortActive && sortConfig.direction === "ascending"
                        ? "descending"
                        : "ascending";
                    const sortArrow =
                      sortConfig.direction === "ascending" ? "↑" : "↓";
                    const sortIcon = isSortActive ? sortArrow : "↑";
                    let leftFieldAlignement;
                    if (field === "Alert ID") {
                      leftFieldAlignement = "0px";
                    } else if (field === "Status") {
                      leftFieldAlignement = "-60px";
                    } else {
                      leftFieldAlignement = "-30px";
                    }

                    return (
                      <div
                        key={field}
                        className={`${tableStyles.columnHeader} ${tableStyles.headerCell} ${tableStyles.filterSelectorContainer}`}
                        style={{
                          display: "inline-flex",
                          textWrap: "nowrap",
                          height: 0,
                          left: 0,
                          position:
                            headerName === "Transaction ID"
                              ? "sticky"
                              : "relative",
                          color: "transparent",
                          backgroundColor: "transparent",
                          padding: "12px 24px",
                          minWidth,
                          zIndex: 11,
                        }}>
                        {headerName}
                        <button
                          className={tableStyles.filterButton}
                          style={{ opacity: 0 }}
                          onClick={(e) =>
                            !openStates[index]
                              ? onFilterClick(openStates[index])
                              : ""
                          }>
                          <img
                            alt=""
                            src={`${process.env.PUBLIC_URL}/Filters lines.svg`}
                          />
                        </button>
                        <button
                          onClick={() => handleSort(field)}
                          className={tableStyles.sortButton}
                          style={{
                            opacity: 0,
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                          }}
                          aria-label={`Sort by ${field} ${nextSortDirection}`}>
                          {sortIcon}
                        </button>

                        {openStates[index] && (
                          <div
                            style={{
                              display: "inline",
                              position: "absolute",
                              left: leftFieldAlignement,
                              top: "60px",
                              zIndex: index === 0 ? 11 : "auto",
                            }}>
                            <FilterSelector
                              all_options={
                                alertOverlapFilters[headerName].filterTypes
                              }
                              applyFunction={
                                alertOverlapFilters[headerName]
                                  .setFilterFunction
                              }
                              title={alertOverlapFilters[headerName].title}
                              selected_options={
                                alertOverlapFilters[headerName].selected_options
                              }
                              setSelectedOptions={
                                alertOverlapFilters[headerName]
                                  .setSelectedOptions
                              }
                              isFilterOpen={true}
                              applyIsFilterOpenFunction={() =>
                                onFilterClick(index)
                              }
                              overwriteClass={tableStyles.filterSelector} // Apply the class for custom positioning
                            />
                          </div>
                        )}
                      </div>
                    );
                  }
                )}
              </div>

              <table ref={scrollWrapperRef} className={tableStyles.table}>
                <thead style={{ position: "sticky", top: "0" }}>
                  <tr style={{ backgroundColor: "#fafaff" }}>
                    {allColumns.map((col) => (
                      <th
                        key={col.field}
                        style={{
                          padding: "none",
                          minWidth: col.minWidth,
                          width: "33%",
                        }}>
                        {col.renderHeader()}
                      </th>
                    ))}
                  </tr>
                  {selectedRow && (
                    <tr style={{ backgroundColor: "#F4EBFF" }}>
                      <th>
                        <div className={tableStyles.inputWrapper}>
                          <input
                            type="text"
                            value={editedName || selectedRow["name"]}
                            onChange={(e) =>
                              handleInputChange("name", e.target.value)
                            }
                          />
                        </div>
                      </th>
                      <th>
                        <Selector
                          color={"#B54708"}
                          bgColor={"#FFFAEB"}
                          options={selectionsOptions}
                          selectedOption={editedType || dataLabelConditionProps}
                          onOptionSelect={(option) =>
                            handleOptionSelect("type", option)
                          }
                          label={""}
                          width={"100%"}
                          height={"45px"}
                        />
                      </th>
                      <th>
                        <div className={tableStyles.inputWrapper}>
                          <input
                            type="text"
                            value={
                              editedDescription || selectedRow["description"]
                            }
                            onChange={(e) =>
                              handleInputChange("description", e.target.value)
                            }
                          />
                        </div>
                      </th>
                    </tr>
                  )}
                </thead>

                <tbody>
                  {sortedRows.map((row, index) => (
                    <tr
                      key={"editRow" + index}
                      className={`
                                                ${tableStyles.row}
                                                ${tableStyles.normalRow}`}
                      style={{
                        backgroundColor: selectedRow === row ? "#F4EBFF" : "",
                      }}
                      onClick={() => {
                        handleRowClick(row);
                      }}>
                      {allColumns.map((col) => (
                        <td
                          key={col.field}
                          className={`${
                            col.field === "Alert ID" ? styles.fixedColumn : ""
                          }`}>
                          <div
                            className={`${
                              col.field === "Alert ID" ? styles.cellfixed : ""
                            }`}>
                            {col.renderCell(row)}
                          </div>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Scrollbar>
          </div>
        </div>
      </div>
      {selectedRow && (
        <div className={styles.footer}>
          <SecondaryButton
            content="Cancel"
            action={() => setSelectedRow(null)}
          />
          <PrimaryButton content="Save" action={() => handleSaveClick()} />
        </div>
      )}
    </div>
  );
};

export default Overview;
