// components/atl_tool/AtlTool.js

import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  fetchOptimisationTable,
   
  fetchScenarios,
  fetchThresholds, 
  fetchSegments,
  fetchRiskScores,
  setSelectedScenario,
  setSelectedSegmentOverview, 
} from '../../features/atl_tool/atlToolSlice';
import styles from './AtlTool.module.css';
import ScenarioSelection from "./scenario_selection/ScenarioSelection";
import SegmentOverview from './segment_overview/SegmentOverview';
import Visualisation from './visualisation/Visualisation';
import AddNewVisualisationButton from './visualisation/AddNewVisualisationButton'
import AlertsTable from "./alert_table/AlertsTable"

const AtlTool = () => {
  const dispatch = useDispatch();

  const {segments, selectedYearState,  selectedScenario, selectedSegment, visualisations, selectedRiskScore, selectedSegmentOverview, reloadOptimisationTable, optimisationTable ,alertsTable, selectedSubScenario} = useSelector((state) => state.atlTool);

  // this fixes a bug where reloading the page after a risk score was selected would break the page 
  if (selectedSegmentOverview > -1 && !selectedRiskScore) {
    dispatch(setSelectedSegmentOverview(-1));
  }

  const prevScenarioRef = useRef();
  const prevSegmentRef = useRef();
  const prevRiskScoreRef = useRef();
  const prevSegmentOverviewRef = useRef();
  const prevSubScenarioRef = useRef();

  const getTotalAlerts = () => {
    let totalAlerts = 0;

    if (selectedRiskScore){
      totalAlerts = selectedRiskScore.non_relevant_alerts + selectedRiskScore.relevant_alerts +  selectedRiskScore.sar_alerts ; 
    }
    
    return totalAlerts ;
  }

  useEffect(() => {
    dispatch(fetchScenarios(selectedYearState));
  }, []);

  useEffect(() => {
    const prevScenario = prevScenarioRef.current;
    const prevSegment = prevSegmentRef.current;
    const prevSubScenario = prevSubScenarioRef.current;
  
    prevScenarioRef.current = selectedScenario;
    prevSegmentRef.current = selectedSegment;
    prevRiskScoreRef.current = selectedRiskScore;
    prevSegmentOverviewRef.current = selectedSegmentOverview;
    prevSubScenarioRef.current = selectedSubScenario;
  
    if (selectedScenario && (prevScenario !== selectedScenario || prevSubScenario !== selectedSubScenario) && selectedScenario.subscenarios?.find(subscenario => subscenario.id === selectedSubScenario?.id)) {
      //dispatch(fetchSegments({ selectedScenarioId: selectedScenario.id, selectedSubScenario, selectedYearState:selectedYearState.id}));
      dispatch(fetchThresholds({ selectedScenario, selectedSubScenario }));
    }

    if (selectedSegment && (prevSegment !== selectedSegment || prevScenario !== selectedScenario || prevSubScenario !== selectedSubScenario)) {
      dispatch(fetchRiskScores({ selectedScenarioId: selectedScenario?.id, selectedSegmentId: selectedSegment.id, selectedSubScenario,selectedYearState: selectedYearState.id }));
    }
  
    if (selectedRiskScore && selectedScenario && selectedSegment) {
      dispatch(fetchOptimisationTable({ selectedScenario, selectedSegment, selectedRiskScore, selectedSubScenario, selectedYearState:selectedYearState.id }));
    }
  }, [selectedScenario, selectedSegment, selectedRiskScore, selectedSegmentOverview, selectedSubScenario, reloadOptimisationTable]);

  const handleScenarioChange = (scenario) => {
    dispatch(setSelectedScenario(scenario));
  };

  return (
    <div className={styles.atlTool}>
      <div className={styles.sectionWrapper}>
        <ScenarioSelection onScenarioChange={handleScenarioChange} />
      </div>
      {selectedScenario && selectedSegment && (
        <>
          <div className={styles.sectionWrapper} style={{ paddingBottom:"0px" , borderRadius:"12px"}}>
            <SegmentOverview />
          </div>
          {selectedSegmentOverview > -1 && selectedRiskScore !== null && (
            <>
              {visualisations.map((visualisation) => (
                <div className={styles.visualisationSectionWrapper}>
                  <Visualisation id={visualisation.key}/>
                </div>
              ))}

              {getTotalAlerts() > 0 && optimisationTable && (
                <AddNewVisualisationButton/>)}

                {alertsTable && ( <>
                <div className={styles.sectionWrapper}>
                  <AlertsTable/>
                  </div></>)}

            </>
          )}
        </>
      )}
    </div>
  );
};

export default AtlTool;
